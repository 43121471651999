import Vue from "vue";
import {
  SEARCH_TIKTOK_USER_ACTION,
  UPDATE_TIKTOK_USER_INFO,
  REFRESH_CUREENT_TIKTOK_USER_INFO,
  GETTER_CURRENT_TIKTOK_USER_INFO,
  DELETE_TIKTOK_USER,
  GEETER_TIKTOK_USER_LIST,
  REFRESH_CURRENT_USER_TIKTOK_DATA,
  LOGIN_TIKTOK_USER_ACTION,
  TIKTOK_ADD_USER_RESOLVE,
  GEETER_TIKTOK_LOGIN_USER,
  DELETE_TIKTOK_LOGIN_USER
} from "@/store/constants/tt_user_types";
import {
  UPDATE_TIKTOK_MEDIA_LIST,
  REFRESH_CURRENT_USER_MEDIA_LIST
} from "@/store/constants/tt_media_types";
import {
  UPDATE_CURRENT_USER_ID,
  GETTER_CURRENT_USER_ID
} from "@/store/constants/index_types";
// eslint-disable-next-line
import { TikUserInfo } from "@/store/modules/tt_user_module";
import { loginSuccessHandle } from "@/services/user_helper/user_helper";
import {
  tt_login,
  tt_search_user,
  tt_logout
} from "@/api/tt-api/tt_api_user/tt_api_user";
import { tt_error_toast } from "@/utils/toast_helpers/tt_toast_helpers";
import {
  act_users_action,
  act_users_mutation
} from "@/services/earn_coins/observer/act_observer";
import Loading from "@/plugins/toast/loading";

type getUserInfoFn = (
  value: any,
  login_type?: "login" | "search"
) => TikUserInfo;

const getNewUserInfo: getUserInfoFn = (
  value: any,
  login_type: "login" | "search" = "search"
) => {
  const { stats, user } = value;
  return {
    user_id: user.id,
    login_type,
    tt_user_info: {
      profile_pic_url: user.avatarMedium,
      avatar_thumb: user.avatarThumb,
      avatar_larger: user.avatarLarger,
      nickname: user.nickname,
      uniqueId: user.uniqueId,
      follower_count: stats.followerCount,
      following_count: stats.followingCount,
      video_count: stats.videoCount,
      secret: user.privateAccount
    }
  };
};

export default {
  async [SEARCH_TIKTOK_USER_ACTION]({ dispatch }: any, payLoad: any) {
    try {
      console.log("SEARCH_TIKTOK_USER_ACTION", payLoad);
      const { uniqueId, showLoading, login_type, from, user_id } = payLoad;
      // show loading
      showLoading && Loading.show();

      // search
      const { response } = await tt_search_user(uniqueId, undefined, user_id);

      dispatch(TIKTOK_ADD_USER_RESOLVE, {
        userInfo: response,
        showLoading,
        login_type,
        from
      });

      Loading.hide();

      return response;
    } catch (error) {
      // error toast
      tt_error_toast(error);
      return Promise.reject(error);
    }
  },
  async [LOGIN_TIKTOK_USER_ACTION]({ dispatch }: any, payload: any) {
    try {
      Loading.show();
      const { response } = await tt_login();
      dispatch(TIKTOK_ADD_USER_RESOLVE, {
        userInfo: response,
        login_type: "login",
        showLoading: true,
        from: payload
      });
    } catch (error) {
      return Promise.reject(error);
    } finally {
      Loading.hide();
    }
  },
  async [TIKTOK_ADD_USER_RESOLVE]({ commit }: any, payload: any) {
    try {
      const { userInfo, login_type, showLoading, from } = payload;
      const newUserInfo: TikUserInfo = getNewUserInfo(userInfo, login_type);
      const user_id = userInfo.user.id;
      await commit(UPDATE_TIKTOK_USER_INFO, {
        userInfo: newUserInfo,
        login_type
      });
      await commit(UPDATE_CURRENT_USER_ID, user_id);
      // 增加act users list:
      if (login_type === "login") {
        act_users_action.act_add_new_users_action(user_id);
      }
      // 需要loading,retry表示新增，需要考虑登录后的操作
      // showLoading && loginSuccessHandle(from);
      loginSuccessHandle(from);
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async [REFRESH_CUREENT_TIKTOK_USER_INFO](
    { dispatch, getters }: any,
    payLoad: boolean = false
  ) {
    try {
      const currentUsrInfo: any = getters[GETTER_CURRENT_TIKTOK_USER_INFO];
      if (!currentUsrInfo) {
        return true;
      }
      console.log("currentUsrInfo", currentUsrInfo);

      await dispatch(SEARCH_TIKTOK_USER_ACTION, {
        uniqueId: currentUsrInfo.tt_user_info.uniqueId,
        showLoading: false,
        newTab: payLoad,
        login_type: currentUsrInfo.login_type,
        user_id: currentUsrInfo.user_id
      });
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async [DELETE_TIKTOK_USER]({ commit, getters }: any, payLoad: TikUserInfo) {
    try {
      if (payLoad.login_type === "login") {
        await tt_logout();
        // reset current act user
        act_users_mutation.clear_current_act_user();
      }
      await commit(UPDATE_TIKTOK_USER_INFO, {
        userInfo: payLoad,
        isDelete: true
      });
      await commit(UPDATE_TIKTOK_MEDIA_LIST, {
        tikMediaInfoList: {
          user_id: payLoad.user_id
        },
        upsateStatus: "delete"
      });
      //如果删除的是当前用户， 更新当前的用户id;
      const currentUserId = getters[GETTER_CURRENT_USER_ID];
      if (currentUserId === payLoad.user_id) {
        const tiktokUserList = getters[GEETER_TIKTOK_USER_LIST];
        const newId =
          tiktokUserList.length > 0 ? tiktokUserList[0].user_id : "";
        commit(UPDATE_CURRENT_USER_ID, newId);
      }

      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async [DELETE_TIKTOK_LOGIN_USER]({ dispatch, getters }: any) {
    try {
      const loginUserInfo = getters[GEETER_TIKTOK_LOGIN_USER];
      loginUserInfo && (await dispatch(DELETE_TIKTOK_USER, loginUserInfo));
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async [REFRESH_CURRENT_USER_TIKTOK_DATA]({ dispatch }: any) {
    try {
      await dispatch(REFRESH_CUREENT_TIKTOK_USER_INFO, false); // 如果隐私账号不能用user detail接口，此处的false 要改成true
      await dispatch(REFRESH_CURRENT_USER_MEDIA_LIST);
      return true;
    } catch (error) {
      return Promise.reject(error);
    }
  }
};
