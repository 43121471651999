


























import { Component, Vue, Prop } from "vue-property-decorator";
import * as types from "@/store/constants/setting_types";
import { Getter } from "vuex-class";

@Component
export default class LuckyCoinsFeed extends Vue {
  @Prop()
  private offer: any;
  @Getter(types.GETTER_TAB_COIN_STORE_SETTINGS)
  private tabCoinStore: any;

  get luckyCoinOffer() {
    return this.tabCoinStore.lucky_coin_offers[0];
  }
  get lucky_effect() {
    return this.luckyCoinOffer && this.luckyCoinOffer.lucky_effect;
  }
  get min() {
    return this.lucky_effect && this.lucky_effect.min
      ? this.lucky_effect.min
      : 250;
  }
  get max() {
    return this.lucky_effect && this.lucky_effect.max
      ? this.lucky_effect.max
      : 1000000;
  }

  private buyCoinFeed() {
    this.$emit("buyCoinFeed");
  }
}
