





















import { Component, Vue, Watch } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import {
  GETTER_CLIENT_SETTINGS,
  UPDATE_SETTING_DATA,
  GETTER_EARN_COINS_LOGIN_NONEED_ENABLED,
  GETTER_ONLINE_SETTING_STATE
} from "@/store/constants/setting_types";
import { GETTER_COINS } from "@/store/constants/cg_user_types";
import * as cgConst from "@/api/cg/base/cg_const";
import {
  TIKTOK_LOGIN_USER_EMPTY,
  GETTER_TIKTOK_USER_EMPTY,
  GEETER_TIKTOK_LOGIN_USER,
  LOGIN_TIKTOK_USER_ACTION
} from "@/store/constants/tt_user_types";
import NavBar from "@/components/NavBar/NavBar.vue";
import SelectUser from "@/components/SelectUser/SelectUser.vue";
import Pool from "./Pool/Pool.vue";
import ButtonGroup from "./ButtonGroup/ButtonGroup.vue";
import { goToCoinStore, goToSearchOrLogin } from "@/utils/router_helper";
import { feeds_getters } from "@/services/earn_coins/observer/feeds_observer";
import {
  skip_feeds,
  skip_feeds_by_purchased,
  act_feed,
  get_feeds,
  standby_feeds
} from "@/services/earn_coins/controller/feeds_controller";
import {
  act_users_getters,
  act_users_mutation
} from "@/services/earn_coins/observer/act_observer";
import EventBus from "@/utils/event_bus";
import { earn_spacing_mutation } from "@/services/earn_coins/observer/spacing_observer";
import { earn_spacing_getters } from "@/services/earn_coins/observer/spacing_observer";
import { loginRequireDialog } from "@/services/dialog_utils/index";
import { skip_delay_getters } from "@/services/earn_coins/observer/skip_delay_observer";
import { findRouteIndex, getCurrentRoute } from "@/plugins/navigation/routes";
import { getNotification } from "@/utils/js_bridge_info";
import UmengPush from "@/services/umeng_push/umeng_push";
import generator from "@/services/open_pop_up/open_generator";
import GuidUtils from "@/utils/guide_utile/guide_utile";

Component.registerHooks(["beforeRouteEnter"]);
@Component({
  components: { NavBar, SelectUser, Pool, ButtonGroup }
})
export default class EarnCoin extends Vue {
  @Getter(GETTER_COINS)
  private userCoins: any;
  @Getter(GETTER_EARN_COINS_LOGIN_NONEED_ENABLED)
  private loginoneed: any;
  @Getter(GETTER_CLIENT_SETTINGS)
  private clientSetting: any;
  @Getter(TIKTOK_LOGIN_USER_EMPTY)
  private isTiktokLoginEmpty?: boolean;
  @Getter(GETTER_TIKTOK_USER_EMPTY)
  private isTiktokUsersEmpty?: boolean;
  @Getter(GEETER_TIKTOK_LOGIN_USER)
  private tiktokLoginUser: any;
  @Getter(GETTER_ONLINE_SETTING_STATE)
  private getterOnlineSettingsState?: boolean;
  @Action(LOGIN_TIKTOK_USER_ACTION)
  private tiktokLogin: any;
  @Action(UPDATE_SETTING_DATA)
  private updateSettingData: any;

  /** user status sort */

  private feed_loading: boolean = false;
  private earn_limited: boolean = false;
  private showEarnCoinGuide = false;


  get feeds() {
    return feeds_getters.feeds();
  }

  get pull_status() {
    return feeds_getters.pull_status();
  }

  get feeds_index() {
    return feeds_getters.index();
  }

  get act_user() {
    return act_users_getters.act_user();
  }

  get is_skip_delay() {
    return skip_delay_getters.is_skip_delay();
  }

  get skip_delay_end_time_stamp() {
    return skip_delay_getters.end_limit_time_stamp();
  }

  get is_limited_act_user() {
    return this.act_user.is_act_limited;
  }

  get end_limit_time_stamp() {
    return this.act_user.end_limit_time_stamp;
  }

  get current_feed() {
    return this.feeds[this.feeds_index] || null;
  }

  get is_abnormal() {
    return act_users_getters.is_login_required();
  }

  get feed_loading_show() {
    return this.pull_status === "running" && this.current_feed === null;
  }

  get is_spacing_limited() {
    return earn_spacing_getters.is_limited();
  }

  get spacing_time_stamp() {
    return earn_spacing_getters.get_limit_time_stamp();
  }

  // 监听是否拉取过最新setting
  @Watch("getterOnlineSettingsState", { immediate: true })
  private onlineSettingStateChange(val: any) {
    // 拉到线上setting之后执行开机弹窗逻辑
    if (val) {
      setTimeout(() => {
        // 必须是在首页才执行开机弹窗逻辑
        const currentRoute = getCurrentRoute();
        const notShowOpenLogic = findRouteIndex("SuperPayReceive") > -1;
        if (
          currentRoute &&
          currentRoute.path &&
          currentRoute.path.includes("/Home")
        ) {
          getNotification()
            ? UmengPush.checkLaunchNotice()
            : !notShowOpenLogic
              ? generator.next()
              : void 0;
        }
      }, 300);
    }
  }

  private handle_coin_feed() {
    // 金币feed的点击:
    this.act_feed();
  }

  private close_earn_limited() {
    //
    console.log("close_earn_limited");
    act_users_mutation.update_act_user_map({
      is_act_limited: false,
      end_limit_time_stamp: 0
    });
  }

  private close_spacing_limited() {
    earn_spacing_mutation.reset_limited();
  }

  private click_select_user() {
    if (this.loginoneed) {
      // 非登录earn
      this.isTiktokUsersEmpty
        ? goToSearchOrLogin()
        : EventBus.$emit("show-account-list", {
          show: true,
          login: false
        });
    } else {
      // 登陆模式下点击不出account_list的弹窗
      this.isTiktokLoginEmpty ? this.tiktokLogin() : void 0;
    }
  }

  private click_change_account() {
    //
    loginRequireDialog("change_account");
  }

  private click_retry() {
    // 拉取更多:
    standby_feeds();
  }

  private clickCoinsIcon() {
    //
    goToCoinStore();
  }

  private skip_feed() {
    skip_feeds();
  }

  private act_feed() {
    act_feed(this.current_feed);
  }

  private click_ads_feed() {
    // 点击广告
    this.act_feed();
  }

  private click_more() {
    goToCoinStore();
  }

  // ======================== lifecycle =========================

  private beforeRouteEnter(to: any, from: any, next: any) {
    console.log("to", to, from);
    next((vm: any) => {
      console.log("from", from);
      if (from.name === "OrderDetail") {
        // need change current purchase feed
        skip_feeds_by_purchased(this.current_feed);
      }
    });
  }

  private created() {
    this.updateSettingData(cgConst.TAB_EARN_COINS_KEYS).catch(() => void 0);

    this.showEarnCoinGuide = GuidUtils.getGuide();
  }

  private mounted() {
    get_feeds();

    EventBus.$on("show_earn_coin_guide", (val: boolean) => {
      this.showEarnCoinGuide = val;
    });
  }

  private activated() {
    //
  }
}
