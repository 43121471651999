











import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { canUseWebP } from "@/utils/lodash";
import { is_expired_url } from "@/api/tt-api/tt_api_helpers/tt_api_helpers";
import { tt_item_detail } from "@/api/tt-api/tt_api_media/tt_api_media";
import { single_post_clean } from "@/store/actions/tt_media_actions";
import VideoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
import ImgFetched from "@/components/ImgFetched/ImgFetched.vue";
@Component({
  components: {
    ImgFetched,
    VideoPlayer
  }
})
export default class PostFeed extends Vue {
  @Prop()
  private resource: any;
  @Prop()
  private showGuide: any;

  private can_user_webp: boolean = canUseWebP();
  private imgAdaptedHeight: string = "auto";


  private post_image_src = "";
  private post_image_show = false;
  private video_src = "";

  get placeHolder() {
    return require("@/assets/icons-file/earn_coin/earn_coins_post_placeholder.png");
  }

  get userName() {
    if (this.resource && this.resource.username) {
      return this.resource.username;
    } else {
      return "";
    }
  }

  /**
   *  tt_video :1.Referer:https://www.tiktok.com/ 2. Cookie
   */
  get postImageSrc() {
    return this.can_user_webp
      ? this.resource &&
      (this.resource.image_high ||
        this.resource.image_low ||
        this.resource.image_thumb)
      : this.resource && this.resource.image_thumb;
  }

  get postMediaId() {
    return this.resource && this.resource.media_id;
  }

  private async get_new_image_src() {
    try {
      // TODO 可能存在请求过慢，组件刚好没有销毁掉，返回值覆盖了上次的
      const post_details = await tt_item_detail(this.postMediaId);
      console.log("post_details:", post_details);
      const { response } = post_details;
      if (response) {
        // reponse返回可能没有值
        const { itemStruct } = response;
        const { image_high, display_url } = single_post_clean(itemStruct);
        this.post_image_src = image_high;
        this.post_image_show = true;
        this.video_src = display_url;
      }
    } catch (error) {
      //
      console.log("post_details error:", error);
    }
  }

  @Watch("postImageSrc", { immediate: true })
  private postImageSrcChange(val: string, oldVal: string) {
    if (val !== oldVal) {
      console.log("postImageSrcChange:", {
        val,
        oldVal
      });
      // 判断链接是否已经过期:
      const isExpiredUrl = is_expired_url(val);

      if (isExpiredUrl) {
        this.post_image_show = false;
        this.get_new_image_src();
      } else {
        // 没有过期可以直接使用
        this.post_image_src = this.postImageSrc;
        this.post_image_show = true;
      }
      console.log("判断链接是否已经过期:", isExpiredUrl);
    }
  }

  @Watch("showGuide")
  private showGuideChange() {
    this.calcPostHeight();
  }

  private async calcPostHeight() {
    await this.$nextTick();
    const imgDom: any = this.$refs.img;

    let radio = this.showGuide ? 0.84 : 1;
    const { width, height } = window.screen;
    if (width === 412 && height === 732) {
      radio = 0.7 * radio;
    }
    const imgWidth =
      Number.parseFloat(getComputedStyle(imgDom)["width"] as any) * radio;

    this.imgAdaptedHeight = `${(imgWidth * 379) / 213}px`;
  }

  private async mounted() {
    this.calcPostHeight();
  }
}
