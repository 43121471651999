// 获取hot app列表调用
import * as cgConst from "@/api/cg/base/cg_const";
import cgRequest from "@/api/cg/base";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import * as helper from "./helper";

let isNeedConnect = true;
const handleADList = (list: any) => {
  const last_ad_list: any = [];
  let first_show_timestamp = 0;
  for (let i = list.length - 1; i >= 0; i--) {
    if (list[i].timestamp) {
      first_show_timestamp = list[i].timestamp;
    }
    last_ad_list.push(list[i].adId);
  }
  last_ad_list.reverse();
  nativeStorage.set("LAST_AD_LIST_AND_TIME_STAMP", {
    last_ad_list: JSON.stringify(last_ad_list),
    first_show_timestamp
  });
};

export const getRecommend = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      // 为了避免vue实例初始化时候connect失败，所以在每次getRecommend都等待connect一次请求
      if (isNeedConnect) {
        await connect();
        isNeedConnect = false;
      }
      const sdkVersion: string = helper.sdkVersion();
      const appId: string = helper.appId();
      const platform: number = helper.platform();
      const userId: string = helper.userId();
      const deviceId: string = helper.deviceId();
      let params = {
        sdkVersion,
        appId,
        platform,
        deviceId,
        userId
      };
      const adParams = await nativeStorage.get("LAST_AD_LIST_AND_TIME_STAMP");
      if (adParams) {
        params = {
          ...params,
          ...adParams
        };
      }
      const res: any = await cgRequest(cgConst.GET_RECOMMEND, "GET", params);
      console.log("getRecommend", res);
      resolve(res);
      res && handleADList(res);
    } catch (e) {
      console.log("getRecommend e", e);
      reject(e);
    }
  });
};

/**
 * 下载app调用
 * @param adId 下载app的campaignId
 */
export const recommendStart = async (adId: string, single: number = 0) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deviceId: string = helper.deviceId();
      const appId: string = helper.appId();
      const userId: string = helper.userId();
      const params = {
        adId,
        appId,
        deviceId,
        userId,
        // 必须转成字符串，因为原生在发送请求的时候会把int转成string
        single: single.toString()
      };
      const res: any = await cgRequest(cgConst.RECOMMEND_START, "POST", params);
      console.log("recommendStart", res);
      resolve(res);
    } catch (e) {
      console.log("recommendStart e", e);
      reject(e);
    }
  });
};

/**
 * recommendFinish
 * @param campaignId app的campaignId
 */
export const recommendFinish = async (campaignId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const deviceId: string = helper.deviceId();
      const appId: string = helper.appId();
      const userId: string = helper.userId();
      const params = {
        deviceId,
        campaignId,
        appId,
        userId
      };
      const res: any = await cgRequest(
        cgConst.RECOMMEND_FINISH,
        "POST",
        params
      );
      resolve(res);
    } catch (e) {
      reject(e);
    }
  });
};

export const connect = async () => {
  return new Promise(async (resolve, reject) => {
    try {
      const deviceId: string = helper.deviceId();
      const appId: string = helper.appId();
      const params = {
        appId,
        deviceId
      };
      const res: any = await cgRequest(cgConst.CONNECT, "POST", params);
      console.log("connect result", res);
      resolve(res);
    } catch (e) {
      //
    }
  });
};
