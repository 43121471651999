









import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
import "@/assets/svg-file/golden-followers/more_active.svg";
import "@/assets/svg-file/golden-followers/never_unfollower.svg";
import "@/assets/svg-file/golden-followers/top_notch.svg";
@Component({
  components: {}
})
export default class GoldenFollowersTip extends Vue {
  private list: any[] = [
    {
      icon: "more_active",
      text: "Never Unfollow"
    },
    {
      icon: "never_unfollower",
      text: "More Active"
    },
    {
      icon: "top_notch",
      text: "Top- Notch"
    }
  ];

  private clickAction() {
    this.$emit("click");
  }
}
