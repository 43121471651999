import Jsbridge from "@/jsbridge";
import { router, store } from "@/main";
import * as types from "@/store/constants/index_types";
import { getRoutesParams } from "@/plugins/navigation/routes";
import Vue from "vue";
import Loading from "@/plugins/toast/loading";
export const receiveOpenUrlMsg = () => {
  // 监听成功回调
  Jsbridge.addListen(
    "open_url",
    async (url: any) => {
      console.log("receiveOpenUrlMsg open_url", url);
      handleOpenUrl(url, "active");
      return true;
    },
    {
      key: "super_pay_receive"
    }
  );
};

// 处理open_url
export const handleOpenUrl = (
  url: any,
  from: string = "static",
  callback?: any
) => {
  try {
    Loading.hide();
    const params: any = {};
    const urls = url.split("jump?");
    const query = urls && urls[1];
    if (!query) {
      if (from === "static") {
        callback();
      }
      return;
    }
    const query_arr = query.split("&");
    for (const i of query_arr) {
      const a = i.split("=");
      params[a[0]] = decodeURIComponent(a[1]);
    }
    if (from === "static") {
      router.addRoutes([
        {
          path: "/",
          redirect: {
            name: "SuperPayReceive",
            query: {
              params: JSON.stringify(params),
              t: new Date().getTime().toString()
            }
          }
        }
      ]);
    } else {
      Vue.prototype.$_Dialog.hide();
      Vue.prototype.$_PopUpDialog.hide();
      const route = {
        name: "SuperPayReceive",
        query: {
          params: JSON.stringify(params),
          t: new Date().getTime().toString()
        }
      };
      if (router.currentRoute.name === "SuperPayReceive") {
        router.replace(route);
      } else {
        router.push(route);
      }
    }
  } catch (e) {
    console.log(e);
  }
};

export const defaultRedirect = () => {
  const currentId = store.getters[types.GETTER_CURRENT_USER_ID];
  if (currentId) {
    router.push({
      name: "Home"
    });
  } else {
    router.replace({
      name: "Splash"
    });
  }
};

// 处理支付成功之后的跳转， 如果是自动跳转，需要历史记录
export const handleSucessRouter = () => {
  const routes = getRoutesParams();
  console.log("routes", routes);
  if (routes.length === 1) {
    defaultRedirect();
  } else {
    console.log("routes[1]", routes[1]);
    if (routes[1] && routes[1].name) {
      router.go(-1);
    } else {
      defaultRedirect();
    }
  }
};
