import { store } from "@/main";
import {
  calcPriceAndEffect,
  getInfoFromOffer,
  getResourceFromOffer,
  Offer
} from "@/services/offer/offer_handle";
import { FeatureItem, PayWay } from "@/services/payment/types";
import * as cg_user_types from "@/store/constants/cg_user_types";
import * as types from "@/store/constants/index_types";
import * as setting_types from "@/store/constants/setting_types";
import {
  getAppPlatform,
  getBundleId,
  getDeviceId,
  getIDFA,
  getIDFV,
  getLang,
  getMachine,
  getNativeCodeVersion,
  getSystemVersion
} from "@/utils/js_bridge_info";
import { objectToQuery } from "./utils";
import SuperPayCache from "@/services/super_pay";
import SuperPayUtil from "@/services/super_pay";
import { removeNoSenseKeys } from "@/utils/lodash";
import { isSubsOffer } from "@/utils/general_utils";
import PaymentUtil from "@/services/payment/paymentCache";
import {
  judgeAppInstalled,
  getInstalledList
} from "@/jsbridge/service/commonMethod";
import { showRecommendDialog, showUpgradeApp } from "@/services/dialog_utils";
import { RedirectApp } from "@/services/open_pop_up/types";
import { App, Apps, AppsCategory } from "@/services/super_pay/types";
import paymentCall from "@/services/payment/paymentCall";
import AutoPaymentCache from "@/services/super_pay/auto_payment_cache";
import { router } from "@/main";
import forceState from "@/services/super_pay/forceState";

export const judgePaymentItemExist = (offer: Offer) => {
  // 获取配置中的paypal和stripe的有效性
  const paypal_enabled = store.getters[cg_user_types.GETTER_PAYPAL_ENABLE];
  const stripe_enabled = store.getters[cg_user_types.GETTER_CARD_ENABLE];
  const superpayParams = store.getters[setting_types.GETTER_SUPER_PAY_PARAMS];
  // pinad的superpay配置
  const super_pay: App = SuperPayCache.getAppsData(AppsCategory.super_pay);

  // 判断是否是订阅
  const isSub = isSubsOffer(offer);

  // 获取支付展示权限的配置
  const { paypal, stripe, superPay } = store.getters[
    setting_types.GETTER_FEATURES_ITEM_BY_FUNCTION
  ](offer);
  // stripe不允许买订阅
  const canShowStripe: boolean =
    !isSub && stripe_enabled && judgeMethodCanUse(stripe);
  const canShowPayPal: boolean = paypal_enabled && judgeMethodCanUse(paypal);
  const canShowSuperPay =
    offer &&
    super_pay &&
    super_pay.appScheme &&
    offer.pay_data &&
    offer.pay_data.super_pay &&
    superpayParams &&
    judgeMethodCanUse(superPay);

  return {
    canShowStripe,
    canShowPayPal,
    canShowSuperPay: !!canShowSuperPay
  };
};

export const assembleWebPayUrl = async (
  offer: Offer,
  pay_way: PayWay,
  defaultResource: any
): Promise<string> => {
  const user_coin = store.getters[cg_user_types.GETTER_COINS];
  const master_id = store.getters[types.GETTER_MASTER_USER_ID];
  // const resource = getResourceFromOffer(offer);
  const resource = defaultResource
    ? defaultResource
    : getResourceFromOffer(offer);
  const actual_effect = calcPriceAndEffect(offer, pay_way)
    .with_vip_reward_effect;

  const params: any = {
    payby: pay_way,
    pf: getAppPlatform(),
    av: getNativeCodeVersion(),
    ss: getBundleId(),
    oi: offer.offer_id,
    pc: offer.effect,
    ps: getInfoFromOffer(offer, pay_way),
    uc: user_coin,
    ak: "getz",
    mt: 0,
    user_id: master_id,
    master_id,
    uj: { user_id: master_id, master_id },
    tj: resource,
    pk: offer.kind,
    sv: getSystemVersion(),
    dm: getMachine(),
    lg: getLang(),
    cui: getDeviceId(),
    iv: getIDFV(),
    ia: getIDFA()
  };
  actual_effect && (params.ae = actual_effect);
  const url: string = store.getters[setting_types.GETTER_WP_URL];
  console.log("params", params);
  return `${url}?${objectToQuery(removeNoSenseKeys(params))}`;
};

export const getCurrentScheme = async (
  pay_way: PayWay
): Promise<string | undefined> => {
  try {
    let current_scheme: string | undefined;
    const appsData: Apps = SuperPayCache.getAppsData();
    const { super_pay, back_up, possible_apps } = appsData;
    const self_bundleId: string = getBundleId();
    console.log("self_bundleId", self_bundleId, super_pay);
    // 如果是ba，取数组的第一个， 如果是sp，直接取sp的app scheme
    if (pay_way === PayWay.back_up) {
      // 可以尝试掉起的app
      const allContemptApps: string[] = [...possible_apps];
      // 如果有back_up或者super_pay，反向注入
      back_up &&
        back_up.appScheme &&
        allContemptApps.unshift(back_up.appScheme);
      super_pay &&
        super_pay.appScheme &&
        allContemptApps.unshift(super_pay.appScheme);
      console.log("allContemptApps", allContemptApps);
      forceState.set("force");
      const installedApp = await getInstalledList();
      for (let item of allContemptApps) {
        if (item !== self_bundleId && installedApp.includes(item)) {
          current_scheme = item;
          break;
        }
      }
    } else {
      const installed: boolean = await judgeAppInstalled(super_pay.appScheme);
      current_scheme = installed ? super_pay.appScheme : "";
    }
    return current_scheme;
  } catch (e) {
    console.log("getCurrentScheme e", e);
    return "";
  }
};

// 判断某种类型是否达到可用限制
export const judgeMethodCanUse = (type: FeatureItem | undefined): boolean => {
  try {
    if (!type) {
      return false;
    }
    const { is_enabled, delay } = type;
    const paymentParams: any = PaymentUtil.getPaymentParams();
    const {
      app_launch_time,
      iap_purchase_time,
      paypal_purchase_time,
      card_purchase_time,
      first_enter_timestamp
    } = paymentParams;
    // 总支付次数
    const purchase_sum =
      iap_purchase_time + paypal_purchase_time + card_purchase_time;
    if (!is_enabled) {
      return false;
    }
    const or_open_app = type.event.find((event: any) => {
      return event.eventName === "or_open_app";
    });
    const open_app = type.event.find((event: any) => {
      return event.eventName === "open_app";
    });
    const pay_by_iap = type.event.find((event: any) => {
      return event.eventName === "pay_by_iap";
    });
    const pay_by_cash = type.event.find((event: any) => {
      return event.eventName === "pay_by_cash";
    });
    const delay_action =
      new Date().getTime() - first_enter_timestamp > delay * 1000;
    const judge_or_open_app =
      !or_open_app || (or_open_app && app_launch_time >= or_open_app.count);
    const judge_open_app =
      !open_app || (open_app && app_launch_time >= open_app.count);
    const judge_pay_by_iap =
      !pay_by_iap || (pay_by_iap && iap_purchase_time >= pay_by_iap.count);
    const judge_pay_by_cash =
      !pay_by_cash || (pay_by_cash && purchase_sum >= pay_by_cash.count);
    return (
      is_enabled &&
      delay_action &&
      (judge_or_open_app ||
        (judge_open_app && judge_pay_by_iap && judge_pay_by_cash))
    );
  } catch (e) {
    return false;
  }
};

// 处理sp ba bids都调不起来的情况
export const handleSpBaBidsUseless = (offer: Offer): void => {
  // 获取接口配置的super_pay
  const super_pay_remote = SuperPayUtil.getAppsData(AppsCategory.super_pay);
  // 获取Boss配置的super_pay
  const super_pay_native = store.getters[setting_types.GETTER_SUPER_PAY_PARAMS];
  const super_pay = { ...super_pay_remote, ...super_pay_native };
  // 获取导量app配置
  const redirect_app: RedirectApp =
    store.getters[setting_types.GETTER_REDIRECT_APP];

  /*
   *  1.如果有super_pay的配置，走super_pay的推荐
   *  2.不满足1，则判断是否有导量app，如果有，走导量，不需要管redirect_app.is_enabled
   *  3.不满足1，2，直接使用iap支付
   *  */
  if (super_pay_remote && super_pay_native) {
    showRecommendDialog({
      super_pay,
      place: "iap_pay",
      offer
    });
  } else if (redirect_app && redirect_app.app_url) {
    showUpgradeApp(redirect_app);
  } else {
    paymentCall.distributePaymentMethod({ offer, pay_way: PayWay.iap });
  }
};

export const redirectFullPageSp = (current_offer: any) => {
  console.log("redirectFullPageSp");
  const { offer, from } = AutoPaymentCache.get();
  // 关闭自动调起的支付，不需要再跳弹窗
  !offer
    ? router.push({
        name: "SuperPayPopup",
        query: {
          routerTransition: "up",
          from: "payment_choice",
          offer: current_offer
        }
      })
    : from
    ? AutoPaymentCache.set({})
    : void 0;
};

export const redirectDialogSp = (current_offer: any) => {
  const { offer, from } = AutoPaymentCache.get();
  console.log("redirectDialogSp from", from, offer);
  !offer
    ? handleSpBaBidsUseless(current_offer)
    : from
    ? AutoPaymentCache.set({})
    : void 0;
};
