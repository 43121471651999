/*
 * @Author: jhonsem
 * @Date: 2021-01-18 18:14:34
 * @Last Modified by: jhonsem
 * @Last Modified time: 2023-07-18 15:12:39
 * ios14，安卓4.2版本 支持webp
 */

import { TTApi, TTScriptResponse, IdItem } from "./types/tt_api_types";
import {
  inject_tt_api_script,
  create_callback_id,
  merge_config,
  isTTScriptError
} from "./tt_api_helpers/tt_api_helpers";
import { Vue } from "@/main";
import GuideUtile from "@/utils/guide_utile/guide_utile";
import EventBus from "@/utils/event_bus/index";
import FailedContent from "@/components/DlalogTsx/EarnCoins/FailedContent";

class TTWebAPI {
  private tt_window_api: any | TTApi;
  private cache_callback_ids: IdItem[];
  private __interceptors: any;
  public interceptors: any;

  static load_tt_script_status: "idle" | "running" | "loaded" | "failed" =
    "idle";
  static load_tt_script_promise: any;
  static tt_api_instance: any;

  static init_tt_web_api = async () => {
    try {
      if (TTWebAPI.load_tt_script_status === "failed") {
        return Promise.reject("loaded tt-script failed.");
      }

      if (TTWebAPI.load_tt_script_status === "loaded") {
        return "loaded";
      }

      if (TTWebAPI.load_tt_script_status === "running") {
        await TTWebAPI.load_tt_script_promise;
        console.log("wait finisth");
        return "loaded";
      }

      await TTWebAPI.load_tt_api_script();

      TTWebAPI.tt_api_instance = new TTWebAPI();

      return "loaded";
    } catch (error) {
      return Promise.reject(error);
    }
  };

  /**
   * 脚本注入
   */
  static load_tt_api_script = async () => {
    let resolve_fn: any;
    let reject_fn: any;
    TTWebAPI.load_tt_script_status = "running";

    TTWebAPI.load_tt_script_promise = new Promise((resolve, reject) => {
      resolve_fn = resolve;
      reject_fn = reject;
    });
    try {
      await inject_tt_api_script();
      TTWebAPI.load_tt_script_status = "loaded";

      resolve_fn();
    } catch (error) {
      // inject failed: 重试一次
      try {
        await inject_tt_api_script();
        TTWebAPI.load_tt_script_status = "loaded";
        resolve_fn();
      } catch (error) {
        TTWebAPI.load_tt_script_status = "failed";
        reject_fn();
        return Promise.reject(error);
      }
    }
  };

  constructor() {
    if (!window.TTWebAPI) {
      throw new Error("Inject tt-script failed,cannot find TTWebAPI.");
    }

    this.tt_window_api = window.TTWebAPI;
    // hack返回结果
    this.init_hack_tt_script_response();
    // 缓存ids
    this.cache_callback_ids = [];
    // 拦截器
    this.__interceptors = {
      response: [],
      request: []
    };
    // response interceptor
    this.interceptors = {
      response: {}
    };
    this.interceptors.response.use = (resolveFn: any, rejectFn: any) => {
      this.__interceptors.response.push({
        fulfilled: resolveFn,
        rejected: rejectFn
      });
    };
  }

  /**
   * 当返回含有type类型时，用于从脚本发过来的控制toast,loading等控制
   */
  private init_hack_tt_script_response() {
    window.__TTMsgHook__ = (res: TTScriptResponse) => {
      console.log("__TTMsgHook__", res);
      const { type, message, duration, title, notes, show } = res;
      if (type) {
        if (type === "show_loading") {
          Vue.prototype.$_Toast.show({
            text: "Processing...",
            type: "loading"
          });
        } else if (type === "hide_loading") {
          Vue.prototype.$_Toast.hide();
        } else if (type === "show_toast") {
          Vue.prototype.$_Toast.show({
            text: message,
            duration
          });
        } else if (type === "show_dialog") {
          Vue.prototype.$_Dialog.show({
            title,
            contentCustom(ctx: any, r: any) {
              return r(FailedContent, { props: { ctx, message, notes } });
            },
            closable: true,
            closeOnClickModal: true,
            confirmBtn: {
              text: "OK"
            },
            onConfirm(done: any) {
              done();
            }
          });
        } else if (type === "earn_coins_guide") {
          GuideUtile.changeGuide(!!show);
          EventBus.$emit("show_earn_coin_guide", !!show);
        }
      } else {
        this.fire_callback_id(res);
      }
    };
  }

  private exc_response_interceptors(
    find_cache_id: IdItem,
    tt_res: TTScriptResponse
  ) {
    const chain: any = [];
    const { data, error } = tt_res;
    let promise: any;

    this.__interceptors.response.forEach(function pushResponseInterceptors(
      interceptor: any
    ) {
      chain.push(interceptor.fulfilled, interceptor.rejected);
    });

    const { resolve, reject, config } = find_cache_id;
    if (isTTScriptError(tt_res)) {
      // 返回错误
      promise = Promise.reject({
        config,
        response: error
      });
    } else {
      // 成功
      promise = Promise.resolve({
        config,
        response: data
      });
    }

    while (chain.length) {
      promise = promise.then(chain.shift(), chain.shift());
    }

    promise.then(
      (result: any) => {
        console.log("result:", result);
        resolve(result);
      },
      (err: any) => {
        console.log("err:", err);
        reject(err);
      }
    );
  }

  private fire_callback_id(res: TTScriptResponse) {
    //
    const { callback_id } = res;

    const find_cache_id = this.cache_callback_ids.find((id: IdItem) => {
      return id.callback_id === callback_id;
    });

    if (find_cache_id) {
      this.exc_response_interceptors(find_cache_id, res);
    }
  }

  private create_push_callback_id(resolve: any, reject: any, config: any) {
    const callback_id = create_callback_id();

    this.cache_callback_ids.push({
      callback_id,
      resolve,
      reject,
      config
    });

    return callback_id;
  }

  /**
   *  tt登陆
   */
  public tt_login(config?: any) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_login"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.login(callback_id);
    });
  }

  /**
   * tt登出
   */
  public tt_logout(config?: any) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_logout"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.logout(callback_id);
    });
  }

  /**
   * 用户信息
   * @param unique_id
   * @param config
   * @param user_id
   */
  public tt_user_info(unique_id: string, config?: any, user_id?: string) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_user_detail"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      console.log("userDetail", user_id);
      this.tt_window_api.userDetail(unique_id, callback_id, user_id);
    });
  }

  /**
   * tt用户列表
   * @param unique_id
   * @param user_id
   * @param min_cursor
   * @param max_cursor
   */
  public tt_user_item_list(
    unique_id: string,
    user_id: string,
    min_cursor: string,
    max_cursor: string = "0",
    config?: any
  ) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_item_list"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.itemList(
        unique_id,
        user_id,
        min_cursor,
        max_cursor,
        callback_id
      );
    });
  }

  /**
   * 获取单个post的信息
   * @param video_id
   */
  public tt_item_detail(video_id: string, config?: any) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_post_detail"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.postItemDetail(video_id, callback_id);
    });
  }

  /**
   * 登陆模式下的follow用户
   * @param username
   */
  public tt_follow_logged_in(username: string, config?: any) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_commit_follow_user",
          api_type: "logged_in"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.commitFollowUser(username, callback_id);
    });
  }

  /**
   * 登陆模式下的like
   * @param username
   * @param media_id
   */
  public tt_like_logged_in(username: string, media_id: string, config?: any) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_commit_item_digg",
          api_type: "logged_in"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.commitItemDigg(username, media_id, callback_id);
    });
  }

  /**
   * 非登陆模式下的follow
   * @param username
   * @param logged_in_username
   * @param config
   * @returns
   */
  public tt_follow_without_login(
    username: string,
    logged_in_username: string,
    config?: any
  ) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_follow_without_login",
          api_type: "without_login"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.commitFollowUserWithoutLogin(
        username,
        logged_in_username,
        callback_id
      );
    });
  }

  /**
   * 非登陆模式下的like
   * @param username
   * @param logged_in_username
   * @param media_id
   * @param config
   * @returns
   */
  public tt_like_without_login(
    username: string,
    logged_in_username: string,
    media_id: string,
    config?: any
  ) {
    return new Promise((resolve, reject) => {
      // 创建config
      const new_config = merge_config(
        {
          api_name: "tt_api_like_without_login",
          api_type: "without_login"
        },
        config
      );

      const callback_id = this.create_push_callback_id(
        resolve,
        reject,
        new_config
      );
      this.tt_window_api.commitItemDiggWithoutLogin(
        username,
        logged_in_username,
        media_id,
        callback_id
      );
    });
  }
}

export default TTWebAPI;
