






















import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import { UPDATE_CURRENT_USER_ID } from "@/store/constants/index_types";
import Propup from "@/components/Propup/Propup.vue";
import NavBar from "@/components/NavBar/NavBar.vue";
import TiktokUserIner from "./user/TiktokUserIner.vue";
import AddAcount from "./user/AddAcount.vue";
import EventBus from "@/utils/event_bus/index";
import { goToSearchOrLogin } from "@/utils/router_helper";
@Component({
  components: { Propup, NavBar, TiktokUserIner, AddAcount }
})
export default class TiktokUserGallery extends Vue {
  @Mutation(UPDATE_CURRENT_USER_ID)
  private updataTiktokUserId: any;
  @Prop()
  private value?: boolean;
  @Prop()
  private showLoginUser?: boolean;
  @Prop()
  private from?: string;
  private popShow = false;

  @Watch("value", { immediate: true })
  private changePop(val: boolean) {
    this.popShow = val;
  }
  @Watch("popShow", { immediate: true })
  private change(val: boolean) {
    this.$emit("input", val);
  }

  private clolsePopup() {
    this.$emit("input", false);
  }

  private async clickItem(val: string) {
    await this.updataTiktokUserId(val);
    this.clolsePopup();

    // 有些地方需要知道切换了用户，切换后刷新当前用户的post信息:
    EventBus.$emit("changed_current_account", val);
  }

  private clickSearch() {
    console.log("clickSearch", this.from);
    goToSearchOrLogin(this.from);
    this.clolsePopup();
  }
}
