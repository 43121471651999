import Vue from "vue";
import App from "@/App.vue";
import router from "@/router";
import store from "@/store";

const js_frame_init = () =>
  new Promise(resolve => {
    new Vue({
      router,
      store,
      render: (h: any) => h(App),
      mounted() {
        this.$nextTick(() => {
          // DOM Ready!
          return resolve(this);
        });
      }
    }).$mount("#app");
  });

export default js_frame_init;
