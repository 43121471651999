import { ObservableEarnSpacing } from "../types/type";
import Vue from "vue";
import { store } from "@/main";
import { GETTER_CLIENT_SETTINGS } from "@/store/constants/setting_types";

const observable_earn_spacing: ObservableEarnSpacing = Vue.observable({
  act_count: 0,
  is_threshhold: false,
  is_limited: false,
  end_limit_time_stamp: 0
});

const earn_spacing_getters = {
  get_limit_config() {
    const { earn_limit } = store.getters[GETTER_CLIENT_SETTINGS];
    const { earn_feed_spacing_time, earn_feed_spacing_threshhold } = earn_limit;
    return {
      spacing_time: earn_feed_spacing_time,
      spacing_threshhold: earn_feed_spacing_threshhold
    };
  },
  get_limit_time_stamp() {
    return observable_earn_spacing.end_limit_time_stamp;
  },
  is_limited() {
    return observable_earn_spacing.is_limited;
  },
  is_threshhold() {
    return observable_earn_spacing.is_threshhold;
  }
};

const earn_spacing_mutation = {
  act_count_up() {
    //
    observable_earn_spacing.act_count += 1;
    return observable_earn_spacing.act_count;
  },
  start_spacing_time(spacing_time: number) {
    observable_earn_spacing.is_limited = true;
    observable_earn_spacing.end_limit_time_stamp =
      new Date().valueOf() + spacing_time * 1000;
  },
  reset_limited() {
    observable_earn_spacing.is_limited = false;
  }
};

const earn_spacing_actions = {
  act_trigger() {
    //
    const {
      spacing_time,
      spacing_threshhold
    } = earn_spacing_getters.get_limit_config();

    // 不需要限制
    if (spacing_time === 0) {
      return;
    }

    if (earn_spacing_getters.is_threshhold()) {
      // 已经触发了限制:
      earn_spacing_mutation.start_spacing_time(spacing_time);
    } else {
      // 没有触发限制:
      const act_count = earn_spacing_mutation.act_count_up();
      if (act_count >= spacing_threshhold) {
        observable_earn_spacing.is_threshhold = true;
        earn_spacing_mutation.start_spacing_time(spacing_time);
      }
    }
  }
};

export {
  observable_earn_spacing,
  earn_spacing_getters,
  earn_spacing_mutation,
  earn_spacing_actions
};
