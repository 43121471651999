import tt_api_base from "@/api/tt-api/tt_api_base/tt_api_base";

/**
 * tt的登陆
 */
export const tt_login = async () => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_login();
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

export const tt_logout = async () => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_logout();
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * 搜索&获取 用户信息
 * @param unique_id
 * @param config
 * @param user_id
 */
export const tt_search_user = async (
  unique_id: string,
  config?: any,
  user_id?: string
) => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_user_info(unique_id, config, user_id);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * follow username
 * @param username
 * @param config
 */
export const tt_follow_logged_in = async (username: string, config?: any) => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_follow_logged_in(username, config);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * 非登录下的follow
 * @param username
 * @param logged_in_username
 * @param config
 * @returns
 */
export const tt_follow_without_login = async (
  username: string,
  logged_in_username: string,
  config?: any
) => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_follow_without_login(
      username,
      logged_in_username,
      config
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * like username
 * @param username
 * @param media_id
 * @param config
 */
export const tt_like_logged_in = async (
  username: string,
  media_id: string,
  config?: any
) => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_like_logged_in(username, media_id, config);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * 非登陆下的like
 * @param username
 * @param logged_in_username
 * @param media_id
 * @param config
 * @returns
 */
export const tt_like_without_login = async (
  username: string,
  logged_in_username: string,
  media_id: string,
  config?: any
) => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_like_without_login(
      username,
      logged_in_username,
      media_id,
      config
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
