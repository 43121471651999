import * as cgConst from "@/api/cg/base/cg_const";
import { assembleAppInfo } from "@/api/cg/util/common_util";
import Jsbridge from "@/jsbridge/index";
import { objectToQuery } from "@/services/payment/utils";
import { removeNoSenseKeys } from "@/utils/lodash";
import cgRequest from "@/api/cg/base";

export const launchEvent = (name: string, value: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const app_info = assembleAppInfo();
      const params = {
        event_name: name,
        event_value: value,
        app_info
      };
      const url = cgConst.LAUNCH_EVENT.realm + cgConst.LAUNCH_EVENT.url;
      const request_url = `${url}?${objectToQuery(removeNoSenseKeys(params))}`;
      const result = await Jsbridge.http({
        url: request_url,
        method: "GET",
        headers: {
          "content-type": "application/x-www-form-urlencoded"
        },
        should_handle_cookies: false,
        timeout_interval: 15
      });
      // const result = await cgRequest(cgConst.LAUNCH_EVENT, "GET", params, {
      //   encryptHandle: false
      // });
      resolve(result);
    } catch (e) {
      reject(e);
    }
  });
};
