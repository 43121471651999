import { isJSON } from "@/utils/lodash";
/**
 * any type to string
 * @param data
 */

export const anyTypeToString = (data: any) => {
  const type = typeof data;
  if (type === "function") {
    return undefined;
  } else if (type === "string") {
    return data;
  } else if (type === "object") {
    return JSON.stringify(data);
  } else {
    return String(data);
  }
};

/**
 * string to object or string
 * @param data
 */
export const stringToData = (data: string) => {
  try {
    if (isJSON(data)) {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (e) {
    return data;
  }
};
