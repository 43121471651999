










import { Component, Prop, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class SkuButton extends Vue {
  @Prop({ default: "" })
  private btnStyle?: any;
}
