export const check_intersection_api = () => {
  return window.IntersectionObserver;
};

export const intersection_observer = (
  root_el: any,
  target_el: any,
  cb: Function
) => {
  if (check_intersection_api() && root_el && target_el) {
    const options = {
      root: root_el,
      rootMargin: "0px",
      threshold: 1.0
    };
    // cb
    const callback = (entries: any, observer: any) => {
      console.log({
        entries,
        observer
      });
      const target_intersect = entries[0] && entries[0].isIntersecting;
      const target_top =
        entries[0] &&
        entries[0].intersectionRect &&
        entries[0].intersectionRect.top;
      const target_ratio = entries[0] && entries[0].intersectionRatio;
      cb({
        target_intersect,
        target_top,
        target_ratio
      });
    };
    // new observer
    let observer = new IntersectionObserver(callback, options);
    observer.observe(target_el);
  }
};
