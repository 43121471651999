export const UIA_COINS_SKU_CLICK = "uia_coins_sku_click";
export const UIA_IAP_SKU_CLICK = "uia_iap_sku_click";
export const PURCHASE_NORMAL = "purchase_normal";
export const PURCHASE_SUBSCRIPTION = "purchase_subscription";
export const PURCHASE_SUBSCRIPTION_FREE = "purchase_subscription_free";
export const UIA_FREE_FOLLOWER_CLICK = "uia_free_follower_click";
export const UIA_WELCOME_CLICK = "uia_welcome_click";
export const UIA_SP_SHOW = "uia_sp_show";
export const UIA_SP_CLICK = "uia_sp_click";
export const LAUNCH_STEP_EXECUTION_TIME = "launch_step_execution_time";
