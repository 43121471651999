
















































import { Component, Prop, Vue } from "vue-property-decorator";
import { GETTER_COINS } from "@/store/constants/cg_user_types";
import { Getter } from "vuex-class";
import { getStatusBarHeight } from "@/utils/js_bridge_info";
import { isIos } from "@/utils/lodash";
@Component({
  components: {}
})
export default class NavBar extends Vue {
  @Getter(GETTER_COINS)
  private totalCoin: any;
  @Prop({ default: false })
  private transparent?: boolean;
  @Prop({ default: false })
  private border?: boolean;
  @Prop({ default: "" })
  private leftType?: string;
  @Prop({ default: false })
  private leftFork?: boolean;
  @Prop({ default: false })
  private flowLayout?: boolean;
  @Prop({ default: false })
  private backWhite?: boolean;
  @Prop({ default: false })
  private showCoin?: boolean;
  @Prop({ default: "" })
  private title?: string;
  @Prop({ default: "" })
  private titleColor?: string;
  @Prop({ default: false })
  private hideLeft?: boolean;

  get slotMidShow() {
    return this.$slots.mid;
  }

  get paddingStyle() {
    return isIos() ? {} : { paddingTop: getStatusBarHeight() + "px" };
  }

  get positionStyle() {
    return isIos() ? {} : { top: getStatusBarHeight() + "px" };
  }

  private clickLeft() {
    this.$emit("clickLeft");
  }

  private clickMid() {
    this.$emit("clickMid");
  }

  private clickRight() {
    this.$emit("clickRight");
  }
}
