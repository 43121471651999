export interface App {
  packageName: string;
  videoUrl: string;
  appScheme: string;
  downloadUrl: string;
  adId: string;
  reward: number;
  iconUrl: string;
  has_install: boolean;
  openStoreInApp: boolean;
  appStoreId: string;
  spServiceName?: string;
}

export interface Apps {
  super_pay: App;
  back_up: App;
  feature_apps: App;
  possible_apps: string[];
}

export enum AppsCategory {
  super_pay = "super_pay",
  back_up = "back_up",
  feature_apps = "feature_apps",
  possible_apps = "possible_apps"
}
