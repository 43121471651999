import * as types from "@/store/constants/setting_types";
export default {
  [types.UPDATE_SETTING_DATA](state: any, payLoad: any) {
    Object.keys(payLoad).forEach((v: any) => {
      state[v] = payLoad[v];
    });
  },
  [types.UPDATE_LOADED_ONLINE_SETTING_STATE](state: any) {
    state.hasLoadedOnlineSetting = true;
  },
  [types.UPDATE_PAGE_SETTING_STATUS](state: any, type: "follow" | "like") {
    state.hasLoadedPageSettingStatus[type] = true;
  }
};
