/*
 * @Author: Jhonsem
 * @Date: 2021-01-06 10:47:14
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2021-01-14 14:02:27
 */

/**
 * 通过原生提供的jsbrdige的存储来进行缓存
 * 临时方案为localstrorage,废弃掉localforage的主要原因是在浏览器上的兼容性较差
 * 切换前后台时容易报错
 */
import { anyTypeToString, stringToData } from "./local_storage_helpers";
import Jsbridge from "@/jsbridge/index";

export class NativeStorage {
  public isIndexDBSupported: boolean;
  constructor() {
    this.isIndexDBSupported = true;
  }

  public async set(key: string, data: any) {
    try {
      const result = await Jsbridge.nativeStorageSet({
        [key]: anyTypeToString(data)
      });
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async get(key: string | string[]): Promise<any> {
    try {
      let result: any = await Jsbridge.nativeStorageGet(key);
      if (Array.isArray(key)) {
        Object.keys(result).forEach((v: string) => {
          result[v] = stringToData(result[v]);
        });
      } else {
        result = stringToData(result[key]);
      }
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async has(key: string): Promise<boolean> {
    try {
      const result: object = await this.get(key);
      const hasResult = Object.values(result).find((v: string) => !!v);
      return !!hasResult;
    } catch (error) {
      return false;
    }
  }

  public async remove(key: string | string[]) {
    try {
      const result = await Jsbridge.nativeStorageRemove(key);
      return !!result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 设置Key存储
   * 提供给vuex-persist的方法
   * @param key
   * @param data
   */
  public async setItem(key: string, data: any) {
    try {
      const result = await this.set(key, data);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 获取某个Key的存储
   * 提供给vuex-persist的方法
   * @param key
   * @param data
   */
  public async getItem(key: string | string[]): Promise<any> {
    try {
      const result = await this.get(key);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 删除某项
   * 提供给vuex-persist的方法
   * @param key
   * @param data
   */
  public async removeItem(key: string | string[]): Promise<any> {
    try {
      const result = await this.remove(key);
      return result;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 获取所有存储的Keys
   */
  public async getKeys(): Promise<string[]> {
    try {
      const keys = await Jsbridge.nativeStorageKeys();
      // 返回的keys可能是null
      return Array.isArray(keys) ? keys : [];
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 获取keys的长度
   * 提供给vuex-persist的方法
   */
  public async length(): Promise<number> {
    try {
      // 先获取到所有的keys,
      const keys = await this.getKeys();
      return keys.length;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 删除所有
   * 提供给vuex-persist的方法
   */
  public async clear(): Promise<void> {
    try {
      // 先获取到所有的keys,再掉用removeItem(keys)
      const keys = await this.getKeys();
      await this.removeItem(keys);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 通过key_index获取他的key_name
   * 提供给vuex-persist的方法
   * @param keyIndex
   */
  public async key(keyIndex: number): Promise<string | null> {
    try {
      // 先获取到所有的keys
      const keys = await this.getKeys();
      return keys[keyIndex] ? keys[keyIndex] : null;
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default new NativeStorage();
