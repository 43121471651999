

















import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { GETTER_CURRENT_TIKTOK_USER_INFO } from "@/store/constants/tt_user_types";
@Component({
  components: {}
})
export default class UserBaseInfo extends Vue {
  @Getter(GETTER_CURRENT_TIKTOK_USER_INFO)
  private currentInsUserInfo: any;

  get profilePic() {
    return (
      this.currentInsUserInfo &&
      this.currentInsUserInfo.tt_user_info.profile_pic_url
    );
  }

  get username() {
    return (
      this.currentInsUserInfo && this.currentInsUserInfo.tt_user_info.uniqueId
    );
  }

  get followerCount() {
    return (
      this.currentInsUserInfo &&
      this.currentInsUserInfo.tt_user_info.follower_count
    );
  }
  private showAccountList() {
    //
  }
}
