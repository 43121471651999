




































import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { GETTER_CURRENT_TIKTOK_USER_MEDIA } from "@/store/constants/tt_media_types";
import postMoreSwiper from "./PostSwiper.vue";
import LessPostSwiper from "./LessPostSwiper.vue";
@Component({
  components: { postMoreSwiper, LessPostSwiper }
})
export default class SelectPost extends Vue {
  @Getter(GETTER_CURRENT_TIKTOK_USER_MEDIA)
  private currentTiktokMedia: any;
  @Prop()
  private cureentMediaInfo: any;

  private showLoading = false;

  get isPostMore() {
    return (
      this.currentTiktokMedia &&
      this.currentTiktokMedia.tt_media_list.length >= 10
    );
  }

  get likeCount() {
    return this.cureentMediaInfo && this.cureentMediaInfo.digg_count;
  }
  get commentCount() {
    return this.cureentMediaInfo && this.cureentMediaInfo.comment_count;
  }

  private goToAllPost() {
    this.$router.push({
      name: "AllPost",
      query: {
        routerTransition: "forward"
      }
    });
  }

  private showLoadingFn() {
    this.showLoading = !this.showLoading;
  }
}
