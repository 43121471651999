import { store } from "@/main";
import * as types from "@/store/constants/cg_user_types";

const master_user_id_login_init = async () => {
  try {
    const result = await store.dispatch(types.INIT_GX_USER_INFO);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default master_user_id_login_init;
