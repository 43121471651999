import { Component, Prop, Vue } from "vue-property-decorator";
import { downloadApps } from "@/utils/app_download";
import WebSessionStorageWorker from "@/utils/nativeStorage/web_session_storage";
import { sendEvent } from "@/utils/log_event";
import { UIA_SP_CLICK } from "@/utils/log_event/event_const";
import SwitchBackgroundPosition, {
  SwitchPositionTypes
} from "@/utils/switch_background/switch_background_position";
import AutoPaymentCache from "@/services/super_pay/auto_payment_cache";

@Component
class SuperPayButton extends Vue {
  @Prop()
  private ctx: any;
  @Prop()
  private padding: any;
  @Prop()
  private item: any;
  @Prop()
  private place: any;
  @Prop()
  private click_point_key: any;
  @Prop()
  private dialog_class: any;
  @Prop()
  private offer: any;
  @Prop()
  private rewards: any;

  private buttonDefaultStyle =
    "background:rgb(255, 61, 89);font-family:AppleSDGothicNeo-Medium, Poppins;color:#fff;padding:9px 0;text-align:center;line-height:26px;font-size:16px;border-radius:8px;";

  public render() {
    // 开机需要padding，支付页不需要
    const buttonStyle = this.padding
      ? "margin:0 32px;" + this.buttonDefaultStyle
      : this.buttonDefaultStyle;
    const coins_icon = require("@/assets/icons-file/coins/coin@3x.png");
    const coins_icon_style = "width: 14px;height: 14px;";
    const rewardButtonStyle =
      "display: flex; align-items: center; justify-content: center;";
    const rewardTextStyle = "margin: 0 2px";
    const renderButton = this.rewards ? (
      <div style={rewardButtonStyle}>
        <span style={rewardTextStyle}>+{this.rewards}</span>
        <img style={coins_icon_style} src={coins_icon} />
      </div>
    ) : (
      "GET"
    );
    return (
      <div
        onClick={async () => {
          if (!this.offer) {
            this.ctx.isShow = false;
          }
          AutoPaymentCache.set({
            offer: this.offer,
            pay_way: "back_up"
          });
          downloadApps(this.item);
          SwitchBackgroundPosition.setPosition(
            this.dialog_class === "back_up"
              ? SwitchPositionTypes.REFRESH_CG_USER_INFO
              : SwitchPositionTypes.NOT_REQUIRE_REFRESH
          );
          const videoUrl = this.item.video_url || this.item.videoUrl;
          const videoPathList = videoUrl.split("/");
          const videoName = videoPathList[videoPathList.length - 1];
          this.dialog_class === "super_pay" &&
            sendEvent(UIA_SP_CLICK, {
              promo_video: videoName,
              situation: "payment_choice",
              style: "alert",
              action: "get"
            });
        }}
        style={buttonStyle}
      >
        {renderButton}
      </div>
    );
  }
}
export default SuperPayButton;
