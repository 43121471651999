/*
 * @Author: jhonsem
 * @Date: 2021-01-21 17:30:01
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-01-25 14:24:14
 * 1.feeds的插入，根据feeds的最后一个task_feeds来插入
 */
import { get_random_offer_feed } from "./random_feed";
import { feeds_getters } from "../observer/feeds_observer";
import { store } from "@/main";
import { GETTER_TAB_EARN_COINS_SETTINGS } from "@/store/constants/setting_types";

const get_random_feed = () => {
  const { feed_coin_offers } = store.getters[GETTER_TAB_EARN_COINS_SETTINGS];
  const random_task_feed = get_random_offer_feed(feed_coin_offers);
  return random_task_feed;
};

/**
 * splice by spacing
 * @param ori_feeds
 * @param feed_spacing
 */
const second_part_feeds = (ori_feeds: any[], feed_spacing: number) => {
  // 首部不补task ,尾部补充task
  const combine_feeds = [];

  for (let i = 0; i < ori_feeds.length; i++) {
    combine_feeds.push(ori_feeds[i]);
    if ((i + 1) % feed_spacing === 0) {
      //

      const random_task_feed = get_random_feed();
      random_task_feed && combine_feeds.push(random_task_feed);
    }
  }
  return combine_feeds;
};

/**
 * lack part of feeds
 * @param ori_feeds
 * @param lack_feeds_count
 */
const one_part_feeds = (ori_feeds: any[], lack_feeds_count: number) => {
  if (ori_feeds.length < lack_feeds_count) {
    return ori_feeds;
  } else {
    // 尾部需要补上task
    const combine_feeds = ori_feeds.slice(0, lack_feeds_count);
    const random_task_feed = get_random_feed();

    random_task_feed && combine_feeds.push(random_task_feed);
    return combine_feeds;
  }
};

// export const
export const insert_feeds = (ori_feeds: any[], feed_spacing: number) => {
  //
  if (ori_feeds.length === 0) {
    return [];
  }

  //index后的tt_feeds count
  const valid_tt_feed_count = feeds_getters.last_group_tt_feed_count();

  // const backup_ori_feeds = [...ori_feeds];//copy
  let one_part = []; //lack part
  let second_part = []; // splic part

  if (valid_tt_feed_count === 0) {
    //
    second_part = second_part_feeds(ori_feeds, feed_spacing);
  } else if (valid_tt_feed_count < feed_spacing) {
    // 需要补上
    const lack_feeds_count = feed_spacing - valid_tt_feed_count;
    one_part = one_part_feeds(ori_feeds, lack_feeds_count);
    // 补上了几个看还是否有剩余的:
    const remain_count = ori_feeds.length - lack_feeds_count;
    if (remain_count > 0) {
      // 有剩余的
      second_part = second_part_feeds(
        ori_feeds.slice(lack_feeds_count),
        feed_spacing
      );
    }
  }

  console.log("insert_feeds:", {
    one_part,
    second_part
  });

  return one_part.concat(second_part);
};
