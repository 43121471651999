import _Vue from "vue";

export function ImgSrcChangePlugin<RouterOption>(
  Vue: typeof _Vue,
  router: any,
  options?: RouterOption
): void {
  Vue.directive("dynamic-src", {
    update: (el: any, binding: any, vnode: any) => {
      const isloaded = el.getAttribute("isloaded");
      // 加载失败了
      if (isloaded === "false" || el.src !== binding.value) {
        el.src = ""; // 先将图片置为空
        setTimeout(() => {
          el.src = binding.value;
        }, 0);
      }
    },
    bind: (el: any, binding: any, vnode: any) => {
      el.src = binding.value;
      el.onerror = (element: any) => {
        el.style.opacity = "0";
        el.setAttribute("isloaded", false);
      };
      el.onload = (element: any) => {
        el.style.opacity = "1";
        el.setAttribute("isloaded", true);
      };
    }
  });
}
