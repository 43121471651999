import { downloadFiles } from "@/jsbridge/service/download_files/download_files";
import VideoUrlCache from "@/services/videoUrlCache";

export const getVideoCache = async (src: string) => {
  return new Promise(async (resolve, reject) => {
    const videoCache: any = VideoUrlCache.get();
    const videoPathList = src && src.split("/");
    const videoName = videoPathList[videoPathList.length - 1];
    if (videoCache && videoCache[videoName]) {
      resolve("storage:/" + videoCache[videoName]);
    } else {
      const result = await downloadFiles(src, videoName);
      const obj = Object.assign(videoCache, { [videoName]: result });
      VideoUrlCache.setCache(obj);
      resolve("storage:/" + result);
    }
  });
};
