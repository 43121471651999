










import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class ActButton extends Vue {
  @Prop({ default: false })
  private disabled: any;

  private clickBtn() {
    !this.disabled && this.$emit("click");
  }
}
