import { purchaseEvent, skuClickEvent } from "@/utils/log_event/purchase_event";
import {
  Offer,
  OfferKind,
  OfferWay,
  PaymentWithMethodParams,
  PaymentWithSituationParams,
  getResourceFromOffer,
  calcPriceAndEffect,
  SkuClickCategory
} from "@/services/offer/offer_handle";
import {
  assembleWebPayUrl,
  getCurrentScheme,
  redirectDialogSp,
  redirectFullPageSp,
  judgePaymentItemExist
} from "@/services/payment/payment_helper";
import { router, store } from "@/main";
import * as setting_types from "@/store/constants/setting_types";
import { getIapProductId } from "@/plugins/filters/offer_filter";
import LocalizeUtil from "@/services/payment/localize";
import {
  IapResult,
  NativePurchaseResult,
  PayWay,
  PurchaseResult,
  ServiceResult
} from "@/services/payment/types";
import { iapPurchase } from "@/jsbridge/service/payment";
import {
  executeSuperPay,
  handleWebPayResult,
  purchaseExecute,
  webPayExecute
} from "@/services/payment/purchaseExecute";
import { purchaseErrorHandle } from "@/services/payment/errorHandle";
import * as index_types from "@/store/constants/index_types";
import { payByCoins } from "@/api/cg/service/pay_service";
import ServiceList from "@/services/serviceList";
import PurchasedList from "@/services/payment/purchasedList";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { HAS_USE_SP_PAY } from "@/utils/nativeStorage/local_keys";
import * as cg_user_types from "@/store/constants/cg_user_types";
import PaymentUtil from "@/services/payment/paymentCache";
import RetryParams from "@/services/payment/retryParams";
import Loading from "@/plugins/toast/loading";
import AutoPaymentCache from "@/services/super_pay/auto_payment_cache";
import { isVipOrCoins } from "@/utils/general_utils";
import FailedOrderList from "@/services/payment/failedOrderList";
import { getOrders } from "@/api/cg/service/pay_service";
import EventBus from "@/utils/event_bus";
import { isIos } from "@/utils/lodash";
import LimitCoinsCacheModule from "@/services/limit_coins/limit_coins_cache";

class PaymentCall {
  // 点击购买项并通过支付限制逻辑
  public payAny(payload: PaymentWithSituationParams) {
    const { offer } = payload;
    if (offer.way === OfferWay.coin) {
      this.payByCoins(payload);
      skuClickEvent({ ...payload, result: SkuClickCategory.ok });
    } else {
      const enterPayment: any = judgePaymentItemExist(offer);
      const { canShowStripe, canShowPayPal, canShowSuperPay } = enterPayment;
      if (
        [canShowStripe, canShowPayPal, canShowSuperPay].filter(v => v === true)
          .length > 0
      ) {
        router.push({
          name: "Payment",
          query: {
            routerTransition: "up",
            offer: JSON.stringify(offer),
            canShowStripe,
            canShowPayPal,
            canShowSuperPay,
            resource: JSON.stringify(getResourceFromOffer(offer))
          }
        });
        skuClickEvent({ ...payload, result: SkuClickCategory.payment_choice });
      } else {
        this.payByOrigin(payload);
        skuClickEvent({ ...payload, result: SkuClickCategory.apple_iap });
      }
    }
  }
  // 进入retry页面
  public async enterRetryPage() {
    const { offer, nativeResult, pay_way, retryAgain } = RetryParams.getRetry();
    const { transactionId } = nativeResult;
    const actual_effect = calcPriceAndEffect(offer, pay_way)
      .with_vip_reward_effect;
    const mockData = {
      offer_data: offer,
      transaction_id: transactionId,
      transaction_time: new Date().getTime(),
      pay_way,
      resource: getResourceFromOffer(offer),
      actual_effect
    };
    FailedOrderList.setFailedOrder({
      ...offer,
      failed: true,
      transaction_id: transactionId,
      transaction_time: new Date().getTime(),
      pay_way,
      retryParams: RetryParams.getRetry(),
      resource: getResourceFromOffer(offer),
      actual_effect
    });
    const routes: any = {
      name: "OrderDetail",
      query: {
        routerTransition: "up",
        result: JSON.stringify(mockData),
        retryAgain,
        t: new Date().getTime(),
        pay_way,
        fromPayment: true,
        isRetry: true
      }
    };
    if (router.currentRoute.name === "Payment") {
      router.replace(routes);
    } else {
      router.push(routes);
    }
  }
  // 重试
  public async retryAgain(silent?: boolean) {
    try {
      const {
        offer,
        pay_way,
        nativeResult,
        cacheTargetUserId,
        cacheMasterId,
        cacheResource
      } = RetryParams.getRetry();
      const { transactionId, code, receipt } = nativeResult;
      // 如果是android gp支付并且code不等于8，携带receipt重新调用iap_purchase
      if (pay_way === PayWay.iap && !isIos() && code && code !== 8) {
        this.payByIap({ offer }, receipt, {
          cacheTargetUserId,
          cacheMasterId,
          cacheResource,
          retryAgain: true
        });
      } else {
        !silent && Loading.show();
        const retryAgain = true;
        const result = await purchaseExecute(
          offer,
          pay_way,
          nativeResult,
          retryAgain,
          cacheTargetUserId,
          cacheMasterId,
          cacheResource
        );
        FailedOrderList.removeRetriedSuccessOrder(transactionId);
        RetryParams.set(null);
        this.handlePayResult({
          purchaseResult: result,
          pay_way,
          retry: true,
          silent
        });
      }
    } catch (e) {
      const { pay_way } = RetryParams.getRetry();
      purchaseErrorHandle(e, pay_way, silent);
    }
  }
  // 分发支付方式
  public distributePaymentMethod(payload: PaymentWithMethodParams) {
    const { pay_way } = payload;
    if (pay_way === PayWay.paypal || pay_way === PayWay.card) {
      this.payByWebPay(payload);
    } else if (pay_way === PayWay.iap) {
      this.payByOrigin(payload);
    } else if (pay_way === PayWay.super_pay) {
      this.payBySuperPay(payload);
    }
  }
  // 自动发起的sp和ba
  public autoExecutePay() {
    const { offer, pay_way } = AutoPaymentCache.get();
    console.log("offer, pay_way", offer, pay_way, pay_way === "super_pay");
    offer &&
      this.payBySuperPay({
        offer,
        pay_way
      });
  }
  // iap支付 上一次失败返回的receipt:上一次失败返回的receipt supplement: 上一次失败订单的补充参数
  public async payByIap(
    payload: PaymentWithSituationParams,
    receipt?: string,
    supplement?: any
  ) {
    try {
      Loading.show();
      const { offer, resource } = payload;
      const pay_way: PayWay = PayWay.iap;
      // 与本地交互获取receipt和transaction_id
      const iapResult: IapResult = await iapPurchase(offer, receipt);
      // iap 支付打点
      purchaseEvent(offer, pay_way);
      const { transaction, receipt_data, code } = iapResult;
      const { transaction_identifier } = transaction;
      const nativePurchaseResult: NativePurchaseResult = {
        transactionId: transaction_identifier,
        receipt: receipt_data,
        code
      };
      // 与后台交互获取支付信息
      const purchaseResult: PurchaseResult = await purchaseExecute(
        offer,
        pay_way,
        nativePurchaseResult,
        supplement && supplement.retryAgain,
        supplement && supplement.cacheTargetUserId,
        supplement && supplement.cacheMasterId,
        supplement && supplement.cacheResource
          ? supplement && supplement.cacheResource
          : resource
      );
      this.handlePayResult({
        purchaseResult,
        pay_way,
        retry: supplement && supplement.retryAgain
      });
    } catch (e) {
      console.log("pay by iap", e);
      purchaseErrorHandle(e, PayWay.iap);
    }
  }
  // 使用coins购买
  private async payByCoins(payload: PaymentWithSituationParams) {
    try {
      Loading.show();
      const { offer } = payload;
      const pay_way: PayWay = PayWay.coin;
      const masterId = store.getters[index_types.GETTER_MASTER_USER_ID];
      const targetUserId = store.getters[index_types.GETTER_CURRENT_USER_ID];
      const transactionId = `${masterId}${new Date().getTime()}`;
      const result: PurchaseResult = await payByCoins(
        offer,
        transactionId,
        masterId,
        targetUserId
      );
      this.handlePayResult({
        purchaseResult: result,
        pay_way
      });
    } catch (e) {
      purchaseErrorHandle(e, PayWay.coin);
    }
  }
  // 使用iap支付入口，判断是否下架，如果下架且product_id无效走back_up，否则走iap
  private payByOrigin(payload: PaymentWithSituationParams) {
    const { offer, resource } = payload;
    // 获取是否下架
    const app_take_down = store.getters[setting_types.GETTER_APP_TAKE_DOWN];
    // 判断product_id是否有效， 如果有效调iap，否则back_up
    const IapProductValid: boolean | undefined = getIapProductId(offer)
      ? LocalizeUtil.judgeProductIdValid(getIapProductId(offer))
      : false;
    if (app_take_down && !IapProductValid) {
      this.payBySuperPay({ offer, pay_way: PayWay.back_up, resource });
    } else {
      this.payByIap(payload);
    }
  }
  // sp和ba支付
  private async payBySuperPay(payload: PaymentWithMethodParams) {
    try {
      const { pay_way, offer, resource } = payload;
      // 获取当前应该掉起的url
      const current_scheme: string | undefined = await getCurrentScheme(
        pay_way
      );
      if (!current_scheme) {
        // 如果是sp，推荐弹窗  如果是ba，展示sp/redirectApp/iap的流程
        if (pay_way === PayWay.super_pay) {
          redirectFullPageSp(offer);
        } else if (pay_way === PayWay.back_up) {
          redirectDialogSp(offer);
        }
        return;
      }
      console.log("current_scheme", current_scheme);
      Loading.show();
      // 与原生交互获取结果
      const spResult: NativePurchaseResult = await executeSuperPay(
        offer,
        pay_way,
        current_scheme,
        resource
      );
      // sp/ba支付打点
      purchaseEvent(offer, pay_way);
      const purchaseResult: PurchaseResult = await purchaseExecute(
        offer,
        pay_way,
        spResult,
        undefined,
        undefined,
        undefined,
        resource
      );
      // 处理支付完成的过程
      this.handlePayResult({
        purchaseResult,
        pay_way
      });
    } catch (e) {
      const { pay_way } = payload;
      purchaseErrorHandle(e, pay_way);
    }
  }
  // web支付
  private async payByWebPay(payload: PaymentWithMethodParams) {
    try {
      Loading.show();
      const { pay_way, offer, resource } = payload;
      // 组装web pay url
      const url: string = await assembleWebPayUrl(offer, pay_way, resource);
      // 获取web pay返回的携带信息的链接
      const webResult: any = await webPayExecute(url, pay_way);
      // 处理携带信息的链接，跳转落地页
      const purchaseResult: PurchaseResult = await handleWebPayResult(
        webResult
      );
      // 处理支付后的内容
      this.handlePayResult({
        purchaseResult,
        pay_way
      });
    } catch (e) {
      const { pay_way } = payload;
      purchaseErrorHandle(e, pay_way);
    }
  }
  // 后台已经成功（error.meta.code===24001)，直接从订单内找对应的订单去落地
  public async handleRetryFailed(silent?: boolean) {
    try {
      const masterId = store.getters[index_types.GETTER_MASTER_USER_ID];
      const orderResult: any = await getOrders(masterId, "");
      const orders = orderResult && orderResult.orders;
      console.log("order list", orders);
      const { nativeResult, pay_way } = RetryParams.getRetry();
      const { transactionId } = nativeResult;
      const result = orders.find(
        (v: any) => v.transaction_id === transactionId
      );
      FailedOrderList.removeRetriedSuccessOrder(transactionId);
      RetryParams.set(null);
      console.log("order existed result", result);
      this.handlePayResult({
        purchaseResult: {
          offer_data: result,
          ...result
        },
        pay_way,
        silent
      });
    } catch (e) {
      purchaseErrorHandle(e);
    }
  }
  // 处理支付结果
  private async handlePayResult(payload: ServiceResult) {
    const { purchaseResult, pay_way, retry, silent } = payload;
    const updateArr: string[] = [];
    let kind: OfferKind;
    let offer: Offer;
    if (pay_way === PayWay.paypal || pay_way === PayWay.card) {
      kind = purchaseResult.receipt_data.kind;
      offer = purchaseResult.receipt_data;
      updateArr.push("paypal_enabled", "stripe_enabled");
      // paypal和card购买结束打点
      purchaseEvent(offer, offer.pay_way ? offer.pay_way : pay_way);
    } else {
      kind = purchaseResult.offer_data.kind;
      offer = purchaseResult.offer_data;
    }
    // 如果是订阅类型，更新serviceList
    if (kind === OfferKind.vip) {
      await ServiceList.updateServiceList();
    }
    // 更新已购买列表 special follow和like需要用
    await PurchasedList.updatePurchaseList(purchaseResult);
    // 判断是不是首次sp购买，有没有加币逻辑
    const has_use_sp_pay = await nativeStorage.has(HAS_USE_SP_PAY);
    const sp_first_pay =
      !has_use_sp_pay &&
      store.getters[setting_types.GETTER_SP_BONUS_COINS] &&
      store.getters[setting_types.GETTER_SP_BONUS_COINS] > 0;
    if (isVipOrCoins(offer) || pay_way === PayWay.coin || sp_first_pay) {
      updateArr.push("coins");
      // 如果是special_coin（vip package）购买，通知vip discount组件灰掉被购买的offer
      if (kind === OfferKind.special_coin) {
        updateArr.push("vip_p_valid_count", "vip_p_valid_time");
      }
    }
    // 刷新用户信息
    if (updateArr.length > 0) {
      store.dispatch(cg_user_types.UPDATE_GX_USER_INFO, updateArr).catch(e => {
        void 0;
      });
    }
    // 如果是sp首次购买
    if (sp_first_pay && pay_way === PayWay.super_pay) {
      nativeStorage.set(HAS_USE_SP_PAY, true);
    }
    // 更新购买次数
    if (pay_way !== PayWay.coin) {
      PaymentUtil.updatePaymentTimes(pay_way);
    }
    // 如果是limited_coin购买,记录购买时间
    if (kind.includes("limited_coin")) {
      LimitCoinsCacheModule.setTime({
        limitCoinsPurchaseTime: new Date().getTime()
      });
    }
    // 如果是sepcial offer购买，则触发special_offer_buying_finished，让special offer组件进入下一阶段
    if (kind.includes("special_follow") || kind.includes("special_like")) {
      const type = kind.includes("like") ? "like" : "follow";
      setTimeout(() => {
        EventBus.$emit("special_offer_buying_finished");
      }, 1000);
    }
    const routes: any = {
      name: "OrderDetail",
      query: {
        routerTransition: "up",
        result: JSON.stringify(purchaseResult),
        t: new Date().getTime(),
        pay_way,
        fromPayment: true,
        retrySuccessTag: retry
      }
    };
    Loading.hide();
    // 如果是静默执行的逻辑，不跳转落地页
    if (!silent) {
      if (router.currentRoute.name === "Payment" || retry) {
        router.replace(routes);
      } else {
        router.push(routes);
      }
    }
  }
}

export default new PaymentCall();
