









import { Component, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { GETTER_CONTACT_EMAIL } from "@/store/constants/setting_types";
import contactUs from "@/jsbridge/service/contact_us";
@Component
export default class NavBar extends Vue {
  @Getter(GETTER_CONTACT_EMAIL)
  private contactEmail: any;
  @Prop({ default: null })
  private offerId?: string;
  @Prop({ default: null })
  private transactionId?: string;

  private contactClick() {
    return contactUs(this.transactionId, this.offerId);
  }
}
