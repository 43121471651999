import {
  FOLLOWER_FEED,
  is_purchase_feed,
  is_tt_feed,
  LIKE_FEED,
  LUCK_COINST_FEED,
  VIDEO_AD_FEED
} from "./../earn_coins_consts/earn_coins_consts";
import { store } from "@/main";
import { payByAny } from "@/services/payment/payment_check/payment_check_logic";
import { Feed } from "../types/type";
import { playVideoEffect } from "@/services/watch_video";
import {
  GEETER_TIKTOK_LOGIN_USER,
  GETTER_CURRENT_TIKTOK_USER_INFO,
  GETTER_TIKTOK_USER_EMPTY,
  LOGIN_TIKTOK_USER_ACTION,
  TIKTOK_LOGIN_USER_EMPTY
} from "@/store/constants/tt_user_types";
import { GETTER_MASTER_USER_ID } from "@/store/constants/index_types";
import { UPDATE_GX_USER_INFO } from "@/store/constants/cg_user_types";
import { actFeed } from "@/api/cg/service/feed_service";
import { skip_feeds } from "../controller/feeds_controller";
import {
  tt_follow_logged_in,
  tt_follow_without_login,
  tt_like_logged_in,
  tt_like_without_login
} from "@/api/tt-api/tt_api_user/tt_api_user";
import { count_limit_feeds } from "./limit_feeds";
import {
  GETTER_CLIENT_SETTINGS,
  GETTER_EARN_COINS_LOGIN_NONEED_ENABLED,
  GETTER_TAB_COIN_STORE_SETTINGS
} from "@/store/constants/setting_types";
import Vue from "vue";
import { act_users_getters } from "@/services/earn_coins/observer/act_observer";
import { loginRequireDialog } from "@/services/dialog_utils/index";
import { earn_spacing_actions } from "@/services/earn_coins/observer/spacing_observer";
import { goToSearchOrLogin } from "@/utils/router_helper/index";
import { OfferSituation } from "@/services/offer/offer_handle";

/**
 * 金币类型的购买
 * @param current_feed
 */
export const purchase_feed_act = (current_feed: null | Feed) => {
  if (current_feed && current_feed.offer) {
    const { kind } = current_feed;
    if (kind === LUCK_COINST_FEED) {
      // lucky coins offer特殊:
      const tab_coins_store_setting =
        store.getters[GETTER_TAB_COIN_STORE_SETTINGS];
      const lucky_coin_offers = tab_coins_store_setting.lucky_coin_offers[0];
      payByAny({
        offer: lucky_coin_offers,
        situation: OfferSituation.feed_lucky_coin
      });
    } else {
      payByAny({
        offer: current_feed.offer,
        situation: OfferSituation.feed_coin
      });
    }
  }
};

/**
 * 广告类型的feed:
 */
export const ad_feed_act = async () => {
  try {
    await playVideoEffect("earn_coins");
    // auto move
    skip_feeds();
  } catch (error) {
    //
  }
};

export const auto_play_video = (feed: Feed) => {
  if (feed && feed.kind && feed.kind === VIDEO_AD_FEED) {
    const { earn_limit } = store.getters[GETTER_CLIENT_SETTINGS];
    if (earn_limit && earn_limit.auto_play_video_ads_enabled) {
      ad_feed_act();
    }
  }
};

const tt_feed_act_check = (feed: Feed) => {
  // 要判断是否需要强登陆
  const earn_coins_login_noneed =
    store.getters[GETTER_EARN_COINS_LOGIN_NONEED_ENABLED];

  if (earn_coins_login_noneed) {
    // 不需要登陆:
    const is_empty_users = store.getters[GETTER_TIKTOK_USER_EMPTY];
    // 没有任何账号，需要跳转到search_user界面
    if (is_empty_users) {
      //
      goToSearchOrLogin();
      return;
    }

    const current_tt_user = store.getters[GETTER_CURRENT_TIKTOK_USER_INFO];
    const { user_id } = current_tt_user;

    // 通过检查:
    return {
      user_id,
      feed,
      without_login: true
    };
  } else {
    const is_empty_tt_loggined = store.getters[TIKTOK_LOGIN_USER_EMPTY];

    if (is_empty_tt_loggined) {
      // 需要登录:
      Vue.prototype.$_Toast.show({
        text: "need login account to earn coins.",
        duration: 3000
      });

      store.dispatch(LOGIN_TIKTOK_USER_ACTION);
      return false;
    }

    const loggined_tt_user = store.getters[GEETER_TIKTOK_LOGIN_USER];
    const { user_id } = loggined_tt_user;

    // 是否在被限制:
    if (act_users_getters.is_login_required()) {
      loginRequireDialog("account_expired");
      return;
    }

    // 通过检查:
    return {
      user_id,
      feed,
      without_login: false
    };
  }
};

const tt_act_request_task = async (
  kind: string,
  username: string,
  media_id?: string
) => {
  try {
    let tt_result: any;
    if (kind === FOLLOWER_FEED) {
      // follow
      tt_result = await tt_follow_logged_in(username);
    } else if (kind === LIKE_FEED && media_id) {
      // like
      tt_result = await tt_like_logged_in(username, media_id);
    }
    const { response } = tt_result;
    const { result } = response;
    return {
      status: "ok",
      message: result
    };
  } catch (error) {
    // 两种错误，tt请求错误
    console.log("tt_act_request_task error:", error);
    const { response } = error;
    if (response && response.error_type) {
      return {
        status: "fail",
        message: response.error_type
      };
    } else {
      return {
        status: "fail",
        message: "syntax error"
      };
    }
  }
};

const tt_act_request_without_login_task = async (
  kind: string,
  username: string,
  logged_in_username: string,
  media_id?: string
) => {
  try {
    let tt_result: any;
    if (kind === FOLLOWER_FEED) {
      // follow
      tt_result = await tt_follow_without_login(logged_in_username, username);
    } else if (kind === LIKE_FEED && media_id) {
      // like
      tt_result = await tt_like_without_login(
        username,
        logged_in_username,
        media_id
      );
    }
    console.log("tt_act_request_without_login_task:tt_result", tt_result);
    const { response } = tt_result;
    const { result } = response;
    return {
      status: "ok",
      message: result
    };
  } catch (error) {
    // 直接抛错，不进行错误处理
    console.log("tt_act_request_without_login_task error:", error);
    const { response } = error;
    if (response && response.data && response.data.error_type) {
      return {
        status: "fail",
        message: response.data.error_type
      };
    } else {
      return {
        status: "fail",
        message: "syntax error"
      };
    }
  }
};

const update_user_coins = (masterInfo: any) => {
  store.commit(UPDATE_GX_USER_INFO, {
    userInfo: masterInfo,
    filters: ["coins"]
  });
};

const tt_feed_act_task_without_login = async (
  work_user_id: string,
  feed: Feed
) => {
  try {
    const { kind, subresources } = feed;
    const resource = subresources[0];
    const { username, user_id, media_id } = resource;
    const master_id = store.getters[GETTER_MASTER_USER_ID];
    let target_id: any;

    if (kind === FOLLOWER_FEED) {
      // follow
      target_id = user_id; // target id
    } else if (kind === LIKE_FEED && media_id) {
      // like
      target_id = media_id;
    }
    let tt_act_request_task_result: any = {};
    // 显示loading,非登陆需要展示
    Vue.prototype.$_Toast.show({
      type: "loading",
      text: "Processing"
    });
    const current_tt_user = store.getters[GETTER_CURRENT_TIKTOK_USER_INFO];
    const current_tt_username = current_tt_user.tt_user_info.uniqueId;

    tt_act_request_task_result = await tt_act_request_without_login_task(
      kind,
      username,
      current_tt_username,
      media_id
    );

    // 隐藏loading
    Vue.prototype.$_Toast.hide();

    const { status, message } = tt_act_request_task_result;

    // 不阻塞当前任务
    actFeed(master_id, work_user_id, status, message, kind, target_id, false)
      .then((server_result: any) => {
        update_user_coins(server_result);
        console.log("server_result:", server_result);
      })
      .catch((e: any) => {
        console.log("server_result e:", e);
      });
  } catch (error) {
    //
    console.log("tt_feed_act_task error:", error);
  }
};

const tt_feed_act_task_loggined = async (work_user_id: string, feed: Feed) => {
  try {
    const { kind, subresources } = feed;
    const resource = subresources[0];
    const { username, user_id, media_id } = resource;
    const master_id = store.getters[GETTER_MASTER_USER_ID];
    let target_id: any;

    if (kind === FOLLOWER_FEED) {
      // follow
      target_id = user_id; // target id
    } else if (kind === LIKE_FEED && media_id) {
      // like
      target_id = media_id;
    }

    // 登陆模式下的任务
    const tt_act_request_task_result = await tt_act_request_task(
      kind,
      username,
      media_id
    );

    const { status, message } = tt_act_request_task_result;

    const server_result = await actFeed(
      master_id,
      work_user_id,
      status,
      message,
      kind,
      target_id,
      false
    );
    console.log("server_result:", server_result);

    update_user_coins(server_result);
  } catch (error) {
    //
    console.log("tt_feed_act_task error:", error);
  }
};

export const tt_feed_act = async (
  user_id: string,
  feed: Feed,
  without_login: boolean
) => {
  // 开始做任务:1.login 2.wihout_lodin
  try {
    if (without_login) {
      // if (true) {
      // 需要等待任务做完
      await tt_feed_act_task_without_login(user_id, feed);
    } else {
      tt_feed_act_task_loggined(user_id, feed);
    }
    // skip 下一个:
    skip_feeds();
    // count limit follow like count:
    count_limit_feeds(user_id, feed);
    // count spacing:
    earn_spacing_actions.act_trigger();
  } catch (error) {
    //
    console.log("tt_feed_act error:", error);
  }
};

export const act_current_feed = (current_feed: null | Feed) => {
  //

  if (current_feed === null || current_feed === undefined) {
    return;
  }

  const { kind } = current_feed;

  if (is_tt_feed(current_feed)) {
    // tt类型的
    const check_result = tt_feed_act_check(current_feed);

    if (check_result) {
      const { user_id, feed, without_login } = check_result;
      tt_feed_act(user_id, feed, without_login);
    }
  } else if (kind === VIDEO_AD_FEED) {
    // video
    ad_feed_act();
  } else if (is_purchase_feed(current_feed)) {
    // 购买类型的
    purchase_feed_act(current_feed);
  }
};
