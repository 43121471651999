import { PURCHASED_LIST } from "@/utils/nativeStorage/local_keys";
import { CacheModule } from "@/services/cacheModule";

class PurchasedList extends CacheModule {
  constructor() {
    super(PURCHASED_LIST, []);
  }

  // 更新购买列表
  public async updatePurchaseList(item: any) {
    const purchasedList = this.get();
    purchasedList.unshift(item);
    this.setCache(purchasedList);
  }

  // 获取购买列表
  public getPurchaseList() {
    return this.get();
  }
  // 通过id找订单
  public getOrderByTransitionId(id: string): object | undefined {
    const purchasedList: [] = this.get();
    console.log("purchasedList", purchasedList);
    return purchasedList.find((order: any) => {
      const transaction_id =
        order.transaction_id ||
        (order.receipt_data && order.receipt_data.transaction_id);
      return transaction_id === id;
    });
  }

  /*初始化过滤一周以上的购买列表
   * params: period: 周期 type: 类型数组
   * */
  public filterPurchaseList(
    params: any = {
      period: 7,
      type: []
    }
  ) {
    const purchasedList: never[] = this.get();
    const nowDate = new Date().getTime();
    const { period, type } = params;
    const dateBefore = period ? nowDate - period * 1000 * 86400 : 0;
    const result = purchasedList.filter((v: any) => {
      const offer = v.receipt_data || v.offer_data || v;
      return v.transaction_time > dateBefore && !type.includes(offer.kind);
    });
    this.setCache(result);
  }

  /*根据过滤条件，初始化过滤一周以上的购买列表
   * params:
   *   period: 周期
   *   type: 类型数组
   *   exclude: 不会被过滤的数据，回调函数需要返回一个boolean，参数是当前购买项对象offer,只会保留返回true的数据
   * */
  public filterPurchaseListByCondition(
    params: any = {
      period: 7,
      type: [],
      exclude: () => true
    }
  ) {
    const purchasedList: never[] = this.get();
    const nowDate = new Date().getTime();
    const { period, type, exclude } = params;
    const dateBefore = period ? nowDate - period * 1000 * 86400 : 0;
    const excludeFn = exclude ? exclude : () => true;
    console.log("filterPurchaseListByCondition purchase begin", purchasedList);
    const result = purchasedList.filter((v: any) => {
      const offer = v.receipt_data || v.offer_data || v;
      const filterConditionOne =
        v.transaction_time > dateBefore && !type.includes(offer.kind);
      const filterConditionTwo =
        v.transaction_time > dateBefore &&
        type.includes(offer.kind) &&
        excludeFn(offer);
      return filterConditionOne || filterConditionTwo;
    });
    this.setCache(result);
    console.log("filterPurchaseListByCondition purchase result", purchasedList);
  }
}

export default new PurchasedList();
