/**
 * 封装http方法 链式调用
 */
import Jsbridge from "@/jsbridge/index";
import InterceptorManager from "@/api/http/interceptor_manager";
import { RequestParams } from "@/api/types";
import { HttpRequest, HttpRes } from "@/jsbridge/types/http";
import { contentTypeEncode } from "@/api/utils/api_helpers";

export const defaultParams = {
  timeout_interval: 15, // 单位s
  should_handle_cookies: false
};
const DEFAULT_CONTENT_TYPE: string = "application/x-www-form-urlencoded";

export default class Http {
  public defaultconfig: RequestParams;
  public interceptors: {
    request: InterceptorManager<RequestParams>;
    response: InterceptorManager<HttpRes>;
  };
  public chain: any;

  constructor(config: RequestParams) {
    this.defaultconfig = config;
    this.interceptors = {
      request: new InterceptorManager<RequestParams>(),
      response: new InterceptorManager<HttpRes>()
    };
  }

  /**
   * NativeRequestFactory
   * @param param0
   */
  public nativeRequestFactory(reqParams: RequestParams) {
    let body = null;
    const headers = reqParams.headers
      ? {
          "content-type": DEFAULT_CONTENT_TYPE,
          ...reqParams.headers
        }
      : { "content-type": DEFAULT_CONTENT_TYPE };
    reqParams.headers = headers;
    console.log("reqParams", reqParams);

    if (reqParams.method === "POST" && reqParams.data) {
      // post请求，对body进行encode的处理
      body = contentTypeEncode(
        reqParams.headers["content-type"],
        reqParams.data,
        !reqParams.disableEncode
      );
    }
    const nativeDefaultRequestSet = {
      ...defaultParams,
      ...this.defaultconfig,
      ...reqParams
    };
    body && (nativeDefaultRequestSet.body = body);
    return nativeDefaultRequestSet;
  }

  /**
   * Request
   * @param param0
   */
  public async request(requestParams: RequestParams) {
    try {
      const nativeRequestSet: RequestParams = this.nativeRequestFactory(
        requestParams
      );
      const chain: any[] = [
        {
          resolved: this.requestAction,
          rejected: (error: any) => {
            return error;
          }
        }
      ];
      let promise: any = Promise.resolve(nativeRequestSet);

      this.interceptors.request.interceptors.forEach((interceptor: any) => {
        chain.unshift(interceptor);
      });
      this.interceptors.response.interceptors.forEach((interceptor: any) => {
        chain.push(interceptor);
      });
      // 通过while循环request的拦截器，发请求，response的拦截器
      while (chain.length) {
        const { resolved, rejected } = chain.shift()!;
        promise = promise.then(resolved, rejected);
      }
      return promise;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async requestAction(requestParams: HttpRequest) {
    try {
      const response: any = await Jsbridge.http(requestParams);
      response.request = requestParams;
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async get(url: string, params: any = null, extra: any = {}) {
    const data = {
      url,
      method: "GET",
      ...params,
      extra_data: extra
    };
    return await this.request(data);
  }

  public async post(url: string, params: any, extra: any = {}) {
    const data = {
      url,
      method: "POST",
      ...params,
      extra_data: extra
    };
    return await this.request(data);
  }
}
