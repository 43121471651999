import mutations from "@/store/mutations/setting_mutations";
import getters from "@/store/getters/setting_getters";
import actions from "@/store/actions/setting_actions";
/**
 * [GX] Setting 模块 分为多个模块，offers,客户端参数，公用参数等
 */
export default {
  state: {
    common: {},
    tab_follow: {},
    tab_like: {},
    tab_earn_coins: {},
    tab_coin_store: {},
    hasLoadedOnlineSetting: false, // 是否加载过线上setting
    hasLoadedPageSettingStatus: {
      // 是否已经加载过指定页面的setting
      follow: false,
      like: false
    }
  },
  mutations,
  getters,
  actions
};
