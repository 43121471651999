


































import { Component, Vue, Prop } from "vue-property-decorator";

@Component
export default class CoinFeed extends Vue {
  @Prop()
  private offer: any;

  private buyCoinFeed() {
    this.$emit("buyCoinFeed");
  }
}
