import cgRequest from "@/api/cg/base";
import * as cgConst from "@/api/cg/base/cg_const";
import { isIos } from "@/utils/lodash";
import { getDeviceId, getIDFA } from "@/utils/js_bridge_info";

/**
 * 登陆
 * @param masterId, userId
 */
export const login = async (
  masterId: string | number,
  userId?: string | number
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const next_page = isIos() ? getIDFA() : getDeviceId();
      const userInfo: any = {
        master_id: masterId.toString(),
        user_id: userId ? userId.toString() : masterId.toString()
      };
      const url = cgConst.GX_LOGIN(masterId.toString());
      const params = { user_info: userInfo, next_page };
      resolve(await cgRequest(url, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 关联账号
 * @param masterId, userId, username: ins账号名, profile_pic_url: ins头像
 */
export const linkAccount = async (
  masterId: string | number,
  userId: string | number,
  username: string,
  profile_pic_url: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const userInfo: any = {
        master_id: masterId.toString(),
        user_id: userId.toString(),
        username,
        profile_pic_url
      };
      const url = cgConst.LINK_ACCOUNT(userId.toString());
      const params = { user_info: userInfo };
      resolve(await cgRequest(url, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 获取订阅列表
 * @param deviceId
 */
export const getServiceList = async (deviceId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        master_id: deviceId
      };
      resolve(await cgRequest(cgConst.SERVICE_LIST, "GET", params));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 获取用户信息
 * @param userId
 */
export const getUserInfoById = async (userId: string | number) => {
  return new Promise(async (resolve, reject) => {
    try {
      resolve(await cgRequest(cgConst.GET_USER_INFO(userId), "GET"));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 解除子账户的关联
 * @param masterId, userId
 */
export const disconnectUser = async (
  masterId: string | number,
  userId?: string | number
) => {
  return new Promise(async (resolve, reject) => {
    try {
      userId = userId ? userId : masterId;
      const params = {
        user_info: {
          master_id: masterId.toString(),
          user_id: masterId.toString()
        }
      };
      const url = cgConst.DISCONNECT_USER(userId.toString());
      resolve(await cgRequest(url, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 判断free follow的领取权限
 * @param masterId, userId
 */
export const checkFreeFollowers = async (
  masterId: string | number,
  userId: string | number
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        master_id: masterId.toString(),
        user_id: userId.toString()
      };
      resolve(await cgRequest(cgConst.CHECK_FREE_FOLLOW, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 领取free follow
 * @param masterId, userId, insInfo: ins账号相关信息
 */
export const deliverFreeFollowers = async (
  masterId: string | number,
  userId: string | number,
  insInfo: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        master_id: masterId.toString(),
        user_id: userId.toString(),
        resource: insInfo
      };
      resolve(await cgRequest(cgConst.DELIVER_FREE_FOLLOW, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 请求广告的奖励
 * @param masterId, platform: GETX_PLATFORM, type: 广告类型 枚举项['admob', 'applovin', 'vungle']
 */
export const advertisementAct = async (
  masterId: string | number,
  platform: string,
  ad_type: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        master_id: masterId.toString(),
        platform,
        ad_type
      };
      resolve(await cgRequest(cgConst.ADVERTISEMENT_ACT, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

/**
 * 判断广告类型是否被允许
 * @param masterId, platform: GETX_PLATFORM, type: 广告类型数组 枚举项['admob', 'applovin', 'vungle']
 */
export const advertisementList = async (
  masterId: string | number,
  platform: string,
  ad_types: string[]
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        master_id: masterId.toString(),
        platform,
        ad_types: JSON.stringify(ad_types)
      };
      resolve(await cgRequest(cgConst.ADVERTISEMENT_LIST, "GET", params));
    } catch (e) {
      reject(e);
    }
  });
};

/*
 * 如果下架，转移主账号内容
 * */
export const transferMasterAccount = async (
  oldUserId: string | number,
  newUerId: string | number,
  oldPlatform: string,
  newPlatform: any = null
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params: any = {
        old_user_id: oldUserId.toString(),
        dest_user_id: newUerId.toString(),
        old_platform: oldPlatform
      };
      if (newPlatform) {
        params.dest_platform = newPlatform;
      }
      resolve(await cgRequest(cgConst.TRANSFER_MASTER_ACCOUNT, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

export const activeTask = async (masterId: string | number, taskId: string) => {
  return new Promise(async (resolve, reject) => {
    try {
      const params = {
        task_id: taskId,
        transaction_id: `${masterId}${new Date().getTime()}`,
        user_info: {
          master_id: masterId.toString(),
          master_name: masterId.toString()
        },
        resource: {}
      };
      const url = cgConst.ACTIVE_TASK(masterId);
      resolve(await cgRequest(url, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

export const bindInsUserVip = async (
  user_id: string,
  username: string,
  profile_pic_url: string,
  masterId: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const resource = {
        user_id,
        username,
        profile_pic_url
      };
      const params = {
        master_id: masterId,
        service_type: "vip",
        target_user_id: user_id,
        lang: "zh",
        resource
      };
      resolve(await cgRequest(cgConst.BIND_VIP, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};
