import nativeStorage from "@/utils/nativeStorage/native_storage";
import { XI_CUSTOM_USER_ID } from "@/utils/nativeStorage/local_keys";
import { isIos } from "@/utils/lodash";
import { randomNum } from "@/utils/num_utils";
import LaunchHelpers from "@/utils/launch_helpers";

let customerUserId: any = null;

export const getStatusBarHeight = () => {
  const deviceInfo = getDeviceInfo();
  const statusBar = deviceInfo && deviceInfo.status_bar_height;
  return statusBar ? statusBar : 0;
};
// 判断是否是iphoneX系列的手机
export const isIphoneX = () => {
  const deviceInfo = getDeviceInfo();
  const statusBar = deviceInfo && deviceInfo.status_bar_height;
  return !!(statusBar > 20 && isIos());
};

export const getDeviceInfo = () => {
  const deviceInfo = LaunchHelpers.getLaunchData("deviceInfo");
  console.log("deviceInfo", deviceInfo);
  return deviceInfo;
};

export const getLaunchParams = () => {
  const launchParams = LaunchHelpers.getLaunchData("launchParams");
  return launchParams;
};

export const getRegisterNotification = () => {
  const launchParams = getLaunchParams();
  return launchParams &&
    launchParams.hasOwnProperty(
      "did_register_for_remote_notifications_with_device_token"
    )
    ? launchParams.did_register_for_remote_notifications_with_device_token
    : null;
};

export const getOpenUrl = () => {
  const launchParams = getLaunchParams();
  return launchParams && launchParams.hasOwnProperty("open_url")
    ? launchParams.open_url
    : null;
};

export const getNotification = () => {
  const launchParams = getLaunchParams();
  return launchParams && launchParams.hasOwnProperty("notification")
    ? launchParams.notification
    : null;
};

export const getReleaseParams = () => {
  const launchParams = getLaunchParams();
  return launchParams && launchParams.hasOwnProperty("release_params")
    ? launchParams.release_params
    : null;
};

/**
 * 广告 id
 */
export const getIDFA = () => {
  const deviceInfo = getDeviceInfo();
  const { advertising_identifier } = deviceInfo;
  return advertising_identifier;
};

/**
 * 获取设备id
 */
export const getDeviceId = () => {
  const deviceInfo = getDeviceInfo();
  const { device_id } = deviceInfo;
  return device_id;
};

export const getBundleId = () => {
  return getDeviceInfo().info.identifier;
};

export const getIDFV = () => {
  const deviceInfo = getDeviceInfo();
  const { device } = deviceInfo;
  const { identifier_for_vendor } = device;
  return identifier_for_vendor;
};

export const getAndroidId = () => {
  const deviceInfo = getDeviceInfo();
  const { device } = deviceInfo;
  return device && device.android_id;
};

export const getAndroidGaid = () => {
  return getIDFA();
};

export const getShortVersion = () => {
  return getDeviceInfo().info && getDeviceInfo().info.short_version;
};

const randomWord = (length: number) => {
  let str = "";
  // a-z 的ASCII码 97～122
  for (let i = 0; i < length; i++) {
    const pos: any = randomNum(97, 122);
    str += String.fromCharCode(pos);
  }
  return str;
};

export const getCustomId = async () => {
  if (customerUserId) {
    return customerUserId;
  } else {
    if (await nativeStorage.has(XI_CUSTOM_USER_ID)) {
      customerUserId = await nativeStorage.get(XI_CUSTOM_USER_ID);
      return customerUserId;
    } else {
      // 生成custom_id:
      const deviceInfo = getDeviceInfo();
      const { info } = deviceInfo;
      const { display_name } = info;
      customerUserId = `${display_name}_${new Date().valueOf()}_${randomWord(
        8
      )}`;
      await nativeStorage.set(XI_CUSTOM_USER_ID, customerUserId);
      return customerUserId;
    }
  }
};

export const getPinadDeviceId = () => {
  // TODO需要和后台联调，特别是安卓平台，ios是否直接用原生提供的device_id
  if (isIos()) {
    const deviceInfo = getDeviceInfo();
    const md5 = require("crypto-js/md5");
    // 如果存在idfa且不为0，则直接md5加密，否则需要自己拼接然后md5加密
    if (getIDFA() && getIDFA() !== "00000000-0000-0000-0000-000000000000") {
      return md5(getIDFA()).toString();
    } else {
      const datajson = {
        name: deviceInfo.device.name,
        system: deviceInfo.device.system_name,
        system_version: deviceInfo.device.system_version,
        model: deviceInfo.device.model,
        localmodel: deviceInfo.device.localized_model,
        country: deviceInfo.locale.identifier,
        language: deviceInfo.locale.preferred_language,
        ip: deviceInfo.device_ip
      };
      return md5(JSON.stringify(datajson)).toString();
    }
  } else {
    return getDeviceId();
  }
};

export const getNativeCodeVersion = () => {
  return getDeviceInfo().native_code_version;
};

export const getAppPlatform = () => {
  return getDeviceInfo().app_platform;
};

export const getSystemVersion = () => {
  return getDeviceInfo().device.system_version;
};

export const getMachine = () => {
  return getDeviceInfo().machine;
};

export const getLang = () => {
  return getDeviceInfo().locale.language_code;
};
