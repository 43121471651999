import mutations from "../mutations/index_mutations";
import actions from "../actions/index_actions";
import getters from "../getters/index_getters";

export default {
  state: {
    current_user_id: null, // 当前INS账号的ID
    master_user_id: null // GX主账号的ID
  },
  mutations,
  actions,
  getters
};
