/*
 * @Author: Jhonsem
 * @Date: 2020-12-21 16:37:06
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2020-12-21 18:04:10
 * 本地setting的关键存取
 */
import { store } from "@/main";
import * as types from "@/store/constants/setting_types";

const local_setting_init = async () => {
  try {
    const result = await store.dispatch(types.INIT_GX_LOCAL_SETTING_DATA);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default local_setting_init;
