/*
 * 注册全局的svg组件
 */
import _Vue from "vue";
import SvgIcon from "@/components/IconSvg/IconSvg.vue";

// This exports the plugin object.
export const SvgIconPlugin = {
  install(Vue: typeof _Vue, options: any) {
    Vue.component("SvgIcon", SvgIcon);
  }
};
