








import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import Jsbridge from "@/jsbridge";
import ImgCache from "@/utils/img_cache/img_cache.ts";
import { base64ToString } from "@/api/utils/api_encrypt";

@Component({
  components: {}
})
export default class ImgFetched extends Vue {
  @Prop({ default: "" })
  private src!: string;
  @Prop({ default: "" })
  private defaultImg!: string;
  @Prop({ default: "" })
  private imgKind!: string;
  @Prop({ default: "" })
  private defaultImgWidth!: string;
  @Prop({ default: false })
  private border!: boolean;

  private actual_src: string = "";
  private default_prefix: string = "data:image/jpeg;base64,";
  private showActual: boolean = false;

  get ImgStyle() {
    return !this.showActual ? { width: this.defaultImgWidth + "px" } : "";
  }

  get showBorder() {
    return this.border && this.actual_src === this.defaultImgSrc();
  }

  private defaultImgSrc() {
    return this.defaultImg
      ? this.defaultImg
      : this.imgKind === "user"
      ? require("@/assets/icons-file/sku/placehold_people.png")
      : this.imgKind === "post"
      ? require("@/assets/icons-file/sku/placehold_post.png")
      : require("@/assets/icons-file/common/pic_error.png");
  }

  @Watch("src", { immediate: true })
  private async nativeFetch(val: boolean, oldVal: boolean) {
    try {
      this.showActual = false;
      this.actual_src = this.defaultImgSrc();
      const isBase64Pic = this.src.includes("data:image");
      if (isBase64Pic) {
        this.showActual = true;
        this.actual_src = this.src;
      } else {
        if (this.src && ImgCache.getImgCache(this.src)) {
          this.showActual = true;
          this.actual_src = ImgCache.getImgCache(this.src);
        } else {
          const temp_src = this.src; // 为避免还未拉取完全前就切换，导致异步行为滞后赋值，需要比对
          const response: any = await Jsbridge.http({
            headers: {},
            url: this.src,
            method: "GET",
            timeout_interval: 5
          });
          // this.actual_src = "";
          try {
            base64ToString(response.data);
            this.actual_src = this.defaultImgSrc();
          } catch (e) {
            // 需要比对，以免错位
            if (temp_src === this.src) {
              this.showActual = true;
              this.actual_src = this.default_prefix + response.data;
              ImgCache.setImgCache(this.src, this.actual_src);
            }
          }
        }
      }
    } catch (e) {
      if (this.src && ImgCache.getImgCache(this.src)) {
        this.showActual = true;
        this.actual_src = ImgCache.getImgCache(this.src);
      } else {
        this.actual_src = this.defaultImgSrc();
      }
    }
  }
}
