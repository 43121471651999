import { CacheModule } from "@/services/cacheModule";
import { PRICE_OFFER_MAP } from "@/utils/nativeStorage/local_keys";

class PriceOfferMap extends CacheModule {
  constructor() {
    super(PRICE_OFFER_MAP, {}, true);
  }
  public async getCacheSource() {
    const cacheInfo = await this.init(PRICE_OFFER_MAP);
    return cacheInfo;
  }
}
export default new PriceOfferMap();
