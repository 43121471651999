import Vue from "vue";
import Vuex from "vuex";
import setting_modules from "@/store/modules/setting_module";
import cg_user_modules from "@/store/modules/cg_user_modules";
import index_module from "./modules/index_module";
import tt_user_module from "@/store/modules/tt_user_module";
import tt_media_module from "@/store/modules/tt_media_module";
import createLogger from "vuex/dist/logger";
import vuex_persisit_plugin from "./plugins/vuex_persisit";

Vue.use(Vuex);

// storage存储用于原生提供的nativeStroa
const vuex_plugins = [vuex_persisit_plugin.plugin];

// vuex logger的开启
if (process.env.VUE_APP_VUEX_LOGGER === "ON") {
  vuex_plugins.push(
    createLogger({
      collapsed: true // vuex logger
    })
  );
}

export default new Vuex.Store({
  modules: {
    index_module,
    setting_modules,
    cg_user_modules,
    tt_user_module,
    tt_media_module
  },
  plugins: vuex_plugins,
  strict: process.env.NODE_ENV !== "production"
});
