import { Module } from "@/services/module";
import { RetryParamsTypes } from "./types";

class RetryParams extends Module {
  constructor() {
    super({});
  }
  public setRetry(params: RetryParamsTypes) {
    this.set(params);
  }

  public getRetry(): RetryParamsTypes {
    return this.get();
  }
}
export default new RetryParams();
