export const FOLLOWER_FEED = "follow";
export const LIKE_FEED = "like";
export const COMMENT_FEED = "comment";
export const VIDEO_AD_FEED = "feed_video_ad";
export const SUPER_PAY_FEED = "feed_super_pay";
export const BACK_UP_FEED = "feed_back_up";
export const COIN_FEED = "feed_coin";
export const VIP_FEED = "feed_vip";
export const LUCK_COINST_FEED = "feed_lucky_coin";

export const POST_FEED_KINDS = [LIKE_FEED, COMMENT_FEED];
export const RANDOM_FEED_KINDS = [COIN_FEED, LUCK_COINST_FEED, VIDEO_AD_FEED];

export const TT_FEED_KINDS = [FOLLOWER_FEED, LIKE_FEED];
export const PURCHASE_FEED_KINDS = [COIN_FEED, LUCK_COINST_FEED];

export const MIN_FEEDS_STACK_NUM = 5;
export const MAX_RETRY_PULL_FEEDS_NUM = 5;

// tt接口校验feed，失败的code，需要上报给后台
export const FEED_INVALID_ERROR_CODE_LIST = [10201, 10202, 10204, 10217];
// 当actFeed接口报错当前feed已经被加币的错误码
export const HAS_REWARD_FEED_ERROR_CODE = 22001;

export const MAX_FEEDS_COUNT = 40; //最大保持队列的数量

/**
 * 是否是tt类型的feed
 * @param feed
 */
export const is_tt_feed = (feed: any) => {
  //
  return feed && feed.kind && TT_FEED_KINDS.includes(feed.kind);
};

export const is_ads_feed = (feed: any) => {
  //
  return feed && feed.kind && feed.kind === VIDEO_AD_FEED;
};

/**
 * 是否是购买类型的feed
 * @param feed
 */
export const is_purchase_feed = (feed: any) => {
  return feed && feed.kind && PURCHASE_FEED_KINDS.includes(feed.kind);
};

export const is_task_feed = (feed: any) => {
  return !is_tt_feed(feed);
};
