import { Feed } from "../types/type";

/**
 * kind的类型的过滤
 * @param ori_feeds
 */
export const filter_invalid_kind_feeds = (
  ori_feeds: Feed[],
  invalid_kinds: string[]
) => {
  //
  return ori_feeds.filter((feed: Feed) => {
    return invalid_kinds.includes(feed.kind);
  });
};

/**
 * 从cache和已经做过的feeds中，过滤掉拉取到的feeds
 * @param ori_feeds
 */
export const filter_same_id_feeds = (
  ori_feeds: Feed[],
  cache_feeds: Feed[]
) => {
  return ori_feeds.filter((feed: Feed) => {
    // return;
    const find_same_feed = cache_feeds.find((cache_feed: Feed) => {
      return cache_feed.feed_id === feed.feed_id;
    });
    return !find_same_feed;
  });
};
