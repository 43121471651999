import { getUUid } from "@/api/utils/api_helpers";
import { log } from "@/utils/console";

interface ListenerOptions {
  key?: string;
}

class ListenNative {
  public listenerManager: any = {};

  /**
   * addListen from native
   * @param action
   * @param Fn
   */
  public async addListen(
    action: string,
    CallBack: (res: any) => void,
    options?: undefined | ListenerOptions
  ) {
    try {
      log("【JS_BRIGE addListen", {
        action
      });
      let key: string = "";
      if (options && options.key) {
        key = options.key;
      } else {
        key = await getUUid();
      }

      const task = {
        action,
        callback: (event: any) => {
          console.log("addEventListener Fire:", event);
          const { response } = event;
          CallBack(response);
        }
      };

      if (!(key in this.listenerManager)) {
        // no exist
        this.listenerManager[key] = [];
      }

      this.listenerManager[key].push(task);

      console.log("listenerManager:", this.listenerManager);

      document.addEventListener(action, task.callback, false);

      return key;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async removeListen(key: string) {
    try {
      log("【JS_BRIGE removeListen", {
        key
      });

      if (key in this.listenerManager) {
        // exist
        this.listenerManager[key].forEach((task: any) => {
          document.removeEventListener(task.action, task.callback, false);
        });

        delete this.listenerManager[key];

        console.log("listenerManager:", this.listenerManager);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
}

export default new ListenNative();
