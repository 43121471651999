import {
  FETCH_TIKTOK_MEDIA_LIST,
  UPDATE_TIKTOK_MEDIA_LIST,
  LOAD_MORE_MEDIA_LIST,
  GETTER_CURRENT_TIKTOK_USER_MEDIA,
  FETCH_FIRST_PAGE_MEDIA_LIST,
  REFRESH_CURRENT_USER_MEDIA_LIST,
  CHECK_FORCE_LOGIN
} from "@/store/constants/tt_media_types";
import {
  GETTER_CURRENT_TIKTOK_USER_INFO,
  TIKTOK_LOGIN_USER_EMPTY
} from "@/store/constants/tt_user_types";
import { GETTER_LIKE_FORCE_LOGIN_ENABLED } from "@/store/constants/setting_types";

import {
  // eslint-disable-next-line
  OneMediaInfo,
  // eslint-disable-next-line
  OneUserMediaInfoList,
  // eslint-disable-next-line
  UpdateTikMediaInfo
} from "@/store/modules/tt_media_module";
import { tt_user_item_list } from "@/api/tt-api/tt_api_media/tt_api_media";
import { forceLoginDialog } from "@/services/dialog_utils/index";

export const single_post_clean = (item: any) => {
  const {
    stats,
    video,
    id,
    forFriend,
    secret,
    digged,
    itemCommentStatus
  } = item;
  return {
    comment_count: stats.commentCount,
    display_url: video.playAddr, // 视频地址
    image_low: video.originCover,
    image_high: video.dynamicCover, // 动图
    image_thumb: video.cover,
    video_id: video.id,
    id,
    digg_count: stats.diggCount,
    digged,
    for_friend: forFriend,
    secret,
    video_play_count: stats.playCount,
    comment_status: itemCommentStatus === 0 //等于0是可以评论
  };
};

const getNewItems: (items: any) => OneMediaInfo[] = (items: any) => {
  if (!items) {
    return [];
  }
  return items.map(single_post_clean);
};

export default {
  async [CHECK_FORCE_LOGIN]({ getters, commit }: any, payload: any) {
    try {
      const { userInfo, from } = payload;
      const { login_type } = userInfo;
      const loginEmpty = getters[TIKTOK_LOGIN_USER_EMPTY];
      // 如果添加了登陆账号，不需要触发弹窗:
      if (login_type && login_type === "search" && loginEmpty) {
        const like_force_login = getters[GETTER_LIKE_FORCE_LOGIN_ENABLED];
        if (like_force_login) {
          // dialog
          forceLoginDialog(from);
          return false;
        }
      }
      return true;
    } catch (error) {
      return false;
    }
  },

  async [FETCH_TIKTOK_MEDIA_LIST](
    { getters, commit, dispatch }: any,
    payload?: string
  ) {
    try {
      const currentUserInfo = getters[GETTER_CURRENT_TIKTOK_USER_INFO];
      if (!currentUserInfo) {
        return;
      }
      // 检查是否需要login_force
      const force_login_check = await dispatch(CHECK_FORCE_LOGIN, {
        userInfo: currentUserInfo,
        from: payload
      });
      console.log("force_login_check", force_login_check);
      if (!force_login_check) {
        return;
      }
      let max_cursor = "0";
      const min_cursor = "0"; // tt native script没有使用这个字段
      if (payload && payload !== "from_like") {
        max_cursor = payload;
      }
      const { response } = await tt_user_item_list(
        currentUserInfo.tt_user_info.uniqueId,
        currentUserInfo.user_id,
        min_cursor,
        max_cursor
      );
      console.log("tt_user_item_list", response);
      const { statusCode, hasMore, items, maxCursor } = response;

      if (statusCode === 0) {
        const tikMediaInfoList: OneUserMediaInfoList = {
          user_id: currentUserInfo.user_id,
          tt_media_list: getNewItems(items),
          has_more: hasMore,
          max_cursor: !items || items.length < 1 ? "" : maxCursor
        };
        const updateMeida: UpdateTikMediaInfo = {
          tikMediaInfoList,
          upsateStatus: max_cursor === "0" ? "new" : "more"
        };
        commit(UPDATE_TIKTOK_MEDIA_LIST, updateMeida);
      }
      return true;
    } catch (error) {
      console.log("FETCH_TIKTOK_MEDIA_LIST err", error);
      return Promise.reject(error);
    }
  },
  async [LOAD_MORE_MEDIA_LIST]({ getters, dispatch }: any) {
    try {
      const currentMediaInfo = getters[GETTER_CURRENT_TIKTOK_USER_MEDIA];
      await dispatch(FETCH_TIKTOK_MEDIA_LIST, currentMediaInfo.max_cursor);
      return true;
    } catch (error) {
      console.log("LOAD_MORE_MEDIA_LIST err", error);
      return Promise.reject(error);
    }
  },
  async [FETCH_FIRST_PAGE_MEDIA_LIST](
    { getters, dispatch }: any,
    from: string
  ) {
    try {
      const currentUserInfo = getters[GETTER_CURRENT_TIKTOK_USER_INFO];
      const currentMediaInfo = getters[GETTER_CURRENT_TIKTOK_USER_MEDIA];
      if (currentUserInfo && !currentMediaInfo) {
        await dispatch(FETCH_TIKTOK_MEDIA_LIST, from);
      }
    } catch (error) {
      console.log("FETCH_FIRST_PAGE_MEDIA_LIST err", error);
      return Promise.reject(error);
    }
  },
  async [REFRESH_CURRENT_USER_MEDIA_LIST]({ getters, dispatch }: any) {
    try {
      const currentUserInfo = getters[GETTER_CURRENT_TIKTOK_USER_INFO];
      if (currentUserInfo) {
        await dispatch(FETCH_TIKTOK_MEDIA_LIST);
      }
      return true;
    } catch (error) {
      console.log("REFRESH_CURRENT_USER_MEDIA_LIST err", error);
      return Promise.reject(error);
    }
  }
};
