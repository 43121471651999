/*
 * @Author: Jhonsem
 * @Date: 2019-08-29 19:35:07
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2019-08-29 20:57:26
 * 1.获取url
 * 2.download
 * 3.save local path and timestamp to IndexDB
 */

import { urlToFilesName } from "./files_helpers";
import { downloadRequestBase } from "./download_base";

const CACHE_DEFAULT_DIRECTORY_NAME = "download_files"; // 默认存的目录名
/**
 *
 * @param download_url
 * @param file_name
 * @param cache_directory
 */
export const downloadFiles = async (
  download_url: string,
  file_name?: string,
  cache_directory: string = CACHE_DEFAULT_DIRECTORY_NAME
) => {
  try {
    if (!file_name) {
      file_name = urlToFilesName(download_url);
    }
    const result = await downloadRequestBase(
      download_url,
      file_name,
      cache_directory
    );
    console.log("downloadFiles result:", result);

    return result;
  } catch (error) {
    console.log("downloadFiles error:", error);
    return Promise.reject(error);
  }
};
