import { Component, Prop, Vue } from "vue-property-decorator";
import { format } from "@/utils/num_utils";
@Component
class CoinContent extends Vue {
  @Prop()
  private ctx: any;
  @Prop()
  private offer: any;
  @Prop()
  private user_coins: any;

  private spanStyle = "font-size: 16px;line-height: 22px;color: #EDA413;";
  private divStyle =
    "font-size: 16px;line-height: 22px;  color: rgb(140,140,140);padding-bottom:20px;";

  public render() {
    return (
      <div style={this.divStyle}>
        Need{" "}
        <span style={this.spanStyle}>
          {format(this.offer && this.offer.coins)} coins
        </span>
        , you only have&nbsp;
        <span style={this.spanStyle}>{format(this.user_coins)} coins</span>.
        Would you like to get more coins?
      </div>
    );
  }
}

export default CoinContent;
