


















import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import NavBar from "@/components/NavBar/NavBar.vue";
import MoreUserInfo from "./MoreUserInfo/MoreUserInfo.vue";
import MoreEntrance from "@/views/More/MoreEntrance/MoreEntrance.vue";

@Component({
  components: {
    NavBar,
    MoreUserInfo,
    MoreEntrance
  }
})
export default class More extends Vue {
  private toSetting() {
    this.$router.push({
      name: "Setting",
      query: {
        routerTransition: "forward"
      }
    });
  }
}
