import { store } from "@/main";
import * as setting_types from "@/store/constants/setting_types";
import * as cg_user_types from "@/store/constants/cg_user_types";
import * as tt_user_types from "@/store/constants/tt_user_types";
import ServiceList from "@/services/serviceList";
import { Offer, OfferKind } from "@/services/offer/offer_handle";
import { VipItem } from "@/services/serviceList/types";
import { formatToThousands } from "@/utils/lodash";

export const isPostKindOffer = (offer: any) => {
  try {
    const { kind } = offer;
    const postKindList: string[] = [OfferKind.like, OfferKind.special_like];
    return postKindList.includes(kind);
  } catch (error) {
    return false;
  }
};

export const isVipOrCoins = (offer: Offer) => {
  const { kind } = offer;
  return kind.includes(OfferKind.coin) || kind.includes(OfferKind.vip);
};

export const isSubsOffer = (offer: Offer) => {
  // 判断这个offer是否是订阅类型的offer
  try {
    let result: boolean;
    if (offer.kind === OfferKind.vip) {
      return true;
    } else {
      result = offer.hasOwnProperty("subscription");
    }
    return result;
  } catch (error) {
    return false;
  }
};

// doubleCoins percentage是否有效
const isDoubleCoinsPercentageValid = (percentage: number): boolean => {
  return percentage === 200 || percentage === 300 || percentage === 400;
};

const isDoubleCoinsOfferValid = (best_offer: any) => {
  const coins_offer = store.getters[setting_types.GETTER_COIN_OFFERS];
  if (!best_offer && !coins_offer) {
    return false;
  }
  return coins_offer.some((item: any) => {
    return best_offer.includes(item.offer_id);
  });
};

// 是否是doubleCoins
export const isDoubleCoins = (): boolean => {
  try {
    const currentTime = new Date().valueOf();
    const sale = store.getters[setting_types.GETTER_COIN_LIMIT_SALE];
    if (!sale) {
      return false;
    }
    if (currentTime >= sale.start_time && currentTime <= sale.end_time) {
      const { percentage, best_offer } = sale;
      return !!(
        percentage &&
        isDoubleCoinsPercentageValid(percentage) &&
        isDoubleCoinsOfferValid(best_offer)
      );
    }
    return false;
  } catch (error) {
    return false;
  }
};

const getVipList = (): VipItem[] => {
  const vipList: VipItem[] = ServiceList.get();
  if (vipList && Array.isArray(vipList) && vipList.length > 0) {
    return vipList.filter((v: any) => v.service_type === "vip");
  } else {
    return [];
  }
};

export const boughtVip = (): boolean => {
  return getVipList() && getVipList().length > 0;
};

export const isValidVipInServicelist = (): VipItem | undefined | null => {
  const currentTime = store.getters[cg_user_types.GETTER_SERVER_CURRENT_TIME];
  const vipList: VipItem[] = getVipList() ? getVipList() : [];
  if (vipList && vipList.length > 0) {
    return vipList.find((v: any) => v.expiration_timestamp > currentTime);
  } else {
    return null;
  }
};

// 是否有未绑定的vip
export const isHasNotBindVip = (): boolean => {
  const validVip: any = isValidVipInServicelist();
  if (validVip && validVip.target_user_id && isNaN(validVip.target_user_id)) {
    return true;
  } else {
    return false;
  }
};

// 判断是否是vip
export const isVip = (): boolean => {
  return !!isValidVipInServicelist();
};

export const vipIsTryForFree = (offer: Offer): boolean => {
  return offer && offer.effect === 1 && !offer.is_no_auto_renew;
};

/*
 * 判断是否存在登陆账号
 * */
export const existLoginUser = (): boolean => {
  return !store.getters[tt_user_types.TIKTOK_LOGIN_USER_EMPTY];
};

export const existUser = (): boolean => {
  return !store.getters[tt_user_types.GETTER_TIKTOK_USER_EMPTY];
};

// 获取lucky coins的占位显示数量
export const getLuckyCoinsPlaceHolder = (offer: Offer) => {
  const lucky_effect = offer && offer.lucky_effect;
  const min = lucky_effect && lucky_effect.min ? lucky_effect.min : 250;
  const max = lucky_effect && lucky_effect.max ? lucky_effect.max : 1000000;
  return formatToThousands(min) + "~" + formatToThousands(max);
};
