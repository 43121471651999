import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class FollowDifferenceBtn extends Vue {
  @Prop()
  private ctx: any;

  public render() {
    return (
      <a
        href="javascript:;"
        onClick={this.clickClose}
        style="display:block;margin:0 15px;background:#ff3d59;font-family:AppleSDGothicNeo-Medium, Poppins;color:#fff;padding:9px 0;text-align:center;line-height:26px;font-size:16px;border-radius:8px;"
      >
        OK
      </a>
    );
  }
  private clickClose() {
    this.ctx.isShow = false;
  }
}

export default FollowDifferenceBtn;
