import Vue from "vue";
import router from "./router";
import store from "./store";
import "@/styles/iconfont.css";
import "swiper/dist/css/swiper.css";
// import "@/plugins/img_error/index"; // img error
import PopupDialog from "@/plugins/popupDialog";
import Dialog from "@/plugins/dialog";
import Toast from "@/plugins/toast";
import Navigation from "@/plugins/navigation/index";
import initLaunch from "@/utils/init_launch/init_launch";
import LocalizeUtil from "@/services/payment/localize";
import { format } from "@/utils/num_utils";
import { SvgIconPlugin } from "@/plugins/svg_icon";
import { ImgSrcChangePlugin } from "@/plugins/directives/img_src_change";
import {
  getIapEffect
  // getOriginalPriceByOffer
} from "@/plugins/filters/offer_filter";
import { error_monitor_init } from "@/utils/error_monitor/error_monitor";
import "lib-flexible/flexible.js";
import LaunchTimeCache, {
  PeriodType
} from "@/utils/log_event/launch_time_event/launchTimeCache";
import { sendWebVitalEvent } from "@/utils/log_event/web_vitals";

// 记录启动的时间戳
LaunchTimeCache.setTime();
// 错误监控需要尽早执行load
if (process.env.NODE_ENV === "production") {
  error_monitor_init();
}
sendWebVitalEvent();

LaunchTimeCache.setPeriod(PeriodType.SENTRY_INIT_PERIOD);

Vue.use(PopupDialog);
Vue.use(Dialog);
Vue.use(Toast);
Vue.use(SvgIconPlugin);
Vue.use(ImgSrcChangePlugin);
Vue.use(Navigation, { router });

Vue.filter("format", (num: any) => {
  return format(num);
});
/*
 * 全局过滤器处理数据本地化*/
Vue.filter("localizePriceByOffer", (offer: any, pay_pay?: any) => {
  return LocalizeUtil.getLocalizePriceByOffer(offer, pay_pay);
});

Vue.filter("localizePrice", (price: string) => {
  return LocalizeUtil.getLocalizePriceByPrice(price);
});

Vue.filter("getIapEffect", (num: any) => {
  return getIapEffect(num);
});

// Vue.filter("originalPriceByOffer", (offer: any) => {
//   return getOriginalPriceByOffer(offer);
// });

Vue.filter("originalPriceByOffer", (offer: any) => {
  return LocalizeUtil.getOriginalPriceByOffer(offer);
});

Vue.config.productionTip = false;
LaunchTimeCache.setPeriod(PeriodType.VUE_USE_AND_FILTER);
initLaunch();

export { router, store, Vue };
