/**
 * 检查的类型
 */

import { store, Vue } from "@/main";
import {
  GETTER_TIKTOK_USER_EMPTY,
  GETTER_CURRENT_TIKTOK_USER_INFO
} from "@/store/constants/tt_user_types";
import { Offer, OfferKind, OfferWay } from "@/services/offer/offer_handle";
import * as cg_user_types from "@/store/constants/cg_user_types";
import TiktokUserMediaUtil from "@/utils/tik_user_media/tik_user_media";
import { tt_item_detail } from "@/api/tt-api/tt_api_media/tt_api_media";
import Loading from "@/plugins/toast/loading";

// 是否有用户
export const emptyUser = () => {
  const currentUserEmpty = store.getters[GETTER_TIKTOK_USER_EMPTY];
  return !!currentUserEmpty;
};

// 是否缺少金币
export const lackCoins = (offer: Offer) => {
  // coins购买类型的offer
  const isCoinsWay = offer.way === OfferWay.coin;
  // 用户coins数量
  const user_coin = store.getters[cg_user_types.GETTER_COINS];
  // 订单coins数量
  const offer_coins = offer.coins ? offer.coins : 0;
  return isCoinsWay && user_coin < offer_coins;
};

// 是否是无post的情况下买like或者comment
export const buyLikeOrCommentWithoutPost = () => {
  const postInfo = TiktokUserMediaUtil.getCurrentPost();
  return !postInfo;
};

// 当前的post是否有效
export const currentPostIsInvalid = async () => {
  try {
    Loading.show();
    const currentPost = TiktokUserMediaUtil.getCurrentPost();
    const { id } = currentPost;
    return Promise.race([
      new Promise(async (resolve, reject) => {
        const res = await tt_item_detail(id);
        const statusCode = res && res.data && res.data.statusCode;
        const isInvalid =
          statusCode === 10204 || statusCode === 10215 || statusCode === 10216;
        resolve(isInvalid);
      }),
      new Promise((resolve, reject) => {
        setTimeout(() => {
          resolve(false);
        }, 10000);
      })
    ])
      .then((result: any) => {
        console.log("race then", result);
        Loading.hide();
        return Promise.resolve(result);
      })
      .catch(() => {
        console.log("race catch");
        Loading.hide();
        return Promise.resolve(false);
      });
  } catch (error) {
    console.log("error", error);
    Loading.hide();
    return Promise.resolve(
      error && error.response && error.response.error_type === "post_invalid"
    );
  }
};

// 是否是隐私账号
export const currentPostIsPrivate = () => {
  try {
    const currentPost = TiktokUserMediaUtil.getCurrentPost();
    const { for_friend, secret } = currentPost;
    return for_friend || secret;
  } catch (e) {
    return false;
  }
};

// 是否是大额购买
export const isLargePurchase = (offer: Offer) => {
  const isCoinsWay = offer.way === OfferWay.coin;
  const offer_coins = offer.coins ? offer.coins : 0;
  return isCoinsWay && offer_coins >= 2000;
};

export const isPrivateUser = () => {
  const currentUserInfo = store.getters[GETTER_CURRENT_TIKTOK_USER_INFO];
  return currentUserInfo.tt_user_info.secret;
};
