/*
 * 到货通知 或者 积分墙 收到后的弹窗等相关逻辑
 *
 * 远程通知：
 * 1.处于active,到货通知，需要弹框
 * 2.处于active,积分墙等视频，需要弹toast,刷一下金币
 * 3.处于
 *
 * 特殊点：有用户点击，并且通知是double coins这种活动，需要跳转到对应的路由去显示
 */
import { UmengPushData, CustomData } from "./umeng_push";
import PurchasedList from "@/services/payment/purchasedList";
import { store } from "@/main";
import * as types from "@/store/constants/cg_user_types";
import * as tt_user_types from "@/store/constants/tt_user_types";
import Event_bus from "@/utils/event_bus";
import { goToDoubleCoin } from "@/utils/router_helper";
import { Vue } from "@/main";
import routes from "@/plugins/navigation/routes";
class RemotePush {
  public receivePush(data: UmengPushData) {
    this.handlePush(data);
  }

  public handlePush(data: UmengPushData) {
    const { user_info, application_state, user_action } = data;
    const { custom_data } = user_info;
    const { type } = custom_data;
    if (type === "order_finished" || type === "daily_subs") {
      this.deliveredTask(custom_data);
    } else if (type === "offerwall") {
      const shouldAddCoin: boolean =
        !user_action || (user_action && application_state === "inactive");
      this.offerWallTask(custom_data, shouldAddCoin);
    } else if (type === "newbie_finished") {
      this.deliveredFreeFollowersTask(custom_data);
    } else if (type === "double_coin") {
      this.showDoubleCoin(custom_data);
    }
  }

  /** 到货 */
  public async deliveredTask(custom_data: CustomData) {
    try {
      const { transaction_id } = custom_data;
      const findOrder: any = PurchasedList.getOrderByTransitionId(
        transaction_id
      );
      if (findOrder) {
        // 到货弹窗，刷新对应的post或者followers
        const { resource } = findOrder;
        const { user_id, username, user_type, media_id, shortcode } = resource;
        console.log("到货findOrder:", findOrder);
        const offer_data = findOrder.offer_data || findOrder.receipt_data;
        Event_bus.$emit("show_order_completed", {
          show: true,
          data: findOrder
        });
        if (offer_data.kind.toLowerCase().includes("follow")) {
          store.dispatch(tt_user_types.REFRESH_CUREENT_TIKTOK_USER_INFO, {
            user_name: username,
            user_id,
            user_type
          });
        } else {
          // store.dispatch(types.REQUEST_ONE_MEDIA_INFO, {
          //   user_id,
          //   media_id,
          //   user_type,
          //   shortcode
          // });
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }

  /**
   * free follower的到货有点特殊，没有transaction_id
   * @param custom_data
   */
  public deliveredFreeFollowersTask(custom_data: CustomData) {
    try {
      //
      Event_bus.$emit("show_order_completed", {
        show: true,
        data: { isFreeFollow: true, image: custom_data.image }
      });
    } catch (error) {
      //
    }
  }

  /** 积分墙类型（视频等） */
  public offerWallTask(custom_data: CustomData, shouldAddCoin: boolean) {
    const { reward } = custom_data;
    if (reward && parseInt(reward, 10) > 0 && shouldAddCoin) {
      // 只拉取后更新最新的金币
      Vue.prototype.$_Toast.show({
        duration: 2000,
        type: "multiInfo",
        content: {
          text: `+ ${reward} Coins`
        }
      });
      store.dispatch(types.UPDATE_GX_USER_INFO, ["coins"]).catch(e => {
        void 0;
      });
    }
  }
  public showDoubleCoin(custom_data: CustomData) {
    const { expired }: any = custom_data;
    const nowTime = new Date().getTime();
    const isHome =
      routes && routes[0] && routes[0].path && routes[0].path.includes("Home");
    if (nowTime < expired && isHome) {
      goToDoubleCoin();
    }
  }
}

export default new RemotePush();
