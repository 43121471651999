import { Component, Prop, Vue } from "vue-property-decorator";
@Component
class PrivateAccount extends Vue {
  @Prop()
  private ctx: any;
  @Prop()
  private kind: any;

  private divStyle =
    "font-size: 16px;line-height: 24px;color: rgb(140,140,140);padding-bottom:20px";

  get contentText() {
    return this.kind && this.kind.includes("follow")
      ? `In order to get followers fast, we do require you to enable your TikTok account to be public in the official TikTok app.
   `
      : `In order to receive likes, we do require you to enable your TikTok account to be public in the official TikTok app.
   `;
  }

  public render() {
    return <div style={this.divStyle}>{this.contentText}</div>;
  }
}

export default PrivateAccount;
