






import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch
} from "vue-property-decorator";

@Component({
  components: {}
})
export default class Container extends Vue {}
