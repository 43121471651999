/*
 * @Author: jhonsem
 * @Date: 2021-01-28 14:53:08
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-01-29 14:12:31
 *
 * https://fonts.google.com/specimen/Noto+Sans?sidebar.open=true&selection.family=Noto+Sans:wght@700
 * https://fonts.google.com/specimen/Varela+Round?sidebar.open=true&selection.family=Varela+Round
 * https://fonts.google.com/specimen/Poppins?sidebar.open=true&selection.family=Poppins:wght@600
 */
import { isIos } from "@/utils/lodash";
import { WEB_FONT_URL } from "@/config/index";

export const font_loader = () => {
  window.WebFontConfig = {
    google: {
      families: ["Varela Round", "Poppins:500", "Noto Sans:700"]
    }
  };

  // 异步添加字体:

  var wf = document.createElement("script");
  const script: any = document.scripts[0];
  wf.src = WEB_FONT_URL;
  wf.async = true;
  script.parentNode.insertBefore(wf, script);
};
