import { responseToError } from "./download_interceptors";
import Http from "@/api/http/index.ts";
const http = new Http({});

http.interceptors.response.use(
  (res: any) => {
    return responseToError(res);
  },
  (error: any) => {
    return Promise.reject(error);
  }
);

/**
 * downloadRequestBase
 * @param download_url
 * @param file_name
 * @param cache_directory
 */
export const downloadRequestBase = (
  download_url: string,
  file_name: string,
  cache_directory: string
) => {
  return http.request({
    url: download_url,
    method: "GET",
    cache_name: file_name,
    cache_directory
  });
};
