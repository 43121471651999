import { Vue, store } from "@/main";
import { TRANSFORM_ACCOUNT } from "@/store/constants/index_types";
import generator from "@/services/open_pop_up/open_generator";
import { download_redirect_app } from "@/utils/app_download";
import FollowDifferenceContent from "@/components/DlalogTsx/FollowDifference/FollowDifferenceContent";
import FollowDifferenceBtn from "@/components/DlalogTsx/FollowDifference/FollowDifferenceBtn";
import WebSessionStorageWorker from "@/utils/nativeStorage/web_session_storage";
import RecommendAppHeader from "@/components/DlalogTsx/SuperPay/RecommendAppHeader.tsx";
import SuperPayButton from "@/components/DlalogTsx/SuperPay/SuperPayButton.tsx";
import SuperPayContent from "@/components/DlalogTsx/SuperPay/SuperPayContent.tsx";
import { RedirectApp } from "@/services/open_pop_up/types";
import {
  LOGIN_TIKTOK_USER_ACTION,
  DELETE_TIKTOK_LOGIN_USER,
  GEETER_TIKTOK_LOGIN_USER,
  GETTER_CURRENT_TIKTOK_USER_INFO
} from "@/store/constants/tt_user_types";
import { sendEvent } from "@/utils/log_event";
import { UIA_SP_CLICK, UIA_SP_SHOW } from "@/utils/log_event/event_const";
import SwitchBackgroundPosition, {
  SwitchPositionTypes
} from "@/utils/switch_background/switch_background_position";
import AutoPaymentCache from "@/services/super_pay/auto_payment_cache";
import { downloadApps } from "@/utils/app_download";
import Loading from "@/plugins/toast/loading";
import { back_up_show_rewards } from "@/services/super_pay/super_pay_helper";

export const showUpgradeApp = async (payload: RedirectApp) => {
  try {
    const { is_force_redirect, title, desc } = payload;
    await Vue.prototype.$_Dialog.show({
      avatar: require("@/assets/icons-file/dialog/picNewversionPopup@2x.png"),
      title,
      content: {
        text: desc
      },
      closeFun: true,
      closable: !is_force_redirect,
      cancelBtn: !is_force_redirect
        ? {
            text: "Not Now"
          }
        : {},
      confirmBtn: {
        text: "UPGRADE NOW"
      },
      onConfirm() {
        try {
          store.dispatch(TRANSFORM_ACCOUNT);
          download_redirect_app(payload);
          SwitchBackgroundPosition.setPosition(
            SwitchPositionTypes.NOT_REQUIRE_REFRESH
          );
        } catch (error) {
          console.log("download_back_up", error);
        }
      },
      onModalCloseWithIcon(done: any) {
        done();
        generator.next();
      },
      onCancel(done: any) {
        done();
        generator.next();
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const showFollowDifference = () => {
  try {
    Vue.prototype.$_Dialog.show({
      title: "Followers Differences",
      closeOnClickModal: true,
      closable: true,
      style: { padding: "0 17px 28px" },
      contentCustom(ctx: any, r: any) {
        return r(FollowDifferenceContent, { props: { ctx } });
      },
      confirmBtnCustom: (ctx: any, r: any) => {
        return r(FollowDifferenceBtn, { props: { ctx } });
      },
      onConfirm(done: any) {
        done();
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const showRecommendDialog = async (payload: any) => {
  const { super_pay, back_up, feature_apps, place, offer } = payload;
  let pic_placeholder: any;
  let content: any;
  let click_point_key = "";
  let dialog_class = "";
  let rewards: number = 0;
  if (super_pay) {
    pic_placeholder = require("@/assets/icons-file/super_pay/super_pay_placeholder/sp_place_n@3x.png");
    content = super_pay;
    dialog_class = "super_pay";
    click_point_key = "uia_superpay_app_click";
    const videoUrl = super_pay.video_url || super_pay.videoUrl;
    const videoPathList = videoUrl.split("/");
    const promo_video = videoPathList[videoPathList.length - 1];
    sendEvent(UIA_SP_SHOW, {
      situation: "payment_choice",
      style: "alert",
      promo_video
    });
    setTimeout(() => {
      AutoPaymentCache.set({ offer, pay_way: "back_up" });
      downloadApps(content);
      sendEvent(UIA_SP_CLICK, {
        promo_video,
        situation: "payment_choice",
        style: "alert",
        action: "auto_get"
      });
    }, 1000);
  } else if (back_up) {
    pic_placeholder = require("@/assets/icons-file/super_pay/super_pay_placeholder/ba_place_n@3x.png");
    content = back_up;
    dialog_class = "back_up";
    click_point_key = "uia_backup_app_click";
    rewards = await back_up_show_rewards();
  } else if (feature_apps) {
    pic_placeholder = require("@/assets/icons-file/super_pay/super_pay_placeholder/fa_place_n@3x.png");
    content = feature_apps;
    click_point_key = "uia_featured_app_click";
  }
  Vue.prototype.$_Dialog.show({
    style: { padding: "0 0 28px" },
    closable: false,
    closeOnClickModal: false,
    headerCustom(ctx: any, r: any) {
      return r(RecommendAppHeader, {
        props: { ctx, content, dialog_class, pic_placeholder, offer }
      });
    },
    contentCustom(ctx: any, r: any) {
      return r(SuperPayContent, { props: { ctx, content } });
    },
    confirmBtnCustom(ctx: any, r: any) {
      return r(SuperPayButton, {
        props: {
          ctx,
          padding: true,
          item: content,
          dialog_class,
          place,
          click_point_key,
          offer,
          rewards
        }
      });
    }
  });
};

const login_required_action = async (from?: string) => {
  try {
    // log out and go login
    Loading.show();
    // delete
    await store.dispatch(DELETE_TIKTOK_LOGIN_USER);
    await store.dispatch(LOGIN_TIKTOK_USER_ACTION, from);
    Loading.hide();
  } catch (error) {
    Loading.hide();
  }
};

export const loginRequireDialog = (
  type: "change_account" | "account_expired"
) => {
  try {
    const loginUserInfo = store.getters[GEETER_TIKTOK_LOGIN_USER];
    const tt_user_info = loginUserInfo && loginUserInfo.tt_user_info;
    const user_name = tt_user_info && tt_user_info.uniqueId;
    const content_text = !user_name
      ? "In order to use our service better, please add a login account first."
      : type === "change_account"
      ? `@${user_name} will be logged out while you logging in another account.`
      : `Your loggined account @${user_name} has expired,need to login again.`;

    Vue.prototype.$_Dialog.show({
      title: "Login Required",
      content: {
        text: content_text
      },
      closable: true,
      confirmBtn: {
        text: "Login Now"
      },
      cancelBtn: {
        text: "Later"
      },
      async onConfirm(done: any) {
        done();
        await login_required_action();
      },
      onCancel(done: any) {
        done();
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

export const forceLoginDialog = (from?: string) => {
  try {
    const { tt_user_info } = store.getters[GETTER_CURRENT_TIKTOK_USER_INFO];
    const user_name = tt_user_info && tt_user_info.uniqueId;
    const content_text = `In order to get the video information of @${user_name}, please add a login account first.`;
    Vue.prototype.$_Dialog.show({
      title: "Login Required",
      content: {
        text: content_text
      },
      closable: true,
      confirmBtn: {
        text: "Login Now"
      },
      cancelBtn: {
        text: "Later"
      },
      async onConfirm(done: any) {
        done();
        await login_required_action(from);
      },
      onCancel(done: any) {
        done();
      }
    });
  } catch (error) {
    return Promise.reject(error);
  }
};
