














import { Component, Vue, Prop } from "vue-property-decorator";
import ImgFetched from "@/components/ImgFetched/ImgFetched.vue";

@Component({
  components: { ImgFetched }
})
export default class AccountFeed extends Vue {
  @Prop()
  private resource: any;
  @Prop()
  private showGuide: any;

  get userAvatar() {
    if (this.resource && this.resource.profile_pic_url) {
      return this.resource.profile_pic_url;
    } else {
      return require("@/assets/icons-file/earn_coin/follower_placeholder.png");
    }
  }

  get userName() {
    if (this.resource && this.resource.username) {
      return this.resource.username;
    } else {
      return "";
    }
  }
}
