














import { Component, Prop, Vue } from "vue-property-decorator";
import { getDeviceInfo, getDeviceId, getIDFA } from "@/utils/js_bridge_info";

@Component({
  components: {}
})
export default class Guide extends Vue {
  @Prop()
  private feedCoins?: number;
  @Prop()
  private guideType?: string;

  get textList() {
    return [
      {
        text: "Click " + this.guideType,
        icon: require("@/assets/icons-file/earn_coin/earn_guide_step_1@3x.png")
      },
      {
        text: "Open TikTok",
        icon: require("@/assets/icons-file/earn_coin/earn_guide_step_2@3x.png")
      },
      {
        text: "Give " + this.guideType,
        icon: require("@/assets/icons-file/earn_coin/earn_guide_step_3@3x.png")
      },
      {
        text: "Back to " + (getDeviceInfo()).info.display_name,
        icon: require("@/assets/icons-file/earn_coin/earn_guide_step_4@3x.png")
      },
      {
        text: `Get ${this.feedCoins} Coins`,
        icon: require("@/assets/icons-file/earn_coin/earn_guide_step_5@3x.png")
      }
    ];
  }

  private toGuidePage() {
    this.$router.push({
      name: "GuidePage",
      query: {
        routerTransition: "up"
      }
    });
  }
}
