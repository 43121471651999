import { Module } from "@/services/module";

// 本地缓存的支付参数的结构
interface LaunchParams {
  before_init_launch_timestamp: number; // 启动时间戳
  [PeriodType.LAUNCH_PARAMS_AND_DEVICE_INFO_INIT_PERIOD]: number;
  [PeriodType.LOCAL_SETTING_INIT_PERIOD]: number;
  [PeriodType.MASTER_USER_ID_INIT_PERIOD]: number;
  [PeriodType.JS_FRAME_INIT_PERIOD]: number;
  [PeriodType.LAUNCH_PARAMS_PERIOD]: number;
  [PeriodType.DEVICE_INFO_PERIOD]: number;
  [PeriodType.STORE_RESTORED_PERIOD]: number;
  [PeriodType.NATIVE_GET_LOCAL_SETTING_PERIOD]: number;
  [PeriodType.LOCAL_SETTING_HANDLE_PERIOD]: number;
  [PeriodType.SENTRY_INIT_PERIOD]: number;
  [PeriodType.VUE_USE_AND_FILTER]: number;
}

export enum PeriodType {
  // launch_params和device_info消耗时间段 - 启动时间戳
  LAUNCH_PARAMS_AND_DEVICE_INFO_INIT_PERIOD = "launch_params_and_device_info_init_period",
  // 本地setting消耗时间段 - 启动时间戳
  LOCAL_SETTING_INIT_PERIOD = "local_setting_init_period",
  // master_id注册时间 - 启动时间戳
  MASTER_USER_ID_INIT_PERIOD = "master_user_id_init_period",
  // vue框架渲染时间 - 启动时间戳
  JS_FRAME_INIT_PERIOD = "js_frame_init_period",
  // launch_params方法使用时间
  LAUNCH_PARAMS_PERIOD = "launch_params_period",
  // device_info方法使用时间
  DEVICE_INFO_PERIOD = "device_info_period",
  // store_restored
  STORE_RESTORED_PERIOD = "store_restored_period",
  // 获取本地setting
  NATIVE_GET_LOCAL_SETTING_PERIOD = "native_get_local_setting_period",
  // store_restored
  LOCAL_SETTING_HANDLE_PERIOD = "local_setting_handle_period",
  // sentry init
  SENTRY_INIT_PERIOD = "sentry_init_period",
  // vue use 和 filter耗时
  VUE_USE_AND_FILTER = "vue_use_and_filter"
}

class LaunchTimeCache extends Module {
  constructor() {
    const defaultParams: LaunchParams = {
      before_init_launch_timestamp: 0,
      launch_params_and_device_info_init_period: 0,
      local_setting_init_period: 0,
      master_user_id_init_period: 0,
      js_frame_init_period: 0,
      launch_params_period: 0,
      device_info_period: 0,
      store_restored_period: 0,
      native_get_local_setting_period: 0,
      local_setting_handle_period: 0,
      vue_use_and_filter: 0,
      sentry_init_period: 0
    };
    super(defaultParams);
  }

  // 设置启动时间
  setTime() {
    const current_timestamp = new Date().getTime();
    const existParams = this.get();
    this.set({
      ...existParams,
      before_init_launch_timestamp: current_timestamp
    });
  }

  // 方法-启动时间的时间差
  setPeriod(params: PeriodType) {
    const current_timestamp = new Date().getTime();
    const existParams = this.get();
    const { before_init_launch_timestamp } = existParams;
    console.log(
      "***** launch params*****",
      params,
      current_timestamp,
      before_init_launch_timestamp
    );
    const period = current_timestamp - before_init_launch_timestamp;
    const resultPeriod = (Math.round(period / 100) / 10).toFixed(1);
    this.set({
      ...existParams,
      [params]: resultPeriod
    });
  }
}
export default new LaunchTimeCache();
