import RetryParams from "@/services/payment/retryParams";
import {
  getResourceFromOffer,
  handleOffer,
  getPaymentPic,
  Offer,
  OfferKind
} from "@/services/offer/offer_handle";
import * as index_types from "@/store/constants/index_types";
import * as types from "@/store/constants/index_types";
import { store } from "@/main";
import { isIos, removeNoSenseKeys } from "@/utils/lodash";
import { base64ToJson } from "@/api/utils/api_encrypt";
import { postOffer } from "@/api/cg/service/pay_service";
import {
  ErrorTypes,
  NativePurchaseResult,
  PayWay
} from "@/services/payment/types";
import Jsbridge from "@/jsbridge";
import listenNative from "@/jsbridge/service/listenNative";
import { getBundleId } from "@/utils/js_bridge_info";
import {
  getInformation,
  getQueryParamsFromUrl,
  objectToQuery,
  removeRubbishInStr
} from "@/services/payment/utils";
import { isSubsOffer, vipIsTryForFree } from "@/utils/general_utils";
import loading from "@/plugins/toast/loading";
import SwitchBackgroundPosition, {
  SwitchPositionTypes
} from "@/utils/switch_background/switch_background_position";

export const purchaseExecute = async (
  offer: Offer,
  pay_way: PayWay,
  nativeResult: NativePurchaseResult,
  retryAgain?: boolean,
  cacheTargetUserId?: string,
  cacheMasterId?: string,
  cacheResource?: any
) => {
  try {
    const masterId = cacheMasterId
      ? cacheMasterId
      : store.getters[index_types.GETTER_MASTER_USER_ID];
    let targetUserId = store.getters[index_types.GETTER_CURRENT_USER_ID];
    const typeIsVipOrCoins =
      offer.kind === OfferKind.vip || offer.kind === OfferKind.coin;
    targetUserId = cacheTargetUserId
      ? cacheTargetUserId
      : !typeIsVipOrCoins
      ? targetUserId
      : masterId;
    const resource = cacheResource
      ? cacheResource
      : getResourceFromOffer(offer);
    // 设置retry参数
    RetryParams.setRetry({
      offer,
      pay_way,
      nativeResult,
      retryAgain,
      cacheMasterId: masterId,
      cacheTargetUserId: targetUserId,
      cacheResource: resource
    });
    // 如果返回了支付错误码并且不是8
    if (nativeResult && nativeResult.code && nativeResult.code !== 8) {
      return Promise.reject({
        type: ErrorTypes.native,
        needRetry: true
      });
    }
    const { transactionId, pay_platform, pay_version } = nativeResult;
    let { receipt } = nativeResult;
    // 后台需要转化
    if (!isIos()) {
      receipt = JSON.stringify(base64ToJson(receipt));
    }
    const result: any = await postOffer(
      offer,
      transactionId,
      masterId,
      targetUserId,
      pay_way,
      receipt,
      resource,
      pay_platform,
      pay_version
    );
    return Promise.resolve(result);
  } catch (e) {
    console.log("purchaseExecute", e);
    return Promise.reject({ ...e, type: ErrorTypes.service });
  }
};

export const webPayExecute = async (url: string, pay_way: PayWay) => {
  return new Promise(async (resolve, reject) => {
    // 打开浏览器
    const browser_id: any = await Jsbridge.openBrowser(url, "in_app_browser", {
      title: pay_way === PayWay.paypal ? "PayPal" : "Credit Card"
    });
    listenNative.removeListen("web_pay");
    loading.hide();
    // 监听用户主动取消
    listenNative.addListen(
      "exit_" + browser_id,
      () => {
        listenNative.removeListen("web_pay");
        reject({
          code: isIos() ? 2 : 1,
          message: "user cancelled",
          type: ErrorTypes.native
        });
      },
      {
        key: "web_pay"
      }
    );
    // 监听成功回调
    listenNative.addListen(
      "open_url",
      (params: any) => {
        console.log("open_url", params);
        loading.show();
        // 处理成功结果
        listenNative.removeListen("web_pay");
        resolve(params);
      },
      {
        key: "web_pay"
      }
    );
  });
};

export const handleWebPayResult = async (params: string) => {
  try {
    // 处理dj后面的字符串并处理
    const dj_index = params.indexOf("dj=");
    if (dj_index > -1) {
      const dj_params = params.substring(dj_index + 3);
      const dj_json = decodeURIComponent(dj_params);
      Jsbridge.closeBrowser("in_app_browser").catch(() => void 0);
      const result = JSON.parse(dj_json);
      return Promise.resolve(result);
    } else {
      const wpf_index = params.indexOf("em=");
      const wpf_params = params.substring(wpf_index + 3);
      const wpf_json = decodeURIComponent(wpf_params);
      try {
        // 如果返回的是对象，则是webview加载失败，需要解析成Network error.
        JSON.parse(wpf_json);
        Jsbridge.closeBrowser("in_app_browser").catch(() => void 0);
        return Promise.reject({
          message: "Network error.",
          type: ErrorTypes.native
        });
      } catch (e) {
        const error_msg_arr = wpf_json.split("&");
        Jsbridge.closeBrowser("in_app_browser").catch(() => void 0);
        return Promise.reject({
          message: error_msg_arr[0],
          type: ErrorTypes.native
        });
      }
    }
  } catch (e) {
    return Promise.reject({ message: "unknown error" });
  }
};

export const executeSuperPay = async (
  offer: Offer,
  pay_way: PayWay,
  current_scheme: string,
  resource: any
) => {
  try {
    const result: any = await openApps(
      offer,
      pay_way,
      current_scheme,
      resource
    );
    return Promise.resolve(result);
  } catch (e) {
    return Promise.reject(e);
  }
};

export const openApps = async (
  offer: Offer,
  pay_way: PayWay,
  current_scheme: string,
  default_resource: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const is_no_auto_renew = offer.hasOwnProperty("is_no_auto_renew") // 是否是自动订阅
        ? offer.is_no_auto_renew
        : true;
      const master_id = store.getters[types.GETTER_MASTER_USER_ID];
      const self_bundleId = getBundleId();
      const resource = handleOffer(offer, pay_way);
      const order_information = getInformation(resource, offer);
      const order_image_link = default_resource
        ? getPaymentPic(offer, default_resource)
        : resource.pic_url;
      const iOSparams: any = {
        price: "$" + resource.total_price,
        auto_renewable: Number(!is_no_auto_renew),
        application_username: master_id,
        order_source_scheme: self_bundleId,
        order_target_scheme: current_scheme,
        order_auto_buy: 1, // 出来的superpay app是否自动购买
        order_style: 1, // ui 对应0、1、2、3、4
        order_show_try_for_free: vipIsTryForFree(offer), // 是否展示try_for_free
        order_information,
        order_image_link,
        sp_identifier: "super_pay"
      };
      const isSub = isSubsOffer(offer);
      const androidParams: any = {
        price: "$" + resource.total_price,
        order_source_scheme: self_bundleId,
        period_count: isSub ? offer.subscription.period : null,
        period_type: isSub ? offer.subscription.period_type : null
      };
      const params = isIos() ? iOSparams : removeNoSenseKeys(androidParams);
      const url = `${current_scheme}://superpay/jump?${objectToQuery(
        params,
        true
      )}`;
      console.log("sp url", url);
      isIos() && loading.hide();
      SwitchBackgroundPosition.setPosition(
        SwitchPositionTypes.NOT_REQUIRE_REFRESH
      );
      // 掉起sp
      await Jsbridge.openBrowser(url, "system_browser");
      // 开始监听结果
      await listenNative.removeListen("super_pay");
      await listenNative.addListen(
        "open_url",
        async (param: any) => {
          isIos() && loading.show();
          // 处理结果
          if (param.indexOf("jump_finished") > -1) {
            const {
              jump_map,
              receipt_data,
              transaction_identifier
            } = getQueryParamsFromUrl(param);
            const { pay_platform, pay_version } = base64ToJson(
              removeRubbishInStr(jump_map)
            );
            const result: NativePurchaseResult = {
              transactionId: transaction_identifier,
              receipt: isIos()
                ? removeRubbishInStr(receipt_data)
                : receipt_data,
              pay_platform,
              pay_version
            };
            resolve(result);
          } else if (param.indexOf("jump_failed") > -1) {
            const {
              error_code,
              error_message,
              code,
              message
            } = getQueryParamsFromUrl(param);
            reject({
              code: isIos() ? parseInt(error_code, 0) : parseInt(code, 0),
              message: isIos() ? error_message : message,
              type: ErrorTypes.native
            });
          }
        },
        {
          key: "super_pay"
        }
      );
    } catch (e) {
      reject(e);
    }
  });
};
