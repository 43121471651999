import { CacheModule } from "@/services/cacheModule";
import { LOCALIZE_PRICE } from "@/utils/nativeStorage/local_keys";
import { getInfoFromOffer, Offer } from "@/services/offer/offer_handle";
import { PayWay } from "@/services/payment/types";
import { getIapPrice, getIapProductId } from "@/plugins/filters/offer_filter";
import Jsbridge from "@/jsbridge";
import { parsePrice } from "@/utils/lodash";
import PriceOfferMap from "./priceOfferMap";
import {
  localize_getters,
  localize_mutations
} from "@/services/payment/localize/localizeStore.ts";
import { LocalizeTypes } from "@/services/payment/localize/types";

class Localize extends CacheModule {
  private inapp_product_ids: any[]; // 消耗型product_id列表
  private subs_product_ids: any[]; // 订阅型product_id列表
  private readonly priceOfferMap: any; //
  constructor() {
    const defaultParams: any = {
      products: {},
      invalid_product_ids: []
    };
    super(LOCALIZE_PRICE, defaultParams);
    this.inapp_product_ids = [];
    this.subs_product_ids = [];
    this.priceOfferMap = {};
    this.getCacheInfo();
  }

  // 更新本地化列表以及不可用product_id
  public async update(settings: any): Promise<void> {
    const subs_set = new Set(); // 订阅型set
    const consume_set = new Set(); // 消耗型set
    // 从setting里面解析offer
    Object.keys(settings).forEach((v: any) => {
      if (v.includes("service_offers")) {
        settings[v].forEach((offer: Offer) => {
          const product_id: string | undefined = getIapProductId(offer);
          product_id && subs_set.add(product_id);
        });
      } else if (v.includes("_offers")) {
        settings[v].forEach((offer: Offer) => {
          const product_id: string | undefined = getIapProductId(offer);
          product_id && consume_set.add(product_id);
        });
      }
    });
    const subs_product_ids: any[] = Array.from(subs_set);
    const inapp_product_ids: any[] = Array.from(consume_set);
    console.log(
      "subs_product_ids, inapp_product_ids",
      subs_product_ids,
      inapp_product_ids
    );
    // 如果当前product_id的长度已经没变化了，就不再拉取
    if (
      subs_product_ids.length !== this.subs_product_ids.length ||
      inapp_product_ids.length !== this.inapp_product_ids.length
    ) {
      try {
        this.subs_product_ids = Array.from(
          new Set(subs_product_ids.concat(this.subs_product_ids))
        );
        this.inapp_product_ids = Array.from(
          new Set(inapp_product_ids.concat(this.inapp_product_ids))
        );
        // 此处拉取本地化，可能直接在低版本会抛出错误
        const result: any = await Jsbridge.iapProduct(
          inapp_product_ids,
          subs_product_ids
        );
        console.log("iapProduct result", result);
        const { products, invalid_product_ids } = this.get();
        const validProductIdList = Object.keys(result.products);
        // 如果开始某些product_id被认为失效了，但是后面又返回了本地化，把这些product_id从失败列表中过滤出来
        const actualInvalidProductIdsList = invalid_product_ids.filter(
          (v: any) => !validProductIdList.includes(v)
        );
        const localizeResult: LocalizeTypes = {
          products: Object.assign(products, result.products),
          invalid_product_ids: Array.from(
            new Set(
              actualInvalidProductIdsList.concat(result.invalid_product_ids)
            )
          )
        };
        this.setCache(localizeResult);
        localize_mutations.update_localize(localizeResult);
        console.log("localize result", this.get());
      } catch (e) {
        //
      }
    }
    this.savePriceProductIdList(settings, true);
  }

  // 存储super_pay被调的列表
  public savePriceProductIdList(settings: any, online: boolean = false) {
    console.log("savePriceProductIdList", settings);
    Object.keys(settings).forEach((v: any) => {
      if (v.includes("_offers")) {
        settings[v].forEach((offer: Offer) => {
          if (offer && getIapProductId(offer) && getIapPrice(offer)) {
            const auto_renew =
              offer.hasOwnProperty("is_no_auto_renew") &&
              !offer.is_no_auto_renew &&
              v.includes("service_");
            const key = auto_renew
              ? "sub_" + getIapPrice(offer)
              : "regular_" + getIapPrice(offer);
            if (
              !this.priceOfferMap[key] ||
              (this.priceOfferMap[key] && !this.priceOfferMap[key].native)
            ) {
              this.priceOfferMap[key] = {
                product_id: getIapProductId(offer),
                native: online
              };
            }
          }
        });
      }
    });
    console.log("priceOfferMap", this.priceOfferMap);
    PriceOfferMap.setCache(this.priceOfferMap);
  }

  // 判断product_id是否有效
  public judgeProductIdValid(product_id: string | undefined): boolean {
    const invalid_product_ids = localize_getters.invalid_product_ids_getter();
    return product_id ? !invalid_product_ids.includes(product_id) : false;
  }

  // 从offer中获取本地化价格
  public getLocalizePriceByOffer(
    offer: Offer,
    pay_way: PayWay = PayWay.iap
  ): string {
    try {
      let productId: string | undefined;
      if (pay_way === PayWay.iap) {
        productId = getIapProductId(offer);
      } else {
        return getInfoFromOffer(offer, pay_way);
      }
      // const { products } = this.get();
      // const localize = productId && products && products[productId];
      const localize = this.getLocalize(productId);
      if (localize) {
        const price = localize.price.toFixed(2);
        return `${localize.currency_symbol}${price}`;
      } else {
        return getInfoFromOffer(offer, pay_way);
      }
    } catch (e) {
      return getInfoFromOffer(offer, pay_way);
    }
  }

  // 通过价格算本地化价格
  public getLocalizePriceByPrice(origin_price: string) {
    try {
      const original_price_num = parseFloat(parsePrice(origin_price));
      // 本地化列表
      // const { products } = this.get();
      // const priceOfferMap = this.getPriceProductIdList();
      const priceOfferMap = PriceOfferMap.get();
      let index: number = 0; // 保存最接近的索引
      let current_close_price: number = 0; // 当前最接近的price
      let d_value = Number.MAX_VALUE; // 保存差值绝对值
      const arr: any[] = Object.keys(priceOfferMap);
      for (let i = 0; i < arr.length; i++) {
        const current_offer_price_num: number = parseFloat(parsePrice(arr[i]));
        const new_d_value = Math.abs(
          current_offer_price_num - original_price_num
        ); // 新差值
        if (new_d_value <= d_value) {
          // 如果新差值绝对值小于等于旧差值绝对值，保存新差值绝对值和索引
          if (
            new_d_value === d_value &&
            current_offer_price_num < current_close_price
          ) {
            // 如果数组中两个数值跟目标数值差值一样，取大
            continue;
          }
          index = i;
          d_value = new_d_value;
          current_close_price = current_offer_price_num;
        }
      }
      const resultPrice = parseFloat(parsePrice(arr[index]));
      const resultValues: any = Object.values(priceOfferMap)[index];
      const products = localize_getters.products_getter();
      console.log("resultPrice", resultPrice, resultValues, original_price_num);
      // const localize = products[resultValues.product_id];
      const localize = products[resultValues.product_id];
      if (localize) {
        const ratio = localize.price / resultPrice;
        return `${localize.currency_symbol}${(
          ratio * original_price_num
        ).toFixed(2)}`; // 返回最接近的数值
      } else {
        return origin_price;
      }
    } catch (e) {
      console.log("localize error=", e);
      return origin_price;
    }
  }

  public getOriginalPriceByOffer(offer: any, pay_way: PayWay = PayWay.iap) {
    try {
      const { discount } = offer;
      const productId = getIapProductId(offer);
      const localize = this.getLocalize(productId);
      let price: number;
      let symbol: string;
      if (localize) {
        price = localize.price;
        symbol = localize.currency_symbol;
      } else {
        price = parseFloat(parsePrice(getInfoFromOffer(offer, pay_way)));
        symbol = "$";
      }
      const price_result = (price / (1 - discount / 100)).toFixed(2);
      return `${symbol}${price_result}`;
    } catch (e) {
      return getInfoFromOffer(offer, pay_way);
    }
  }

  public getLocalize(productId: any) {
    const products = localize_getters.products_getter();
    return productId && products && products[productId];
  }

  // 主动等待缓存
  private async getCacheInfo() {
    const cacheInfo = await this.init(LOCALIZE_PRICE);
    cacheInfo && localize_mutations.update_localize(cacheInfo);
  }
}
export default new Localize();
