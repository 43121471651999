var render, staticRenderFns
import script from "./CardListItem.vue?vue&type=script&lang=tsx&"
export * from "./CardListItem.vue?vue&type=script&lang=tsx&"
import style0 from "./CardListItem.vue?vue&type=style&index=0&id=45aa5172&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45aa5172",
  null
  
)

export default component.exports