import actions from "@/store/actions/tt_media_actions";
import mutations from "@/store/mutations/tt_media_mutations";
import getters from "@/store/getters/tt_media_getters";

export interface OneMediaInfo {
  comment_count: number;
  display_url: string; // 视频的播放的地址
  image_low: string;
  image_high: string;
  video_id: string;
  digg_count: number;
  video_icon: string;
  digged: boolean;
  for_friend: boolean;
  video_play_count: number;
  comment_status: boolean;
}
export interface OneUserMediaInfoList {
  user_id: string;
  has_more: boolean;
  max_cursor: string;
  tt_media_list?: OneMediaInfo[];
}
export interface UpdateTikMediaInfo {
  tikMediaInfoList: OneUserMediaInfoList;
  upsateStatus: "new" | "delete" | "more"; // 刷新与新增都是new,delete 删除， more 加载更多
}

interface TikUserMediaList {
  tt_user_media_list: OneUserMediaInfoList[];
}
type stateType = () => TikUserMediaList;
const state: stateType = () => ({
  tt_user_media_list: []
});

export default {
  state,
  actions,
  mutations,
  getters
};
