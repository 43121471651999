import { Module } from "@/services/module";

class ImgCache extends Module {
  constructor() {
    super({});
  }

  public setImgCache(name: string, value: string) {
    const imgCache: any = this.get();
    if (name && !imgCache[name]) {
      imgCache[name] = value;
      this.set(imgCache);
    }
  }

  public getImgCache(name: string) {
    const map = this.get();
    return map && map[name];
  }
}
export default new ImgCache();
