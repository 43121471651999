import WebIndexDBLocalStorage from "@/utils/nativeStorage/native_storage";
import * as types from "@/store/constants/setting_types";
import { store } from "@/main";
import PurchasedList from "@/services/payment/purchasedList";

/**
 * special offers的逻辑简单梳理：
 * 1. 因为周期和阶段的倒计时长需要在app退出后再次进入和上次保持同步，所以需要存入IndexDB
 * 2. 为了避免操作阶段和周期数据与存取IndexDB操作混乱，存DB不在实例其余方法内置，而是暴露出去手动调用，在spcial offer组件内只需要在周期或阶段发生改变时存取即可
 * 3. 需要处理什么时候倒计时长取周期时间，而且要判断进入下一个阶段，是正常走完倒计时触发，还是手动在倒计时之前强行触发
 * 4. 把durationTimeStamp为0作为不需要显示的标志
 */

/**
 * 实例只处理周期和阶段的倒计时，以及当前阶段的offer数据。
 */
export default class SpecialOfferController {
  // special offer的类型，follow或者like
  private type: any;
  // special offer的整个周期，周期时间优先级最高
  private interval: number = 0;
  // 当前处于哪个阶段
  private stage: number = 0;
  // 当前阶段的offers
  private stageControls: any;
  // 当前阶段的倒计时时间戳
  private durationTimeStamp: number = 0;
  // 当前周期的倒计时时间戳
  private deadlineTimeStamp: number = 0;
  // 当前周期的所有阶段offers集合
  private offerControls: any;
  // 匹配到对应type的follw或者like的offers
  private targetOffers: any;
  private hasDBExist: any;
  private indexDBKey: any;

  /**
   * init必须要public并由实例手动await调用，因为很多初始化的值是异步获取，但是构造器不允许async关键字
   *
   * @param {string} type follw或like
   * @returns {Promise} 返回durationTimeStamp和offerInfo的对象
   */
  public async init(type: any): Promise<any> {
    try {
      const specialOffer = store.getters[types.GETTER_CLIENT_SETTINGS]
        .special_offer
        ? store.getters[types.GETTER_CLIENT_SETTINGS].special_offer
        : {};
      const followOffers = store.getters[types.GETTER_SPECIAL_FOLLOW_OFFERS];
      const likeOffers = store.getters[types.GETTER_SPECIAL_LIKE_OFFERS];
      const targetOffers = type === "follow" ? followOffers : likeOffers;
      const offerControls =
        type === "follow"
          ? JSON.parse(
              JSON.stringify(
                specialOffer.special_follow ? specialOffer.special_follow : {}
              )
            )
          : JSON.parse(
              JSON.stringify(
                specialOffer.special_like ? specialOffer.special_like : {}
              )
            );
      this.interval =
        type === "follow"
          ? specialOffer.special_follow_refresh_interval || 604800
          : specialOffer.special_like_refresh_interval || 604800;
      // 当前阶段内的offers，填充offer的购买信息
      this.type = type;
      this.offerControls = offerControls;
      this.targetOffers = targetOffers;
      // 初始化indexDB数据存储
      this.indexDBKey = `SPECIAL_OFFERS_${type.toUpperCase()}`;
      this.hasDBExist = await WebIndexDBLocalStorage.has(this.indexDBKey);
      await this.updateDataFromDB();
      this.fixDurationTimeStamp();
      const isShow = this.durationTimeStamp !== 0;
      return {
        durationTimeStamp: this.durationTimeStamp,
        offerInfo: this.getOffers(),
        isShow
      };
    } catch (error) {
      console.log("[special offer controller init error]:", error);
      return Promise.reject(error);
    }
  }

  /**
   * 设置并返回当前stage的offerInfo, 包括limit_time和offers两个key
   *
   * @param {number} stage 哪一阶段，默认是当前实例的stage
   * @returns {any} 返回包含limit_count和offers信息的对象
   */
  public getOffers(stage?: number): any {
    const currentStage = stage === undefined ? this.stage : stage;
    this.stageControls = this.offerControls[currentStage];
    if (this.stageControls) {
      this.stageControls.offers = this.stageControls.offers.map((item: any) => {
        return {
          limit_count: item.limit_count,
          ...this.targetOffers.find((o: any) => item.offer_id === o.offer_id)
        };
      });
    }
    return this.stageControls;
  }

  /**
   * 前进一个stage，更新实例数据，并返回durationTimeStamp和offerInfo
   * 必须区分是正常倒计时走完，还是手动在duration结束前调入到下一个stage，因为影响到durationTimeStamp的计算
   *
   * @param {boolean} isNoramlCountDownEnd 是否是倒计时自动结束触发的，默认为true
   * @returns {Promise<any>} isAllStageOffersBuyOut、durationTimeStamp和offerInfo的对象
   */
  public stageNextStep(isNoramlCountDownEnd: boolean = true): any {
    try {
      const currentStage = this.stage;
      // 是否需要进入下一周期
      const needStepNextInterval: boolean =
        new Date().valueOf() >= this.deadlineTimeStamp;
      // 是否需要结束阶段循环，等待当前周期结束，当进入下一周期时，不能结束阶段循环！
      const needCloseStageCircle: boolean =
        (currentStage >= this.offerControls.length - 1 ||
          this.durationTimeStamp === this.deadlineTimeStamp) &&
        !needStepNextInterval;
      let isShow: boolean = this.durationTimeStamp !== 0;

      const closeStageCircleHandler = () => {
        isShow = false;
        this.stage = 0;
        // 将durationTimeStamp重置为0表示当前不需要显示!
        this.durationTimeStamp = 0;
      };

      this.stage++;
      if (needStepNextInterval) {
        this.intervalNextStep();
      }
      // 再处理durationTimeStamp
      if (isNoramlCountDownEnd) {
        if (needCloseStageCircle) {
          closeStageCircleHandler();
        } else {
          // 正常走完当前duration
          this.durationTimeStamp =
            new Date().valueOf() + this.getOffers().limit_time * 1000;
          if (this.durationTimeStamp >= this.deadlineTimeStamp) {
            this.durationTimeStamp = this.deadlineTimeStamp;
          }
        }
      } else {
        // 在duration完成之前手动跳入下一个stage,先判读当前stage的offers是否全部购买完，购买完才跳入下一stage，否则减去被前进的stage恢复到原本stage
        if (this.isStageOffersBuyOut(currentStage)) {
          if (needCloseStageCircle) {
            closeStageCircleHandler();
          } else {
            const isDeadlinetimestampNotEnough =
              this.deadlineTimeStamp - new Date().valueOf() <
              this.getOffers().limit_time * 1000;
            if (isDeadlinetimestampNotEnough) {
              this.durationTimeStamp = this.deadlineTimeStamp;
            } else {
              this.durationTimeStamp =
                new Date().valueOf() + this.getOffers().limit_time * 1000;
            }
          }
        } else {
          this.stage = currentStage;
        }
      }
      return {
        durationTimeStamp: this.durationTimeStamp,
        offerInfo: this.getOffers(),
        isShow
      };
    } catch (error) {
      console.log("[controller stage goes to next step error]:", error);
      return Promise.reject(error);
    }
  }

  /**
   * 更新indexdb数据，需要手动存储，为了避免实例数据和DB数据同时处理导致内存泄漏数据不同步等问题
   */
  public async updateIndexDB() {
    try {
      const ctx = this;
      const isFirst: boolean = !this.hasDBExist;
      await WebIndexDBLocalStorage.set(this.indexDBKey, {
        isFirst,
        stage: ctx.stage,
        lastInterval: ctx.interval,
        durationTimeStamp: ctx.durationTimeStamp,
        deadlineTimeStamp: ctx.deadlineTimeStamp
      });
    } catch (error) {
      console.log("[controller update IndexDB error]:", error);
      return Promise.reject(error);
    }
  }

  /**
   * 进入下一周期，重置所有数据并删除购买历史记录
   */
  private intervalNextStep() {
    this.stage = 0;
    this.deadlineTimeStamp = new Date().valueOf() + this.interval * 1000;
    this.durationTimeStamp =
      new Date().valueOf() + this.getOffers(0).limit_time * 1000;
    this.resetBuyedOfferHistory();
  }

  /**
   * 返回指定stage的offers是否全被购买过，通过本地的PurchasedList来判断，如果app被卸载。则历史记录被清空
   *
   * @param {number} stage 哪一个阶段
   * @returns {boolean}
   */
  private isStageOffersBuyOut(stage?: number): boolean {
    const currentStage = stage === undefined ? this.stage : stage;
    const allPurchasedList = PurchasedList.getPurchaseList();
    if (allPurchasedList.length === 0) {
      return false;
    } else {
      return this.getOffers(currentStage).offers.every((item: any) => {
        return allPurchasedList.some((subItem: any) => {
          const offerData = subItem.offer_data || subItem.receipt_data;
          return offerData.offer_id === item.offer_id;
        });
      });
    }
  }

  // 清除本地PurchasedList中的special offer订单，因为周期更新时需要重新记录已被购买的offer。
  private resetBuyedOfferHistory() {
    // 如果是follow，则需要保留第一个stage的数据
    if (this.type === "follow") {
      PurchasedList.filterPurchaseListByCondition({
        type: ["special_follow"],
        exclude: (v: any) => {
          const firstStageOfferIds = this.getOffers(0).offers.map(
            (item: any) => {
              return item.offer_id;
            }
          );
          return firstStageOfferIds.includes(v.offer_id);
        }
      });
    } else if (this.type === "like") {
      PurchasedList.filterPurchaseList({
        type: ["special_like"]
      });
    }
  }

  /**
   * 找出当前周期处于哪个阶段
   * @param {number} interval 周期时长
   * @param {number[]} limitTime 所有阶段时长的数组
   * @returns {number} 如果周期时间大于所有阶段时间总和，则返回-1，否则返回具体阶段的索引值
   */
  private findWhichSage(interval: number, limitTime: number[]): number {
    let targetStage = -1;
    limitTime.reduce((total: number, item: number, index: number) => {
      if (total + item >= interval) {
        targetStage = index - 1;
      }
      return total + item;
    }, 0);
    return targetStage;
  }

  // 初始化时修正duration时间戳，当上次时间戳小于等于当前时间戳，则自动进入下一个stage
  private fixDurationTimeStamp() {
    try {
      // 必须排除durationTimeStamp为0的情况，为0表示不需要显示，等待周期结束结束下一周期
      const needToNextStage =
        (this.durationTimeStamp <= new Date().valueOf() ||
          this.isStageOffersBuyOut()) &&
        this.durationTimeStamp !== 0;
      // 当duration时间大于剩余周期时间，取剩余周期时间
      const isLastStage = this.durationTimeStamp >= this.deadlineTimeStamp;
      if (isLastStage) {
        this.durationTimeStamp = this.deadlineTimeStamp;
      }
      if (needToNextStage) {
        this.stageNextStep();
      }
    } catch (error) {
      console.log("[controller fix duration timestamp error]:", error);
    }
  }

  // 更新实例属性,DB里有就从DB里更新
  private async updateDataFromDB() {
    try {
      if (this.hasDBExist) {
        const {
          lastInterval,
          stage,
          deadlineTimeStamp,
          durationTimeStamp
        } = await WebIndexDBLocalStorage.get(this.indexDBKey);
        // 如果周期时间发生改变或进入下一周期，则不从DB里取数据，并且需要清除已被购买的special offers订单数据！！
        if (
          lastInterval !== this.interval ||
          new Date().valueOf() >= deadlineTimeStamp
        ) {
          this.intervalNextStep();
        } else {
          this.stage = stage;
          this.deadlineTimeStamp = deadlineTimeStamp;
          this.durationTimeStamp = durationTimeStamp;
        }
      } else {
        this.deadlineTimeStamp = new Date().valueOf() + this.interval * 1000;
        this.durationTimeStamp =
          new Date().valueOf() + this.getOffers(0).limit_time * 1000;
      }
    } catch (error) {
      console.log("[controller update instance data from data error]:", error);
    }
  }
}
