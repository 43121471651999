import { isEmpty } from "@/utils/lodash";
import * as types from "@/store/constants/cg_user_types";
export default {
  [types.UPDATE_GX_USER_INFO](state: any, payLoad: any) {
    // fitler字段可以只更新对应的字段
    // 如果不传filter则只比较和更新规定的key_maps
    let { filters }: any = payLoad;
    const { userInfo } = payLoad;
    if (isEmpty(filters)) {
      filters = Object.keys(state);
    }
    filters.forEach((key: string) => {
      userInfo.hasOwnProperty(key) && (state[key] = userInfo[key]);
    });
  }
};
