import SuperPayCache from "@/services/super_pay/index";
import { judgeMethodCanUse } from "@/services/payment/payment_helper";
import * as setting_types from "@/store/constants/setting_types";
import { store } from "@/main";
import { recommendStart } from "@/api/cg/service/pinad_service";
import { existUser } from "@/utils/general_utils";
import { getLocalVideos } from "@/jsbridge/service/download_files/local_videos";
import { isIos } from "@/utils/lodash";
import PinadCoinAdd from "@/services/pinad_add_coin";
import { downloadApps } from "@/utils/app_download";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { judgeAppInstalled } from "@/jsbridge/service/commonMethod";
import { App, AppsCategory } from "@/services/super_pay/types";
import { FeatureFunctionItem, FeatureItem } from "@/services/payment/types";
import SwitchBackgroundPosition, {
  SwitchPositionTypes
} from "@/utils/switch_background/switch_background_position";
import { BACK_UP_HAS_ADD_COINS } from "@/utils/nativeStorage/local_keys";
import forceState from "@/services/super_pay/forceState";
import PinadModule from "@/services/pinad_add_coin/cache";

// 判断super_pay是否可以被推荐
export const super_pay_can_recommend = async (): Promise<boolean> => {
  try {
    // superPay的支付限制逻辑
    const { superPay } = store.getters[
      setting_types.GETTER_FEATURES_ITEM_BY_FUNCTION
    ]();
    // super_pay接口拉取的数据
    let super_pay: App = SuperPayCache.getAppsData(AppsCategory.super_pay);
    if (!super_pay) {
      await SuperPayCache.getOnlineApps();
      super_pay = SuperPayCache.getAppsData(AppsCategory.super_pay);
    }
    // super_pay是否安装
    const packageName: string | undefined = super_pay && super_pay.packageName;
    const super_pay_installed = await judgeAppInstalled(packageName);
    // 是否配置了super_pay的推荐逻辑
    const superpayParams = store.getters[setting_types.GETTER_SUPER_PAY_PARAMS];
    // 是否支持支付限制逻辑
    const supportRule: boolean = judgeMethodCanUse(superPay);
    return (
      superPay &&
      super_pay &&
      super_pay.downloadUrl &&
      !super_pay_installed &&
      superpayParams &&
      supportRule
    );
  } catch (error) {
    return false;
  }
};

// 判断back_up是否可以被推荐
export const back_up_can_recommend = async (): Promise<boolean> => {
  try {
    // back_up接口参数
    let back_up: App = SuperPayCache.getAppsData(AppsCategory.back_up);
    console.log("back_up", back_up);
    if (!back_up) {
      await SuperPayCache.getOnlineApps();
      back_up = SuperPayCache.getAppsData(AppsCategory.back_up);
    }
    // back_up是否安装
    const back_up_installed =
      back_up && (await judgeAppInstalled(back_up.packageName));
    // 是否下架
    const { backup } = store.getters[
      setting_types.GETTER_FEATURES_ITEM_BY_FUNCTION
    ]();
    const supportRule = judgeMethodCanUse(backup);
    const result =
      back_up && !back_up_installed && back_up.downloadUrl && supportRule;
    return !!result;
  } catch (e) {
    console.log("back_up_can_recommend", e);
    return false;
  }
};

// 下载super_pay
export const download_super_pay = (from: string): void => {
  const super_pay: App = SuperPayCache.getAppsData(AppsCategory.super_pay);
  SwitchBackgroundPosition.setPosition(SwitchPositionTypes.NOT_REQUIRE_REFRESH);
  downloadApps(super_pay);
  forceState.set("force");
  // 记录这次下载的点击来源
  nativeStorage.set("uia_superpay_app_click", {
    place: from,
    scheme: super_pay.appScheme
  });
};

// 下载back_up
export const download_back_up = async (
  from: string,
  func?: () => void
): Promise<void> => {
  try {
    const back_up: App = SuperPayCache.getAppsData(AppsCategory.back_up);
    downloadApps(back_up, func);
    // 记录这次下载的点击来源
    nativeStorage.set("uia_backup_app_click", {
      place: from,
      scheme: back_up.appScheme
    });
    // 如果有adId 并且有配置奖励，并且没有下载过
    if (back_up && back_up.adId && (await back_up_show_rewards())) {
      if (isIos()) {
        recommendStart(back_up.adId, 1).catch(() => void 0);
        PinadModule.addCacheApp(back_up, false);
      } else {
        PinadCoinAdd.startAndSetCache(back_up, 1);
      }
      SwitchBackgroundPosition.setPosition(
        SwitchPositionTypes.REFRESH_CG_USER_INFO
      );
    } else {
      SwitchBackgroundPosition.setPosition(
        SwitchPositionTypes.NOT_REQUIRE_REFRESH
      );
    }
  } catch (e) {
    //
  }
};

// back_up是否显示加币
export const back_up_show_rewards = async (): Promise<number> => {
  const back_up: App = SuperPayCache.getAppsData(AppsCategory.back_up);
  const has_add_coins = await nativeStorage.get(BACK_UP_HAS_ADD_COINS);
  if (existUser() && back_up && !has_add_coins) {
    return back_up && back_up.reward;
  } else {
    return 0;
  }
};

// back_up图标
export const back_up_icon = (): string | undefined => {
  const back_up: App = SuperPayCache.getAppsData(AppsCategory.back_up);
  return back_up && back_up.iconUrl;
};

// 获取super_pay的视频
export const getSuperPayVideo = async (): Promise<string | undefined> => {
  const videoList: any = await getLocalVideos();
  console.log("videoList", videoList);
  const video: any = videoList.find((v: any) => v.name.includes("super_toast"));
  // 是否配置了super_pay的推荐逻辑
  const superpayParams = store.getters[setting_types.GETTER_SUPER_PAY_PARAMS];
  const onlineVideo =
    superpayParams.video_files.addition_page &&
    superpayParams.video_files.addition_page.url;
  return video ? video.path : onlineVideo;
};

// 获取super_pay视频的封面
export const getSuperPayPost = () => {
  return isIos()
    ? require("@/assets/icons-file/super_pay/lite_hybird_ios_face.jpg")
    : require("@/assets/icons-file/super_pay/lite_hybird_android_face.jpg");
};

// // 判断featureApp的弹窗是否可以显示
export const showFeatureAppAlert = async (): Promise<boolean> => {
  try {
    const feature_apps: App = SuperPayCache.getAppsData(
      AppsCategory.feature_apps
    );
    const feature_app_has_installed: boolean = await judgeAppInstalled(
      feature_apps.packageName
    );
    const feature_event_ready = judgeFeatureEvent(FeatureFunctionItem.feature);
    return feature_apps && feature_event_ready && !feature_app_has_installed;
  } catch (e) {
    return false;
  }
};

export const judgeFeatureEvent = (
  feature_name: FeatureFunctionItem
): boolean => {
  const featureList: FeatureItem[] =
    store.getters[setting_types.GETTER_FEATURES];
  const feature_way: FeatureItem | undefined =
    featureList && featureList.find((v: any) => v.function === feature_name);
  return judgeMethodCanUse(feature_way);
};

export const super_pay_has_installed = async () => {
  try {
    const super_pay: App = SuperPayCache.getAppsData(AppsCategory.super_pay);
    const packageName = super_pay && super_pay.packageName;
    const super_pay_has_installed: boolean = await judgeAppInstalled(
      packageName
    );
    return super_pay_has_installed;
  } catch (e) {
    return false;
  }
};
