/**
 * 处理ins与cg api的加密
 * 写在同一文件夹避免重复引入crypto-js
 */
import CryptoJS from "crypto-js/core";
import { getDeviceInfo, getIDFA } from "@/utils/js_bridge_info";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import AES from "crypto-js/aes";
import Hex from "crypto-js/enc-hex";
import HmacSHA512 from "crypto-js/hmac-sha512";
import MD5 from "crypto-js/md5";

const key = Utf8.parse("9vApxLk5G3PAsJrM");
const iv = Utf8.parse("FnJL7EDzjqWjcaY9");
const zvKey = "gps";
const encryptVersion = "I3";

// =======================  cg api的加密  ===========================

/**
 * cg api 参数加密
 * @param str
 */
const encrypt = (str: any) => {
  const aes: any = AES.encrypt(JSON.stringify(str), key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return aes.ciphertext.toString();
};
/**
 * 获取加密后的数据
 * @param str
 */
export const getEncryptData = (str: any) => {
  return {
    zv: zvKey,
    data: encryptVersion + encrypt(str)
  };
};

/**
 * cg api response解密
 * @param word
 */
export const decrypt = (word: any) => {
  const encryptedHexStr = Hex.parse(word);
  const srcs = Base64.stringify(encryptedHexStr);
  const result = AES.decrypt(srcs, key, {
    iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  const decryptedStr = result.toString(Utf8);
  return decryptedStr.toString();
};

/**
 * obejct to base64
 * @param data
 */
export const base64Object: (data: any) => string = (
  data: object | undefined
) => {
  return btoa(JSON.stringify(data));
};

// base64转成json
export const base64ToJson = (data: any) => {
  const words = Base64.parse(data);
  const textString = Utf8.stringify(words);
  return JSON.parse(textString);
};

// pinad请求加密header
export const cryptoBodyOrParams = (word: string) => {
  return HmacSHA512(word, key).toString();
};
/**
 * pinad 对device id加密
 */
export const deviceId = () => {
  const deviceInfo = getDeviceInfo();
  // 如果存在idfa且不为0，则直接md5加密，否则需要自己拼接然后md5加密
  if (getIDFA() !== "00000000-0000-0000-0000-000000000000") {
    return MD5(getIDFA()).toString();
  } else {
    const datajson = {
      name: deviceInfo.device.name,
      system: deviceInfo.device.system_name,
      system_version: deviceInfo.device.system_version,
      model: deviceInfo.device.model,
      localmodel: deviceInfo.device.localized_model,
      country: deviceInfo.locale.country_code,
      language: deviceInfo.locale.language_code,
      ip: deviceInfo.device_ip
    };
    return MD5(JSON.stringify(datajson)).toString();
  }
};

export const base64ToString = (data: any) => {
  const words = Base64.parse(data);
  const textString = Utf8.stringify(words);
  return textString;
};
