




















import { Component, Prop, Vue } from "vue-property-decorator";
import ImgFetched from "@/components/ImgFetched/ImgFetched.vue";

@Component({
  components: {
    ImgFetched
  }
})
export default class UserOneItem extends Vue {
  @Prop()
  private userList: any;

  get placeHolder() {
    return require("@/assets/icons-file/sku/placehold_people.png");
  }

  private clickMore() {
    this.$emit("click");
  }
}
