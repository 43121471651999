// eslint-disable-next-line
import { GetterTree } from "vuex";
import {
  GETTER_CURRENT_TIKTOK_USER_MEDIA,
  CURRENT_TIKTOK_USER_MEDIA_IS_EMPTY
} from "@/store/constants/tt_media_types";
import { GETTER_CURRENT_USER_ID } from "@/store/constants/index_types";

const Getters: GetterTree<any, any> = {
  [GETTER_CURRENT_TIKTOK_USER_MEDIA](state, getters, rootState) {
    const currentId = getters[GETTER_CURRENT_USER_ID];
    if (!currentId) {
      return null;
    }
    const findUserMedia = state.tt_user_media_list.find(
      (item: any) => item.user_id === currentId
    );
    return findUserMedia;
  },
  [CURRENT_TIKTOK_USER_MEDIA_IS_EMPTY](state, getters) {
    const findUserMedia = getters[GETTER_CURRENT_TIKTOK_USER_MEDIA];
    return !findUserMedia || findUserMedia.tt_media_list.length < 1;
  }
};
export default Getters;
