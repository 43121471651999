import { ActionTree } from "vuex";
import * as types from "@/store/constants/setting_types";
import { GETTER_MASTER_USER_ID } from "@/store/constants/index_types";
import Jsbridge from "@/jsbridge/index";
import * as cgConst from "@/api/cg/base/cg_const";
import { getSettingData } from "@/api/cg/service/setting_service";
import { getDeviceInfo, getReleaseParams } from "@/utils/js_bridge_info";
import nativeSettingReplace from "@/jsbridge/service/nativeSettingReplace";
import { isIos } from "@/utils/lodash";
import Localize from "@/services/payment/localize";
import LaunchTimeCache, {
  PeriodType
} from "@/utils/log_event/launch_time_event/launchTimeCache";

const actions: ActionTree<any, any> = {
  async [types.INIT_GX_LOCAL_SETTING_DATA]({ getters, commit }) {
    // 初始化本地setting
    try {
      // 判断本地db是否有setting
      const hasNativeSetting = getters[types.GETTER_SETTING_EXIST];
      if (!hasNativeSetting) {
        // 获取本地json类型的文件地址
        const result: any = await Jsbridge.localPathGet(false, ["HLJson"]);
        // setting对应的地址
        const url = result.HLJson.find(
          (v: string) => v.indexOf("setting") > -1
        );
        const deviceInfo = getDeviceInfo();
        const identifier =
          deviceInfo && deviceInfo.info && deviceInfo.info.identifier;
        // 通过setting的地址获取本地setting的文件
        const local_file_settings: any = await nativeSettingReplace(
          url,
          identifier
        );
        const release_params = getReleaseParams();
        // ios包内的ads参数和web_p_url分发
        if (isIos() && release_params) {
          const client_settings =
            local_file_settings && local_file_settings.client_settings;
          const release_keys: any = Object.keys(release_params);
          const { ads } = client_settings;
          // 替换ads内的参数
          Object.keys(ads).forEach((param: any) => {
            if (release_keys.includes(param)) {
              ads[param] = release_params[param];
            }
          });
          client_settings.web_p_url = release_params.web_p_url;
        }
        const settings: any = {
          [cgConst.COMMON_KEYS.module_name]: {},
          [cgConst.TAB_FOLLOW_KEYS.module_name]: {},
          [cgConst.TAB_LIKE_KEYS.module_name]: {},
          [cgConst.TAB_EARN_COINS_KEYS.module_name]: {},
          [cgConst.TAB_COIN_STORE_KEYS.module_name]: {}
        };
        const structure: any[] = [
          cgConst.COMMON_KEYS,
          cgConst.TAB_FOLLOW_KEYS,
          cgConst.TAB_LIKE_KEYS,
          cgConst.TAB_EARN_COINS_KEYS,
          cgConst.TAB_COIN_STORE_KEYS
        ];

        Object.keys(local_file_settings).forEach((v: any) => {
          structure.forEach((item: any) => {
            if (item.keys.includes(v)) {
              settings[item.module_name][v] = local_file_settings[v];
            }
          });
        });
        commit(types.UPDATE_SETTING_DATA, settings);
        LaunchTimeCache.setPeriod(PeriodType.LOCAL_SETTING_HANDLE_PERIOD);
        return {
          settings,
          local_file_settings
        };
      } else {
        // 如果已经有了本地setting:
        return {
          settings: getters[types.GETTER_ALL_SETTING]
        };
      }
    } catch (error) {
      console.log("INIT_GX_SETTING_DATA error:", error);
      return Promise.reject(error);
    }
  },
  // 拉取线上setting数据并更新, 传入需要拉取的setting部分
  async [types.UPDATE_SETTING_DATA]({ commit, getters }: any, payload: any) {
    try {
      const { module_name, keys } = payload;
      // 拉取需要的线上setting
      const masterId = getters[GETTER_MASTER_USER_ID];
      const setting = await getSettingData(masterId, keys);
      Localize.update(setting);
      commit(types.UPDATE_SETTING_DATA, {
        [module_name]: setting
      });
      return setting;
    } catch (error) {
      //
      return Promise.reject(error);
    }
  },
  async [types.FIRST_FETCH_COMMON_SETTING]({ dispatch }) {
    // 静默拉取线上setting
    dispatch(types.UPDATE_SETTING_DATA, cgConst.COMMON_KEYS)
      .then(() => {
        // 拉取到线上的setting的后续操作(改变最新配置中的加载webview的地址) TODO 原生是否帮我们做了判断如果host一样没有执行,浪费了执行
        dispatch(types.CONFIG_HY_WEB_HOST);
      })
      .catch((error: any) => {
        return Promise.reject(error);
      });
  },
  async [types.CONFIG_HY_WEB_HOST]({ getters }) {
    //
    try {
      const { hy_web_host } = getters[types.GETTER_CLIENT_SETTINGS];
      if (hy_web_host) {
        await Jsbridge.configWebHost(hy_web_host);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }
};

export default actions;
