import { Offer } from "@/services/offer/offer_handle";

// 支付类型枚举
export enum PayWay {
  iap = "iap",
  paypal = "paypal",
  card = "card",
  super_pay = "super_pay",
  back_up = "back_up",
  coin = "coin",
  service_back_up = "backup"
}

// 错误类型枚举 native是和原生交互的过程返回的错误
// service是和后台http交互产生的错误（1、原生给的 2、后台给的）
export enum ErrorTypes {
  native,
  service
}

// iap原生返回处理之后的扁平结构
export interface NativePurchaseResult {
  transactionId: string;
  receipt: string;
  pay_platform?: string;
  pay_version?: string;
  code?: number; // 安卓错误码
}

// iap原生返回
export interface IapResult {
  transaction: {
    transaction_identifier: string;
  };
  receipt_data: string;
  jump_map?: string;
  code?: number; // 安卓错误码
}

export interface OrderItem {
  transaction_id: string;
  transaction_time: number;
}
export interface FailedOrderItem extends OrderItem {
  failed: boolean;
  pay_way: PayWay;
  retryParams: RetryParamsTypes;
  resource: any;
  actual_effect: number;
}

interface Transaction {
  transaction_time: number;
  transaction_id: string;
}

// paypal类型支付完成之后后台返回的receipt结构
export interface ReceiptData extends Offer, Transaction {
  pay_way: PayWay;
}

// post offer之后后台返回的结构
export interface PurchaseResult extends Transaction {
  receipt_data: ReceiptData;
  offer_data: Offer;
  actual_effect: number;
  data: {
    final_effect: number;
  };
  resource: any;
  failed?: boolean;
  retryParams?: any;
}

// handle offer需要的结构
export interface ServiceResult {
  purchaseResult: PurchaseResult;
  pay_way: PayWay;
  retry?: boolean;
  silent?: boolean;
}

// 本地缓存的支付参数的结构
export interface PaymentCacheParams {
  app_launch_time: number;
  iap_purchase_time: number;
  paypal_purchase_time: number;
  card_purchase_time: number;
  first_enter_timestamp: number;
  lifecycle_enter_timestamp: number;
}

export enum EventName {
  or_open_app = "or_open_app",
  open_app = "open_app",
  pay_by_iap = "pay_by_iap",
  pay_by_cash = "pay_by_cash"
}

export enum FeatureFunctionItem {
  super_pay = "superpay_way",
  back_up = "backup_app",
  feature = "featured_app",
  small_paypal = "small_pp_way",
  small_card = "small_card_way",
  big_paypal = "big_pp_way",
  big_card = "big_card_way"
}

export interface EventItem {
  count: number;
  eventName: EventName;
}

// feature需要的结构
export interface FeatureItem {
  function: FeatureFunctionItem;
  is_enabled: boolean;
  delay: number;
  event: EventItem[];
}

interface NativeError {
  code: number;
  domain?: string;
  message: string;
  type?: ErrorTypes.native | ErrorTypes.service | undefined;
  needRetry?: boolean;
}

interface ServiceError {
  meta: {
    code: number;
    error_type: string;
    error_message: string;
  };
}

export interface PurchaseError extends NativeError, ServiceError {}

export interface RetryParamsTypes {
  offer: Offer;
  pay_way: PayWay;
  nativeResult: NativePurchaseResult;
  cacheMasterId: string;
  cacheTargetUserId: string;
  retryAgain?: boolean;
  cacheResource: any;
}
