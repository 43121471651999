// 存储各种类型的sku列表对应的信息
const typeData: any = [
  {
    type: "follow",
    settingName: "follow_offers",
    title: "Followers",
    titleContent: "Real & active Instagram followers",
    itemIcon: "icon-item_icon_follower"
  },
  {
    type: "goldenFollower",
    settingName: "golden_follow_offers",
    title: "Golden Followers",
    titleContent: "",
    itemIcon: "icon-item_icon_golden_follower"
  },
  {
    type: "like",
    title: "Likes",
    settingName: "like_offers",
    titleContent: "Real & fast Instagram likes",
    itemIcon: "icon-item_icon_like"
  },
  {
    type: "coin",
    // title: "Regular Packages",
    settingName: "coin_offers",
    titleContent: "",
    itemImg: require("@/assets/icons-file/sku/coins_3.png")
  }
];

// 接收类型，返回该类型对应的icon等信息
const getTypeObj: any = (type: any) => {
  return typeData.find((item: any) => {
    return item.type === type;
  });
};
/*
 * 传入offer，判断当前是否展示tag标签
 * */
const bestTagShow = (offer: any) => {
  return offer.hasOwnProperty("is_best_deal") && offer.is_best_deal;
};
export { getTypeObj, bestTagShow };
