






import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
@Component({
  components: {}
})
export default class IconSvg extends Vue {
  @Prop({ default: "" })
  private iconClass!: string;

  get iconName() {
    return `#icon_${this.iconClass}`;
  }
}
