import { getCLS, getFCP, getFID, getLCP, getTTFB } from "web-vitals";
import { sendEvent } from "@/utils/log_event";
import paymentCache from "@/services/payment/paymentCache";
import { launchEvent } from "@/api/cg/service/launch_event";
import { getDeviceInfo } from "@/utils/js_bridge_info";

enum webVitalsType {
  LCP = "LCP",
  FID = "FID",
  TTFB = "TTFB",
  FCP = "FCP"
}

enum webVitalsStatus {
  GOOD = "good",
  MEDIUM = "medium",
  POOR = "poor"
}

enum webVitalsPointStatus {
  FIRST = "first_",
  NOT_FIRST = "not_first_"
}

// const web_vitals_default_name = "web_vitals_";

const web_vitals_exist_ids: any[] = [];

const web_vitals_exist_cache = new Map();

const web_vitals_range_map = {
  [webVitalsType.LCP]: {
    min: 2500,
    max: 4000
  },
  [webVitalsType.FID]: {
    min: 100,
    max: 300
  },
  [webVitalsType.TTFB]: {
    min: 50,
    max: 500
  },
  [webVitalsType.FCP]: {
    min: 2000,
    max: 4000
  }
};

const web_vitals_period_map = {
  [webVitalsType.LCP]: [0, 1500, 2500, 3300, 4500, 6000, 8000, 10000],
  [webVitalsType.FID]: [0, 50, 100, 200, 300, 400, 500, 700],
  [webVitalsType.TTFB]: [0, 50, 200, 350, 500, 650, 800, 1000],
  [webVitalsType.FCP]: [0, 1000, 2000, 3000, 4000, 5000, 6000, 8000]
};
// 筛选数据结果，大于最大值 => poor，小于等于最小值 => good， 在区间内 => medium
const rangeFunc = (num: number, max: number, min: number) => {
  if (num > max) {
    return webVitalsStatus.POOR;
  } else if (num < min) {
    return webVitalsStatus.GOOD;
  } else {
    return webVitalsStatus.MEDIUM;
  }
};

const handleWebVitalsResult = (name: webVitalsType, delta: number) => {
  const { max, min } = web_vitals_range_map[name];
  return rangeFunc(delta, max, min);
};

const handleWebVitalsRange = (name: webVitalsType, delta: number) => {
  const arr = web_vitals_period_map[name];
  const index = binarySearch(arr, delta, 0, arr.length - 1);
  return `${arr[index]} ~ ${index === arr.length - 1 ? "" : arr[index + 1]}`;
};

const sendPoint = (params: any) => {
  try {
    const { name, delta, id } = params;
    // 如果没有存储过id，才打点，避免多次打点数据不准
    if (!web_vitals_exist_ids.includes(id)) {
      // 获取当前的launch信息，如果获取到直接打点
      if (paymentCache.existLaunchParams() && getDeviceInfo()) {
        const event_name = paymentCache.isFirstLaunch()
          ? webVitalsPointStatus.FIRST + name.toLowerCase()
          : webVitalsPointStatus.NOT_FIRST + name.toLowerCase();
        const event_value = {
          status: handleWebVitalsResult(name, delta),
          range: handleWebVitalsRange(name, delta),
          value: parseInt(delta)
        };
        sendEvent(event_name, event_value);
        launchEvent(event_name, event_value);
      }
      // 否则缓存相应的打点map
      else {
        web_vitals_exist_cache.set(name.toLowerCase(), {
          status: handleWebVitalsResult(name, delta),
          range: handleWebVitalsRange(name, delta),
          value: parseInt(delta)
        });
      }
      web_vitals_exist_ids.push(id);
    }
  } catch (e) {
    console.log("web vitals sendPoint error", e);
  }
};

const binarySearch = (
  arr: any[],
  findVal: number,
  leftIndex: number,
  rightIndex: number
): any => {
  if (leftIndex > rightIndex) {
    return leftIndex - 1;
  }
  const midIndex = Math.floor((leftIndex + rightIndex) / 2);
  const midVal = arr[midIndex];
  if (midVal > findVal) {
    return binarySearch(arr, findVal, leftIndex, midIndex - 1);
  } else if (midVal < findVal) {
    return binarySearch(arr, findVal, midIndex + 1, rightIndex);
  } else {
    return midIndex + 1;
  }
};

export const sendWebVitalEvent = () => {
  // // 首屏页面渲染过程中所有元素结点相对原始位置所发生的位置偏移累积量；
  // getCLS(sendPoint);
  // 用户第一次交互事件触发到主线程接收事件然后反应之间的时间；
  getFID(sendPoint, true);
  // 视窗（viewport）内所有可见元素中尺寸最大的文本块或图像所需的渲染时间
  getLCP(sendPoint, true);
  // 检测第一个文本块或者图像渲染完成的时间
  getFCP(sendPoint, true);
  // 用户浏览器从开始加载网页内容到接收到第一个字节的网页内容之间的耗时
  getTTFB(sendPoint);
};

export const sendWebVitalCacheEvent = (first: boolean) => {
  try {
    const pointStatus = first
      ? webVitalsPointStatus.FIRST
      : webVitalsPointStatus.NOT_FIRST;
    web_vitals_exist_cache.forEach((value: any, key: string) => {
      sendEvent(pointStatus + key, value);
      launchEvent(pointStatus + key, value);
    });
  } catch (e) {
    //
  }
};
