























import { Component, Vue, Emit } from "vue-property-decorator";

@Component({
  components: {}
})
export default class EmptyUser extends Vue {
  @Emit("clickEmptyUser")
  private clickEmptyUser() {}
}
