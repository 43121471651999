





























import { Component, Vue, Prop, Watch } from "vue-property-decorator";
@Component
export default class NavTab extends Vue {
  @Prop({ default: 0 })
  private value!: number;
  @Prop()
  private tabsNav?: any;
  @Prop({ default: false })
  private showBottomBorder?: boolean; // 是否展示下方的边框
  @Prop({ default: "" })
  private activeTextColor?: string; // 是否展示下方的边框
  @Prop({ default: "relative" })
  private position?: string;

  private offsetX: number = 0; // 底部状态条的位置

  private activeIndex: number = 0; // 当前激活状态的状态index
  private defaultActiveColor: string = "#12b7f5"; // 当前激活状态的文本默认颜色
  private defaultLabelColor: string = "rgb(194, 194, 194)"; // 当前失活状态的文本默认颜色

  @Watch("value", { immediate: true })
  private valuechange() {
    this.activeIndex = this.value;
    this.setOffsetX();
  }

  get itemWidth() {
    return document && document.body.clientWidth / this.tabsNav.length;
  }

  get tabLineWidth() {
    return this.itemWidth * 0.4;
  }

  get currentActiveColor() {
    return (
      this.tabsNav[this.activeIndex].acitveColor || this.defaultActiveColor
    );
  }

  get lineStyle() {
    return {
      width: `${this.tabLineWidth}px`,
      transform: "translate3d(" + this.offsetX + "px,0,0)",
      backgroundColor: this.currentActiveColor
    };
  }

  private getColorStyle(index: number, item: any) {
    if (this.value === index) {
      return item.activeTextColor
        ? `color: ${item.activeTextColor}`
        : `color: ${this.defaultActiveColor}`;
    } else {
      return item.labelColor
        ? `color: ${item.labelColor}`
        : `color: ${this.defaultLabelColor}`;
    }
  }

  private setOffsetX() {
    this.offsetX =
      this.activeIndex * this.itemWidth + (this.itemWidth * 0.6) / 2;
  }

  private initTab() {
    this.$nextTick(() => {
      this.setOffsetX();
    });
  }

  private clickTab(index: number) {
    if (this.activeIndex !== index) {
      this.activeIndex = index;
      this.setOffsetX();
      this.$emit("input", index);
    }
  }

  private mounted() {
    this.initTab();
  }
}
