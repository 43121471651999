import { render, staticRenderFns } from "./Propup.vue?vue&type=template&id=89dc5ab6&scoped=true&"
import script from "./Propup.vue?vue&type=script&lang=ts&"
export * from "./Propup.vue?vue&type=script&lang=ts&"
import style0 from "./propup.scss?vue&type=style&index=0&id=89dc5ab6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89dc5ab6",
  null
  
)

export default component.exports