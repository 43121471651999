/*
 * @Author: Jhonsem
 * @Date: 2021-01-05 15:42:46
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2021-01-05 17:38:38
 * 通过sentry的错误监控,js可能会被墙，会导致sentry的加载失败
 * 需要对sentry的使用进行与判断是否存在
 */

const sentry_load_check = () => {
  return window.Sentry;
};

export const error_monitor_init = () => {
  if (!sentry_load_check()) {
    // script脚本中的js加载失败，加载被墙:
    return;
  }

  window.Sentry.onLoad(() => {
    // Use whatever Sentry.* function you want
    console.log("Sentry onload");
  });

  // 配置sentry的参数
  window.Sentry.init({
    debug: process.env.VUE_APP_CONSOLE_LOG === "ON",
    environment: process.env.VUE_APP_ENV_NAME,
    release: `${process.env.VUE_APP_NAME}@${process.env.VUE_APP_VERSION}`
  });

  // 开始手动下载sentry
  window.Sentry.forceLoad();
};

/**
 * 手动抛出错误
 * @param error
 */
export const error_monitor_capture_error = (error: Error) => {
  if (!sentry_load_check()) {
    // script脚本中的js加载失败，加载被墙:
    return;
  }
  window.Sentry.captureException(error);
};

/**
 * 手动抛出错误信息
 * @param error_message
 */
export const error_monitor_capture_error_message = (error_message: string) => {
  if (!sentry_load_check()) {
    // script脚本中的js加载失败，加载被墙:
    return;
  }
  window.Sentry.captureMessage(error_message);
};
