import { router, store, Vue } from "@/main";
import * as setting_types from "@/store/constants/setting_types";
import {
  getLaunchRecommendOffer,
  isCanshowFreeFollow,
  isShowAppOutOfStock,
  judgeSuperPayShow,
  showRecommendOffer
} from "./util";
import SuperPayCache from "@/services/super_pay";
import { showFeatureAppAlert } from "@/services/super_pay/super_pay_helper";
import { goToDoubleCoin } from "@/utils/router_helper";
import { isDoubleCoins } from "@/utils/general_utils";
import * as types from "@/store/constants/index_types";
import { showRecommendDialog, showUpgradeApp } from "@/services/dialog_utils";
import { RedirectApp } from "@/services/open_pop_up/types";
import { App, AppsCategory } from "@/services/super_pay/types";

const init_logic = async function*() {
  try {
    console.log("generator start");
    // 开机导量
    const showRedirect: boolean | undefined = await isShowAppOutOfStock();
    if (showRedirect) {
      const redirect_app: RedirectApp =
        store.getters[setting_types.GETTER_REDIRECT_APP];
      yield showUpgradeApp(redirect_app);
    }
    console.log("generator next");
    // free follow
    if (await isCanshowFreeFollow()) {
      yield Vue.prototype.$_PopUpDialog.show({
        show: true
      });
    }
    console.log("generator after");
    // 当前是否有ins账户
    const currentId: null | string =
      store.getters[types.GETTER_CURRENT_USER_ID];
    // super_pay的dialog是否展示
    const super_pay_dialog_show: boolean = await judgeSuperPayShow(currentId);
    if (super_pay_dialog_show) {
      yield router.push({
        name: "SuperPayPopup",
        query: {
          routerTransition: "up"
        }
      });
    }
    console.log("generator finish");
    /*
     * 最后一级有三种选择，优先级是 double > feature > recommend
     * */
    if (isDoubleCoins()) {
      goToDoubleCoin(super_pay_dialog_show);
    } else if (
      currentId &&
      Math.random() * 2 > 1 &&
      (await showFeatureAppAlert())
    ) {
      // feature app 弹窗
      const feature_apps: App = SuperPayCache.getAppsData(
        AppsCategory.feature_apps
      );
      showRecommendDialog({
        feature_apps,
        place: "launch_popup"
      });
    } else {
      // 根据权重随机推荐
      const recommendOffer = await getLaunchRecommendOffer();
      if (!recommendOffer) {
        return;
      }
      showRecommendOffer(recommendOffer);
    }
  } catch (e) {
    console.log("init_logic e", e);
  }
};

const generator = init_logic();
export default generator;
