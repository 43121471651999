import nativeStorage from "@/utils/nativeStorage/native_storage";
import { UUID_TYPE } from "@/utils/nativeStorage/local_keys";
import { isEmpty, isObject, isBlob } from "@/utils/lodash";
import { base64Object } from "./api_encrypt";
import qs from "qs";
// import InsPersistCookieHandle from "@/api/ins/cookie_manager/persist_cookie";
/**
 * default UUID from localstorage OR create
 */
let UUID: string = "";

export function creatUUid(length: number = 36, upper: boolean = true) {
  const uid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, (c: any) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .slice(0, length);
  return upper ? uid.toUpperCase() : uid;
}

export const getUUid = async () => {
  if (UUID !== "") {
    return UUID;
  } else {
    const result = await nativeStorage.get(UUID_TYPE);
    if (result) {
      return result;
    } else {
      UUID = creatUUid();
      await nativeStorage.set(UUID_TYPE, UUID);
      return UUID;
    }
  }
};

/**
 * 将params参数转成string
 * @param params
 */

export function transformParamsToString(params: any) {
  return params
    ? Object.keys(params)
        .map(
          (k: any) =>
            encodeURIComponent(k) + "=" + encodeURIComponent(params[k])
        )
        .join("&")
    : "";
}

/**
 * Cookie format
 * @param cookie
 */
export function formatCookie(cookie: any) {
  if (isEmpty(cookie)) {
    return null;
  } else {
    // cookie的拼接
    const paramsArray: any = [];
    Object.keys(cookie).forEach((key: any) => {
      if (cookie[key]) {
        paramsArray.push(key + "=" + cookie[key]);
      }
    });
    return paramsArray.join(";") + ";";
  }
}

/**
 * 统一将不同平台的cookie统一返回规范化
 * @param cookies
 */
export function unitizeCookie(cookies: any) {
  return cookies.map((cookie: any) => {
    const newCookie: any = {};
    for (const props in cookie) {
      if (cookie.hasOwnProperty(props)) {
        newCookie[props.toLowerCase()] = cookie[props];
      }
    }
    return newCookie;
  });
}

/**
 * 手动编码
 * @param contentType
 * @param data
 */
export function contentTypeEncode(
  contentType: string,
  data: any,
  encode: boolean = true
) {
  if (contentType === "application/x-www-form-urlencoded") {
    return btoa(qs.stringify(data, { encode }));
  } else if (contentType === "application/octet-stream") {
    return data;
  } else if (contentType === "application/json") {
    return base64Object(data);
  }
}

/**
 * 转成原生请求需要的base64_string
 * @param content_type
 * @param data
 */
export const content_type_base64_body = (
  content_type: string,
  data: string | object | Blob
) => {
  if (content_type.includes("application/x-www-form-urlencoded")) {
    // string类型需要编码
    if (typeof data === "string") {
      return btoa(data);
    } else {
      throw new Error("Invalid data of content-type");
    }
  } else if (content_type.includes("application/octet-stream")) {
    // Blob类型不需要转base64编码
    if (isBlob(data)) {
      return data;
    } else {
      throw new Error("Invalid data of content-type");
    }
  } else if (content_type.includes("application/json")) {
    // 对象类型需要base64编码
    if (isObject(data)) {
      return base64Object(data);
    } else {
      throw new Error("Invalid data of content-type");
    }
  }
};

/**
 * 将get参数转化成object
 * @param search
 */
export const formatQueryToObject = (search: string) => {
  try {
    return search.split("&").reduce((prev: any, curr) => {
      const p: any = curr.split("=");
      prev[decodeURIComponent(p[0])] = decodeURIComponent(p[1]);
      return prev;
    }, {});
  } catch (error) {
    console.log("formatQueryToObject error:", error);
    return null;
  }
};

/**
 * 通过持久化cookie拿取csrftoken
 * @param userId
 */
export const getInsCrsTokenFromCookie = async (userId: string) => {
  // try {
  //   const cookieMap = await InsPersistCookieHandle.getCookieMap(userId);
  //   if (cookieMap) {
  //     return cookieMap.csrftoken;
  //   } else {
  //     return Promise.reject("Empty Cookie Error");
  //   }
  // } catch (error) {
  //   return Promise.reject(error);
  // }
};

/**
 * 创建canceltoken,时间戳加uuid
 */
export const createCancleToken = () => {
  return `${new Date().valueOf()}-${creatUUid(8, false)}`;
};

/**
 * challenge的path转换
 * @param api_path
 * @param operate
 */
export const chanllengePathParse = (
  api_path: string,
  operate: "reset" | "replay"
) => {
  return api_path.replace("/challenge/", `/challenge/${operate}/`);
};
