import { store } from "@/main";
import * as setting_types from "@/store/constants/setting_types";
import * as types from "@/store/constants/index_types";
import { isIos } from "@/utils/lodash";
import {
  getAppPlatform,
  getNativeCodeVersion,
  getPinadDeviceId
} from "@/utils/js_bridge_info";

export const masterId = () => store.getters[types.GETTER_MASTER_USER_ID];
export const sdkVersion = () => getNativeCodeVersion();
export const appId = () => store.getters[setting_types.GETTER_AD_APP_ID];
export const platform = () => (isIos() ? 1 : 2);
export const userId = () =>
  `${store.getters[types.GETTER_MASTER_USER_ID]}|${getAppPlatform()}`;
export const deviceId = () => getPinadDeviceId();
