import { getDeviceInfo } from "@/utils/js_bridge_info";
import { isIos } from "@/utils/lodash";

// 比较版本号，如果curV >= reqV 返回true， 否则返回false
const judgeVersion: any = (curV: any, reqV: any) => {
  if (curV && reqV) {
    const curArr: any = curV.split(".");
    const reqArr: any = reqV.split(".");

    // 将版本号拆成数字
    const minL: any = Math.min(curArr.length, reqArr.length);
    let pos: any = 0; // 当前比较的位数
    let diff: any = 0; // 当前位数是否相等

    // 逐个比较如果当前位相等则继续比较下一位
    while (pos < minL) {
      diff = parseInt(curArr[pos], 10) - parseInt(reqArr[pos], 10);
      if (diff !== 0) {
        break;
      }
      pos++;
    }
    return diff >= 0;
  } else {
    return false;
  }
};

const versionGreaterThan: any = (version: string) => {
  const nativeCodeVersion = getDeviceInfo().native_code_version;
  return judgeVersion(nativeCodeVersion, version);
};

// 安卓1.1.0版本以上用缓存的视频
export const judgeAndroidVideoSourceFetch = () => {
  try {
    return !isIos() && versionGreaterThan("1.1.0");
  } catch (e) {
    return false;
  }
};
