/**
 * localstorage keys
 */
export const UUID_TYPE = "UUID_TYPE";
export const XI_CUSTOM_USER_ID = "XI_CUSTOM_USER_ID";
export const SERVICE_LIST = "SERVICE_LIST";
export const PAYMENT_PARAMS = "PAYMENT_PARAMS";
export const PRICE_OFFER_MAP = "PRICE_OFFER_MAP";
export const PURCHASED_LIST = "PURCHASED_LIST";
export const LOCALIZE_PRICE = "LOCALIZE_PRICE";
export const APP_FLYER_INFO = "APP_FLYER_INFO";
export const LIMIT_COINS_CACHE = "LIMIT_COINS_CACHE";
export const PINAD_CACHE = "PINAD_CACHE";
export const SUPER_PAY_PARAMS = "SUPER_PAY_PARAMS";
export const HAS_USE_SP_PAY = "HAS_USE_SP_PAY";
export const FAILED_ORDER_LIST = "FAILED_ORDER_LIST";
export const IS_LIMITEDCOIN_INITIAL: string = "IS_LIMITEDCOIN_INITIAL";
export const LIMITEDCOIN_COUNT_TIMESTAMP: string =
  "LIMITEDCOIN_COUNT_TIMESTAMP";
export const LIMITEDCOIN_COUNT_TIMESTAMP_FLAG: string =
  "LIMITEDCOIN_COUNT_TIMESTAMP_FLAG";
export const LIMITEDCOIN_COUNT_DURATION: string = "LIMITEDCOIN_COUNT_DURATION";

export const HAS_SHOW_BA_WHEN_PAYMENT_SUCCESSFUL_CLOSE =
  "HAS_SHOW_BA_WHEN_PAYMENT_SUCCESSFUL_CLOSE";
export const HAS_SHOW_FA_WHEN_PAYMENT_SUCCESSFUL_CLOSE =
  "HAS_SHOW_FA_WHEN_PAYMENT_SUCCESSFUL_CLOSE";
export const BACK_UP_HAS_ADD_COINS = "BACK_UP_HAS_ADD_COINS";
export const HAS_SHOW_FREE_FOLLOW = "HAS_SHOW_FREE_FOLLOW";
export const HAS_ENTER_RECOMMEND = "HAS_ENTER_RECOMMEND_OFFER";
export const PERSIST_USER_HELPERS = "PERSIST_USER_HELPERS";
export const HAS_SHOE_FOLLOW_DIFFERENCE = "HAS_SHOE_FOLLOW_DIFFERENCE";
// act users:
export const ACT_USERS_LIST = "ACT_USERS_LIST";
export const WEBVIEW_SCRIPT = "WEBVIEW_SCRIPT";
// user name explain:
// dot click:
export const CLICKED_BACK_UP_FREE_COINS = "CLICKED_BACK_UP_FREE_COINS";
export const VIDEO_URL_CACHE = "VIDEO_URL_CACHE";

export const REQUEST_NOTIFICATION_AUTH = "REQUEST_NOTIFICATION_AUTH";
