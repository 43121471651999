
import { CreateElement, VNode } from "vue";
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";

// cardlistitem 组件，接收render函数来自定义渲染，渲染回调函数返回当前行数据
@Component({})
export default class CardList extends Vue {
  @Prop()
  private currentIndex: any;
  @Prop()
  private totalIndex: any;
  @Prop()
  private data: any;
  @Prop()
  private customRender: any;
  @Prop()
  private itemStyle: any;
  @Prop()
  private seperateLineStyle: any;

  private render(h: CreateElement) {
    const isCustomRender = typeof this.customRender === "function";
    const customRenderContent = isCustomRender
      ? this.customRender(this.data, this.currentIndex, h)
      : "";
    const seperateLine =
      this.currentIndex < this.totalIndex - 1 ? (
        <section class="seperate-line" style={this.seperateLineStyle}></section>
      ) : (
        ""
      );
    return (
      <section class="card-list-item-wrap" style={this.itemStyle}>
        <section class="card-list-item">{customRenderContent}</section>
        {seperateLine}
      </section>
    );
  }
}
