






import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
@Component({
  components: {}
})
export default class StoreTitle extends Vue {
  @Prop({ default: "Regular Packages" })
  private title: any;
  @Prop({ default: false })
  private border?: boolean;

  get margin() {
    return this.title !== "Lucky Offer Reward"
      ? "margin: 0 12px 0 16px"
      : "margin: 0 12px 0 0;";
  }
}
