import Http from "@/api/http";
import {
  encryptRequestHeaders,
  addDefaultParamsAndEncrypt,
  responseDataDecode,
  responseToError
} from "../interceptors";
import { CgRequestParams } from "../types/index";
import { UrlMap } from "@/api/cg/base/cg_const";
import {
  sendCgInterfaceError,
  sendInterfaceSuccess
} from "@/utils/log_event/api_event";

/**
 * 实例化Http类
 */
const http = new Http({});

/**
 * 对header签名，pinad api需要
 * 对于header需要签名的api，设置
 * extra_data:{
 *  sign_header:true
 * }
 */
// http.interceptors.request.use(
//   config => encryptRequestHeaders(config),
//   error => Promise.reject(error)
// );
/**
 * 添加默认参数并加密
 * 需要设置 extra_data.encrypt_body 为true
 */
// http.interceptors.request.use(
//   config => addDefaultParamsAndEncrypt(config),
//   error => Promise.reject(error)
// );

/**
 * 通过拦截器处理返回的结果
 */
http.interceptors.response.use(
  res => responseToError(res),
  error => Promise.reject(error)
);

http.interceptors.response.use(
  res => responseDataDecode(res),
  error => Promise.reject(error)
);

export default async function cgRequest(
  map: UrlMap,
  method: "GET" | "POST",
  params: object = {},
  extra: any = {}
) {
  try {
    const data: CgRequestParams = {
      url: map.url,
      method,
      params,
      extra_data: {
        encrypt_body: true,
        ...extra
      }
    };
    const requestData: any = await addDefaultParamsAndEncrypt(data);
    const result = await http.request(requestData);
    // 如果有name，需要打点
    map.name && sendInterfaceSuccess(map.name);
    return Promise.resolve(result);
  } catch (e) {
    // 如果有name，需要打点
    map.name && sendCgInterfaceError(map.name, e);
    return Promise.reject(e);
  }
}
