import tt_api_base from "@/api/tt-api/tt_api_base/tt_api_base";

/**
 * tt的media列表
 */
export const tt_user_item_list = async (
  unique_id: string,
  user_id: string,
  min_cursor: string,
  max_cursor: string = "0",
  config?: any
) => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_user_item_list(
      unique_id,
      user_id,
      min_cursor,
      max_cursor,
      config
    );
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};

/**
 * item details
 * @param video_id
 * @param config
 */
export const tt_item_detail = async (video_id: string, config?: any) => {
  try {
    const tt_api = await tt_api_base();
    const res = await tt_api.tt_item_detail(video_id, config);
    return res;
  } catch (error) {
    return Promise.reject(error);
  }
};
