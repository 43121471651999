/*
 * @Author: Jhonsem
 * @Date: 2021-01-06 11:57:49
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2021-01-06 11:58:10
 */

import LaunchHelpers from "@/utils/launch_helpers";
import UmengPush from "@/services/umeng_push/umeng_push";

const launch_params_and_device_info_init = async () => {
  try {
    // 提前注册友盟通知的监听，fix首次启动收不到的问题
    UmengPush.listenRegisterTime();
    const result = await LaunchHelpers.init();
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default launch_params_and_device_info_init;
