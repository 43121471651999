










































import { Component, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import NavBar from "@/components/NavBar/NavBar.vue";
import { SEARCH_TIKTOK_USER_ACTION } from "@/store/constants/tt_user_types";
import routerStacks from "@/plugins/navigation/routes";
import AndroidBack from "@/utils/android_back";
import paymentCheckModule from "@/services/payment/payment_check/index";
import { open_guides } from "@/utils/guides/guides";
import { isIos } from "@/utils/lodash";
import listenNative from "@/jsbridge/service/listenNative";
import { launchBack } from "@/utils/router_helper";
import { getStatusBarHeight } from "@/utils/js_bridge_info";

@Component({
  components: { NavBar }
})
export default class SearchUser extends Vue {
  @Action(SEARCH_TIKTOK_USER_ACTION)
  private searchUser: any;

  private inputText = "";
  private addUserCount: number = 0; //android 第一次可能失败，所以多次请求
  private isCanSearch = true; //防止连续触发两次
  private inputAutofocus: boolean = false;
  private from: any = "";
  private keyboard_type: any = null;
  private keyBoardListener: any = null; // 键盘的监听

  get paddingStyle() {
    return isIos() ? {} : { paddingTop: getStatusBarHeight() + "px" };
  }

  private async clickSearch(retry: boolean) {
    try {
      if (!this.isCanSearch) {
        this.isCanSearch = false;
        setTimeout(() => {
          this.isCanSearch = true;
        }, 500);
        return;
      }
      const username = this.inputText.trim();
      if (!username) {
        this.enterCheck();
        return;
      }
      const reg = /^[a-zA-Z0-9_.]+$/;
      if (!reg.test(username)) {
        this.checkUsername();
        return;
      }

      await this.searchUser({
        uniqueId: username,
        showLoading: true,
        retry,
        from: this.from
      });

      // this.from === "payment_check" && paymentCheckModule.get().next();
      this.addUserCount = 0;
    } catch (error) {
      //
      console.log("clickSearch errro:", error);
    }
  }

  private checkUsername() {
    this.$_Toast.show({
      type: "warning",
      duration: 3000,
      text: "Only letters, numbers, underscores, or periods are allowed."
    });
  }

  private forgetUsername() {
    this.showGuides();
  }

  private clickHelpBtn() {
    this.showGuides();
  }

  private showNetWorkError() {
    this.$_Toast.show({
      duration: 5000,
      text: "Please check your network and try again later."
    });
  }

  private invalidUserError() {
    this.$_Toast.show({
      duration: 5000,
      text: "Please check your TikTok username and try again."
    });
  }

  private enterCheck() {
    this.$_Toast.show({
      type: "warning",
      duration: 5000,
      text: "Please enter your TikTok username."
    });
  }

  private clickClose() {
    launchBack();
    AndroidBack.destroy(this.$route.name as any);
  }

  private async addKeyBoardListen() {
    if (isIos()) {
      this.keyBoardListener = await listenNative.addListen(
        "keyboard",
        (data: any) => {
          console.log("keyboard", data);
          const { type } = data;
          this.keyboard_type = type;
        }
      );
    }
  }

  private created() {
    this.from = this.$route.query.from;
    this.addKeyBoardListen();
    AndroidBack.listen({
      key: this.$route.name,
      fn: () => {
        this.clickClose();
      }
    });
  }

  private showGuides() {
    // guides的webview
    open_guides();
  }
}
