import TTWebAPI from "@/api/tt-api/tt-api";

import {
  tt_response_event_track_interceptor,
  tt_response_error_interceptor
} from "../tt_api_interceptors/tt_api_interceptors";

const add_interceptors = (tt_api_base: any) => {
  // 打点
  tt_api_base.interceptors.response.use(
    tt_response_event_track_interceptor("resolve"),
    tt_response_event_track_interceptor("reject")
  );

  // 错误拦截
  tt_api_base.interceptors.response.use(
    undefined,
    tt_response_error_interceptor
  );
};

const tt_api_base = async () => {
  try {
    //
    if (TTWebAPI.load_tt_script_status === "loaded") {
      return TTWebAPI.tt_api_instance;
    } else {
      // 等待加载完毕
      await TTWebAPI.init_tt_web_api();
      // 添加拦截器:
      add_interceptors(TTWebAPI.tt_api_instance);
    }
    return TTWebAPI.tt_api_instance;
  } catch (error) {
    //
    return Promise.reject(error);
  }
};

export default tt_api_base;
