import { store } from "@/main";
import * as types from "@/store/constants/index_types";

const master_user_id_init = async () => {
  try {
    const result = await store.dispatch(types.UPDATE_MASTER_USER_ID);
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default master_user_id_init;
