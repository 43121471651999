












import { Component, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class App extends Vue {
  private enterTransitionName = "";
  private leaveTransitionName = "";
  private includeRoutes = ["Home", "Orders"];
  private transitionStart(
    direct: string,
    to: any,
    from: any,
    isChildRoute: boolean = false
  ) {
    let transition: string = "forward"; // default transition git
    if (direct === "back") {
      transition = this.getBackTrantion(from.query.routerTransition);
    } else if (direct === "replace" || direct === "forward") {
      if (isChildRoute) {
        transition = "static";
      } else if (to && to.query && to.query.routerTransition) {
        transition = to.query.routerTransition;
      }
    }

    this.getRouterTransition(transition);
  }

  private getBackTrantion(name: any) {
    let transition = "";
    switch (name) {
      case "forward":
        transition = "back";
        break;
      case "back":
        transition = "forward";
        break;
      case "up":
        transition = "down";
        break;
      case "down":
        transition = "up";
        break;
      default:
        transition = "";
        break;
    }
    return transition;
  }

  private getRouterTransition(name: any) {
    switch (name) {
      // delete leave transition for performance
      case "back":
        this.enterTransitionName = "animated slideInLeft";
        // this.leaveTransitionName = "animated slideOutRight";
        break;
      case "forward":
        this.enterTransitionName = "animated slideInRight";
        // this.leaveTransitionName = "animated slideOutLeft";
        break;
      case "down":
        this.enterTransitionName = "animated slideInDown";
        // this.leaveTransitionName = "animated slideOutDown";
        break;
      case "up":
        this.enterTransitionName = "animated slideInUp";
        // this.leaveTransitionName = "animated slideOutUp";
        break;
      case "static":
        this.enterTransitionName = "";
        this.leaveTransitionName = "";
        break;
      default:
        this.enterTransitionName = "";
        this.leaveTransitionName = "";
        break;
    }
  }

  private created() {
    this.$navigation.on("forward", (to: any, from: any) => {
      this.transitionStart("forward", to, from);
    });

    this.$navigation.on("back", (to: any, from: any) => {
      this.transitionStart("back", to, from);
    });

    this.$navigation.on(
      "replace",
      (to: any, from: any, isChildRoute: boolean) => {
        this.transitionStart("replace", to, from, isChildRoute);
      }
    );
  }
}
