
















import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {}
})
export default class Button extends Vue {
  @Prop({ default: "" }) // large normal small mini
  private size?: string;
  @Prop({ default: "" }) // primary default... 同一种按钮的多种形态
  private type?: string;
  @Prop({ default: "" }) // 自定义按钮class
  private from?: string;
  @Prop() // 补充的自定义样式
  private btnStyle?: any;
  @Prop({ default: "normal" }) // 是否展示loading
  private status?: any;
  @Prop({ default: "" }) // column row row-reverse column-reverse
  private flex?: any;

  private clickButton() {
    this.status === "normal" && this.$emit("clickButton");
  }

  get flexProp() {
    return this.flex ? "flexClass " + this.flex : this.flex;
  }
}
