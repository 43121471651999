


















import { Component, Prop, Vue } from "vue-property-decorator";
import { Action } from "vuex-class";
import EventBus from "@/utils/event_bus";
import { payByAny } from "@/services/payment/payment_check/payment_check_logic";

@Component
export default class SpecialOfferItem extends Vue {
  @Prop({ default: "follow" })
  private type?: any;
  @Prop()
  private offer: any;

  get singleOfferIno() {
    if (this.type === "follow") {
      return {
        avatar: "icon-item_icon_follower_red",
        text: "Followers",
        // background: "follow"
        background: "like"
      };
    } else {
      return {
        avatar: "icon-item_icon_like_red",
        text: "Likes",
        background: "like"
      };
    }
  }
  // 点击先购买，然后监听event bus的购买完成事件，触发进入下一个stage
  public async buyOffers() {
    this.offer.purchase_event_params = {
      label: `special_${this.type}_buy`,
      value: {
        offerID: this.offer.offer_id
      }
    };
    // 确保只会监听一次special_offer_buying_finished事件，避免拉起购买时取消购买，导致购买成功后事件多次触发！！
    EventBus.$off("special_offer_buying_finished");
    EventBus.$once("special_offer_buying_finished", () => {
      this.$emit("buyOffers", this.offer);
    });
    payByAny({ offer: this.offer });
  }
}
