import { UPDATE_TIKTOK_MEDIA_LIST } from "@/store/constants/tt_media_types";
import {
  // eslint-disable-next-line
  UpdateTikMediaInfo,
  // eslint-disable-next-line
  OneUserMediaInfoList
} from "@/store/modules/tt_media_module";
import TiktokUserMedaiUtil from "@/utils/tik_user_media/tik_user_media";
import Event_bus from "@/utils/event_bus/index";
export default {
  [UPDATE_TIKTOK_MEDIA_LIST](state: any, payload: UpdateTikMediaInfo) {
    const { tikMediaInfoList, upsateStatus } = payload;
    const findIndex = state.tt_user_media_list.findIndex(
      (item: OneUserMediaInfoList) => item.user_id === tikMediaInfoList.user_id
    );
    if (upsateStatus === "delete") {
      state.tt_user_media_list.splice(findIndex, 1);
    } else if (upsateStatus === "new") {
      findIndex > -1
        ? state.tt_user_media_list.splice(findIndex, 1, tikMediaInfoList)
        : state.tt_user_media_list.unshift(tikMediaInfoList);
      TiktokUserMedaiUtil.init();
    } else {
      const mediaList =
        tikMediaInfoList && tikMediaInfoList.tt_media_list
          ? tikMediaInfoList.tt_media_list
          : [];
      const newMediaList = {
        user_id: tikMediaInfoList.user_id,
        has_more: tikMediaInfoList.has_more,
        max_cursor: tikMediaInfoList.max_cursor,
        tt_media_list: [
          ...state.tt_user_media_list[findIndex].tt_media_list,
          ...mediaList
        ]
      };
      Event_bus.$emit("ins_media_change", {
        upsateStatus,
        media_list: [...mediaList]
      });
      state.tt_user_media_list.splice(findIndex, 1, newMediaList);
    }
    return true;
  }
};
