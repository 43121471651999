









import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import { GETTER_CURRENT_USER_ID } from "@/store/constants/index_types";
@Component({
  components: {}
})
export default class ManageEntrance extends Vue {
  @Getter(GETTER_CURRENT_USER_ID)
  private currentUserId: any;
  private clickMore() {
    this.$emit("click");
  }
  get needBind() {
    return false;
  }

  get entranceName() {
    return this.currentUserId ? "Manage Accounts" : "Add Accounts";
  }
}
