import {
  getInfoFromOffer,
  isCoins,
  isFollow,
  Offer,
  OfferKind,
  OfferSituation,
  OfferWay,
  SkuClickOfferEventParams
} from "@/services/offer/offer_handle";
import { PayWay } from "@/services/payment/types";
import { isSubsOffer } from "@/utils/general_utils";
import { isIos, isNumber, parsePrice } from "@/utils/lodash";
import { getIapPrice, getIapProductId } from "@/plugins/filters/offer_filter";
import {
  PURCHASE_NORMAL,
  PURCHASE_SUBSCRIPTION,
  PURCHASE_SUBSCRIPTION_FREE,
  UIA_COINS_SKU_CLICK,
  UIA_IAP_SKU_CLICK
} from "@/utils/log_event/event_const";
import { sendEvent } from "@/utils/log_event";
import JsBridge from "@/jsbridge";
import { ga_custom_event } from "@/utils/log_event/ga_event/ga_event";
import PurchaseCache from "./cache";
import { isNativePayTypes } from "@/services/payment/utils";
import LocalizeUtil from "@/services/payment/localize";

export const purchaseEvent = (offer: Offer, pay_way: PayWay) => {
  try {
    if (pay_way === PayWay.coin) {
      return;
    }
    const isSub = isSubsOffer(offer);
    const subIsFree = !!offer.free_days;
    const af_revenue = getInfoFromOffer(
      offer,
      pay_way === PayWay.back_up ? PayWay.iap : pay_way
    );
    const productId = getIapProductId(offer);
    const localize = LocalizeUtil.getLocalize(productId);
    const currency_code = localize && localize.currency_code;
    const price = localize && localize.price;
    const price_number = af_revenue.split("$")[1].trim();
    console.log("price_number", price_number);
    const localize_price = isIos()
      ? price
        ? price
        : price_number
      : price_number;
    const iapPrice = getIapPrice(offer);
    const iap_price_num = iapPrice.split("$")[1].trim();
    console.log("iap_price_num", iap_price_num);
    let eventName = "";
    if (isIos()) {
      eventName =
        isSub &&
        offer.hasOwnProperty("is_no_auto_renew") &&
        !offer.is_no_auto_renew &&
        isNativePayTypes(pay_way)
          ? PURCHASE_SUBSCRIPTION
          : PURCHASE_NORMAL;
    } else {
      eventName = isSub
        ? subIsFree
          ? PURCHASE_SUBSCRIPTION_FREE
          : PURCHASE_SUBSCRIPTION
        : PURCHASE_NORMAL;
    }
    const params = {
      af_content_id: getIapProductId(offer),
      af_currency: isIos() ? (currency_code ? currency_code : "USD") : "USD",
      usd_price: price_number,
      offer_id: offer.offer_id,
      pay_method: pay_way,
      offer_detail: PurchaseCache.get()
    };
    sendEvent(eventName, {
      ...params,
      af_revenue: localize_price.toString()
    });
    setTimeout(() => {
      // af大点需要的价格是Number类型
      JsBridge.logEvent(["apps_flyer"], eventName, {
        ...params,
        af_revenue: Number(localize_price)
      });
      JsBridge.logEvent(["apps_flyer"], `purchase_${iap_price_num}`, {
        ...params,
        af_revenue: Number(localize_price)
      });
    }, 0);
  } catch (e) {
    console.log("purchaseEvent e", e);
  }
};

export const appFlyerTapEvent = (offer: Offer) => {
  try {
    const label: OfferKind = offer.kind;
    let tag: any;
    if (isCoins(label)) {
      tag = "o";
    } else if (isFollow(label)) {
      tag = "f";
    } else if (label === OfferKind.like || label === OfferKind.special_like) {
      tag = "l";
    } else if (label === OfferKind.vip) {
      tag = "vip";
    }
    if (getIapPrice(offer) && tag) {
      const price = getIapPrice(offer);
      let priceString: any;
      if (isNumber(price)) {
        priceString = price.toString();
      } else {
        priceString = price as string;
      }
      const priceResult: any = parseFloat(parsePrice(priceString));
      const action = "tap_" + tag + "_" + priceResult;
      const af_content_type = label ? label : "null";
      console.log("appFlyerSendEvent event", {
        [action]: {
          af_content_type
        }
      });
      JsBridge.logEvent(["apps_flyer"], action, {
        af_content_type
      });
      ga_custom_event(action, {
        af_content_type
      });
    }
  } catch (e) {
    //
  }
};

export const skuClickEvent = (params: SkuClickOfferEventParams) => {
  try {
    const { offer, result, situation } = params;
    const eventName =
      offer.way === OfferWay.coin ? UIA_COINS_SKU_CLICK : UIA_IAP_SKU_CLICK;
    const cost =
      offer.way === OfferWay.coin
        ? { coins: offer.coins }
        : { usd_price: parsePrice(offer.pay_data!.iap.price) };
    const offer_detail =
      offer.offer_id + "." + (situation ? situation : OfferSituation.default);
    PurchaseCache.set(offer_detail);
    console.log("skuClickEvent", eventName, {
      result,
      offer_detail,
      offer_id: offer.offer_id,
      ...cost
    });
    sendEvent(eventName, {
      result,
      offer_detail,
      offer_id: offer.offer_id,
      ...cost
    });
  } catch (e) {
    //
  }
};
