import Vue from "vue";

export const tt_error_toast = (res: any) => {
  const { response, message } = res;

  if (response && response.message) {
    Vue.prototype.$_Toast.show({
      duration: 3000,
      text: response.message,
      type: "warning"
    });
  } else if (message) {
    Vue.prototype.$_Toast.show({
      duration: 3000,
      text: message,
      type: "warning"
    });
  } else {
    Vue.prototype.$_Toast.show({
      duration: 3000,
      text: "unknown error",
      type: "warning"
    });
  }
};
