import store from "@/store";
import * as cgConst from "@/api/cg/base/cg_const";
import * as types from "@/store/constants/setting_types";

/**
 * 返回settings
 */
const online_setting_init = async () => {
  try {
    const result = await store.dispatch(
      types.UPDATE_SETTING_DATA,
      cgConst.COMMON_KEYS
    );
    return result;
  } catch (error) {
    return Promise.reject(error);
  }
};

export default online_setting_init;
