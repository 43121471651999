import jsBridge from "@/jsbridge/index";
import { store } from "@/main";
import { GETTER_HELP_GUIDES_URL } from "@/store/constants/setting_types";

// ua,ap,vp

export const open_guides = (type: "vp" | "ap" | "ua" = "ua") => {
  const guideBaseURL = store.getters[GETTER_HELP_GUIDES_URL];
  jsBridge
    .openBrowser(`${guideBaseURL}?chapter=${type}`, "in_app_browser", {
      title: "Help"
    })
    .catch(() => void 0);
};
