




import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {}
})
export default class TTLoading extends Vue {
  @Prop()
  private backGround?: any;
}
