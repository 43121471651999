export const logInfo = (tag: string, data: any) => {
  console.info(`%c ${tag}:`, "color: #47ae92;font-weight:bold", data);
};

export const logErr = (tag: string, data: any) => {
  console.info(`%c ${tag}:`, "color: #ee687b;font-weight:bold", data);
};

export const log = (tag: string, data: any, color: string = "#529cd4") => {
  console.info(`%c ${tag}:`, `color: ${color};font-weight:bold`, data);
};
