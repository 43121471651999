import { LocalizeTypes } from "@/services/payment/localize/types";
import Vue from "vue";

const localize_store: LocalizeTypes = Vue.observable({
  products: {},
  invalid_product_ids: []
});

const localize_mutations = {
  update_localize(params: LocalizeTypes) {
    const { products, invalid_product_ids } = params;
    localize_store.products = products;
    localize_store.invalid_product_ids = invalid_product_ids;
  }
};

const localize_getters = {
  products_getter() {
    return localize_store.products;
  },
  invalid_product_ids_getter() {
    return localize_store.invalid_product_ids;
  }
};

export { localize_mutations, localize_getters };
