/**
 * 购买前的检查
 *  1、检查是类型，如果是非coins和vip，则需要等待登录
 *  2、如果是通过coins购买，则检查当前金币是否充足
 *  3、是否是隐私账号,隐私买follow直接购买，买like阻塞
 *  4、如果购买的是post类型的，需检查是否有po
 *  5、如果购买的是post类型的，需检查是po是否有效
 *  6、如果购买的是post类型的，需检查是po是否是私密的
 *  7、如果是通过coins购买，并且是大额购买，则弹窗提示
 */

import * as checkUtil from "./payment_check_helper";
import {
  getResourceFromOffer,
  PaymentWithSituationParams,
  SkuClickCategory
} from "@/services/offer/offer_handle";
import { isPostKindOffer, isVipOrCoins } from "@/utils/general_utils";
import paymentCall from "@/services/payment/paymentCall";
import { goToSearchOrLogin } from "@/utils/router_helper";
import {
  coinsNotEnoughDialog,
  largePurchaseConfirm,
  showPostPrivateDialog,
  showPrivateAccountDialog
} from "./payment_check_dialog";
import { Vue } from "@/main";
import { skuClickEvent } from "@/utils/log_event/purchase_event";

const paymentCheckGenerator = async function* PaymentCheck(
  params: PaymentWithSituationParams
) {
  const { offer } = params;
  // 如果不是vip或者coins类型，需要往下校验
  if (!isVipOrCoins(offer)) {
    // 判断有没有登录，如果没有登录，出登录弹窗
    if (checkUtil.emptyUser()) {
      skuClickEvent({ ...params, result: SkuClickCategory.login_required });
      yield goToSearchOrLogin("payment_check");
    }
    // 如果金币不够，出金币不够弹窗
    if (checkUtil.lackCoins(offer)) {
      yield coinsNotEnoughDialog(params);
    }
    // 如果是隐私账号
    if (checkUtil.isPrivateUser()) {
      yield showPrivateAccountDialog(
        offer.kind,
        isPostKindOffer(offer),
        params
      );
    }
    // 如果买like和comment没有post
    if (isPostKindOffer(offer)) {
      // 如果没有po
      if (checkUtil.buyLikeOrCommentWithoutPost()) {
        skuClickEvent({ ...params, result: SkuClickCategory.need_post });
        Vue.prototype.$_Toast.show({
          duration: 2000,
          type: "warning",
          text: `No post yet!`
        });
        return;
      }
      // if po invalid
      if (await checkUtil.currentPostIsInvalid()) {
        Vue.prototype.$_Toast.show({
          duration: 3000,
          type: "warning",
          text: "This video isn't available."
        });
        return;
      }
      // if po is private
      if (checkUtil.currentPostIsPrivate()) {
        skuClickEvent({ ...params, result: SkuClickCategory.private_post });
        yield showPostPrivateDialog();
      }
    }
    // if large purchase
    if (checkUtil.isLargePurchase(offer)) {
      yield largePurchaseConfirm(params);
    }
  }
  paymentCall.payAny(params);
};

class PaymentCheckModule {
  private generator: any;

  public init(params: PaymentWithSituationParams) {
    this.generator = paymentCheckGenerator(params);
  }

  public get() {
    return this.generator;
  }
}

export default new PaymentCheckModule();
