import InitLifyCycle from "@/utils/init_lifecycle/init_lifecycle";
import jsBridge from "@/jsbridge/index";
import { store } from "@/main";
import { isIos } from "@/utils/lodash";
import { connect } from "@/api/cg/service/pinad_service";
import { configVideo } from "@/services/watch_video";
import * as types from "@/store/constants/index_types";
import * as setting_types from "@/store/constants/setting_types";
import PinadCoinAdd from "@/services/pinad_add_coin";
import UmengPush from "@/services/umeng_push/umeng_push";
import SwitchBackground from "@/utils/switch_background";
import AndroidBack from "@/utils/android_back";
import LocalizeUtil from "@/services/payment/localize";
import { receiveOpenUrlMsg } from "@/services/payment/super_pay_receive";
import { act_users_action } from "@/services/earn_coins/observer/act_observer";
import ServiceList from "@/services/serviceList";
import SuperPayCache from "@/services/super_pay";
import { font_loader } from "@/utils/font_loader/font_loader";
import tt_api_base from "@/api/tt-api/tt_api_base/tt_api_base";
import AppFlyerInfo from "@/services/appFlyerInfo/appFlyerInfo";
import { xiLaunch } from "@/utils/log_event/xi_event/xi_event";
import PaymentUtil from "@/services/payment/paymentCache";
import { launchEvent } from "@/utils/log_event/launch_time_event/launch_event";
import { sendWebVitalCacheEvent } from "@/utils/log_event/web_vitals";

const initLaunch = () => {
  new InitLifyCycle({
    launchParamsAndDeviceInfoReady: async () => {
      // ================= 设备参数和启动参数ready!====================
      font_loader();
      // 提前下载脚本和执行脚本
      tt_api_base().catch(() => void 0);
    },
    localSettingReady: async ({ local_file_settings }) => {
      // ================= 本地setting的数据ready!====================

      // 前后台切换监听，安卓返回键监听
      SwitchBackground.init();
      // 安卓才初始化安卓返回键
      !isIos() && AndroidBack.initial();
      // 存储本地化列表
      if (local_file_settings) {
        LocalizeUtil.savePriceProductIdList(local_file_settings);
      }
      // 初始化act_user_list：
      act_users_action.act_users_restore();
    },
    masterUserIdReady: async () => {
      // ================= 主账号id的ready!==========================
      // 初始化和动态增加路由
      store.dispatch(types.INIT_DEFAULT_ROUTER);
    },
    jsFrameDomReady: async () => {
      // ================= Dom 在nextTick回调中ready!====================

      // 隐藏掉原生的loading覆盖层
      jsBridge.hideLoading().catch(() => void 0);
    },
    masterUserIdLoginedReady: async () => {
      // ================= 主账号id向cg后台登陆ready!====================
      // 更新service list
      ServiceList.updateServiceList();
      // 更新sp apps
      SuperPayCache.getOnlineApps();
      // app启动需要预先pinad connect
      connect();
      // app启动先对安卓pinad手动加币
      PinadCoinAdd.launchAddCoin();
      // umeng init from remote or local
      UmengPush.init();
      // 支付次数等缓存以及app_flyers_info拉取到之后进行xi launch相关的打点
      Promise.all([
        PaymentUtil.launchExecute(),
        AppFlyerInfo.waitAppFlyerInfo()
      ]).then(() => {
        xiLaunch();
        // 记录首次启动的各阶段加载时间
        PaymentUtil.isFirstLaunch() && launchEvent();
        sendWebVitalCacheEvent(PaymentUtil.isFirstLaunch());
      });
      isIos() && receiveOpenUrlMsg();
    },
    onlineSettingReady: async () => {
      // ================= 线上的setting拉取成功后的ready!====================

      // 告诉原生最新的web_host是什么地址
      store.dispatch(setting_types.CONFIG_HY_WEB_HOST).catch(() => void 0);
      // 判断是否获取过最新的setting，如果没有获取过，则更新state状态
      const has_loaded_online_setting: boolean =
        store.getters[setting_types.GETTER_ONLINE_SETTING_STATE];
      !has_loaded_online_setting &&
        store.commit(setting_types.UPDATE_LOADED_ONLINE_SETTING_STATE);
      // 配置好 video ad，需要拉取到最新setting后再config_video
      configVideo().catch(() => void 0);
    }
  });
};

export default initLaunch;
