import { RANDOM_FEED_KINDS } from "./../earn_coins_consts/earn_coins_consts";
import { creatUUid } from "@/api/utils/api_helpers";

export const get_random_offer_feed = (feed_coin_offers: any) => {
  // 从feed coins中根据权重取出:
  const randomArr = [];
  let totalWeight = 0;

  // 先过滤掉写死的类型：
  feed_coin_offers = feed_coin_offers.filter((offer: any) => {
    return RANDOM_FEED_KINDS.includes(offer.kind);
  });

  for (const item of feed_coin_offers) {
    totalWeight += item.green_light.weight;
  }

  for (const offer of feed_coin_offers) {
    const times: any = Math.ceil(
      (offer.green_light.weight / totalWeight) * 100
    );
    for (let i = 0; i < times; i++) {
      randomArr.push({
        kind: offer.kind,
        sub_kind: offer.kind,
        offer: {
          ...offer
        },
        feed_id: creatUUid(),
        coins: 0,
        subresources: []
      });
    }
  }

  // 从0-100选一个随机数:
  const randomNum = Math.floor(Math.random() * 100);
  return randomArr[randomNum];
};
