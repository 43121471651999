import Jsbridge from "@/jsbridge";
import { getDeviceInfo } from "@/utils/js_bridge_info";
import { store } from "@/main";
import * as setting_types from "@/store/constants/setting_types";
import * as types from "@/store/constants/index_types";
import { isIos } from "@/utils/lodash";
import { xiAds } from "@/utils/log_event/xi_event/xi_event";
import { advertisementAct } from "@/api/cg/service/user_service";
import Vue from "vue";
import SwitchBackgroundPosition, {
  SwitchPositionTypes
} from "@/utils/switch_background/switch_background_position";

/**
 * 获取不同平台的平台列表
 */
const getPlayPriority = () => {
  if (isIos()) {
    // return ["app_lovin", "ad_mob"];
    return ["ad_mob", "app_lovin"];
  } else {
    return ["app_lovin", "vungle"];
  }
};

// app启动时，配置所有级别的视频
export const configVideo = async () => {
  try {
    const getPlayList: string[] = getPlayPriority();
    getPlayList.forEach((type: any) => {
      // 通过setting去配置不同平台的初始化启动
      configVideoByType(type);
    });
  } catch (error) {
    console.log("configVideo error", error);
    return Promise.reject(error);
  }
};

export const configVideoByType = async (
  type: "app_lovin" | "ad_mob" | "vungle"
) => {
  try {
    const { ads } = store.getters[setting_types.GETTER_CLIENT_SETTINGS];
    if (type === "app_lovin") {
      // 双平台都有
      await Jsbridge.configVideo(type, ads.applovin_app_id);
    } else if (type === "ad_mob") {
      // IOS才有
      await Jsbridge.configVideo(type, "", ads.admob_placement_free_coins);
    } else if (type === "vungle") {
      // 安卓才有
      await Jsbridge.configVideo(type, ads.vungle_app_id, [
        ads.vungle_placement_free_coins
      ]);
    }
  } catch (error) {
    //
  }
};

export const checkVideoReady = async (
  type: "app_lovin" | "ad_mob" | "vungle"
) => {
  try {
    const { ads } = store.getters[setting_types.GETTER_CLIENT_SETTINGS];
    const result = await Jsbridge.getVideoReady(
      type,
      ads.vungle_placement_free_coins
    );
    return result;
  } catch (error) {
    return false;
  }
};

export const playVideoByType = async (
  type: "app_lovin" | "ad_mob" | "vungle"
) => {
  try {
    let result;
    if (type === "app_lovin") {
      result = await playAppLovin();
    } else if (type === "ad_mob") {
      result = await playAdmob();
    } else if (type === "vungle") {
      result = await playVungle();
    }
    return result;
  } catch (error) {
    return false;
  }
};

export const playAdmob = async () => {
  try {
    const result = await Jsbridge.playVideo("ad_mob");
    xiAds("video_imp", "admob");
    return result;
  } catch (error) {
    return false;
  }
};

export const playAppLovin = async () => {
  try {
    const masterId = store.getters[types.GETTER_MASTER_USER_ID];
    const { app_platform } = getDeviceInfo();
    const user_identifier = `${masterId}|${app_platform}|freecoin`;
    const result = await Jsbridge.playVideo("app_lovin", user_identifier);
    xiAds("video_imp", "applovin");
    return result;
  } catch (error) {
    return false;
  }
};

export const playVungle = async () => {
  try {
    const masterId = store.getters[types.GETTER_MASTER_USER_ID];
    const { app_platform } = getDeviceInfo();
    const { ads } = store.getters[setting_types.GETTER_CLIENT_SETTINGS];
    const user_identifier = `${masterId}|${app_platform}|freecoin`;
    const placement_id = ads.vungle_placement_free_coins;
    const result = await Jsbridge.playVideo(
      "vungle",
      user_identifier,
      placement_id
    );
    xiAds("video_imp", "vungle");
    return result;
  } catch (error) {
    return false;
  }
};

// 获取并根据视频ready状态自动播放对应级别的视频，只会播放一个视频
// 返回一个对象，type表示视频类型，readyStatus表示视频获取状态，result表示播放结果
export const playVideo = async (from?: string): Promise<any> => {
  try {
    const getPlayList: any = getPlayPriority();
    let type: any;
    let result: any;
    let readyStatus: boolean = false;

    for (const key of getPlayList) {
      const checkResult = await checkVideoReady(key);
      if (checkResult) {
        readyStatus = true;
        result = await playVideoByType(key);
        // 一旦视频可以播放，则不需要请求别的级别视频，跳出for循环
        type = key;

        break;
      }
    }
    return {
      readyStatus,
      type,
      result
    };
  } catch (error) {
    console.log("playVideo error", error);
  }
};

/**
 * watch video common logic
 * @param from
 */
export const playVideoEffect = async (from: string) => {
  try {
    SwitchBackgroundPosition.setPosition(
      SwitchPositionTypes.NOT_REQUIRE_REFRESH
    );
    const { readyStatus, result, type } = await playVideo(from);
    if (readyStatus) {
      if (!result) {
        Vue.prototype.$_Toast.show({
          type: "fail",
          text: "watch video failed"
        });
      } else {
        if (type === "ad_mob") {
          try {
            await advertisementAct(
              store.getters[types.GETTER_MASTER_USER_ID],
              getDeviceInfo().app_platform,
              "admob"
            );
          } catch (error) {
            if (error) {
              console.log(error);
              Vue.prototype.$_Toast.show({
                text: "Act AD too fast"
              });
            }
          }
        }
      }
    } else {
      Vue.prototype.$_Toast.show({
        text: "videos are not ready yet"
      });
    }
  } catch (error) {
    //
    Vue.prototype.$_Toast.show({
      type: "fail",
      text: "watch video failed"
    });
    return Promise.reject(error);
  }
};
