class GuideUtile {
  private showGuide = false;

  public changeGuide(val: boolean) {
    this.showGuide = val;
  }

  public getGuide() {
    return this.showGuide;
  }
}

export default new GuideUtile();
