import { getAppsData } from "@/api/cg/service/setting_service";
import { store } from "@/main";
import * as types from "@/store/constants/index_types";
import { SUPER_PAY_PARAMS } from "@/utils/nativeStorage/local_keys";
import { CacheModule } from "@/services/cacheModule";
import { getBundleId } from "@/utils/js_bridge_info";
import { AppsCategory } from "@/services/super_pay/types";
import { getInstalledList } from "@/jsbridge/service/commonMethod";

const defaultParams: any = {
  [AppsCategory.super_pay]: null,
  [AppsCategory.back_up]: null,
  [AppsCategory.feature_apps]: null,
  [AppsCategory.possible_apps]: []
};
const superPayMap: any = {
  sp: AppsCategory.super_pay,
  ba: AppsCategory.back_up,
  fa: AppsCategory.feature_apps,
  bids: AppsCategory.possible_apps
};

class SuperPayCache extends CacheModule {
  constructor() {
    super(SUPER_PAY_PARAMS, defaultParams);
  }

  public async getOnlineApps() {
    try {
      console.log("getOnlineApps");
      const master_id = store.getters[types.GETTER_MASTER_USER_ID];
      const bundle_id = getBundleId();
      const results: any = {};
      const possible_bundle_ids = await getInstalledList();
      const appsData: any = await getAppsData(
        master_id,
        bundle_id,
        possible_bundle_ids
      );
      Object.keys(appsData).forEach(key => {
        if (Object.keys(superPayMap).includes(key)) {
          results[superPayMap[key]] = appsData[key];
        }
      });
      console.log("getOnlineApps", results);
      this.setCache(results);
    } catch (e) {
      //
      console.log("getOnlineApps", e);
    }
  }

  public getAppsData(name?: AppsCategory) {
    const appsData = this.get();
    return name ? appsData[name] : appsData;
  }
}
export default new SuperPayCache();
