import { Component, Prop, Vue } from "vue-property-decorator";
import IntervalTimer from "../interval_timer";
import paymentCheckModule from "@/services/payment/payment_check";
@Component
class ConfirmBtn extends Vue {
  @Prop()
  private ctx: any;
  @Prop()
  private offer: any;

  private time = 5;

  private divStyle =
    "font-size: 16px;line-height: 22px;color: rgb(140,140,140);padding-bottom:20px;color:#fff;background:rgb(255, 61, 89);border-radius:8px;padding:10px 0;font-family:AppleSDGothicNeo-Medium, Poppins;";
  private spanStyle = "";

  public render() {
    return (
      <div onClick={this.buyOffer} style={this.divStyle}>
        OK <span style={this.spanStyle}>({this.time}s)</span>
      </div>
    );
  }

  private mounted() {
    this.setIntervalTime();
  }

  private destroyed() {
    clearInterval(IntervalTimer.getTimer());
  }

  private setIntervalTime() {
    clearInterval(IntervalTimer.getTimer());
    const timer = setInterval(() => {
      IntervalTimer.setTimer(timer);
      --this.time;
      if (this.time <= 0) {
        this.buyOffer();
      }
    }, 1000);
  }
  private buyOffer() {
    clearInterval(IntervalTimer.getTimer());
    this.ctx.isShow = false;
    setTimeout(() => {
      paymentCheckModule.get().next();
    }, 0);
  }
}

export default ConfirmBtn;
