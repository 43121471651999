import jsbridge from "@/jsbridge/index";
import { isIos } from "@/utils/lodash";
import { RedirectApp } from "@/services/open_pop_up/types";
import { App } from "@/services/super_pay/types";
import listenNative from "@/jsbridge/service/listenNative";
import paymentCall from "@/services/payment/paymentCall";
import { Offer } from "@/services/offer/offer_handle";

// tslint:disable-next-line:ban-types
export const downloadApps = async (
  item: App,
  listenCbParams?: undefined | Offer | Function
): Promise<void> => {
  // 应用内打开app store下载
  if (item.openStoreInApp && isIos()) {
    const res: any = await jsbridge.openAppStore(item.appStoreId);
    if (res) {
      const listenId: string = await listenNative.addListen(
        `exit_store_${res}`,
        () => {
          if (typeof listenCbParams === "function") {
            listenCbParams();
          } else {
            paymentCall.autoExecutePay();
            // remove listen:
            listenNative.removeListen(listenId);
          }
        }
      );
    }
  } else {
    // 跳转链接下载
    jsbridge
      .openBrowser(item.downloadUrl, "system_browser")
      .catch(() => void 0);
  }
};

export const download_redirect_app = (item: RedirectApp) => {
  jsbridge.openBrowser(item.app_url, "system_browser").catch(() => void 0);
};
