export const LOGIN_MASTER_ID_BY_GX = "LOGIN_MASTER_ID_BY_GX";
export const UPDATE_GX_USER_INFO = "UPDATE_GX_USER_INFO";
export const UPDATE_VIP_PACKAGE_STATUS = "UPDATE_VIP_PACKAGE_STATUS";
export const INIT_GX_USER_INFO = "INIT_GX_USER_INFO";
// ============= getters ============
export const GETTER_COINS = "GETTER_COINS";
export const GETTER_PAYPAL_ENABLE = "GETTER_PAYPAL_ENABLE";
export const GETTER_CARD_ENABLE = "GETTER_CARD_ENABLE";
export const GETTER_SERVER_CURRENT_TIME = "GETTER_SERVER_CURRENT_TIME";
export const GETTER_XMAIL = "GETTER_XMAIL";
export const GETTER_VIP_VALID_COUNT = "GETTER_VIP_VALID_COUNT";
export const GETTER_VIP_VALID_TIME = "GETTER_VIP_VALID_TIME";
