/*
 * @Author: Jhonsem
 * @Date: 2021-01-06 12:01:06
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2021-01-06 14:10:57
 * 1.launchParams
 * 2.deviceInfo
 * 3.store从native_storage restore
 */

import Jsbridge from "@/jsbridge/index";
import { store } from "@/main";
import LaunchTimeCache, {
  PeriodType
} from "@/utils/log_event/launch_time_event/launchTimeCache";

class LaunchDataHelpers {
  public launchData!: any;

  constructor() {
    this.launchData = new Map();
  }

  public init() {
    return new Promise((resolveFinally, rejectFinally) => {
      Promise.all([
        this.launchParamsPromise(),
        this.deviceInfoPromise(),
        this.storeRestoredPromise() // restored from native_storage
      ])
        .then(([launchParams, deviceInfo]) => {
          const launchDataSet = {
            launchParams,
            deviceInfo
          };
          this.setLaunchData(launchDataSet);
          resolveFinally(launchDataSet);
        })
        .catch((error: any) => {
          console.log("error:", error);
          rejectFinally(error);
        });
    });
  }

  public storeRestoredPromise() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await (store as any).restored;
        LaunchTimeCache.setPeriod(PeriodType.STORE_RESTORED_PERIOD);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  public launchParamsPromise() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Jsbridge.launchParams();
        LaunchTimeCache.setPeriod(PeriodType.LAUNCH_PARAMS_PERIOD);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  public deviceInfoPromise() {
    return new Promise(async (resolve, reject) => {
      try {
        const res = await Jsbridge.deviceInfo();
        LaunchTimeCache.setPeriod(PeriodType.DEVICE_INFO_PERIOD);
        resolve(res);
      } catch (error) {
        reject(error);
      }
    });
  }

  public setLaunchData(params: any) {
    for (const key of Object.keys(params)) {
      this.launchData.set(key, params[key]);
    }
  }

  public getLaunchData(key: string) {
    return this.launchData.get(key);
  }
}

export default new LaunchDataHelpers();
