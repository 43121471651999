















import { Component, Emit, Vue } from "vue-property-decorator";
import Button from "@/components/Button/Button.vue";

@Component({
  components: { Button }
})
export default class AddAcount extends Vue {
  @Emit("addAccount")
  private addAccount() {
    // console.log("click");
  }
}
