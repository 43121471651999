










let vm: any;
import { Component, Vue } from "vue-property-decorator";
import { canUseWebP } from "@/utils/lodash";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Getter, Action } from "vuex-class";
import {
  GETTER_CURRENT_TIKTOK_USER_MEDIA,
  LOAD_MORE_MEDIA_LIST
} from "@/store/constants/tt_media_types";
import TiktokUserMedaiUtil from "@/utils/tik_user_media/tik_user_media";
import Event_bus from "@/utils/event_bus";
@Component({
  components: { swiper, swiperSlide }
})
export default class postMoreSwiper extends Vue {
  @Getter(GETTER_CURRENT_TIKTOK_USER_MEDIA)
  private currentMediaList: any;
  @Action(LOAD_MORE_MEDIA_LIST)
  private loadMoreMedia: any;
  private showLoading = false;
  private slideIndex: number = 0;
  private can_user_webp: boolean = canUseWebP();

  get postList() {
    return this.currentMediaList && this.currentMediaList.tt_media_list;
  }
  get postListLength() {
    return this.postList && this.postList.length;
  }

  get swiperOption() {
    return {
      slidesPerView: 4,
      centeredSlides: true,
      observer: true,
      initialSlide: this.slideIndex,
      lazy: {
        loadPrevNextAmount: 3
      },
      virtual: {
        slides: [...this.postList],
        addSlidesAfter: 1,
        renderSlide: (slide: any) => {
          const image_pic = slide.image_thumb;
          const slideIn = slide.isLoading
            ? `<img class="post-loading" src="${slide.img}" />`
            : `<div class="post-item"><img class="pic" src="${image_pic}" /></div>`;
          return `<div class="swiper-slide">${slideIn}</div>`;
        }
      },
      on: {
        slideChange() {
          vm.slideChange();
        },
        tap() {
          vm.clickSlide();
        }
      }
    };
  }

  private slideChange() {
    if (this.postMoreSwiper) {
      const activeIndex = this.postMoreSwiper.activeIndex;
      this.changeSwiper(activeIndex);
    }
  }

  private async changeSwiper(val: number) {
    const currentMedia = this.postList[val];
    this.slideIndex = val;
    TiktokUserMedaiUtil.setCurrentPost(currentMedia);
    if (
      val >= this.postListLength - 1 &&
      !this.showLoading &&
      this.moreAvailable
    ) {
      // 加载更多
      try {
        this.showLoading = true;
        this.$emit("showLaoding");
        await this.loadMoreMedia();
        this.showLoading = false;
        this.$emit("showLaoding");
        this.postMoreSwiper && this.postMoreSwiper.slideTo(val, 0, false);
      } catch (error) {
        this.showLoading = false;
        this.$emit("showLaoding");
        this.$_Toast.show({
          duration: 2000,
          text: "Please check your network and try again later."
        });
      }
    }
  }

  private setVirtualSlides(value: any) {
    try {
      if (this.postMoreSwiper) {
        const { upsateStatus, media_list } = value;
        if (upsateStatus === "more") {
          this.postMoreSwiper.virtual.appendSlide(media_list);
        } else {
          this.postMoreSwiper.virtual.removeAllSlides();
          this.postMoreSwiper.virtual.appendSlide([...this.postList]);
        }
      }
    } catch (error) {
      //
    }
  }

  private clickSlide() {
    try {
      if (this.postMoreSwiper) {
        const clickedIndex = this.postMoreSwiper.clickedIndex;
        this.postMoreSwiper &&
          this.postMoreSwiper.slideTo(clickedIndex, 0, false);
      }
    } catch (error) {
      //
    }
  }

  get postMoreSwiper() {
    return (
      this.$refs.postMoreSwiper && (this.$refs.postMoreSwiper as any).swiper
    );
  }

  get moreAvailable() {
    return this.currentMediaList && this.currentMediaList.max_cursor;
  }

  private swiperSwipe() {
    this.$nextTick(() => {
      try {
        const postInfo = TiktokUserMedaiUtil.getCurrentPost();
        if (postInfo) {
          let index = this.postList.findIndex((item: any) => {
            return item.id === postInfo.id;
          });
          if (this.slideIndex !== index) {
            this.postMoreSwiper && this.postMoreSwiper.slideTo(index, 0, false);
            this.slideIndex = index;
          }
        }
      } catch (error) {
        //
      }
    });
  }
  private created() {
    vm = this;
  }

  private mounted() {
    Event_bus.$on("ins_media_change", (val?: any) => {
      if (val) {
        this.setVirtualSlides(val);
      }
      this.swiperSwipe();
    });
  }
}
