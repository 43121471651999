export const SEARCH_TIKTOK_USER_ACTION = "SEARCH_TIKTOK_USER_ACTION";
export const LOGIN_TIKTOK_USER_ACTION = "LOGIN_TIKTOK_USER_ACTION";
export const TIKTOK_ADD_USER_RESOLVE = "TIKTOK_ADD_USER_RESOLVE";
export const UPDATE_TIKTOK_USER_INFO = "UPDATE_TIKTOK_USER_INFO";
export const GETTER_CURRENT_TIKTOK_USER_INFO =
  "GETTER_CURRENT_TIKTOK_USER_INFO";
export const GETTER_TIKTOK_USER_EMPTY = "GETTER_TIKTOK_USER_EMPTY";
export const GEETER_TIKTOK_USER_LIST = "GEETER_TIKTOK_USER_LIST";
export const GEETER_TIKTOK_LOGIN_USER = "GEETER_TIKTOK_LOGIN_USER";
export const TIKTOK_LOGIN_USER_EMPTY = "TIKTOK_LOGIN_USER_EMPTY";
export const REFRESH_CUREENT_TIKTOK_USER_INFO =
  "REFRESH_CUREENT_TIKTOK_USER_INFO";
export const DELETE_TIKTOK_USER = "DELETE_TIKTOK_USER";
export const DELETE_TIKTOK_LOGIN_USER = "DELETE_TIKTOK_LOGIN_USER";
export const GETTER_CURRENT_TIKTOK_USER_SECRET =
  "GETTER_CURRENT_TIKTOK_USER_SECRET";
export const REFRESH_CURRENT_USER_TIKTOK_DATA =
  "REFRESH_CURRENT_USER_TIKTOK_DATA";
export const BIND_VIP_TO_TIKTOK_USER = "BIND_VIP_TO_TIKTOK_USER";
