

































import { Component, Vue } from "vue-property-decorator";
import { Getter, Action } from "vuex-class";
import * as cg_user_types from "@/store/constants/cg_user_types";
import * as setting_types from "@/store/constants/setting_types";
import Container from "@/components/Container/Container.vue";
import NavTab from "@/components/NavTab/NavTab.vue";
import Swiper from "@/components/Swiper/Swiper.vue";
import { swiperSlide } from "vue-awesome-swiper";
import Scroller from "@/components/Scroller/Scroller.vue";
import RegularCoin from "@/components/CoinEntrance/RegularCoin.vue";
import StoreTitle from "./StoreTitle/index.vue";
import { isVip } from "@/utils/general_utils";
import Event_bus from "@/utils/event_bus";
import * as cgConst from "@/api/cg/base/cg_const";
import NavBar from "@/components/NavBar/NavBar.vue";
import SpacerArea from "@/components/SkuTitle/SpacerArea.vue";
@Component({
  components: {
    Container,
    NavTab,
    Swiper,
    swiperSlide,
    Scroller,
    RegularCoin,
    StoreTitle,
    DailyChallenge: () =>
      import("@/components/DailyChallenge/DailyChallenge.vue"),
    LuckyCoin: () => import("@/components/CoinEntrance/LuckyCoin.vue"),
    LimitedCoin: () =>
      import("@/components/CoinEntrance/limitCoins/limitCoins.vue"),
    BackUpAppRecommend: () =>
      import("@/components/BackUpAppRecommend/BackUpAppRecommend.vue"),
    HotApp: () => import("@/components/HotApps/HotApps.vue"),
    NavBar,
    // TTLoading,
    SpacerArea
  }
})
export default class CoinsContainer extends Vue {
  @Getter(setting_types.GETTER_CLIENT_SETTINGS)
  private settingData: any;
  @Getter(cg_user_types.GETTER_COINS)
  private userCoins: any;
  @Action(setting_types.UPDATE_SETTING_DATA)
  private updateCoinStoreSetting: any;
  private activeIndex = 0;
  private vipButtom = 80;
  private loadingShow: boolean = true; //占位图

  private isVip: boolean = false;
  private tabsArr: object[] = [
    {
      label: "Coins",
      activeTextColor: "#1c1e1f",
      acitveColor: "#ff1a50"
    },
    {
      label: "Free Coins",
      activeTextColor: "#1c1e1f",
      acitveColor: "#ff1a50"
    }
  ];

  private changeLoading() {
    this.loadingShow = false;
  }

  private slideTranslate() {
    const query: any = this.$route.query;
    const slideIndex = query.slideIndex;
    if (slideIndex) {
      this.activeIndex = parseInt(slideIndex, 10);
    }
  }

  private created() {
    this.slideTranslate();
    this.updateCoinStoreSetting(cgConst.TAB_COIN_STORE_KEYS).catch(
      () => void 0
    );
    // 一旦进入coins路由，则需要通知double coin bubble关闭
    Event_bus.$emit("show_double_coin_bubble", false);
  }

  private async activated() {
    this.slideTranslate();
    this.isVip = isVip();
  }
  private getVipButtomHeight() {
    this.$nextTick(() => {
      try {
        if (!this.isVip) {
          this.vipButtom =
            window.innerHeight -
            (document.querySelector("#coin-store") as any).offsetHeight;
        }
      } catch (error) {
        //
      }
    });
  }

  private async mounted() {
    this.getVipButtomHeight();
  }
}
