import jsbridge from "@/jsbridge/index";
import { store } from "@/main";
import * as types from "@/store/constants/index_types";
import * as setting_types from "@/store/constants/setting_types";
import { getDeviceInfo, getDeviceId } from "@/utils/js_bridge_info";
import { isVip } from "@/utils/general_utils";
import Vue from "vue";
import SwitchBackgroundPosition, {
  SwitchPositionTypes
} from "@/utils/switch_background/switch_background_position";

const contactUs = async (transactionId?: string, offerId?: string) => {
  try {
    const deviceInfo = getDeviceInfo();
    const masterId = store.getters[types.GETTER_MASTER_USER_ID];
    const deviceId = getDeviceId();
    const userId = masterId ? masterId.toString() : deviceId;
    let title = "";
    if (deviceInfo.info && deviceInfo.native_code_version) {
      title =
        deviceInfo.info.display_name + "" + deviceInfo.native_code_version;
    }
    const transactionParams = transactionId
      ? `\n Receipt Id: ${transactionId}`
      : "";
    const offerIdParams = offerId ? `\n Offer Id: ${offerId}` : "";
    const content = `App Version: ${
      deviceInfo.info.short_version
    }\n Code Version: ${deviceInfo.native_code_version} \n OS Version: ${
      deviceInfo.device.system_version
    }\n Device Model: ${deviceInfo.device.model} \n OS Name: ${
      deviceInfo.device.system_name
    }\nDevice Brand: ${deviceInfo.machine},${
      deviceInfo.app_platform
    }\n isVip: ${isVip()} \n Device Id: ${userId}\nDevice Resolution: ${window
      .screen.availWidth * window.devicePixelRatio}*${window.screen
      .availHeight * window.devicePixelRatio}\ndevicePixelRatio:${
      window.devicePixelRatio
    }${transactionParams}${offerIdParams}`;
    const recipients: string =
      store.getters[setting_types.GETTER_CONTACT_EMAIL];
    // 打开邮件不需要刷新信息
    SwitchBackgroundPosition.setPosition(
      SwitchPositionTypes.NOT_REQUIRE_REFRESH
    );
    const res: any = await jsbridge.openEmail([recipients], title, content);
    if (!res) {
      Vue.prototype.$_Toast.show({
        text: "Please add an email account in System app: Mail"
      });
    }
  } catch (error) {
    console.log("open emial error", error);
    Vue.prototype.$_Toast.show({
      text: "Please add an email account in System app: Mail"
    });
  }
};
export default contactUs;
