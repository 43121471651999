import JsBridge from "@/jsbridge";
import {
  getDeviceInfo,
  getRegisterNotification,
  getNotification,
  getOpenUrl
} from "@/utils/js_bridge_info";
import { store } from "@/main";
import * as types from "@/store/constants/index_types";
import RemotePush from "./remote_push";
import LocalPush from "./local_push";
import { log } from "@/utils/console";
import { isIos } from "@/utils/lodash";
import listenNative from "@/jsbridge/service/listenNative";
import ConfigTimeParams from "./configTimeParams";

export interface UmengPushData {
  type: "remote" | "local";
  user_info: any;
  application_state: "active" | "inactive" | "background";
  user_action: boolean;
  identifier?: string;
}

export interface CustomData {
  customer_id?: string;
  extension?: string;
  feed_id?: string;
  feed_type?: string;
  finish_number?: string;
  image?: string;
  reward?: string;
  task_status?: string;
  total_number?: string;
  transaction_id: string;
  type: string;
  url?: string;
  expired?: number;
}
class UmengPush {
  public async init() {
    // 初始化配置参数:
    try {
      // ios需要根据时机手动注册和配置，Android则只关注配置
      if (isIos()) {
        await this.judgeRegisterPush();
        await this.judgeConfigPush();
      } else {
        if (!ConfigTimeParams.get()) {
          await this.androidConfigPush(true);
        } else {
          await this.androidConfigPush(false);
        }
      }
    } catch (error) {
      // init failed
      // return Promise.reject(error);
    }
  }

  /**
   * ios逻辑
   *
   * 判断是否需要注册push，如果当前app是sp，则不注册.
   */
  public async judgeRegisterPush() {
    try {
      const open_url = getOpenUrl();
      if (!open_url) {
        await JsBridge.registerUmengPush();
      }
    } catch (error) {
      console.log("[register umeng push error]:", error);
      return Promise.reject(error);
    }
  }

  /**
   * 先执行安卓的
   */
  public async androidConfigPush(register: boolean) {
    //
    try {
      if (register) {
        const result: any = await JsBridge.registerUmengPush();
        if (result) {
          await this.configPush();
        }
      } else {
        await this.configPush();
      }
    } catch (error) {
      setTimeout(() => {
        this.configPush();
      }, 1500);
    }
  }

  /**
   *
   * 判断是否需要配置push，如果启动app，发现did_register_for_remote_notifications_with_device_token不存在，则不配置.
   * 同时，如果没有配置push，则需要监听did_register_for_remote_notifications_with_device_token事件，一旦原生触发该事件，则再次配置cofnig.
   */
  public async judgeConfigPush() {
    try {
      const did_register_for_remote_notifications_with_device_token = getRegisterNotification();
      if (
        did_register_for_remote_notifications_with_device_token ||
        ConfigTimeParams.get()
      ) {
        // 安卓由于没有放在启动参数里面，永远不会走此处的逻辑，而是走原生触发
        await this.configPush();
      } else {
        listenNative.addListen(
          "did_register_for_remote_notifications_with_device_token",
          () => {
            this.configPush().catch(e => void 0);
          },
          { key: "should_config_umeng_push" }
        );
      }
    } catch (error) {
      console.log("[config umeng push error]:", error);
      return Promise.reject(error);
    }
  }

  public listenRegisterTime() {
    listenNative.addListen(
      "did_register_for_remote_notifications_with_device_token",
      async (val) => {
        ConfigTimeParams.set(true);
        if (!isIos()) {
          if (val && val.result) {
            await this.androidConfigPush(false);
          } else {
            await this.androidConfigPush(true);
          }
        }
      },
      { key: "should_config_umeng_push" }
    );
  }

  /**
   * 配置push.
   */
  public async configPush() {
    try {
      listenNative.removeListen("should_config_umeng_push");
      const { app_platform }: any = getDeviceInfo();
      const master_id = store.getters[types.GETTER_MASTER_USER_ID];
      await JsBridge.configUmengPush(master_id, app_platform);
      // 初始化成功后监听umeng push
      this.addUmengPushListener();
    } catch (error) {
      // TODO暂时加上重试:
      try {
        const { app_platform }: any = getDeviceInfo();
        const master_id = store.getters[types.GETTER_MASTER_USER_ID];
        await JsBridge.configUmengPush(master_id, app_platform);
        // 初始化成功后监听umeng push
        console.log("configUmengPush success");
        this.addUmengPushListener();
      } catch (retry_error) {
        return Promise.reject(retry_error);
      }
    }
  }

  /**
   *  返回2种类型：远程和本地
   *  每种类型分点击方式和通知方式，同时也包含触发时候的前后台状态
   */
  public addUmengPushListener() {
    try {
      listenNative.addListen(
        "did_receive_notification",
        (data: UmengPushData) => {
          this.dispatchPush(data);
          return true;
        }
      );
    } catch (error) {
      //
      return Promise.reject(error);
    }
  }

  public dispatchPush(data: UmengPushData) {
    log("Receive_notification", data, "red");
    const { type } = data;
    if (type === "remote") {
      // 远程通知
      this.remoteNotice(data);
    } else {
      // 本地通知
      this.localNotice(data);
    }
  }

  /** 启动参数里面的通知 */
  public checkLaunchNotice() {
    const notification = getNotification();
    if (notification) {
      this.dispatchPush(notification);
    }
  }

  /** 远程通知 */
  public remoteNotice(data: UmengPushData) {
    RemotePush.receivePush(data);
  }

  /** 本地通知 */
  public localNotice(data: UmengPushData) {
    LocalPush.receivePush(data);
  }
}

export default new UmengPush();
