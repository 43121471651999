/**
 * 购买free follow
 */
import {
  checkFreeFollowers,
  deliverFreeFollowers
} from "@/api/cg/service/user_service";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { Vue } from "@/main";
import { store } from "@/main";
import * as types from "@/store/constants/index_types";
import * as tt_user_types from "@/store/constants/tt_user_types";
import generator from "@/services/open_pop_up/open_generator";
import { HAS_SHOW_FREE_FOLLOW } from "@/utils/nativeStorage/local_keys";
import { sendEvent } from "@/utils/log_event";
import { UIA_FREE_FOLLOWER_CLICK } from "@/utils/log_event/event_const";
import { goToSearchOrLogin } from "@/utils/router_helper";
import Loading from "@/plugins/toast/loading";
import EventBus from "@/utils/event_bus";
class FreeFollowUtil {
  get masterId() {
    return store.getters[types.GETTER_MASTER_USER_ID];
  }
  get currentInsUserId() {
    return store.getters[types.GETTER_CURRENT_USER_ID];
  }

  get currentInsUserInfo() {
    return store.getters[tt_user_types.GETTER_CURRENT_TIKTOK_USER_INFO];
  }

  public showGetFailedDialog() {
    Vue.prototype.$_Dialog.show({
      title: "Sorry!",
      content: {
        text: `FREE followers are exclusive for newbies, please enjoy our other benefits. More bonus are waiting for you!`
      },
      closable: false,
      closeOnClickModal: true,
      avatar: require("@/assets/icons-file/common/pic_pop_sorry@3x.png"),
      onConfirm(done: any) {
        done();
        generator.next();
      }
    });
  }
  public getFreeFollow() {
    if (
      !this.currentInsUserId
      // this.currentInsUserInfo.user_type === "search"
    ) {
      goToSearchOrLogin("free_follow");
      sendEvent(UIA_FREE_FOLLOWER_CLICK, { action: "get_login" });
    } else {
      sendEvent(UIA_FREE_FOLLOWER_CLICK, { action: "get_ok" });
      // 有用户，直接隐藏popup:
      EventBus.$emit("hide_free_popup");
      this.getFollower();
    }
  }
  public closeFreeFollowDialog() {
    nativeStorage.set(HAS_SHOW_FREE_FOLLOW, true);
    Loading.hide();
  }
  public async getFollower() {
    try {
      this.closeFreeFollowDialog();
      Loading.show();
      // 检测是否可以领取
      const checkResult: any = await checkFreeFollowers(
        this.masterId,
        this.currentInsUserId
      );
      if (checkResult && checkResult.free_status === 1) {
        // 直接领取
        const deliverResult: any = await deliverFreeFollowers(
          this.masterId,
          this.currentInsUserId,
          {
            ...this.currentInsUserInfo.tt_user_info,
            initial_count: this.currentInsUserInfo.tt_user_info.follower_count,
            username: this.currentInsUserInfo.tt_user_info.uniqueId,
            full_name: this.currentInsUserInfo.tt_user_info.nickname,
            user_id: this.currentInsUserId
          }
        );
        if (deliverResult) {
          // 发toast 提示成功。
          Vue.prototype.$_Toast.show({
            duration: 3000,
            text: `Congrats @${this.currentInsUserInfo.tt_user_info.uniqueId}, 5 followers will arrive soon.`
          });
        }
        setTimeout(() => {
          Loading.hide();
          generator.next();
        }, 3100);
      } else {
        this.showGetFailedDialog();
        Loading.hide();
      }
    } catch (error) {
      // 弹失败的框
      this.showGetFailedDialog();
      Loading.hide();
    }
  }
}
export default new FreeFollowUtil();
