import JsBridge from "@/jsbridge";
import Base64 from "crypto-js/enc-base64";
import Utf8 from "crypto-js/enc-utf8";
import LaunchTimeCache, {
  PeriodType
} from "@/utils/log_event/launch_time_event/launchTimeCache";

export default async function(path: string, product_id?: any) {
  try {
    const local_file_res: any = await JsBridge.localFileRead(path);
    LaunchTimeCache.setPeriod(PeriodType.NATIVE_GET_LOCAL_SETTING_PERIOD);
    const words = Base64.parse(local_file_res);
    let textString = Utf8.stringify(words);
    // PRODUCT_BUNDLE_IDENTIFIER作为占位，需要替换成bundleId
    if (product_id && textString.includes("PRODUCT_BUNDLE_IDENTIFIER")) {
      textString = textString.replace(/PRODUCT_BUNDLE_IDENTIFIER/g, product_id);
    }
    return JSON.parse(textString);
  } catch (error) {
    //
    return {};
  }
}
