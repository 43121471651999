import { GetterTree } from "vuex";
import * as types from "@/store/constants/index_types";
const Getters: GetterTree<any, any> = {
  [types.GETTER_MASTER_USER_ID](state) {
    return state.master_user_id;
  },
  [types.GETTER_CURRENT_USER_ID](state, getters, rootState) {
    return state.current_user_id;
  }
};
export default Getters;
