import { findRouteIndex, findExactRoute } from "@/plugins/navigation/routes";
import FreeFollowUtil from "@/services/free_follow";
import { router, store } from "@/main";
import paymentCheckModule from "@/services/payment/payment_check/index";
import EventBus from "@/utils/event_bus";

/**
 * 登陆后返回, from携带从哪里触发的支付，if payment_check，需要继续走支付逻辑
 */
export const loginSuccessHandle = async (from?: string) => {
  let routerNum = 0;
  if (findRouteIndex("SearchUser") > -1) {
    routerNum -= 1;
  }
  if (findRouteIndex("Home") > -1) {
    if (routerNum < 0) {
      await router.go(routerNum);
    }
    if (from === "free_follow") {
      // 有用户，直接隐藏popup:
      EventBus.$emit("hide_free_popup");
      FreeFollowUtil.getFollower();
    } else if (from === "payment_check") {
      setTimeout(() => {
        paymentCheckModule.get().next();
      }, 0);
    } else if (from === "from_like") {
      EventBus.$emit("changed_current_account");
    }
  } else {
    router.replace({
      name: "Earns"
    });
  }
};
