/*
 * @Author: jhonsem
 * @Date: 2021-01-25 14:15:57
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-01-25 19:19:17
 * 在earn coins ui的状态中：
 * 1.空数组，并且没有在拉取
 * 2.空数组，在拉取状态中
 * 3.有用户，被earn限制
 * 4.没有用户，需要登录
 * 5.用户有异常状态
 */

import { store } from "@/main";
import { feeds_getters, feeds_mutations } from "../observer/feeds_observer";
import { getFeeds } from "@/api/cg/service/feed_service";
import { GETTER_MASTER_USER_ID } from "@/store/constants/index_types";
import { GETTER_CURRENT_TIKTOK_USER_INFO } from "@/store/constants/tt_user_types";
import { TT_FEED_KINDS } from "./../earn_coins_consts/earn_coins_consts";
import { GETTER_CLIENT_SETTINGS } from "@/store/constants/setting_types";
import {
  filter_invalid_kind_feeds,
  filter_same_id_feeds
} from "./filter_feeds";
import { insert_feeds } from "./insert_feeds";

// 需要过滤获取的feeds,并且接力往feeds里面添加

class GetFeeds {
  private feeds_filter(ori_feeds: any) {
    const invalid_kind_feeds = filter_invalid_kind_feeds(
      ori_feeds,
      TT_FEED_KINDS
    );
    const invalid_feeds = filter_same_id_feeds(
      invalid_kind_feeds,
      feeds_getters.feeds()
    );
    return invalid_feeds;
  }

  public async get_feed_from_server() {
    try {
      //重复多次请求
      if (!feeds_getters.can_pull_feeds()) {
        return;
      }
      feeds_mutations.change_pull_status("running");

      const master_id = store.getters[GETTER_MASTER_USER_ID];
      const user_id =
        (store.getters[GETTER_CURRENT_TIKTOK_USER_INFO] &&
          store.getters[GETTER_CURRENT_TIKTOK_USER_INFO].user_id) ||
        "";
      const user_ids: string[] = [String(user_id)];
      const ori_feeds: any = await getFeeds(master_id, user_ids);
      console.log("ori_feds:", ori_feeds);
      //过滤，去重
      const filtered_feeds = this.feeds_filter(ori_feeds);
      console.log("filtered_feeds:", filtered_feeds);
      //插入random
      const { earn_limit } = store.getters[GETTER_CLIENT_SETTINGS];
      const insert_finnal_feeds = insert_feeds(
        filtered_feeds,
        earn_limit.other_feed_spacing || 5
      );

      return insert_finnal_feeds;
    } catch (error) {
      //
      console.log("get_feed_from_server error:", error);

      return Promise.reject(error);
    } finally {
      feeds_mutations.change_pull_status("idle");
    }
  }
}

export default new GetFeeds();
