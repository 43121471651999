/*
 * @Author: jhonsem
 * @Date: 2021-01-21 16:43:37
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-02-04 11:12:44
 */

import Vue from "vue";
import { ObservableFeeds } from "../types/type";
import {
  MIN_FEEDS_STACK_NUM,
  is_tt_feed
} from "../earn_coins_consts/earn_coins_consts";
import { auto_play_video } from "../feeds/act_feeds";

const observable_feeds: ObservableFeeds = Vue.observable({
  feeds: [],
  index: 0,
  pull_status: "idle" // 拉取状态
});

const feeds_actions = {
  after_change_current_feed() {
    // check current feed
    auto_play_video(observable_feeds.feeds[observable_feeds.index]);
  },

  move_index(index: number) {
    if (observable_feeds.feeds[observable_feeds.index] === undefined) {
      // 如果发现没有不在后移
      return;
    }
    feeds_mutations.move_index(index);
    //
    this.after_change_current_feed();
  }
};

const feeds_mutations = {
  change_index(index: number) {
    observable_feeds.index = index;
  },
  move_index(index: number) {
    observable_feeds.index += index;
  },
  push_feeds(feeds: any) {
    observable_feeds.feeds.push(...feeds);
  },
  change_pull_status(status: "idle" | "running") {
    observable_feeds.pull_status = status;
  },
  delete_feeds(start: number, count?: number) {
    if (count) {
      observable_feeds.feeds.splice(start, count);
    } else {
      observable_feeds.feeds.splice(start);
    }
  }
};

const feeds_getters = {
  pull_status() {
    return observable_feeds.pull_status;
  },
  index() {
    return observable_feeds.index;
  },
  feeds() {
    return observable_feeds.feeds;
  },
  feed_length() {
    return observable_feeds.feeds.length;
  },
  valid_feed_count() {
    if (observable_feeds.feeds.length === 0) {
      return 0;
    } else {
      return observable_feeds.feeds.length - observable_feeds.index - 1;
    }
  },
  valid_tt_feed_count() {
    // 返回可用的tt类型feed的数量
    let feed_count = 0;
    for (
      let i = observable_feeds.index;
      i < observable_feeds.feeds.length;
      i++
    ) {
      if (is_tt_feed(observable_feeds.feeds[observable_feeds.index])) {
        feed_count += 1;
      }
    }
    return feed_count;
  },
  can_pull_feeds() {
    return observable_feeds.pull_status === "idle";
  },
  last_group_tt_feed_count() {
    const last_index = this.last_task_feed_index();
    if (last_index === undefined) {
      return observable_feeds.feeds.length;
    } else {
      return observable_feeds.feeds.length - 1 - last_index;
    }
  },
  last_task_feed_index() {
    for (let i = observable_feeds.feeds.length - 1; i > 0; i--) {
      if (
        observable_feeds.feeds[i] &&
        observable_feeds.feeds[i].kind &&
        !is_tt_feed(observable_feeds.feeds[i])
      ) {
        return i;
      }
    }
    return undefined;
  },
  near_by_task_feed_index() {
    for (let i = observable_feeds.index; i > 0; i--) {
      if (
        observable_feeds.feeds[i] &&
        observable_feeds.feeds[i].kind &&
        !is_tt_feed(observable_feeds.feeds[i])
      ) {
        return i;
      }
    }
    return undefined;
  },
  is_enough_tt_feed() {
    return this.valid_tt_feed_count() > MIN_FEEDS_STACK_NUM;
  }
};

export { observable_feeds, feeds_mutations, feeds_getters, feeds_actions };
