
import { CreateElement, VNode } from "vue";
import { Component, Prop, Emit, Vue, Watch } from "vue-property-decorator";
import CardListItem from "./CardListItem.vue";

const CardListItemComponent: any = CardListItem;
// @author: alanchen
// @date: 2019/8/26
// cardlist 组件，接收render函数来自定义渲染行组件
// data和customRender必选。customHead可选，用于渲染头部。
// listStyle、itemStyle和seperateLineStyle均可选，用于定制外层list、内部item和分割线的样式。
@Component({
  components: {
    CardListItem
  }
})
export default class CardList extends Vue {
  @Prop({ default: () => [] })
  private data: any;
  @Prop({ default: (): any => () => false })
  private customHead?: any;
  @Prop()
  private customRender: any;
  @Prop({ default: (): any => ({}) })
  private listStyle?: any;
  @Prop({ default: (): any => ({}) })
  private itemStyle?: any;
  @Prop({ default: (): any => ({}) })
  private seperateLineStyle?: any;
  private render(h: CreateElement): any {
    const header = this.customHead() ? (
      <section class="card-head">{this.customHead(this.data)}</section>
    ) : (
      ""
    );
    const cardlist = this.data.map((item: any, i: number, arr: any) => {
      return (
        <CardListItemComponent
          data={item}
          currentIndex={i}
          totalIndex={arr.length}
          customRender={this.customRender}
          itemStyle={this.itemStyle}
          seperateLineStyle={this.seperateLineStyle}
        />
      );
    });
    return (
      <section class="card-wrap" style={this.listStyle}>
        {header}
        {cardlist}
      </section>
    );
  }
}
