import { Component, Prop, Vue } from "vue-property-decorator";

@Component
class FollowDifferenceContent extends Vue {
  @Prop()
  private ctx: any;

  private tdStyle =
    "height:44px;font-size:10px;line-height:14px;color:rgb(89,89,89);border:1px solid #fff;";
  private tdYellow =
    this.tdStyle +
    "background:linear-gradient(to right,rgb(255,252,234),rgb(255,236,185));";
  private yellowColor = "color:rgb(177,74,21)";
  public render() {
    return (
      <table style="background:rgb(245,246,250);border:1px solid #fff;width:100%;border-collapse:collapse;margin-bottom:24px;border-radius:2px;">
        <tr>
          <td style={this.tdStyle} />
          <td style={this.tdStyle}>
            <p>Basic</p>
            <p>Followers</p>
          </td>
          <td style={this.tdYellow}>
            <p>Golden</p>
            <p>Followers</p>
          </td>
        </tr>
        <tr>
          <td style={this.tdStyle}>
            <p>Completion</p>
            <p>Time</p>
          </td>
          <td style={this.tdStyle}>
            <p>within</p>
            <p>1 min - 12 hours</p>
          </td>
          <td style={this.tdYellow}>
            <p>within</p>
            <p style={this.yellowColor}>10 s - 1 hour</p>
          </td>
        </tr>
        <tr>
          <td style={this.tdStyle}>
            <p>Followers</p>
            <p>Compensation</p>
          </td>
          <td style={this.tdStyle}>/</td>
          <td style={this.tdYellow}>
            <p>compensate</p>
            <p>
              within <span style={this.yellowColor}>7 days </span>
            </p>
          </td>
        </tr>
        <tr>
          <td style={this.tdStyle}>
            <p>Followers</p>
            <p>Quality</p>
          </td>
          <td style={this.tdStyle}>real followers</td>
          <td style={this.tdYellow}>
            <p style={this.yellowColor}>real & active</p>
            <p>followers</p>
          </td>
        </tr>
        <tr>
          <td style={this.tdStyle}>
            <p>Followers</p>
            <p>Drop Rate</p>
          </td>
          <td style={this.tdStyle}>8-15%</td>
          <td style={this.tdYellow}>
            {" "}
            <span style={this.yellowColor}>0-3%</span>{" "}
          </td>
        </tr>
      </table>
    );
  }
}

export default FollowDifferenceContent;
