/*
 * @Author: Jhonsem
 * @Date: 2020-12-21 16:42:01
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-01-19 16:15:12
 * 初始化流程的生命周期：
 * 1.launchParamsAndDeviceInfoReady   （launch_params和device_info）
 * 2.localSettingReady (本地setting的本地读取和处理准备)
 * 3.masterUserIdReady (masterUserId主账号id，用于cg请求必要参数)
 * 4.masterUserIdLoginedReady (masterUserId主账号id，在后台注册成功)
 * 5.jsFrameReady (vue框架的实例化完成)
 * 6.onlineSettingReady (线上setting的获取)
 */
import local_setting_init from "./local_setting_lifecycle";
import launch_params_and_device_info_init from "./launch_and_device_lifecycle";
import js_frame_init from "./js_frame_lifecycle";
import master_user_id_init from "./master_user_id_lifecycle";
import online_setting_init from "./online_setting_lifecycle";
import master_user_id_login_init from "./master_user_id_login_init";
import LaunchTimeCache, {
  PeriodType
} from "@/utils/log_event/launch_time_event/launchTimeCache";
interface ParamsType {
  launchParamsAndDeviceInfoReady: (params: any) => any;
  localSettingReady: (params: any) => any;
  masterUserIdReady: (params: any) => any;
  masterUserIdLoginedReady: (params: any) => any;
  onlineSettingReady: (params: any) => any;
  jsFrameDomReady: (params: any) => any;
}

export default class InitLifyCycle {
  private params: ParamsType;

  constructor(params: ParamsType) {
    this.params = params;
    this.run();
  }

  private async runQueItem(
    initFunction: () => any,
    lifeCycleName:
      | "launchParamsAndDeviceInfoReady"
      | "localSettingReady"
      | "masterUserIdReady"
      | "masterUserIdLoginedReady"
      | "onlineSettingReady"
      | "jsFrameDomReady"
  ) {
    try {
      //
      const result = await initFunction();
      console.info(
        `%c ============= ${lifeCycleName} ==============`,
        "color: #a0c3ff;font-weight:bold"
      );
      this.params[lifeCycleName](result);
      return true;
    } catch (error) {
      //
      return Promise.reject(error);
    }
  }

  private async run() {
    try {
      // ============= launchParamsAndDeviceInfoReady ==============
      await this.runQueItem(
        launch_params_and_device_info_init,
        "launchParamsAndDeviceInfoReady"
      );
      LaunchTimeCache.setPeriod(
        PeriodType.LAUNCH_PARAMS_AND_DEVICE_INFO_INIT_PERIOD
      );
      // ============= jsFrameReady ==============
      await this.runQueItem(js_frame_init, "jsFrameDomReady");
      LaunchTimeCache.setPeriod(PeriodType.JS_FRAME_INIT_PERIOD);
      // ============= localSettingReady ==============
      await this.runQueItem(local_setting_init, "localSettingReady");
      LaunchTimeCache.setPeriod(PeriodType.LOCAL_SETTING_INIT_PERIOD);
      // ============= masterUserIdReady ==============
      await this.runQueItem(master_user_id_init, "masterUserIdReady");
      LaunchTimeCache.setPeriod(PeriodType.MASTER_USER_ID_INIT_PERIOD);
      // ============= masterUserIdLoginedReady ==============
      await this.runQueItem(
        master_user_id_login_init,
        "masterUserIdLoginedReady"
      );
      // ============= onlineSettingReady ==============
      await this.runQueItem(online_setting_init, "onlineSettingReady");
    } catch (error) {
      //
      return Promise.reject(error);
    }
  }
}
