import { getServiceList } from "@/api/cg/service/user_service";
import { store } from "@/main";
import * as types from "@/store/constants/index_types";
import { SERVICE_LIST } from "@/utils/nativeStorage/local_keys";
import { CacheModule } from "@/services/cacheModule";

class ServiceList extends CacheModule {
  constructor() {
    super(SERVICE_LIST, []);
  }

  public async updateServiceList() {
    try {
      const deviceId = store.getters[types.GETTER_MASTER_USER_ID];
      const serviceList = await getServiceList(deviceId);
      this.setCache(serviceList);
    } catch (e) {
      //
    }
  }
}
export default new ServiceList();
