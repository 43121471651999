import {
  getInfoFromOffer,
  Offer,
  OfferProperty
} from "@/services/offer/offer_handle";
import { PayWay } from "@/services/payment/types";
import LocalizeUtil from "@/services/payment/localize";
import { parsePrice } from "@/utils/lodash";

export const getOriginalPriceByOffer = (
  offer: any,
  pay_way: PayWay = PayWay.iap
): string => {
  try {
    const productId = getIapProductId(offer);
    const { discount } = offer;
    const { localPriceList } = LocalizeUtil.get();
    const localize = productId && localPriceList && localPriceList[productId];
    if (localize) {
      const price: number = parseFloat(localize.price.toFixed(2));
      const originalPrice = (price / (1 - discount / 100)).toFixed(2);
      return `${localize.currency_symbol}${originalPrice}`;
    } else {
      const price: number = parseFloat(
        parsePrice(getInfoFromOffer(offer, pay_way))
      );
      const originalPrice = (price / (1 - discount / 100)).toFixed(2);
      return `$${originalPrice}`;
    }
  } catch (e) {
    return getInfoFromOffer(offer, pay_way);
  }
};

export const getIapPrice = (offer: Offer) => {
  try {
    return getInfoFromOffer(offer);
  } catch (e) {
    return "";
  }
};

export const getIapEffect = (offer: Offer) => {
  try {
    return getInfoFromOffer(offer, PayWay.iap, OfferProperty.effect);
  } catch (e) {
    return "";
  }
};

export const getIapProductId = (offer: Offer): string | undefined => {
  try {
    return getInfoFromOffer(offer, PayWay.iap, OfferProperty.product_id);
  } catch (e) {
    return "";
  }
};
