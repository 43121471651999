








import { Component, Prop, Watch, Vue } from "vue-property-decorator";
@Component({
  components: {}
})
export default class TabBar extends Vue {
  @Prop({ default: "home-tab-bar" })
  private iconClass: any;
  @Prop({ default: "home-tab-label" })
  private labelClass: any;
  @Prop({ default: true })
  private lineTop: any;
  @Prop({ default: 0 })
  private value?: number;

  private index: number = -1;
  private currentIndex: any = this.index;
  private number: number = this.$children.length;

  @Watch("currentIndex", { immediate: false })
  private watchCurrentIndex(val: any, oldVal: any) {
    const oldItem: any = this.$children[oldVal];
    const newItem: any = this.$children[val];
    if (oldVal > -1 && oldItem) {
      oldItem.currentSelected = false;
    }
    if (val > -1 && newItem) {
      newItem.currentSelected = true;
    }
    this.$emit("input", val);
    this.$emit("on-index-change", val, oldVal);
  }

  @Watch("index", { immediate: false })
  private watchIndex(val: any) {
    this.currentIndex = val;
  }

  @Watch("value", { immediate: true })
  private watchValue(val: any) {
    // console.log("value", this.value);
    this.index = val;
  }

  private updateIndex() {
    if (!this.$children || !this.$children.length) {
      return;
    }
    this.number = this.$children.length;
    const children: any = this.$children;
    for (let i = 0; i < children.length; i++) {
      children[i].currentIndex = i;
      if (children[i].currentSelected) {
        this.index = i;
      }
    }
  }
}
