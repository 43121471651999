/*
 * @Author: jhonsem
 * @Date: 2021-01-19 09:56:52
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-03-15 10:12:42
 */
import { TTScriptResponse } from "../types/tt_api_types";
import { TT_SCRIPT_URL } from "@/config/index";
import Http from "@/api/http/index";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { WEBVIEW_SCRIPT } from "@/utils/nativeStorage/local_keys/index";

const TT_WEB_API_SCRIPT = TT_SCRIPT_URL;

const EXPIRED_QUERY = ["x-expires", "x-orig-expires"];

const request_base = new Http({
  method: "GET"
});

request_base.interceptors.response.use(
  ((response: any) => {
    const { status_code, data } = response;
    if (status_code && status_code >= 200 && status_code < 400) {
      return atob(data);
    } else {
      return Promise.reject(atob(data));
    }
  }) as any,
  (error: any) => {
    return Promise.reject(error);
  }
);

const get_script_from_local = async () => {
  const local_script = await nativeStorage.get(WEBVIEW_SCRIPT);
  return local_script;
};

const save_script_to_local = async () => {
  try {
    const script: any = await load_js(TT_WEB_API_SCRIPT);
    nativeStorage.set(WEBVIEW_SCRIPT, script);
    return script;
  } catch (error) {
    // 抛出错误
    return Promise.reject(error);
  }
};
/**
 * 注入tt script
 */
export const inject_tt_api_script = async () => {
  try {
    // 如果本地没有，从原生拿去默认的，再静默下载:
    const local_script = await get_script_from_local();
    if (local_script) {
      console.log("已经有本地脚本");
      eval(local_script); // 执行脚本
      save_script_to_local().catch((e: Error) => void 0); // 静默执行更新本地
    } else {
      // 从原生获取:
      const script = await save_script_to_local();
      eval(script);
    }
  } catch (error) {
    console.log("inject_tt_api_script error", error);
    return Promise.reject(error);
  }
};

export const merge_config = (config1: any, config2?: any) => {
  if (config2) {
    return {
      ...config1,
      ...config2
    };
  } else {
    return config1;
  }
};

/**
 * load js
 * @param url
 */
export const load_js = async (url: string) => {
  try {
    console.log("load_js:", url);
    const script_string = await request_base.get(url);
    return script_string;
  } catch (error) {
    console.log("script error:", error);
    return Promise.reject(error);
  }
};

export const isIos = () => {
  const u = window.navigator.userAgent;
  const isHigherVersionPad =
    window.navigator.platform === "MacIntel" &&
    window.navigator.maxTouchPoints > 1;
  const isiOS =
    !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || isHigherVersionPad; // ios终端
  return isiOS;
};

export const create_callback_id = (
  length: number = 36,
  upper: boolean = true
) => {
  const uid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx"
    .replace(/[xy]/g, (c: any) => {
      const r = (Math.random() * 16) | 0;
      const v = c === "x" ? r : (r & 0x3) | 0x8;
      return v.toString(16);
    })
    .slice(0, length);
  return upper ? uid.toUpperCase() : uid;
};

/**
 * 脚本返回错误
 * @param res
 */
export const isTTScriptError = (res: TTScriptResponse) => {
  const { error } = res;
  return error;
};

const getParameterByName = (name: string, url: string) => {
  name = name.replace(/[\[\]]/g, "\\$&");
  var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    results = regex.exec(url);
  if (!results) return null;
  if (!results[2]) return "";
  return decodeURIComponent(results[2].replace(/\+/g, " "));
};

/**
 * 判断链接是否过期
 * @param url
 */
export const is_expired_url = (url: string) => {
  //
  let expired_time_stamp = null;

  for (const key of EXPIRED_QUERY) {
    if (getParameterByName(key, url)) {
      expired_time_stamp = getParameterByName(key, url);
    }
  }

  if (expired_time_stamp) {
    const expired_time_stamp_new = parseFloat(expired_time_stamp) * 1000;
    if (new Date().valueOf() > expired_time_stamp_new) {
      return true;
    }
  }
  return false;
};
