import { Component, Prop, Vue } from "vue-property-decorator";
import { isIos } from "@/utils/lodash";
@Component
class SuperPayContent extends Vue {
  @Prop()
  private content: any;

  public render() {
    const {
      appBenefits,
      app_benefits,
      iconUrl,
      appName,
      app_name
    } = this.content;
    const benefits = app_benefits || appBenefits;
    const appname = app_name || appName;
    const isMinClient = document.body.clientHeight <= 640;
    const icon_ok = require("@/assets/icons-file/super_pay/full_duigou_18@3x.png");
    const rate = require("@/assets/icons-file/super_pay/pic_suppay_rating@3x.png");
    const fit_font = isIos() ? "font-size: 20px;" : "font-size: 16px;";
    const fitWidthLength =
      "display: -webkit-box;-webkit-box-orient: vertical;-webkit-line-clamp: 2;overflow: hidden;";
    const title_style = isMinClient
      ? "font-size: 14px;" +
        "font-family: AppleSDGothicNeo-Bold,'Noto Sans';color: rgb(38, 38, 38);margin-bottom: 4px;" +
        fitWidthLength
      : fit_font +
        "font-family: AppleSDGothicNeo-Bold,'Noto Sans';color: rgb(38, 38, 38);margin-bottom: 4px;" +
        fitWidthLength;
    const rate_text_style =
      "font-size: 12px;color: $black;margin-left: 5px;line-height: 19px;vertical-align: top;";
    const span_text_style = isMinClient
      ? "font-size: 12px;line-height: 20px;color: rgb(140, 140, 140);"
      : "font-size: 15px;line-height: 22px;color: rgb(140, 140, 140);";
    return (
      <section style="margin-bottom: 14px;padding: 0 24px;">
        <div style="display: flex;align-items: center;margin-bottom: 19px;">
          <img
            src={iconUrl}
            style="width: 56px;height: 56px;margin-right: 13px;flex-shrink: 0;"
          />
          <div style="text-align: left;">
            <p style={title_style}>{appname}</p>
            <div style="flex:1">
              <img
                style="width: 83px;height: 15px;display: inline-block"
                src={rate}
              />
              <span style={rate_text_style}>5.0</span>
            </div>
          </div>
        </div>
        {benefits.map((text: any) => {
          return (
            <div style="display: flex;">
              <img
                src={icon_ok}
                style="width: 20px;height: 20px;flex-shrink: 0;"
              />
              <div style="margin-left: 8px;text-align: left;margin-bottom: 12px;">
                <span style={span_text_style}>{text}</span>
              </div>
            </div>
          );
        })}
      </section>
    );
  }
}
export default SuperPayContent;
