import { xiEvent } from "@/api/cg/service/xi_event";
import PaymentUtil from "@/services/payment/paymentCache";

/**
 * 首次打开应用
 */
export const xiLaunch = async () => {
  try {
    if (PaymentUtil.isFirstLaunch()) {
      await xiEvent("liquidate", { event_name: "first_launch" });
    } else {
      await xiEvent("liquidate", { event_name: "launch" });
    }
  } catch (error) {
    //
  }
};

export const xiAds = async (event_name: string, ad_platform: string) => {
  try {
    const params = {
      event_name,
      ad_platform,
      placement_key: "video",
      placement_id: ""
    };
    await xiEvent("liquidate", params);
  } catch (error) {
    //
  }
};
