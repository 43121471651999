import { ActionTree } from "vuex";
import * as types from "@/store/constants/index_types";
import { LOGIN_MASTER_ID_BY_GX } from "@/store/constants/cg_user_types";
import {
  getDeviceInfo,
  getNotification,
  getOpenUrl
} from "@/utils/js_bridge_info";
import { router, store } from "@/main";
import { isEmpty } from "@/utils/lodash";
import { getDeviceId } from "@/utils/js_bridge_info";
import { transferMasterAccount } from "@/api/cg/service/user_service";
import { handleOpenUrl } from "@/services/payment/super_pay_receive";
import Splash from "@/views/Splash/Splash.vue";

const actions: ActionTree<any, any> = {
  async [types.INIT_DEFAULT_ROUTER]({ getters }) {
    // superpay被调相关的open_url参数
    // 启动参数，用来before_vip
    const open_url = getOpenUrl();

    const currentId = getters[types.GETTER_CURRENT_USER_ID];
    const notification = getNotification();
    // 默认的跳转方法
    const defaultRedirect = () => {
      if (notification || currentId) {
        router.addRoutes([
          {
            path: "/",
            name: "Earns",
            redirect: "/Home/Earns"
          }
        ]);
      } else {
        router.addRoutes([
          {
            path: "/",
            name: "Splash",
            component: Splash
          }
        ]);
      }
    };
    // 如果是superpay被调
    if (open_url) {
      handleOpenUrl(open_url, "static", defaultRedirect);
      return;
    }
    defaultRedirect();
  },
  async [types.UPDATE_MASTER_USER_ID]({ commit, getters }: any) {
    try {
      const masterUserId = getters[types.GETTER_MASTER_USER_ID];
      if (isEmpty(masterUserId)) {
        const deviceId = getDeviceId();
        commit(types.UPDATE_MASTER_USER_ID, deviceId);
      }
      return masterUserId;
    } catch (error) {
      console.log("UPDATE_MASTER_USER_ID error:", error);
      return Promise.reject(error);
    }
  },
  async [types.TRANSFORM_ACCOUNT]({ commit, getters }: any) {
    try {
      const deviceId = getDeviceId();
      const masterId = getters[types.GETTER_MASTER_USER_ID];
      const { app_platform } = getDeviceInfo();
      if (deviceId && deviceId !== masterId) {
        await transferMasterAccount(masterId, deviceId, app_platform);
        commit(types.UPDATE_MASTER_USER_ID, deviceId);
      }
    } catch (e) {
      console.log("transferAccount", e);
    }
  }
};

export default actions;
