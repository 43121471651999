import { umengEvent } from "../index";
import { PurchaseError } from "@/services/payment/types";

export const sendInterfaceSuccess = (name: string) => {
  umengEvent(name, {
    error_code: 200,
    error_message: "ok"
  });
};

export const sendCgInterfaceError = (name: string, error: PurchaseError) => {
  if (error.hasOwnProperty("meta")) {
    const { meta } = error;
    const { error_message, code } = meta;
    umengEvent(name, {
      error_code: code,
      error_message
    });
  } else {
    if (error) {
      const { code, message } = error;
      umengEvent(name, {
        error_code: code,
        error_message: message
      });
    }
  }
};

export const sendTTInterfaceError = (name: string, error: any) => {
  try {
    if (error.data && error.data.error_type) {
      // 非登录模式下的错误有data的字段包裹
      umengEvent(name, {
        error_code: 400,
        error_message: error.data.error_type
      });
    } else {
      const { error_type } = error;
      umengEvent(name, {
        error_code: 400,
        error_message: error_type
      });
    }
  } catch (e) {
    //
  }
};
