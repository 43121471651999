





















import { Component, Vue, Prop } from "vue-property-decorator";
import CountDwonTime from "@/components/CountDwonTime/CountDwonTime.vue";
@Component({
  components: {
    CountDwonTime
  }
})
export default class LimitStatus extends Vue {
  @Prop({ default: 0 })
  private limitedEndTimeStamp?: number;

  private shouldCloseLimit() {
    this.$emit("shouldCloseLimit");
  }
}
