/*
 * @Author: Jhonsem
 * @Date: 2019-09-11 10:08:46
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2019-09-11 10:32:36
 * 前后台的切换 某些全局的监听逻辑
 */

import ListenNative from "@/jsbridge/service/listenNative";
import { store } from "@/main";
import * as cg_user_types from "@/store/constants/cg_user_types";
import SwitchBackgroundPosition, {
  SwitchPositionTypes
} from "@/utils/switch_background/switch_background_position";
import {
  REFRESH_CURRENT_USER_TIKTOK_DATA,
  TIKTOK_LOGIN_USER_EMPTY
} from "@/store/constants/tt_user_types";
import { UPDATE_SETTING_DATA } from "@/store/constants/setting_types";
import { REFRESH_CURRENT_USER_MEDIA_LIST } from "@/store/constants/tt_media_types";
import * as types from "@/store/constants/cg_user_types";
import * as cgConst from "@/api/cg/base/cg_const";
import { getCurrentRoute } from "@/plugins/navigation/routes";

class SwitchBackground {
  // 切换至后台
  public enterBackGround() {
    ListenNative.addListen("did_enter_background", () => {
      const position = SwitchBackgroundPosition.get();
      const currentRoute = getCurrentRoute();
      const currentRouteIsEarn =
        currentRoute &&
        currentRoute.path &&
        currentRoute.path.includes("/Earns");
      console.log("=== did_enter_background ===", currentRouteIsEarn);
      // 没有position代表用户手动切换前后台，需要刷新tt信息
      if (!position && !currentRouteIsEarn) {
        this.updateCurrentInsUser();
      }
      //刷新客户端参数settings: // catch需要捕获不处理
      store.dispatch(UPDATE_SETTING_DATA, cgConst.COMMON_KEYS).catch(e => {
        void 0;
      });
    });
  }

  // 切换至前台
  public enterForeGround() {
    ListenNative.addListen("will_enter_foreground", () => {
      const position = SwitchBackgroundPosition.get();
      // 如果当前的position是不需要刷新用户信息，
      if (position !== SwitchPositionTypes.NOT_REQUIRE_REFRESH) {
        // catch需要捕获不处理
        store.dispatch(types.UPDATE_GX_USER_INFO, ["coins"]).catch(e => {
          void 0;
        });
      }
      SwitchBackgroundPosition.clearPosition();
    });
  }

  public updateCurrentInsUser() {
    console.log("updateCurrentInsUser");
    const loginEmpty = store.getters[TIKTOK_LOGIN_USER_EMPTY];
    if (loginEmpty) {
      return;
    }
    // 更新当前的user info
    store.dispatch(REFRESH_CURRENT_USER_TIKTOK_DATA).catch((error: any) => {
      //
    });
    // // 更新当前的user media
    store.dispatch(REFRESH_CURRENT_USER_MEDIA_LIST).catch((error: any) => {
      //
    });
  }

  public init() {
    this.enterForeGround();
    this.enterBackGround();
  }
}

export default new SwitchBackground();
