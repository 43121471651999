import { Module } from "@/services/module";

export enum SwitchPositionTypes {
  REFRESH_CG_USER_INFO = "refresh_cg_user_info",
  NOT_REQUIRE_REFRESH = "not_require_refresh"
}
class SwitchBackgroundPosition extends Module {
  constructor() {
    super(null);
  }

  setPosition(val: SwitchPositionTypes) {
    this.set(val);
  }

  clearPosition() {
    this.set(null);
  }
}
export default new SwitchBackgroundPosition();
