import { FAILED_ORDER_LIST } from "@/utils/nativeStorage/local_keys";
import { CacheModule } from "@/services/cacheModule";
import { FailedOrderItem, OrderItem } from "@/services/payment/types";

class FailedOrderList extends CacheModule {
  constructor() {
    super(FAILED_ORDER_LIST, []);
  }
  // 存储失败需要retry的订
  public setFailedOrder(order: FailedOrderItem) {
    const failedList = this.get();
    const hasCache = failedList.some(
      (v: any) => v.transaction_id === order.transaction_id
    );
    !hasCache && this.setCache([order, ...failedList]);
  }

  // retry成功，从失败订单中去除
  public removeRetriedSuccessOrder(transactionId: string) {
    const failedList = this.get();
    const resultList = failedList.filter(
      (v: FailedOrderItem) => transactionId !== v.transaction_id
    );
    this.setCache(resultList);
  }

  // 从成功的订单里面过滤掉已经成功的失败订单，并返回当前成功订单的最后一条之前的失败订单
  public getFailedOrdersByTimeSlot(orders: OrderItem[], all: boolean) {
    const failedOrders = this.get();
    console.log("failedOrders", failedOrders);
    const lastOrderTimestamp = all
      ? 0
      : orders[orders.length - 1].transaction_time;
    return failedOrders.filter((order: any) => {
      if (
        orders.find(
          (v: any) => !v.failed && v.transaction_id === order.transaction_id
        )
      ) {
        this.removeRetriedSuccessOrder(order.transaction_id);
        return false;
      } else {
        return order.transaction_time > lastOrderTimestamp;
      }
    });
  }
}
export default new FailedOrderList();
