import {
  PaymentWithSituationParams,
  SkuClickCategory
} from "@/services/offer/offer_handle";
import { router, store, Vue } from "@/main";
import * as cg_user_types from "@/store/constants/cg_user_types";
import CoinContent from "@/components/DlalogTsx/NotCoinEnough/CoinContent";
import ConfirmOrderHeader from "@/components/DlalogTsx/LargePurchase/ConfirmOrderHeader";
import ConfirmContent from "@/components/DlalogTsx/LargePurchase/ConfirmContent";
import IntervalTimer from "@/components/DlalogTsx/interval_timer";
import ConfirmBtn from "@/components/DlalogTsx/LargePurchase/ConfirmBtn";
import PrivateAccount from "@/components/DlalogTsx/PrivateUser/PrivateAccount";
import paymentCheckModule from "@/services/payment/payment_check";
import { skuClickEvent } from "@/utils/log_event/purchase_event";
import { open_guides } from "@/utils/guides/guides";

/**
 * 购买前检查相关的弹框
 */

export const coinsNotEnoughDialog = (params: PaymentWithSituationParams) => {
  const { offer } = params;
  Vue.prototype.$_Dialog.show({
    title: "Not Enough Coins ?",
    avatar: require("@/assets/icons-file/dialog/coins_not_enough.png"),
    avatarstyle: {
      width: "100px",
      height: "100px",
      background: "rgb(233,236,240)",
      borderRadius: "50%"
    },
    contentCustom(ctx: any, r: any) {
      const user_coins = store.getters[cg_user_types.GETTER_COINS];
      return r(CoinContent, { props: { ctx, offer, user_coins } });
    },
    closable: true,
    confirmBtn: {
      text: "RECHARGE"
    },
    onConfirm(done: any) {
      done();
      skuClickEvent({ ...params, result: SkuClickCategory.alert_recharge });
      router.push({
        name: "Coins",
        query: {
          slideIndex: "0"
        }
      });
    },
    cancelBtn: {
      text: "FREE COINS"
    },
    onCancel(done: any) {
      done();
      skuClickEvent({ ...params, result: SkuClickCategory.alert_free_coins });
      router.push({
        name: "Coins",
        query: {
          slideIndex: "1"
        }
      });
    },
    onModalCloseWithIcon(done: any) {
      skuClickEvent({ ...params, result: SkuClickCategory.alert_cancel });
      done();
    }
  });
};

export const largePurchaseConfirm = (params: PaymentWithSituationParams) => {
  const { offer } = params;
  Vue.prototype.$_Dialog.show({
    title: "Confirm Order",
    headerCustom(ctx: any, r: any) {
      return r(ConfirmOrderHeader, { props: { ctx, offer } });
    },
    contentCustom(ctx: any, r: any) {
      return r(ConfirmContent, { props: { ctx, offer } });
    },
    closable: true,
    onModalCloseWithIcon(done: any) {
      done();
      clearInterval(IntervalTimer.getTimer());
      skuClickEvent({ ...params, result: SkuClickCategory.confirm_cancel });
    },
    confirmBtnCustom(ctx: any, r: any) {
      return r(ConfirmBtn, {
        props: { ctx, offer }
      });
    },
    cancelBtn: {
      text: "Not Now"
    },
    onCancel(done: any) {
      clearInterval(IntervalTimer.getTimer());
      done();
      skuClickEvent({ ...params, result: SkuClickCategory.confirm_cancel });
    }
  });
};

export const showPrivateAccountDialog = (
  kind: string,
  isPostKind: boolean,
  params: PaymentWithSituationParams
) => {
  Vue.prototype.$_Dialog.show({
    avatar: require("@/assets/icons-file/dialog/private@3x.png"),
    title: "This Account is Private",
    contentCustom(ctx: any, r: any) {
      return r(PrivateAccount, { props: { ctx, kind } });
    },
    closable: true,
    confirmBtn: {
      text: "OK"
    },
    onConfirm(done: any) {
      done();
      !isPostKind
        ? setTimeout(() => {
            paymentCheckModule.get().next();
          }, 0)
        : skuClickEvent({ ...params, result: SkuClickCategory.private_user });
    },
    cancelBtn: {
      text: "Check how to set account to public.",
      style: {
        textColor: "#FF3D59",
        fontSize: "12px",
        extraStyle: {
          textDecoration: "underline"
        }
      }
    },
    onCancel(done: any) {
      // to guides webview
      open_guides("ap");
      skuClickEvent({ ...params, result: SkuClickCategory.private_user });
      done();
    },
    onModalCloseWithIcon(done: any) {
      skuClickEvent({ ...params, result: SkuClickCategory.private_user });
      done();
    }
  });
};

export const showPostPrivateDialog = () => {
  Vue.prototype.$_Dialog.show({
    avatar: require("@/assets/icons-file/dialog/private@3x.png"),
    title: "This Video is Private",
    content: {
      text:
        'In order to receive likes, you need keep your videos public. Please set "everyone on TikTok" can watch this video in the Privacy settings in the official TikTok app.'
    },
    closable: true,
    confirmBtn: {
      text: "OK"
    },
    onConfirm(done: any) {
      done();
    },
    cancelBtn: {
      text: "Check how to set video to public.",
      style: {
        textColor: "#FF3D59",
        fontSize: "12px",
        extraStyle: {
          textDecoration: "underline"
        }
      }
    },
    onCancel(done: any) {
      // to guides webview
      open_guides("vp");
      done();
    }
  });
};
