






















import { Component, Prop, Watch, Vue } from "vue-property-decorator";
// import { ChildMixin } from "@/mixins/multiChildMixin";

@Component({
  components: {}
})
export default class TabBarItem extends Vue {
  @Prop({ default: false })
  private showDot: any;
  @Prop({ default: "" })
  private badge: any;
  @Prop({ default: "" })
  private link?: string;
  @Prop({ default: "" })
  private iconClass: any;
  @Prop({ default: "" })
  private labelClass: any;
  @Prop({ default: false })
  private selected?: boolean;

  private simple: boolean = false;
  private hasActiveIcon: boolean = false;
  private currentIndex: any = -1;
  private currentSelected: any = this.selected;

  get isActive() {
    return (this.$parent as any).index === this.currentIndex;
  }

  @Watch("currentSelected", { immediate: false })
  private watchCurrentSelected(val: any) {
    if (val) {
      const parent: any = this.$parent;
      parent.index = this.currentIndex;
    }
  }

  @Watch("selected", { immediate: false })
  private watchSelected(val: any) {
    this.currentSelected = val;
  }

  private onItemClick(hasLink: any) {
    if (
      typeof (this as any).disabled === "undefined" ||
      (this as any).disabled === false
    ) {
      this.currentSelected = true;
      (this.$parent as any).currentIndex = this.currentIndex;
      this.$nextTick(() => {
        this.$emit("on-item-click", this.currentIndex);
      });
    }
    if (hasLink === true) {
      this.$router.push({ name: this.link });
    }
  }

  private mounted() {
    if (!this.$slots.icon) {
      this.simple = true;
    }
    if (this.$slots["icon-active"]) {
      this.hasActiveIcon = true;
    }
    const parent: any = this.$parent;
    parent.updateIndex();
  }
}
