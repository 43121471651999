import Vue from "vue";
import Router from "vue-router";
import Home from "../views/Home.vue";
import Followers from "@/views/Follow/Follow.vue";
import Likes from "@/views/Like/Like.vue";
import EarnCoin from "@/views/EarnCoin/EarnCoin.vue";
import Coin from "@/views/Coin/index.vue";
import More from "@/views/More/More.vue";
import SearchUser from "@/views/SearchUser/SearchUser.vue";
import Splash from "@/views/Splash/Splash.vue";
import SuperPayPopup from "@/views/SuperPayPopup/SuperPayPopup.vue";

const Test = () => import("@/views/Test/Test.vue");
const Payment = () => import("@/views/Payment/Payment.vue");
const OrderDetail = () => import("@/views/OrderDetail/OrderDetail.vue");
const Setting = () => import("@/views/Setting/Setting.vue");
const Orders = () => import("@/views/Orders/Orders.vue");
const DoubleCoins = () => import("@/views/DoubleCoins/DoubleCoins.vue");
const SuperPayReceive = () => import("@/views/SuperPayReceive/index.vue");
const AllPost = () => import("@/views/AllPost/AllPost.vue");
const AccountManage = () => import("@/views/AccountManage/AccountManage.vue");
const GuidePage = () => import("@/views/GuidePage/GuidePage.vue");

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/Home",
      name: "Home",
      component: Home,
      redirect: "/Home/Earns",
      children: [
        {
          path: "Followers",
          name: "Followers",
          component: Followers
        },
        {
          path: "Likes",
          name: "Likes",
          component: Likes
        },
        {
          path: "Earns",
          name: "Earns",
          component: EarnCoin
        },
        {
          path: "Coins",
          name: "Coins",
          component: Coin
        },
        {
          path: "More",
          name: "More",
          component: More
        }
      ]
    },
    {
      path: "/Test",
      name: "Test",
      component: Test
    },
    {
      path: "/Payment",
      name: "Payment",
      component: Payment
    },
    {
      path: "/OrderDetail",
      name: "OrderDetail",
      component: OrderDetail
    },
    {
      path: "/Setting",
      name: "Setting",
      component: Setting
    },
    {
      path: "/Orders",
      name: "Orders",
      component: Orders
    },
    {
      path: "/SuperPayPopup",
      name: "SuperPayPopup",
      component: SuperPayPopup
    },
    {
      path: "/DoubleCoins",
      name: "DoubleCoins",
      component: DoubleCoins
    },
    {
      path: "/SuperPayReceive",
      name: "SuperPayReceive",
      component: SuperPayReceive
    },
    {
      path: "/AllPost",
      name: "AllPost",
      component: AllPost
    },
    {
      path: "/SearchUser",
      name: "SearchUser",
      component: SearchUser
    },
    {
      path: "/AccountManage",
      name: "AccountManage",
      component: AccountManage
    },
    {
      path: "/Splash",
      name: "Splash",
      component: Splash
    },
    {
      path: "/GuidePage",
      name: "GuidePage",
      component: GuidePage
    }
  ]
});
