


































import { Component, Prop, Vue } from "vue-property-decorator";
import ImgFetched from "@/components/ImgFetched/ImgFetched.vue";

@Component({
  components: {
    ImgFetched
  }
})
export default class UserMoreItem extends Vue {
  @Prop()
  private userList: any;

  private error_img = "da;faskfa";

  get placeHolder() {
    return require("@/assets/icons-file/sku/placehold_people.png");
  }
  private clickMore() {
    this.$emit("click");
  }
}
