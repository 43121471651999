










import { Component, Vue } from "vue-property-decorator";
import * as setting_types from "@/store/constants/setting_types";
import { Getter } from "vuex-class";

@Component
export default class CoinFeed extends Vue {
  @Getter(setting_types.GETTER_TAB_COIN_STORE_SETTINGS)
  private settingData: any;

  get watchVideoReward() {
    return this.settingData.rewards.video;
  }

  private clickAds() {
    this.$emit("clickAds");
  }
}
