





















import { Component, Prop, Vue } from "vue-property-decorator";
import NavBar from "@/components/NavBar/NavBar.vue";

@Component({
  components: { NavBar }
})
export default class Propup extends Vue {
  @Prop({ default: "slidedown" }) // slideup
  private transtionName?: string;
  @Prop()
  private value?: boolean;
  @Prop()
  private innerStyle?: string;

  private closePropup() {
    this.$emit("input", false);
    this.$emit("closePropup");
  }
}
