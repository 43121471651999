import { Vue } from "@/main";

class Loading {
  public show() {
    Vue.prototype.$_Toast.show({
      type: "loading",
      text: "Processing",
      isPayment: true
    });
  }

  public hide() {
    Vue.prototype.$_Toast.hide();
  }
}

export default new Loading();
