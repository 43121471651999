export const HTTP = "http";
export const HTTP_ABORT = "http_abort";
export const HIDE_LAUNCH_LOADING = "hide_loading";
export const LAUNCH_PARAMS = "get_launch_params";
export const DEVICE_INFO = "device_info";
export const LOCAL_PATH_GET = "local_path_get";
export const BUNDLE_LIST = "bundle_list";
export const CACHE_LIST = "cache_list";
export const BUNDLE_RES = "bundle";
export const CACHE_RES = "cache";
export const IAP_PRODUCT = "iap_product";
export const LOCAL_FILE_READ = "local_file_get";
export const FETCH_INSTALLED_APPS = "get_all_resources";
export const LOG_EVENT = "log_event";
export const CONFIG_VIDEO = "config_video_ad";
export const GET_VIDEO_READY = "get_video_ad_ready";
export const PLAY_VIDEO = "play_video_ad";
export const REGISTER_UMENG_PUSH = "register_umeng_push";
export const CONFIG_UMENG_PUSH = "config_umeng_push";
export const KILL_APP = "kill_app";
export const IAP_PURCHASE = "iap_purchase";
export const OPEN_BROWSER = "open_browser";
export const CLOSE_BROWSER = "close_browser";
export const OPEN_EMAIL = "open_email";
export const OPEN_APP_STORE = "open_app_store";
export const CONFIG_HY_WEB_HOST = "config_hy_web_host";
export const GET_APP_FLYER_INFO = "get_apps_flyer_info";
export const OPEN_IMAGE = "open_image_picker";
export const DEBUG_LOAD_HY_WEB_HOST = "debug_load_hy_web_host";
export const NATIVE_STORAGE_SET = "native_storage_set";
export const NATIVE_STORAGE_GET = "native_storage_get";
export const NATIVE_STORAGE_REMOVE = "native_storage_remove";
export const NATIVE_STORAGE_KEYS = "native_storage_keys";
export const CHANGE_STATUS_BAR_STYLE = "change_status_bar_style";
export const PLAY_IMPACT = "play_impact";
export const REMOVE_NOTIFICATION = "remove_notification";
export const GET_NOTIFICATION_PERMISSION = "get_notification_authorization";
export const ADD_LOCAL_NOTIFICATION = "add_local_notification";
export const CONFIG_WEBIVEW_SCRIPT = "config_webview_script";
export const CHANGE_STATUS_BAR_HIDDEN = "change_status_bar_hidden";
export const GET_NOTIFICATION_AUTHORIZATION = "get_notification_authorization";
export const REQUEST_NOTIFICATION_AUTHORIZATION = "request_notification_authorization";
