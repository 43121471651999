import { render, staticRenderFns } from "./SelectPost.vue?vue&type=template&id=42bde4c8&"
import script from "./SelectPost.vue?vue&type=script&lang=ts&"
export * from "./SelectPost.vue?vue&type=script&lang=ts&"
import style0 from "./select_post.scss?vue&type=style&index=0&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports