import AppFlyerInfo from "@/services/appFlyerInfo/appFlyerInfo";
import {
  getAndroidGaid,
  getAndroidId,
  getBundleId,
  getDeviceId,
  getIDFA,
  getIDFV,
  getShortVersion
} from "@/utils/js_bridge_info";
import { isIos } from "@/utils/lodash";

export const assembleAppInfo = () => {
  try {
    const apps_flyer_info = AppFlyerInfo.get();
    const app_version = getShortVersion();
    const bundle_id = getBundleId();
    const device_info = isIos()
      ? {
          idfv: getIDFV(),
          customer_user_id: getDeviceId(),
          idfa: getIDFA()
        }
      : {
          android_id: getAndroidId(),
          customer_user_id: getDeviceId(),
          gaid: getAndroidGaid()
        };
    const app_info: any = {
      apps_flyer_info,
      app_version,
      bundle_id,
      device_info
    };
    for (const key in app_info) {
      if (app_info.hasOwnProperty(key)) {
        app_info[key] === undefined || app_info[key] === null
          ? delete app_info[key]
          : void 0;
      }
    }
    console.log("assembleAppInfo result", app_info);
    return app_info;
  } catch (e) {
    console.log("assembleAppInfo error", e);
    return null;
  }
};
