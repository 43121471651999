













import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { swiper } from "vue-awesome-swiper";
@Component({
  components: { swiper }
})
export default class SwiperContainer extends Vue {
  @Prop()
  private value?: number;
  @Prop()
  private FollowHeight?: any;
  @Prop({ default: false })
  private abheight?: boolean; // 如果swiper的高度继承父元素，需要传true 解决ios10 的兼容问题
  @Prop()
  private gray?: boolean;
  private slideIndex = 0;
  get swiperOptions() {
    return {
      loop: false,
      observer: true,
      initialSlide: this.slideIndex,
      observeParents: false,
      on: {
        slideChangeTransitionStart: () => {
          const refs: any = this.$refs;
          if (refs.subSwiper && refs.subSwiper.swiper) {
            this.$emit("input", refs.subSwiper.swiper.realIndex);
          }
        }
      }
    };
  }
  @Watch("value", { immediate: true })
  private watchValue(val: any) {
    this.$nextTick(() => {
      const refs: any = this.$refs;
      this.slideIndex = val;
      if (refs.subSwiper && refs.subSwiper.swiper) {
        refs.subSwiper.swiper.slideTo(val);
      }
    });
  }
}
