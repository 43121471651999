import { Feed } from "../types/type";
import {
  observable_feeds,
  feeds_mutations,
  feeds_getters,
  feeds_actions
} from "../observer/feeds_observer";
import GetFeeds from "../feeds/get_feeds";
import { act_current_feed } from "./../feeds/act_feeds";
import JsBridge from "@/jsbridge";
import {
  is_purchase_feed,
  MAX_FEEDS_COUNT
} from "../earn_coins_consts/earn_coins_consts";

export const delete_exceed_feed = () => {
  //
  const near_by_task_feed_index: any = feeds_getters.near_by_task_feed_index();
  //
  console.log("near_by_task_feed_index:", near_by_task_feed_index);
  if (near_by_task_feed_index > 0) {
    try {
      feeds_mutations.delete_feeds(0, near_by_task_feed_index);
      // 删除后需要移动index指针:
      const current_index = feeds_getters.index();
      feeds_mutations.change_index(current_index - near_by_task_feed_index);
      console.log("delete_feeds observable_feeds:", observable_feeds);
    } catch (error) {
      console.log("delete_feeds observable_feeds errro:", error);
    }
  }
};

/**
 * 检查是否达到了上限制
 */
export const check_cache_feeds_max_count = () => {
  const feeds_length = feeds_getters.feed_length();

  if (feeds_length >= MAX_FEEDS_COUNT) {
    // reach throld:
    console.log("reach throld");
    delete_exceed_feed();
  }
};

export const get_feeds = async () => {
  try {
    const feeds: any = await GetFeeds.get_feed_from_server();
    feeds_mutations.push_feeds(feeds);
    console.log("observable_feeds:", observable_feeds);
    check_cache_feeds_max_count();
  } catch (error) {
    // feed请求可能失败，不抛出错误
  }
};

export const standby_feeds = async () => {
  try {
    //
    if (feeds_getters.can_pull_feeds() && !feeds_getters.is_enough_tt_feed()) {
      //
      await get_feeds();
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

export const skip_feeds = () => {
  feeds_actions.move_index(1);
  // standby:
  standby_feeds();
  // impact:
  JsBridge.playImpact();
};

export const skip_feeds_by_purchased = (current_feed: Feed) => {
  //
  if (is_purchase_feed(current_feed)) {
    // 当前是购买类型的offer:
    feeds_actions.move_index(1);
  }
  // standby:
  standby_feeds();
};

export const act_feed = (feed: null | Feed) => {
  // impact:
  JsBridge.playImpact();
  // act feed
  act_current_feed(feed);
};
