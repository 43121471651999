/**
 * 记录当前选择的post信息,更改当前的post
 */
import { store } from "@/main";
import { GETTER_CURRENT_TIKTOK_USER_MEDIA } from "@/store/constants/tt_media_types";
import Event_bus from "@/utils/event_bus";

class TiktokUserMedaiUtil {
  private currentPost: any = null;

  get currentMediaList() {
    return store.getters[GETTER_CURRENT_TIKTOK_USER_MEDIA];
  }

  public init() {
    console.log("TiktokUserMedaiUtil init");
    this.getInitMedia();
  }

  public getCurrentPost() {
    return this.currentPost;
  }

  public setCurrentPost(posInfo: any) {
    this.currentPost = posInfo;
    Event_bus.$emit("ins_media_change");
  }

  private getInitMedia() {
    if (
      !this.currentMediaList ||
      this.currentMediaList.length < 1 ||
      this.currentMediaList.tt_media_list.length < 1
    ) {
      this.currentPost = null;
      Event_bus.$emit("ins_media_change", { upsateStatus: "new" });
    } else {
      const notExist = !(
        this.currentPost &&
        this.currentMediaList.tt_media_list.find(
          (v: any) => v.id === this.currentPost.id
        )
      );
      if (notExist) {
        this.currentPost = this.currentMediaList.tt_media_list[0];
        Event_bus.$emit("ins_media_change", { upsateStatus: "new" });
      }
    }
  }
}

export default new TiktokUserMedaiUtil();
