/*
 * @Author: Jhonsem
 * @Date: 2019-08-20 16:52:34
 * @Last Modified by:   Jhonsem
 * @Last Modified time: 2019-08-20 16:52:34
 * 特殊情况是嵌套的Home路由之前的跳转，
 */

const routes: any = [];

export const findRouteIndex = (value: string) => {
  return routes.findIndex((item: any) => {
    return item.path && item.path.includes(value);
  });
};

export const findExactRoute = (val: string) => {
  return routes.find((item: any) => {
    return item.name && item.name === val;
  });
};

export const findRoutesByName = (value: string) => {
  return routes.filter((item: any) => {
    return item.path && item.path.includes(value);
  });
};

export const getCurrentRoute = () => {
  return routes[0];
};

export const clearRoutes = () => {
  routes.splice(0, routes.length);
};

export const getRoutesParams = () => {
  return routes;
};

export const getLastRoute = () => {
  return routes[1];
};

export default routes;
