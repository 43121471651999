








































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import SpecialOfferController from "./special_offer_controller";
import SpecialOfferMultiItem from "./SpecialOfferMultiItem.vue";
import SpecialOfferSingleItem from "./SpecialOfferSingleItem.vue";
import CountDwonTime from "@/components/CountDwonTime/CountDwonTime.vue";
import { Getter } from "vuex-class";
import { GETTER_PAGE_SETTING_STATUS } from "@/store/constants/setting_types";
@Component({
  components: {
    CountDwonTime,
    SpecialOfferMultiItem,
    SpecialOfferSingleItem
  }
})
export default class SpecialOffer extends Vue {
  // 用来禁用打点功能
  @Prop({ default: false })
  private disableLogEvent: any;
  @Prop({ default: "follow" })
  private type: any;
  @Getter(GETTER_PAGE_SETTING_STATUS)
  private getterPageOnlineSettingStatus: any;
  private isShow: any = false;
  private controller: any;
  private countDownTimeStamp: any = 0;
  private specilOffers: any = [];
  private hasLogEvent: boolean = false;

  get pageOnlineSetting() {
    return this.getterPageOnlineSettingStatus(this.type);
  }

  get isSingle() {
    return this.specilOffers.length === 1;
  }

  get offerDiscount() {
    return `${this.specilOffers[0].discount}%`;
  }

  get singleOfferIno() {
    if (this.type === "follow") {
      return {
        avatar: require("@/assets/icons-file/special_offer/icon_followers_white.png"),
        text: "Followers",
        background: "follow",
        icon: require("@/assets/icons-file/special_offer/icon_followers.png")
      };
    } else {
      return {
        avatar: require("@/assets/icons-file/special_offer/icon_like_white.png"),
        text: "Likes",
        background: "like",
        icon: require("@/assets/icons-file/special_offer/icon_likes.png")
      };
    }
  }

  @Watch("pageOnlineSetting", { immediate: true })
  private watchOnlineSetting(val: any) {
    this.initSpecialOffer();
  }

  // 主动拉起单个special offer的购买
  private clickToButSingleOffer() {
    if (this.isSingle) {
      (this.$refs.SpecialOfferSingleItem as any).buyOffers();
    }
  }

  // 通过购买成功，结束当前duration，触发跳入special offer的下一个阶段
  private buyOffers() {
    this.goSpecialOffersNextStage(false);
  }

  // show打点
  private logEvent() {
    try {
      // speical offer controller是全局维护一个，但是展示的ui目前是两个同时存在，为了避免重复打点，需要给第二个ui关闭show打点功能
      if (!this.disableLogEvent) {
        const type = `special_${this.controller.type}_show`;
      }
    } catch (e) {
      //
    }
  }

  // 通过倒计时结束的方式，触发跳入special offer的下一个阶段
  private async goSpecialOffersNextStage(flag = true) {
    console.log("goSpecialOffersNextStage>>>>>>>");
    const {
      isShow,
      durationTimeStamp,
      offerInfo
    } = this.controller.stageNextStep(flag);
    this.isShow = isShow;
    this.specilOffers = offerInfo && offerInfo.offers;
    this.countDownTimeStamp = durationTimeStamp;
    await this.controller.updateIndexDB();
    // 只在倒计时结束触发，因为手动购买进入下一阶段，会重新进入组件触发created或者actived钩子，使show打点重复
    if (flag && isShow) {
      this.logEvent();
    }
  }

  // 在init的阶段必须保存实例数据到DB，为了避免当DB里没有数据时同步失败
  private async initSpecialOffer() {
    console.log("initSpecialOffer>>>>>>>");
    this.controller = new SpecialOfferController();
    const { isShow, durationTimeStamp, offerInfo } = await this.controller.init(
      this.type
    );
    this.isShow = isShow;
    this.specilOffers = offerInfo.offers;
    this.countDownTimeStamp = durationTimeStamp;
    if (!this.disableLogEvent) {
      console.log(this.controller);
    }
    if (isShow) {
      this.logEvent();
    }
    await this.controller.updateIndexDB();
  }

  private mounted() {
    if (!this.hasLogEvent) {
      this.initSpecialOffer();
    }
    this.hasLogEvent = true;
  }

  private activated() {
    if (!this.hasLogEvent) {
      this.initSpecialOffer();
    }
    this.hasLogEvent = true;
  }

  private destroyed() {
    this.hasLogEvent = false;
  }

  private deactivated() {
    this.hasLogEvent = false;
    /**
     * 必须要在失活时重置special offer，否则组件会缓存当前状态。下次进入路由激活组件时，上次缓存的倒计时会立马触发进入下一阶段，进行show打点，避免初始化打点一次，进入下一阶段同时再打点一次
     */
    this.countDownTimeStamp = 0;
    this.isShow = false;
    this.specilOffers = [];
  }
}
