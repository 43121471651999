























import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  GETTER_TIKTOK_USER_EMPTY,
  GETTER_CURRENT_TIKTOK_USER_SECRET,
  GETTER_CURRENT_TIKTOK_USER_INFO
} from "@/store/constants/tt_user_types";
import {
  CURRENT_TIKTOK_USER_MEDIA_IS_EMPTY,
  GETTER_CURRENT_TIKTOK_USER_MEDIA
} from "@/store/constants/tt_media_types";
import EmptyUser from "@/components/UserInfo/EmptyUser.vue";
import SelectPost from "@/components/SelectPost/SelectPost.vue";
import { goToSearchOrLogin } from "@/utils/router_helper";
@Component({
  components: { EmptyUser, SelectPost }
})
export default class LikeInfo extends Vue {
  @Getter(GETTER_TIKTOK_USER_EMPTY)
  private isUserEmpty: any;
  @Getter(CURRENT_TIKTOK_USER_MEDIA_IS_EMPTY)
  private currentTiktokMediaListIsEmpty: any;
  @Getter(GETTER_CURRENT_TIKTOK_USER_SECRET)
  private currentTikUserSecret: any;
  @Getter(GETTER_CURRENT_TIKTOK_USER_MEDIA)
  private currentTikUserMedia: any;
  @Getter(GETTER_CURRENT_TIKTOK_USER_INFO)
  private currentTiktokUserInfo: any;
  @Prop()
  private cureentMediaInfo: any;

  get showSecret() {
    return (
      this.currentTikUserSecret &&
      this.currentTiktokUserInfo &&
      this.currentTiktokUserInfo.login_type === "search"
    );
  }

  private clickEmptyUser() {
    goToSearchOrLogin();
  }
}
