import { isIos } from "@/utils/lodash";
import JsBridge from "@/jsbridge";
import { router, Vue } from "@/main";
import listenNative from "@/jsbridge/service/listenNative";

// ======================= Kill App =========================
const killAppRouterName: any = [
  "Followers",
  "Likes",
  "Earns",
  "More",
  "Coins",
  "Splash"
];
let killAppInfoTimer: any = null;
let killAppCount: number = 0;

const killAppInfo = () => {
  // 如果toast在显示过程中，不触发toast，避免会关闭先前的toast:

  console.log("Vue.prototype.$_Toast:", Vue.prototype.$_Toast.isShowing);

  if (Vue.prototype.$_Toast && Vue.prototype.$_Toast.isLoading) {
    return;
  }

  if (killAppCount >= 1) {
    JsBridge.killApp("finish").catch(() => void 0);
    clearTimeout(killAppInfoTimer);
  }
  killAppCount += 1;
  Vue.prototype.$_Toast.show({
    text: "Double click to exit."
  });
  killAppInfoTimer = setTimeout(() => {
    killAppCount = 0;
  }, 1000);
};

// ====================== Map Fn =========================

const stackFn = new Map();
export const DIALOG_KEY = "dialog";
export const ORDER_COMPLETED = "order_completed";
export const POPUP_DIALOG = "popup_dialog";

const runBackPressFn = () => {
  const size = stackFn.size;
  console.log("stackFn", stackFn);
  // 获取当前map的最近一条
  if (size) {
    const stackValues = [...stackFn.values()];
    const latestValue = stackValues[size - 1];
    latestValue && latestValue();
  }
};

// ====================== Back Press Class ===============
export default {
  initial() {
    listenNative.addListen("back_pressed", () => {
      runBackPressFn();
    });
  },
  listen(data: any) {
    // android only:
    if (!isIos()) {
      const { key, fn } = data;
      stackFn.set(key, fn);
    }
  },
  destroy(key: string) {
    // android only:
    if (!isIos()) {
      stackFn.has(key) ? stackFn.delete(key) : void 0;
    }
  },
  pageListen(to: any, from: any) {
    stackFn.has(from.name) && stackFn.delete(from.name);
    stackFn.set(to.name, () => {
      // 如果是点击需要提示关闭的页面，先提示，连点关闭
      if (killAppRouterName.includes(to.name)) {
        killAppInfo();
      } else {
        router.go(-1);
      }
    });
  }
};
