
























import { Component, Vue, Prop } from "vue-property-decorator";
import { Getter } from "vuex-class";
import Scroller from "@/components/Scroller/Scroller.vue";
import {
  GEETER_TIKTOK_USER_LIST,
  GEETER_TIKTOK_LOGIN_USER
} from "@/store/constants/tt_user_types";
import { GETTER_CURRENT_USER_ID } from "@/store/constants/index_types";
import UserItem from "@/components/UserItem/UserItem.vue";
@Component({
  components: { Scroller, UserItem }
})
export default class InsUserIner extends Vue {
  @Getter(GEETER_TIKTOK_USER_LIST)
  private tiktokUserList: any;
  @Getter(GETTER_CURRENT_USER_ID)
  private currentTiktokUserId: any;
  @Getter(GEETER_TIKTOK_LOGIN_USER)
  private loginUserInfo: any;
  @Prop()
  private showLoginUser?: boolean;

  private clickItem(id: string) {
    this.$emit("clickItem", id);
  }

  get showUserList() {
    return this.showLoginUser
      ? this.loginUserInfo
        ? [this.loginUserInfo]
        : []
      : this.tiktokUserList;
  }

  get itemListHeight() {
    return 96 * this.showUserList.length;
  }
}
