import { BACK_UP_HAS_ADD_COINS } from "@/utils/nativeStorage/local_keys";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { isIos } from "@/utils/lodash";
import {
  recommendStart,
  recommendFinish
} from "@/api/cg/service/pinad_service";
import jsBridge from "@/jsbridge";
import { getInstalledList } from "@/jsbridge/service/commonMethod";
import { App } from "@/services/super_pay/types";
import PinadModule from "@/services/pinad_add_coin/cache";

export class PinadCoinAdd {
  /**
   * 进入界面后调用，app加币，只针对andriod平台，需要手动处理finish逻辑前端自己加币
   */
  public async addCoin(isHotApps: boolean = true) {
    try {
      if (!isIos()) {
        const installAppList = await getInstalledList();
        if (isHotApps) {
          const apps: any[] = PinadModule.getCacheApp(isHotApps);
          Promise.all(
            apps.map(item => this.executeAddCoins(installAppList, item))
          );
        } else {
          const app: App = PinadModule.getCacheApp(isHotApps);
          // 返回的app可能是null
          if (
            app &&
            app.packageName &&
            installAppList.includes(app.packageName)
          ) {
            await recommendFinish(app.adId);
            await PinadModule.removeCacheApp(app);
            nativeStorage.set(BACK_UP_HAS_ADD_COINS, 1);
          }
        }
      } else {
        const app: App = PinadModule.getCacheApp(isHotApps);
        const installAppList = await getInstalledList();
        if (
          app &&
          app.packageName &&
          installAppList.includes(app.packageName)
        ) {
          await nativeStorage.set(BACK_UP_HAS_ADD_COINS, 1);
        }
      }
    } catch (error) {
      //TODO pinad add coins error: TypeError: Cannot read property 'packageName' of null
      console.log("pinad add coins error:", error);
    }
  }

  launchAddCoin() {
    const { hotApps, back_up } = PinadModule.get();
    if (hotApps.length > 0) {
      this.addCoin();
    }
    if (back_up) {
      this.addCoin(false);
    }
  }

  /**
   * 点击下载app后立即调用，只针对andriod平台，发出recommendStart请求并存储app数据
   * 因为可能会出现用户点击下载app并安装后，退出了lite app，所以只能缓存下来，在app启动时加币一次
   *
   * @param appData 接口获取到的单条app数据
   * @param single 0或1，0表示普通app，1表示backup app
   */
  public async startAndSetCache(appData: any, single: number = 0) {
    try {
      await recommendStart(appData.adId, single);
      appData = {
        ...appData,
        single
      };
      if (!isIos()) {
        PinadModule.addCacheApp(appData, !single);
      }
    } catch (error) {
      console.log("pinad recommendStart or set cache error:", error);
    }
  }

  private async executeAddCoins(installAppList: any[], item: any) {
    if (item.packageName && installAppList.includes(item.packageName)) {
      await recommendFinish(item.adId);
      await PinadModule.removeCacheApp(item);
    }
  }

  private async requestUrl(url: string) {
    try {
      const result: any = await jsBridge.http({ url, method: "GET" });
      console.log("requestUrl result", result);
    } catch (error) {
      console.log("requestUrl error", error);
    }
  }
}

export default new PinadCoinAdd();
