export const UPDATE_SETTING_DATA = "UPDATE_SETTING_DATA";
export const UPDATE_LOADED_ONLINE_SETTING_STATE =
  "UPDATE_LOADED_ONLINE_SETTING_STATE";
export const GETTER_ONLINE_SETTING_STATE = "GETTER_ONLINE_SETTING_STATE";
export const CONFIG_HY_WEB_HOST = "CONFIG_HY_WEB_HOST";
export const FIRST_FETCH_COMMON_SETTING = "FIRST_FETCH_COMMON_SETTING";
export const INIT_GX_LOCAL_SETTING_DATA = "INIT_GX_LOCAL_SETTING_DATA";
export const UPDATE_PAGE_SETTING_STATUS = "UPDATE_PAGE_SETTING_STATUS";
// ============= setting =============
export const GETTER_SETTING_EXIST = "GETTER_SETTING_EXIST";
export const GETTER_ALL_SETTING = "GETTER_ALL_SETTING";
export const GETTER_CLIENT_SETTINGS = "GETTER_CLIENT_SETTINGS";
export const GETTER_FOLLOW_OFFERS = "GETTER_FOLLOW_OFFERS";
export const GETTER_SERVICE_OFFERS = "GETTER_SERVICE_OFFERS";
export const GETTER_TAB_COIN_STORE_SETTINGS = "GETTER_TAB_COIN_STORE_SETTINGS";
export const GETTER_COIN_LIMIT_SALE = "GETTER_COIN_LIMIT_SALE";
export const GETTER_COIN_OFFERS = "GETTER_COIN_OFFERS";
export const GETTER_LIKE_OFFERS = "GETTER_LIKE_OFFERS";
export const GETTER_AD_APP_ID = "GETTER_AD_APP_ID";
export const GETTER_SP_BONUS_COINS = "GETTER_SP_BONUS_COINS";
export const GETTER_SALE = "GETTER_SALE";
export const GETTER_ENABLE = "GETTER_ENABLE";
export const GETTER_APP_TAKE_DOWN = "GETTER_APP_TAKE_DOWN";
export const GETTER_FORCE_LOGIN_ENABLED = "GETTER_FORCE_LOGIN_ENABLED";
export const GETTER_EARN_COINS_LOGIN_NONEED_ENABLED =
  "GETTER_EARN_COINS_LOGIN_NONEED_ENABLED";
export const GETTER_LIKE_FORCE_LOGIN_ENABLED =
  "GETTER_LIKE_FORCE_LOGIN_ENABLED";
export const GETTER_WP_URL = "GETTER_WP_URL";
export const GETTER_CONTACT_EMAIL = "GETTER_CONTACT_EMAIL";
export const GETTER_REDIRECT_APP = "GETTER_REDIRECT_APP";
export const GETTER_LIMIT_COINS_SETTING = "GETTER_LIMIT_COINS_SETTING";
export const GETTER_FREE_FOLLOW_ENABLE = "GETTER_FREE_FOLLOW_ENABLE";
export const GETTER_RECOMMEND_OFFER_LIST = "GETTER_RECOMMEND_OFFER_LIST";
export const GETTER_FEATURES = "GETTER_FEATURES";
export const GETTER_SUPER_PAY_PARAMS = "GETTER_SUPER_PAY_PARAMS";
export const GETTER_BIG_PRICE_LIST = "GETTER_BIG_PRICE_LIST";
export const GETTER_FEATURES_ITEM_BY_FUNCTION =
  "GETTER_FEATURES_ITEM_BY_FUNCTION";
export const GETTER_TAB_EARN_COINS_SETTINGS = "GETTER_TAB_EARN_COINS_SETTINGS";
export const GETTER_DOUBLE_COINS_PERCENTAGE = "GETTER_DOUBLE_COINS_PERCENTAGE";
export const GETTER_GOLDEN_FOLLOW_OFFERS = "GETTER_GOLDEN_FOLLOW_OFFERS";
export const GETTER_COMMENT_OFFERS = "GETTER_COMMENT_OFFERS";
export const GETTER_VIEW_OFFERS = "GETTER_VIEW_OFFERS";
export const GETTER_SPECIAL_FOLLOW_OFFERS = "GETTER_SPECIAL_FOLLOW_OFFERS";
export const GETTER_SPECIAL_LIKE_OFFERS = "GETTER_SPECIAL_LIKE_OFFERS";
export const GETTER_OFFERS_BY_TYPE = "GETTER_OFFERS_BY_TYPE";
export const GETTER_HELP_GUIDES_URL = "GETTER_HELP_GUIDES_URL";
export const GETTER_PAGE_SETTING_STATUS = "GETTER_PAGE_SETTING_STATUS";
export const GETTER_SP_FIRST = "GETTER_SP_FIRST";
