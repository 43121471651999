import cgRequest from "@/api/cg/base";
import * as cgConst from "@/api/cg/base/cg_const";
import { requestDefaultParams } from "@/api/utils/xi_helper";
import AppFlyerInfo from "@/services/appFlyerInfo/appFlyerInfo";
import { assembleAppInfo } from "@/api/cg/util/common_util";

/**
 * 获取推荐
 * @param kind 类型
 */
export const xiEvent = async (
  type: "liquidate" | "behavior" | "push",
  data: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const defaultParams: any = await requestDefaultParams();
      const app_info = assembleAppInfo();
      const params = {
        ...defaultParams,
        ...data
      };
      app_info && (params.app_info = app_info);
      resolve(await cgRequest(cgConst.EVENT_TRACKING, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};
