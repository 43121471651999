import { formatToThousands } from "@/utils/lodash";
import { Offer, OfferKind } from "@/services/offer/offer_handle";
import { PayWay } from "@/services/payment/types";

export const objectToQuery = (obj: any, encode: boolean = true) => {
  const pushArr = [];
  if (Object.prototype.toString.call(obj) === "[object Object]") {
    for (const key in obj) {
      if (Object.prototype.toString.call(obj[key]) === "[object Object]") {
        pushArr.push(
          `${key}=${
            encode
              ? encodeURIComponent(JSON.stringify(obj[key]))
              : JSON.stringify(obj[key])
          }`
        );
      } else if (obj[key] !== undefined) {
        pushArr.push(
          `${key}=${encode ? encodeURIComponent(obj[key]) : obj[key]}`
        );
      }
    }
    return pushArr.join("&");
  } else {
    throw new Error("expect object type param");
  }
};

export const getInformation = (resource: any, offer: Offer) => {
  const effect = resource.with_vip_reward_effect || resource.effect;
  const reward = resource.reward_effect;
  let effect_result = reward ? effect + reward : effect;
  const offerIsVip = offer.kind === OfferKind.vip;
  if (offerIsVip) {
    effect_result =
      effect_result > 1 ? effect_result + " Months" : effect_result + " Month";
  }
  return formatToThousands(effect_result) + " " + resource.type_name;
};

/*
 * 传入地址，获取query
 * */
export const getQueryParamsFromUrl = (url: string) => {
  const queryIndex = url.indexOf("?");
  const query = url.substring(queryIndex + 1);
  const params = query.split("&");
  const result: any = {};
  params.forEach((v: any) => {
    const pair = v.split("=");
    const first_equal_index = v.indexOf("=");
    result[pair[0]] = v.slice(first_equal_index + 1);
  });
  return result;
};

// 去除字符串中的/r/n
export const removeRubbishInStr = (str: string) => {
  let result = decodeURIComponent(str).replace(new RegExp("\r", "g"), "");
  result = result.replace(new RegExp("\n", "g"), "");
  return result;
};

export const isNativePayTypes = (pay_way: PayWay) => {
  return (
    pay_way === PayWay.super_pay ||
    pay_way === PayWay.back_up ||
    pay_way === PayWay.iap
  );
};
