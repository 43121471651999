/*
 * @Author: Jhonsem
 * @Date: 2021-01-05 11:01:53
 * @Last Modified by: Jhonsem
 * @Last Modified time: 2021-01-05 15:59:32
 */
import { Purchase, AddToCard } from "./types/ga_even";
/**
 * 使用ga4的自定义事件需要在ga平台注册后才能使用
 * @param action_name
 * @param params
 */
export const ga_custom_event = (action_name: string, params: object) => {
  window.gtag("event", action_name, params);
};

/**
 * ga4的购买打点
 * @param purchase_event
 */
export const ga_purchase_event = (purchase_event: Purchase) => {
  window.gtag("event", "purchase", purchase_event);
};

/**
 * ga4的添加购物车的打点，用于点击购买项
 * @param purchase_event
 */
export const ga_add_to_cart_event = (purchase_event: AddToCard) => {
  window.gtag("event", "add_to_cart", purchase_event);
};
