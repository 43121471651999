export class Module {
  private value: any;
  private readonly defaultValue: any;

  constructor(defaultValue: any) {
    this.value = defaultValue;
    this.defaultValue = defaultValue;
  }

  public get() {
    return this.value ? this.value : this.defaultValue;
  }

  public set(value: any) {
    this.value = value;
  }
}
