import DialogComponent from "@/components/PopupDialog/PopupDialog.vue";
import { CreateElement, VNode, PluginObject, VueConstructor } from "vue";
export const PopUpDialog: any = DialogComponent;

declare module "vue/types/vue" {
  export interface Vue {
    $_PopUpDialog: any;
  }
}

const plugin: PluginObject<any> = {
  install(Vue: VueConstructor, opts?: any) {
    const pluginRootEle = document.createElement("div");
    pluginRootEle.id = "popUpDialogPluginRootInstance";
    document.body.appendChild(pluginRootEle);

    new Vue({
      data: {
        isShow: false,
        kind: "",
        effect: 0,
        discount: 0,
        offer: null
      },
      created() {
        const ctx = this;
        Vue.prototype.$_PopUpDialog = {
          show({ kind = "", effect = 0, discount = 0, offer = null }) {
            ctx.isShow = true;
            ctx.kind = kind;
            ctx.discount = discount;
            ctx.effect = effect;
            ctx.offer = offer;
          },
          hide() {
            ctx.isShow = false;
          }
        };
      },
      render(h: CreateElement): VNode {
        return (
          <PopUpDialog
            show={this.isShow}
            kind={this.kind}
            effect={this.effect}
            discount={this.discount}
            offer={this.offer}
          />
        );
      }
    }).$mount("#popUpDialogPluginRootInstance");
  }
};
export default plugin;
