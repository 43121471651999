/*
 * 收到本地通知点击进来的触发逻辑
 *
 * 本地通知比较特殊：
 * 1.应用切到后台，收到了本地通知，然后点击来，是通过 “did_receive_notification” 监听
 * 2.应用初始被杀死状态，收到了本地通知，然后点进来，是通过jsbridge的其他接口收到
 */
import { UmengPushData } from "./umeng_push";
import JsBridge from "@/jsbridge";

class LocalPush {
  public receivePush(data: UmengPushData) {
    const { application_state, user_action, identifier } = data;
    if (user_action && application_state !== "active") {
      // 用户从后台点击进来
      this.handlePush(data);
    }
  }

  public handlePush(data: UmengPushData) {
    const { user_info, application_state, user_action } = data;
    const { type } = user_info;
    // this.pushOrderCreateNotice();
    // 本地通知的user_info是传什么给什么 没有 custom_data
    // if (type === "sepcial_follow_notice") {
    //   this.specialFollowerTask(user_info);
    // }
  }

  public specialFollowerTask(user_info: any) {
    //
  }

  public pushOrderCreateNotice(
    type: "enter_background" | "right_now",
    pushData: any
  ) {
    JsBridge.addLocalNotification(type, pushData);
  }
}

export default new LocalPush();
