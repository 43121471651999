import nativeStorage from "@/utils/nativeStorage/native_storage";
import { store, Vue } from "@/main";
import * as types from "@/store/constants/index_types";
import * as setting_types from "@/store/constants/setting_types";
import { randomNum } from "@/utils/num_utils";
import { checkFreeFollowers } from "@/api/cg/service/user_service";
import { RecommendOffer, OfferKind } from "@/services/offer/offer_handle";
import {
  HAS_SHOW_FREE_FOLLOW,
  HAS_ENTER_RECOMMEND
} from "@/utils/nativeStorage/local_keys";
import { App, AppsCategory } from "@/services/super_pay/types";
import SuperPayCache from "@/services/super_pay";
import { judgeAppInstalled } from "@/jsbridge/service/commonMethod";
import { judgeFeatureEvent } from "@/services/super_pay/super_pay_helper";
import { FeatureFunctionItem } from "@/services/payment/types";
import { getIapPrice } from "@/plugins/filters/offer_filter";
/**
 * isCanshowFreeFollow
 */
export const isCanshowFreeFollow = async () => {
  // 如果之前没显示free follow，并且登陆了账号，然后设备也没买过free follow,
  // 并且boss配置的free follow可以领取，开机才显示 free follow
  const is_has_show_free_follow = await nativeStorage.get(HAS_SHOW_FREE_FOLLOW);
  const currentInsUserId: string | undefined =
    store.getters[types.GETTER_CURRENT_USER_ID];
  const freeFollowEnable: boolean =
    store.getters[setting_types.GETTER_FREE_FOLLOW_ENABLE];
  if (is_has_show_free_follow || !freeFollowEnable) {
    // 展示过了，boss配置不可以显示free follow，没有登陆账号，不显示
    return false;
  } else {
    if (!currentInsUserId) {
      // 没账号显示 free follow  初次进入app，要么是没账号，要么就是从splash登陆的账号
      return true;
    }
    // 有账号先 检测账号是否可以领取
    const masterId = store.getters[types.GETTER_MASTER_USER_ID];
    try {
      const checkResult: any = await checkFreeFollowers(
        masterId,
        currentInsUserId
      );
      if (checkResult && checkResult.free_status === 1) {
        //  账号可以领取
        return true;
      } else {
        nativeStorage.set(HAS_SHOW_FREE_FOLLOW, true);
        return false;
      }
    } catch (error) {
      return false;
    }
  }
};

// app下架并且没安装superpay 和backup app,并且有back up
export const isShowAppOutOfStock = async (): Promise<boolean> => {
  // 首先判断是否下架  app_takedown take_down_launch_enabled都为true 就是下架了
  const clientSetting = store.getters[setting_types.GETTER_CLIENT_SETTINGS];
  const isOutOfStock: boolean | undefined =
    clientSetting &&
    clientSetting.enable &&
    clientSetting.enable.app_takedown &&
    clientSetting.redirect_app &&
    clientSetting.redirect_app.is_enabled;
  return !!isOutOfStock;
};

/**
 * getLaunchRecommendOffer
 */
export const getLaunchRecommendOffer = async (): Promise<
  null | RecommendOffer | undefined
> => {
  const recommendOfferList: RecommendOffer[] | undefined =
    store.getters[setting_types.GETTER_RECOMMEND_OFFER_LIST];
  if (!recommendOfferList) {
    return null;
  }
  const canRecommendOfferList: RecommendOffer[] = recommendOfferList.filter(
    (v: any) => v.kind === OfferKind.coin || v.kind === OfferKind.follow
  );
  const has_enter_recommend: boolean = await nativeStorage.has(
    HAS_ENTER_RECOMMEND
  );
  if (!has_enter_recommend) {
    nativeStorage.set(HAS_ENTER_RECOMMEND, true);
    return canRecommendOfferList[0];
  }
  const luaunchOfferList: RecommendOffer[] = canRecommendOfferList.filter(
    (item: any) => {
      return item.green_light && item.green_light.hasOwnProperty("weight");
    }
  );

  let totalWeight = 0;
  for (const j of luaunchOfferList) {
    j.minWeight = totalWeight;
    totalWeight += j.green_light.weight;
    j.maxWeight = totalWeight;
  }
  const num: number = randomNum(0, totalWeight);
  return luaunchOfferList.find((item: any) => {
    return item.minWeight <= num && item.maxWeight > num;
  });
};

export const judgeSuperPayShow = async (
  currentId: string | null
): Promise<boolean> => {
  if (currentId) {
    const super_pay: App = SuperPayCache.getAppsData(AppsCategory.super_pay);
    const packageName = super_pay && super_pay.packageName;
    const super_pay_has_installed: boolean = await judgeAppInstalled(
      packageName
    );
    const is_enable_show_super_pay: boolean = judgeFeatureEvent(
      FeatureFunctionItem.super_pay
    );
    const result: boolean =
      super_pay && !super_pay_has_installed && is_enable_show_super_pay;
    return Promise.resolve(result);
  } else {
    SuperPayCache.getOnlineApps();
    return Promise.resolve(false);
  }
};

/**
 * superpay是否安装过
 * @returns
 */
export const judgedSuperPayInstalled = async () => {
  try {
    const super_pay: App = SuperPayCache.getAppsData(AppsCategory.super_pay);
    const packageName = super_pay && super_pay.packageName;
    const super_pay_has_installed: boolean = await judgeAppInstalled(
      packageName
    );
    return super_pay_has_installed;
  } catch (error) {
    return false;
  }
};

export const showRecommendOffer = (offer: RecommendOffer) => {
  const { discount, effect, kind } = offer;
  const price = getIapPrice(offer);
  Vue.prototype.$_PopUpDialog.show({
    discount,
    effect,
    kind,
    offer,
    price,
    show: true
  });
};
