import { Feed } from "../types/type";
import { act_users_action } from "@/services/earn_coins/observer/act_observer";

/**
 * 限制逻辑的计数
 * @param user_id
 * @param feed
 */
export const count_limit_feeds = (user_id: string, feed: Feed) => {
  const { kind } = feed;
  act_users_action.trigger_limit_count(user_id, kind);
};
