import actions from "@/store/actions/tt_user_action";
import mutations from "@/store/mutations/tt_user_mutation";
import getters from "@/store/getters/tt_user_getters";

export interface TikUserInfo {
  user_id: string;
  login_type: "login" | "search";
  tt_user_info: {
    profile_pic_url: string;
    avatar_thumb: string;
    avatar_larger: string;
    nickname: string;
    uniqueId: string;
    follower_count: number;
    following_count: number;
    video_count: number;
    secret: boolean;
  };
}
export interface UpdateTikUserInfo {
  userInfo: TikUserInfo;
  isDelete?: boolean | undefined;
}

interface TikUserInfoList {
  tt_user_info_list: TikUserInfo[];
}

export const state: () => TikUserInfoList = () => ({
  tt_user_info_list: []
});

export default {
  state,
  actions,
  mutations,
  getters
};
