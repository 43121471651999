import JsBridge from "@/jsbridge";
import forceState from "@/services/super_pay/forceState.ts";
import InstalledAppList from "@/services/super_pay/installedAppList";

export const getInstalledList = async () => {
  try {
    let all_bundle_ids;
    if (forceState.get() === "force" || InstalledAppList.get().length === 0) {
      forceState.set("cache");
      all_bundle_ids = await JsBridge.FetchInstalledApps();
      InstalledAppList.set(all_bundle_ids);
    } else {
      all_bundle_ids = InstalledAppList.get();
    }
    return all_bundle_ids;
  } catch (e) {
    return [];
  }
};

export const judgeAppInstalled = async (packageName: string) => {
  try {
    const installAppList: any[] = await getInstalledList();
    console.log("installAppList", installAppList);
    return installAppList.includes(packageName);
  } catch (e) {
    return false;
  }
};
