import { PayWay } from "@/services/payment/types";
import { store } from "@/main";
import * as cg_user_types from "@/store/constants/cg_user_types";
import * as types from "@/store/constants/index_types";
import * as setting_types from "@/store/constants/setting_types";
import {
  isDoubleCoins,
  isPostKindOffer,
  isSubsOffer,
  isVip
} from "@/utils/general_utils";
import { formatToThousands, removeNoSenseKeys } from "@/utils/lodash";
import { getIapPrice } from "@/plugins/filters/offer_filter";
import { GETTER_CURRENT_TIKTOK_USER_INFO } from "@/store/constants/tt_user_types";
import TiktokUserMediaUtil from "@/utils/tik_user_media/tik_user_media";

type OfferHandle = (
  offer: Offer,
  payWay?: PayWay,
  property?: OfferProperty
) => any;

export interface DefaultPaymentParams {
  offer: Offer;
  resource?: any;
}

export interface PaymentWithMethodParams extends DefaultPaymentParams {
  pay_way: PayWay;
}

export interface PaymentWithSituationParams extends DefaultPaymentParams {
  situation?: OfferSituation;
  custom_id?: string; // random uuid
}

export interface SkuClickOfferEventParams extends PaymentWithSituationParams {
  result: SkuClickCategory;
}

export interface OfferItem {
  price: string;
  effect: number;
  product_id?: string;
  button_id?: string;
}

export interface Offer {
  effect: number;
  offer_id: string;
  way: string;
  kind: OfferKind;
  coins?: number;
  subscription: {
    period_type: string;
    period: number;
  };
  pay_data?: {
    iap: OfferItem;
    paypal?: OfferItem;
    card?: OfferItem;
    super_pay?: OfferItem;
  };
  discount?: number;
  is_no_auto_renew?: boolean;
  lucky_effect?: {
    min: number;
    max: number;
  };
  free_days?: number;
  finished?: string;
  pay_way?: PayWay;
}

export interface RecommendOffer extends Offer {
  minWeight: number;
  maxWeight: number;
  green_light: {
    weight: number;
  };
}

export interface Resource {
  user_id: string;
  user_coin: number;
  username?: string;
  full_name?: string;
  profile_pic_url?: string;
  follower_count?: string;
  media_id?: string;
  media_type?: number;
  image_thumb?: string;
  image_low?: string;
  image_high?: string;
  user_type?: string;
  shortcode?: string;
  initial_count?: string;
}

export enum OfferKind {
  follow = "follow",
  special_follow = "special_follow",
  golden_follow = "golden_follow",
  like = "like",
  special_like = "special_like",
  coin = "coin",
  feed_coin = "feed_coin",
  lucky_coin = "lucky_coin",
  special_coin = "special_coin",
  limited_coin = "limited_coin",
  vip = "vip"
}

export enum OfferSituation {
  default = "default",
  launch = "launch",
  launch_double = "double_activity",
  store_double = "double",
  feed_coin = "feed_coin",
  feed_lucky_coin = "feed_lucky_coin"
}

export enum SkuClickCategory {
  login_required = "login_required",
  private_user = "private_user",
  need_post = "need_post",
  alert_recharge = "alert_recharge",
  alert_free_coins = "alert_free_coins",
  alert_cancel = "alert_cancel",
  confirm_cancel = "confirm_cancel",
  ok = "ok",
  apple_iap = "iap",
  payment_choice = "payment_choice",
  private_post = "private_post"
}

export enum OfferWay {
  iap = "iap",
  coin = "coin"
}

export enum OfferProperty {
  effect = "effect",
  product_id = "product_id",
  price = "price"
}

const GoodsMap: any = {
  follow: "follower_count",
  like: "digg_count",
  special_like: "digg_count",
  view: "view_count",
  comment: "comment_count"
};

export const handleOffer: OfferHandle = (
  offer: Offer,
  payWay: PayWay = PayWay.iap
) => {
  try {
    // ins的post或者video信息
    const currentInsMediaInfo = TiktokUserMediaUtil.getCurrentPost();
    // 用户信息
    const user_info = store.getters[GETTER_CURRENT_TIKTOK_USER_INFO]
      ? store.getters[GETTER_CURRENT_TIKTOK_USER_INFO].tt_user_info
      : null;

    const pic_url = user_info ? user_info.profile_pic_url : "";
    const user_name = user_info ? user_info.uniqueId : "";

    const cal_result = calcPriceAndEffect(offer, payWay);

    let result: any = {
      offer,
      userName: user_name,
      pic_url,
      ...cal_result
    };
    if (isSubsOffer(offer)) {
      const unit_result = calcUnit(offer, cal_result);
      result = {
        ...result,
        ...unit_result
      };
    }
    switch (result.offer_kind) {
      case OfferKind.follow: {
        return {
          ...result,
          pic_type: "circle",
          type_name: "Followers",
          type_icon: "icon-app_follower"
        };
      }
      case OfferKind.special_follow: {
        return {
          ...result,
          pic_type: "circle",
          type_name: "Followers",
          type_icon: "icon-app_follower"
        };
      }
      case OfferKind.golden_follow: {
        return {
          ...result,
          pic_type: "circle",
          type_name: "Golden Followers",
          type_icon: "icon-app_golden"
        };
      }
      case OfferKind.like: {
        return {
          ...result,
          pic_url: currentInsMediaInfo && currentInsMediaInfo.image_thumb,
          pic_type: "cube",
          type_name: "Likes",
          type_icon: "icon-app_like"
        };
      }
      case OfferKind.special_like: {
        return {
          ...result,
          pic_url: currentInsMediaInfo && currentInsMediaInfo.image_thumb,
          pic_type: "cube",
          type_name: "Likes",
          type_icon: "icon-app_like"
        };
      }
      case OfferKind.coin: {
        return {
          ...result,
          pic_type: "circle",
          type_name: "Coins",
          type_icon: require("@/assets/icons-file/sku/coins.png")
        };
      }
      case OfferKind.feed_coin: {
        return {
          ...result,
          pic_type: "circle",
          type_name: "Coins",
          type_icon: require("@/assets/icons-file/sku/coins.png")
        };
      }
      case OfferKind.lucky_coin: {
        const { lucky_effect } = offer;
        lucky_effect && lucky_effect.min
          ? (result.typeEffect =
              lucky_effect.min + "~" + formatToThousands(lucky_effect.max))
          : void 0;
        return {
          ...result,
          pic_type: "circle",
          type_name: "Lucky Coins",
          type_icon: require("@/assets/icons-file/sku/coins.png")
        };
      }
      case OfferKind.special_coin: {
        return {
          ...result,
          pic_type: "circle",
          type_name: "Coins",
          type_icon: require("@/assets/icons-file/sku/coins.png")
        };
      }
      case OfferKind.limited_coin: {
        return {
          ...result,
          pic_type: "circle",
          type_name: "Coins",
          type_icon: require("@/assets/icons-file/sku/coins.png")
        };
      }
      case OfferKind.vip: {
        // Vip固定的单位是Month:
        return {
          ...result,
          pic_type: "circle",
          type_name: "VIP",
          type_icon: require("@/assets/icons-file/payment/lh_app_vip_crown.png"),
          unit: offer.effect > 1 ? "Months" : "Month"
        };
      }
      default:
        break;
    }
  } catch (e) {
    console.log("handle offer error", {
      e,
      offer,
      payWay
    });
  }
};

export const calcPriceAndEffect = (
  offer: Offer,
  payWay: PayWay = PayWay.iap
) => {
  const offer_kind: OfferKind = offer.kind; // 类型
  const discount = offer.discount ? offer.discount : 0; // 折扣数
  // double活动倍数
  const doubleCoinMultiple = Math.floor(
    store.getters[setting_types.GETTER_DOUBLE_COINS_PERCENTAGE] / 100
  );
  let total_price = getIapPrice(offer)
    ? parseFloat(getIapPrice(offer).substring(1))
    : 0;
  let reduced_price: number = 0;
  let original_price: number = 0;
  let reward_effect: number = 0;
  let with_vip_reward_effect: number = 0;
  const base_price = total_price;
  const effect =
    isDoubleCoins() && offer_kind === OfferKind.coin
      ? offer.effect * doubleCoinMultiple
      : offer.effect;
  let typeEffect: number = 0;
  if (offer.pay_data) {
    const pay_way: PayWay = payWay === PayWay.back_up ? PayWay.iap : payWay;
    total_price = parseFloat(getInfoFromOffer(offer, pay_way).substring(1));
    typeEffect = getInfoFromOffer(offer, pay_way, OfferProperty.effect);
    reduced_price = base_price - total_price;
    original_price =
      Math.floor((total_price / (1 - discount / 100)) * 100) / 100;
    reward_effect =
      (isDoubleCoins() && offer_kind === OfferKind.coin
        ? typeEffect * doubleCoinMultiple
        : typeEffect) - effect;
  }
  // 增加vip的奖励判断
  if (!isCoins(offer_kind) && offer_kind !== OfferKind.vip && isVip()) {
    with_vip_reward_effect =
      typeEffect + Math.floor((typeEffect + reward_effect) * 0.1);
  } else {
    with_vip_reward_effect = typeEffect;
  }
  // double coins的处理
  if (isDoubleCoins() && offer_kind === OfferKind.coin) {
    with_vip_reward_effect = typeEffect = typeEffect * doubleCoinMultiple;
  }
  return {
    reduced_price,
    original_price,
    reward_effect,
    with_vip_reward_effect,
    total_price,
    offer_kind,
    effect,
    typeEffect
  };
};

// 计算订阅单位
const calcUnit = (offer: Offer, cal_result: any) => {
  const subscription = offer.subscription ? offer.subscription : ({} as any);
  const { total_price, effect } = cal_result;
  let unit: string; // 订阅的单位
  let unit_price: any; // 单位下的价格，用于订阅
  // 首字母大写：
  unit =
    subscription.period_type.substring(0, 1).toUpperCase() +
    subscription.period_type.substring(1);
  // 添加末尾的s
  if (subscription.period > 1) {
    unit = unit + "s";
  }
  unit_price = (total_price / effect).toFixed(2);
  return { unit, unit_price };
};

export const getInfoFromOffer: OfferHandle = (
  offer: Offer,
  payWay: PayWay = PayWay.iap,
  property: OfferProperty = OfferProperty.price
): string | undefined => {
  try {
    const pay_data: any = offer.pay_data ? offer.pay_data : {};
    payWay =
      payWay === PayWay.back_up || payWay === PayWay.service_back_up
        ? PayWay.iap
        : payWay;
    const item: OfferItem = pay_data[payWay];
    return (item as any)[property] as string;
  } catch (e) {
    return "";
  }
};

export const getResourceFromOffer: OfferHandle = (offer: Offer) => {
  const user_coin = store.getters[cg_user_types.GETTER_COINS]; // 用户coins数量
  const master_id = store.getters[types.GETTER_CURRENT_USER_ID];
  const resource: Resource = {
    user_coin,
    user_id: master_id
  };
  const { kind } = offer;
  const tiktokUser = store.getters[GETTER_CURRENT_TIKTOK_USER_INFO];
  const user_info = tiktokUser ? tiktokUser.tt_user_info : null;
  // console.log("user_info", user_info);
  if (!isCoins(kind) && kind !== OfferKind.vip) {
    const { uniqueId, nickname, profile_pic_url, follower_count } = user_info;
    resource.user_id = store.getters[types.GETTER_CURRENT_USER_ID]
      ? store.getters[types.GETTER_CURRENT_USER_ID]
      : store.getters[types.GETTER_MASTER_USER_ID];
    resource.username = uniqueId;
    resource.user_type = tiktokUser.login_type;
    resource.full_name = nickname;
    resource.profile_pic_url = profile_pic_url;
    resource.follower_count = follower_count;
    const goods_kind = GoodsMap[kind] ? GoodsMap[kind] : "follower_count";
    if (isPostKindOffer(offer)) {
      const postInfo = TiktokUserMediaUtil.getCurrentPost();
      console.log("postInfo", postInfo);
      resource.media_id = postInfo.id;
      resource.media_type = 2;
      resource.image_thumb = postInfo.image_thumb;
      resource.image_low = postInfo.image_low;
      resource.image_high = postInfo.image_high;
      resource.initial_count = (postInfo && postInfo[goods_kind]) || 0;
    } else {
      resource.image_thumb = user_info && user_info.avatar_thumb;
      resource.image_low = user_info && user_info.profile_pic_url;
      resource.image_high = user_info && user_info.avatar_larger;
      resource.initial_count = user_info[goods_kind] || 0;
    }
  }
  // console.log("resource", resource);
  return removeNoSenseKeys(resource);
};

export const isCoins = (kind: OfferKind) => {
  return (
    kind === OfferKind.lucky_coin ||
    kind === OfferKind.limited_coin ||
    kind === OfferKind.special_coin ||
    kind === OfferKind.feed_coin ||
    kind === OfferKind.coin
  );
};

export const isFollow = (kind: OfferKind) => {
  return (
    kind === OfferKind.follow ||
    kind === OfferKind.special_follow ||
    kind === OfferKind.golden_follow
  );
};

export const getPaymentPic = (offer: Offer, resource: any) => {
  return isPostKindOffer(offer) ? resource.image_low : resource.profile_pic_url;
};
