import { LimitCoinsCache } from "./types";
import { LIMIT_COINS_CACHE } from "@/utils/nativeStorage/local_keys";
import { CacheModule } from "@/services/cacheModule";

class LimitCoinsCacheModule extends CacheModule {
  constructor() {
    super(LIMIT_COINS_CACHE, {
      limitCoinsStartShowTime: 0,
      limitCoinsPurchaseTime: 0
    } as LimitCoinsCache);
  }

  getStartShowTime() {
    return this.get().limitCoinsStartShowTime;
  }

  setTime(cache: LimitCoinsCache) {
    this.setCache({
      ...this.get(),
      ...cache
    });
  }

  getPurchaseTime() {
    return this.get().limitCoinsPurchaseTime;
  }
}
export default new LimitCoinsCacheModule();
