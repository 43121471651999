import { Component, Prop, Vue } from "vue-property-decorator";
import { store } from "@/main";
import { GETTER_CURRENT_TIKTOK_USER_INFO } from "@/store/constants/tt_user_types";
import TiktokUserMediaUtil from "@/utils/tik_user_media/tik_user_media";
@Component
class ConfirmOrderHeader extends Vue {
  @Prop()
  private ctx: any;
  @Prop()
  private offer: any;

  private imgStyle = "width:100%;height:100%;object-fit:cover;";
  private imgFollowStyle = this.imgStyle + "border-radius:50%;";
  private imgLikeStyle = this.imgStyle + "border-radius:8px;";
  private divStyle =
    "width:68px;height:68px;box-sizing:content-box;background:rgb(233,236,240);padding:16px;margin:0 auto;";
  private followStyle = this.divStyle + "border-radius:50%;";
  private postStyle = this.divStyle + "border-radius:24px;";

  get isFollow() {
    return this.offer.kind.includes("follow");
  }
  public render() {
    return (
      <div style={this.isFollow ? this.followStyle : this.postStyle}>
        <img
          style={this.isFollow ? this.imgFollowStyle : this.imgLikeStyle}
          src={this.getCurrentPost()}
          alt=""
        />
      </div>
    );
  }
  private getCurrentPost() {
    return this.offer.kind.includes("follow")
      ? store.getters[GETTER_CURRENT_TIKTOK_USER_INFO].tt_user_info
          .profile_pic_url
      : TiktokUserMediaUtil.getCurrentPost().image_thumb;
  }
}

export default ConfirmOrderHeader;
