import { Vue } from "@/main";
import { ErrorTypes, PayWay, PurchaseError } from "@/services/payment/types";
import { isIos } from "@/utils/lodash";
import paymentCall from "@/services/payment/paymentCall";
import loading from "@/plugins/toast/loading";
import { isNativePayTypes } from "@/services/payment/utils";

// 错误处理
export const purchaseErrorHandle = (
  e: PurchaseError,
  pay_way: PayWay = PayWay.iap,
  silent?: boolean
) => {
  console.log("purchaseErrorHandle", e);
  loading.hide();
  /*
   * 1、如果是后台的错误，直接提示；
   * 2、如果是原生抛出的网络层面的问题，并且是iap/sp/ba，需要retry；
   * 3、如果是payment_cancelled，不处理；
   * 4、其他原生错误，提示；
   * */
  if (e.hasOwnProperty("meta")) {
    const { meta } = e;
    const { error_message, code } = meta;
    console.log("error_message", error_message);
    if (code === 24001 && isNativePayTypes(pay_way)) {
      paymentCall.handleRetryFailed(silent);
    } else {
      Vue.prototype.$_Toast.show({
        duration: 3000,
        type: "fail",
        text: error_message
      });
    }
  } else {
    const { code, domain, message, type, needRetry } = e;
    const isCancel = (isIos() && code === 2) || (!isIos() && code === 1);
    console.log("isCancel", isCancel, e);
    if (type === ErrorTypes.native && isCancel) {
      // user cancel 不处理
    } else if (
      (type === ErrorTypes.service && isNativePayTypes(pay_way)) ||
      needRetry
    ) {
      // retry流程
      paymentCall.enterRetryPage();
    } else {
      message &&
        Vue.prototype.$_Toast.show({
          duration: 3000,
          type: "fail",
          text: pay_way === PayWay.coin ? "Network error." : message
        });
    }
  }
};
