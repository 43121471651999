/*
 * @Author: jhonsem
 * @Date: 2021-01-19 15:49:53
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-01-31 18:22:48
 */

import {
  sendInterfaceSuccess,
  sendTTInterfaceError
} from "@/utils/log_event/api_event";
import { loginRequireDialog } from "@/services/dialog_utils/index";
import { act_users_mutation } from "@/services/earn_coins/observer/act_observer";

export const tt_response_event_track_interceptor = (
  interceptor_type: "resolve" | "reject"
) => {
  // 请求返回的打点:

  return (result: any) => {
    const { config, response } = result;

    const api_name = config && config.api_name;
    //
    console.log("tt_response_event_track_interceptor:", result);

    if (interceptor_type === "resolve") {
      api_name && sendInterfaceSuccess(api_name);
      return result;
    } else {
      api_name && sendTTInterfaceError(api_name, response);
      return Promise.reject(result);
    }
  };
};

/**
 * 拦截部分错误类型
 * @param error
 */
export const tt_response_error_interceptor = (error: any) => {
  const { config, response } = error;
  console.log("tt_response_error_interceptor:", error);
  if (response && response.error_type && config && config.api_type) {
    const { error_type } = response;
    const { api_type } = config;
    if (
      (error_type === "login_required" || error_type === "verify_required") &&
      api_type === "logged_in"
    ) {
      // 需要重新登陆: 1.弹窗 2.设置字段
      console.log("需要重新登陆");
      // dialog
      loginRequireDialog("account_expired");
      // set is_login_required status
      act_users_mutation.update_act_user_map({
        is_login_required: true
      });
    }
  }

  return Promise.reject(error);
};
