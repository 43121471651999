import { render, staticRenderFns } from "./SearchUser.vue?vue&type=template&id=bd6e2590&scoped=true&"
import script from "./SearchUser.vue?vue&type=script&lang=tsx&"
export * from "./SearchUser.vue?vue&type=script&lang=tsx&"
import style0 from "./SearchUser.vue?vue&type=style&index=0&id=bd6e2590&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bd6e2590",
  null
  
)

export default component.exports