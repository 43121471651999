import actions from "@/store/actions/cg_user_actions";
import mutations from "@/store/mutations/cg_user_mutations";
import getters from "@/store/getters/cg_user_getters";

export default {
  state: {
    coins: 0,
    connections: [], // 子账号 punish_expire, punish_level, user_id
    server_current_time: null, // 服务器时间
    paypal_enabled: false,
    stripe_enabled: false,
    vip_p_valid_count: null, // vipPackage可购买数量
    vip_p_valid_time: null // vipPackage有效期
  },
  actions,
  mutations,
  getters
};
