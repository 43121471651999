import {
  getDeviceInfo,
  getIDFA,
  getIDFV,
  getAndroidId,
  getAndroidGaid,
  getDeviceId
} from "@/utils/js_bridge_info/index";
import { isIos } from "@/utils/lodash";
import PaymentUtil from "@/services/payment/paymentCache";

export const requestDefaultParams = async () => {
  const deviceInfo = getDeviceInfo();
  const { app_platform, info, locale, device } = deviceInfo;
  const { short_version, identifier } = info;
  const { country_code } = locale;
  const { system_version } = device;
  const defaultParams: any = {
    app_name: app_platform,
    app_version: short_version,
    bundle_id: identifier,
    country_code,
    customer_user_id: getDeviceId(),
    os_version: system_version,
    time: getNowTime()
  };
  if (isIos()) {
    defaultParams.platform = "ios";
    defaultParams.idfv = getIDFV();
    getIDFA() && (defaultParams.idfa = getIDFA());
  } else {
    defaultParams.platform = "android";
    defaultParams.android_id = getAndroidId();
    defaultParams.gaid = getAndroidGaid();
  }
  if (PaymentUtil.getFistLaunchTime()) {
    defaultParams.init_time = ISOTime(PaymentUtil.getFistLaunchTime());
  }
  return defaultParams;
};

const getNowTime = () => {
  return new Date().toISOString();
};

const ISOTime = (timeStamp: number) => {
  return new Date(timeStamp).toISOString();
};
