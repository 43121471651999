import { Offer } from "@/services/offer/offer_handle";
import Jsbridge from "@/jsbridge/index";
import { ErrorTypes, PayWay } from "@/services/payment/types";
import { getIapProductId } from "@/plugins/filters/offer_filter";
import { isSubsOffer } from "@/utils/general_utils";
import { Vue } from "@/main";

// 基础的iap/google支付
export const iapPurchase = async (offer: Offer, receipt_data?: string) => {
  try {
    const product_id: string | undefined = getIapProductId(offer);
    if (!product_id) {
      Vue.prototype.$_Toast.show({
        duration: 3000,
        text: `Offer invalid`
      });
      return;
    }
    const is_sub: boolean = isSubsOffer(offer);
    const purchaseResult: any = await Jsbridge.iapPurchase(
      product_id,
      is_sub,
      receipt_data
    );
    console.log("purchaseResult", purchaseResult);
    return Promise.resolve(purchaseResult);
  } catch (e) {
    return Promise.reject({ ...e, type: ErrorTypes.native });
  }
};
