import cgRequest from "@/api/cg/base";
import * as cgConst from "@/api/cg/base/cg_const";
import { getUUid, creatUUid } from "@/api/utils/api_helpers";

/**
 * 获取feeds
 * @param userId
 * @param effectUserSize
 */
export const getFeeds = async (
  userId: string,
  user_ids: string[],
  effectUserSize: number = 1
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const uid: any = userId ? userId : await getUUid();
      const url = cgConst.GET_FEED(uid);
      const params: any = {
        kind: "all",
        need_all: true,
        allow_all_kind: true,
        active_account_count: effectUserSize,
        follow_account_count: effectUserSize,
        like_account_count: effectUserSize,
        comment_account_count: effectUserSize,
        auto_mode: false,
        user_ids
      };
      const result = await cgRequest(url, "GET", params);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * act feeds
 * @param masterId
 * @param userId
 * @param status
 * @param message
 * @param feedKind
 * @param resourceId
 * @param isSilent
 */
export const actFeed = async (
  masterId: string,
  userId: string,
  status: string,
  message: string,
  feedKind: string,
  resourceId: string,
  isSilent: boolean = false
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = cgConst.ACT_FEED(resourceId);
      const userInfo = {
        master_id: masterId,
        user_id: userId
      };
      const params: any = {
        user_info: userInfo,
        result_body: { status, message },
        kind: feedKind,
        resource_id: resourceId,
        transaction_id: creatUUid(),
        is_silent: isSilent
      };
      const result = await cgRequest(url, "POST", params);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
