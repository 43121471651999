






import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch
} from "vue-property-decorator";
import TTLoading from "@/components/LoadingAnimation/TTLoading.vue";
@Component({
  components: {
    TTLoading
  }
})
export default class RenderPlaceholder extends Vue {}
