import { PAYMENT_PARAMS } from "@/utils/nativeStorage/local_keys";
import { PaymentCacheParams, PayWay } from "@/services/payment/types";
import { CacheModule } from "@/services/cacheModule";

class PaymentUtil extends CacheModule {
  constructor() {
    const defaultParams: PaymentCacheParams = {
      app_launch_time: 0, // app启动次数
      iap_purchase_time: 0, // iap购买次数
      paypal_purchase_time: 0, // paypal购买次数
      card_purchase_time: 0, // 信用卡购买次数
      first_enter_timestamp: 0, // 首次启动的时间戳
      lifecycle_enter_timestamp: 0 // 当前生命周期启动的时间戳
    };
    super(PAYMENT_PARAMS, defaultParams);
  }

  public async launchExecute() {
    let {
      app_launch_time,
      first_enter_timestamp,
      lifecycle_enter_timestamp
    } = this.get();
    app_launch_time++;
    lifecycle_enter_timestamp = new Date().getTime();
    if (!first_enter_timestamp) {
      first_enter_timestamp = new Date().getTime();
    }
    this.setPaymentParams({
      app_launch_time,
      lifecycle_enter_timestamp,
      first_enter_timestamp
    });
  }

  public getPaymentParams(params?: string) {
    const paymentParams = this.get();
    return params ? paymentParams[params] : paymentParams;
  }
  public updatePaymentTimes(pay_way: PayWay) {
    let {
      iap_purchase_time,
      paypal_purchase_time,
      card_purchase_time
    } = this.get();
    if (
      pay_way === PayWay.iap ||
      pay_way === PayWay.super_pay ||
      pay_way === PayWay.back_up
    ) {
      iap_purchase_time++;
      this.setPaymentParams({ iap_purchase_time });
    } else if (pay_way === PayWay.paypal) {
      paypal_purchase_time++;
      this.setPaymentParams({ paypal_purchase_time });
    } else if (pay_way === PayWay.card) {
      card_purchase_time++;
      this.setPaymentParams({ card_purchase_time });
    }
  }

  public isFirstLaunch() {
    return this.getPaymentParams("app_launch_time") <= 1;
  }

  public existLaunchParams() {
    return this.getPaymentParams("app_launch_time") !== 0;
  }

  public getFistLaunchTime() {
    return this.getPaymentParams("first_enter_timestamp");
  }

  public getCurrentLaunchTime() {
    return this.getPaymentParams("lifecycle_enter_timestamp");
  }

  private setPaymentParams(params: any) {
    const paymentParams = this.get();
    Object.keys(params).forEach((key: string) => {
      paymentParams[key] = params[key];
    });
    this.setCache(paymentParams);
  }
}
export default new PaymentUtil();
