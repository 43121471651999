/*
 * @Author: Jhonsem
 * @Date: 2021-01-06 10:35:20
 * @Last Modified by: jhonsem
 * @Last Modified time: 2021-01-20 18:04:24
 * storage存储用于原生提供的nativeStorage接口
 */

import VuexPersistence from "vuex-persist";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { AsyncStorage } from "vuex-persist/dist/types/AsyncStorage";

const PERSIST_MODULE: string[] = [
  "setting_modules",
  "cg_user_modules",
  "tt_user_module",
  "index_module",
  "tt_media_module"
];

/**
 * 在native_storage中实现了localstorage的所有方法
 */
export default new VuexPersistence({
  storage: nativeStorage as AsyncStorage,
  strictMode: false,
  asyncStorage: true,
  modules: PERSIST_MODULE
});
