import Vue from "vue";
import { ObservableAct } from "../types/type";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { ACT_USERS_LIST } from "@/utils/nativeStorage/local_keys/index";
import {
  FOLLOWER_FEED,
  LIKE_FEED
} from "./../earn_coins_consts/earn_coins_consts";
import { store } from "@/main";
import { GEETER_TIKTOK_LOGIN_USER } from "@/store/constants/tt_user_types";
import { GETTER_CLIENT_SETTINGS } from "@/store/constants/setting_types";

const observable_act_user: ObservableAct = Vue.observable({
  user_id: "",
  follow_count: 0,
  follow_start_time_stamp: 0,
  like_count: 0,
  like_start_time_stamp: 0,
  is_act_limited: false,
  is_login_required: false,
  end_limit_time_stamp: 0
});

// ----------------------------- getters -----------------------------
const act_users_getters = {
  act_user() {
    return observable_act_user;
  },
  get_limit_config() {
    const { earn_limit } = store.getters[GETTER_CLIENT_SETTINGS];
    const {
      window_follow_limit,
      window_like_limit,
      ratelimit_followerwindow_size,
      ratelimit_slidingwindow_size
    } = earn_limit.level_1;
    return {
      follow_limit_count: window_follow_limit,
      like_limit_count: window_like_limit,
      follow_limit_period_time: ratelimit_followerwindow_size * 1000,
      like_limit_period_time: ratelimit_slidingwindow_size * 1000
    };
  },
  is_limit() {
    const act_user: any = this.act_user();
    return act_user && act_user.is_act_limited;
  },
  is_login_required() {
    const act_user: any = this.act_user();
    return act_user && act_user.is_login_required;
  },
  end_limit_time_stamp() {
    const act_user: any = this.act_user();
    return act_user && act_user.end_limit_time_stamp;
  }
};

// ----------------------------- action -----------------------------
const act_users_action = {
  async get_local_act_users() {
    const act_users = await nativeStorage.get(ACT_USERS_LIST);
    return act_users;
  },

  async act_users_restore() {
    // 从本地取出放回内存：
    const act_users = await this.get_local_act_users();
    // 更新到内存
    const tiktokLoginUser = store.getters[GEETER_TIKTOK_LOGIN_USER];
    if (tiktokLoginUser && act_users && act_users[tiktokLoginUser.user_id]) {
      const act_user = act_users[tiktokLoginUser.user_id];
      act_users_mutation.update_act_user_map(act_user);
    }
  },

  async act_add_new_users_action(user_id: string | number) {
    // 先从本地找
    const act_users = await this.get_local_act_users();
    if (act_users && act_users[user_id]) {
      // 已经存在本地缓存的用户,此时is_login_required需要reset
      act_users[user_id].is_login_required = false;
      act_users_mutation.update_act_user_map(act_users[user_id]);
    } else {
      act_users_mutation.set_new_act_user(user_id);
    }

    // 存储到本地
    this.refresh_local_save();
  },
  async refresh_local_save() {
    const act_users = await this.get_local_act_users();
    if (act_users) {
      act_users[observable_act_user.user_id] = observable_act_user;
      nativeStorage.set(ACT_USERS_LIST, act_users);
    } else {
      const new_list: any = {};
      new_list[observable_act_user.user_id] = observable_act_user;
      nativeStorage.set(ACT_USERS_LIST, new_list);
    }
  },
  check_is_reach_limit(user_id: string | number, kind: string) {
    const {
      follow_limit_count,
      like_limit_count,
      follow_limit_period_time,
      like_limit_period_time
    } = act_users_getters.get_limit_config();

    const now_time_stamp = new Date().valueOf();

    if (kind === FOLLOWER_FEED) {
      // follow
      if (observable_act_user.follow_start_time_stamp === 0) {
        observable_act_user.follow_start_time_stamp = now_time_stamp;
      }
      if (
        now_time_stamp - observable_act_user.follow_start_time_stamp >
        follow_limit_period_time
      ) {
        // reset
        act_users_mutation.reset_user_act_count(user_id, kind);
      }
      //
      if (
        observable_act_user.follow_count >= follow_limit_count &&
        now_time_stamp - observable_act_user.follow_start_time_stamp <=
          follow_limit_period_time
      ) {
        // need limit
        observable_act_user.end_limit_time_stamp =
          follow_limit_period_time +
          observable_act_user.follow_start_time_stamp;
        // limit
        observable_act_user.is_act_limited = true;
      }
    } else if (kind === LIKE_FEED) {
      // like
      if (observable_act_user.like_start_time_stamp === 0) {
        observable_act_user.like_start_time_stamp = now_time_stamp;
      }
      if (
        now_time_stamp - observable_act_user.like_start_time_stamp >
        like_limit_period_time
      ) {
        // reset
        act_users_mutation.reset_user_act_count(user_id, kind);
      }
      if (
        observable_act_user.like_count >= like_limit_count &&
        now_time_stamp - observable_act_user.like_start_time_stamp <=
          like_limit_period_time
      ) {
        // need limit
        //
        observable_act_user.end_limit_time_stamp =
          like_limit_period_time + observable_act_user.like_start_time_stamp;
        // limit
        observable_act_user.is_act_limited = true;
      }
    }
    console.log("observable_act_user:", observable_act_user);
  },
  trigger_limit_count(user_id: string | number, kind: string) {
    if (kind === FOLLOWER_FEED) {
      // follow
      observable_act_user.follow_count += 1;
    } else if (kind === LIKE_FEED) {
      // like
      observable_act_user.like_count += 1;
    }

    this.check_is_reach_limit(user_id, kind);
    // 存储到本地
    this.refresh_local_save();
  }
};

// ----------------------------- mutation -----------------------------
const act_users_mutation = {
  reset_user_act_count(user_id: string | number, kind: string) {
    if (kind === FOLLOWER_FEED) {
      // follow
      observable_act_user.follow_count = 0;
      observable_act_user.follow_start_time_stamp = 0;
    } else if (kind === LIKE_FEED) {
      // like
      observable_act_user.like_count = 0;
      observable_act_user.like_start_time_stamp = 0;
    }
  },
  clear_current_act_user() {
    this.set_new_act_user("");
  },
  set_new_act_user(user_id: string | number) {
    // 设置当前act用户为全新的用户
    const new_act_user: any = {
      user_id,
      follow_count: 0,
      follow_start_time_stamp: 0,
      like_count: 0,
      like_start_time_stamp: 0,
      is_act_limited: false,
      is_login_required: false,
      end_limit_time_stamp: 0
    };
    this.update_act_user_map(new_act_user);
  },
  update_act_user_map(act_user_map: any) {
    for (let key in act_user_map) {
      // 更新当前的值
      (observable_act_user as any)[key] = act_user_map[key];
    }
  }
};

export {
  act_users_getters,
  act_users_mutation,
  act_users_action,
  observable_act_user
};
