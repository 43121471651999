import { HotAppsTypes } from "./types";
import { PINAD_CACHE } from "@/utils/nativeStorage/local_keys";
import { CacheModule } from "@/services/cacheModule";
import { App } from "@/services/super_pay/types";

class PinadModule extends CacheModule {
  constructor() {
    super(PINAD_CACHE, {
      hotApps: [],
      back_up: null
    } as HotAppsTypes);
  }

  addCacheApp(app: any, isHotApps: boolean = true) {
    const result: HotAppsTypes = this.get();
    const { hotApps } = result;
    let { back_up } = result;
    if (isHotApps) {
      const appExisted = hotApps.some(v => v.packageName === app.packageName);
      !appExisted && hotApps.push(app);
    } else {
      back_up = app;
    }
    console.log("addCacheApp", {
      hotApps,
      back_up
    });
    this.setCache({
      hotApps,
      back_up
    });
  }

  getCacheApp(isHotApps: boolean = true) {
    const { hotApps, back_up } = this.get();
    console.log("getCacheApp", {
      hotApps,
      back_up
    });
    return isHotApps ? hotApps : back_up;
  }

  async removeCacheApp(app: any | App, isHotApps: boolean = true) {
    const { hotApps } = this.get();
    let { back_up } = this.get();
    if (isHotApps) {
      const findIndex = hotApps.findIndex(
        (v: any) => v.packageName === app.packageName
      );
      hotApps.splice(findIndex, 1);
    } else {
      back_up = null;
    }
    console.log("removeCacheApp", {
      hotApps,
      back_up
    });
    await this.setCache({
      hotApps,
      back_up
    });
  }
}
export default new PinadModule();
