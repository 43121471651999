class IntervalTimer {
  public timer: any = null;

  public getTimer() {
    return this.timer;
  }
  public setTimer(val: any) {
    this.timer = val;
  }
}

export default new IntervalTimer();
