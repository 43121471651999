






import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch
} from "vue-property-decorator";

@Component({
  components: {}
})
export default class Tag extends Vue {
  @Prop({ default: "normal" })
  private size?: string;
  @Prop()
  private type?: string; // 背景色
}
