




















import { Component, Vue } from "vue-property-decorator";
import { goToFaqs } from "@/utils/router_helper";
import { open_guides } from "@/utils/guides/guides";
import contactUs from "@/jsbridge/service/contact_us";

@Component
export default class MoreEntrance extends Vue {
  get entranceList() {
    return [
      {
        icon: require("@/assets/icons-file/more/image_more_item_order@3x.png"),
        text: "Orders",
        clickFunction: () => {
          this.$router.push({
            name: "Orders",
            query: {
              routerTransition: "forward"
            }
          });
        }
      },
      {
        icon: require("@/assets/icons-file/more/image_more_item_invite@3x.png"),
        text: "FAQ",
        clickFunction: () => {
          goToFaqs();
        }
      },
      {
        icon: require("@/assets/icons-file/more/tab5_icon_help.png"),
        text: "Help",
        clickFunction: () => {
          open_guides();
        }
      },
      {
        icon: require("@/assets/icons-file/more/tab5_icon_contact.png"),
        text: "Contact Us",
        clickFunction: () => {
          contactUs();
        }
      }
    ];
  }
}
