import { logInfo, log, logErr } from "@/utils/console";
import { HttpRequest, HttpRes } from "./types/http.d";
import * as ActionTypes from "./constants/jsbridge_constants";
import { OpenBrowserOption, ListenerOptions } from "./types/index";
import { creatUUid } from "@/api/utils/api_helpers";
import { isObject } from "@/utils/lodash";

const listenerManager: any = {};

class Jsbridge {
  public Jsbridge: any;

  constructor() {
    window && window.HybridLite
      ? (this.Jsbridge = window.HybridLite)
      : this.throwError();
  }

  public throwError() {
    throw new Error("Init Jsbrige failed");
  }

  /**
   * send msg to native
   * @param action
   * @param params
   */
  public async call<T>(action: string, params: T) {
    log("【JS_BRIGE CALL】", {
      action,
      params
    });

    return new Promise((resolve, reject) => {
      this.Jsbridge.callNative(
        (result: any) => {
          resolve(result);
          logInfo("【JS_BRIGE RESPONSE】", {
            action,
            result
          });
        },
        (error: any, response: any) => {
          const containResponse = response && isObject(error);
          reject(containResponse ? { ...error, response } : error);
          logErr("【JS_BRIGE ERROR】", {
            action,
            error,
            response
          });
        },
        action,
        params
      );
    });
  }

  /**
   * addListen from native
   * @param action
   * @param Fn
   */
  public async addListen(
    action: string,
    CallBack: (res: any) => void,
    options?: undefined | ListenerOptions
  ) {
    try {
      log("【JS_BRIGE addListen", {
        action
      });
      let key: string = "";
      if (options && options.key) {
        key = options.key;
      } else {
        key = creatUUid(5, false);
      }

      const task = {
        action,
        callback: (event: any) => {
          console.log("addEventListener Fire:", event);
          const { response } = event;
          CallBack(response);
        }
      };

      if (!(key in listenerManager)) {
        // no exist
        listenerManager[key] = [];
      }

      listenerManager[key].push(task);

      console.log("listenerManager:", listenerManager);

      document.addEventListener(action, task.callback, false);

      return key;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async removeListen(key: string) {
    try {
      log("【JS_BRIGE removeListen", {
        key
      });

      if (key in listenerManager) {
        // exist
        listenerManager[key].forEach((task: any) => {
          document.removeEventListener(task.action, task.callback, false);
        });

        delete listenerManager[key];

        console.log("listenerManager:", listenerManager);
      }
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * Http
   * @param params
   */
  public async http(params: HttpRequest) {
    try {
      console.log("params", params);
      const res = await this.call(ActionTypes.HTTP, params);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  /**
   * 请求取消
   * @param cancleToken
   */
  public async httpAbort(cancleToken: string) {
    try {
      const res = await this.call(ActionTypes.HTTP_ABORT, {
        cancel_token: cancleToken
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  /**
   * hide loading
   */
  public async hideLoading() {
    try {
      const res = await this.call(ActionTypes.HIDE_LAUNCH_LOADING, null);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }
  /**
   * Launch params
   */
  public async launchParams() {
    try {
      const res = await this.call(ActionTypes.LAUNCH_PARAMS, null);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * DeviceInfo msg
   */
  public async deviceInfo() {
    try {
      const res = await this.call(ActionTypes.DEVICE_INFO, null);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * LOCALPATHGET
   * @param isCache // true: web自定义创建  false：默认存在
   * @param listName
   */
  public async localPathGet(isCache: boolean, listName: string[]) {
    try {
      const res: any = await this.call(ActionTypes.LOCAL_PATH_GET, {
        [isCache ? ActionTypes.CACHE_LIST : ActionTypes.BUNDLE_LIST]: listName
      });
      return isCache ? res[ActionTypes.CACHE_RES] : res[ActionTypes.BUNDLE_RES];
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * iap_product 拉取iap本地化以及无效iap
   * */
  public async iapProduct(
    inapp_product_ids: string[],
    subs_product_ids: string[]
  ) {
    try {
      const res = await this.call(ActionTypes.IAP_PRODUCT, {
        inapp_product_ids,
        subs_product_ids
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   *
   * @param path
   */
  public async localFileRead(path: string) {
    try {
      const res = await this.call(ActionTypes.LOCAL_FILE_READ, {
        path
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  // 获取本机已安装过的所有app的BundleId
  public async FetchInstalledApps() {
    try {
      const res: any = await this.call(ActionTypes.FETCH_INSTALLED_APPS, {});
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 打点接口
   * @param types "umeng" | "apps_flyer" | "fb"
   * @param event
   * @param params
   */
  public async logEvent(types: string[], event: string, params: object) {
    try {
      const res = await this.call(ActionTypes.LOG_EVENT, {
        types,
        event,
        attributes: params
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 配置视频
   * @param type
   * @param app_id string, 都传, admob的iOS不用, 因为iOS使用了最新的SDK, 直接读info.plist里的
   * @param placement_id 当为ios时admob专用, 其他不传，当为安卓时，vungle必传，但是需要处理key为placementIds的数组
   */
  public async configVideo(
    type: "app_lovin" | "ad_mob" | "vungle",
    app_id?: string,
    placement_id?: any
  ) {
    try {
      const paramKeys: any = {
        type
      };
      app_id && (paramKeys.app_id = app_id);
      if (typeof placement_id === "string") {
        paramKeys.placement_id = placement_id;
      } else if (Array.isArray(placement_id)) {
        paramKeys.placementIds = placement_id;
      }
      const res = await this.call(ActionTypes.CONFIG_VIDEO, paramKeys);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 查询视频
   * @param type
   */
  public async getVideoReady(
    type: "app_lovin" | "ad_mob" | "vungle",
    placement_id?: string
  ): Promise<any> {
    try {
      const paramKeys: any = {
        type
      };
      if (type === "vungle" && placement_id) {
        paramKeys.placement_id = placement_id;
      }
      const res = await this.call(ActionTypes.GET_VIDEO_READY, paramKeys);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 播放视频
   * @param type
   * @param user_identifier app_lovin和vungle必传, master_id|platform|freecoin或者user_id|platform|casino
   * @param placement_id vungle必传，此时传的是vungle_pid_free
   */
  public async playVideo(
    type: string,
    user_identifier?: string,
    placement_id?: string
  ): Promise<any> {
    try {
      const paramKeys: any = {
        type
      };
      if (type === "app_lovin" && user_identifier) {
        paramKeys.user_identifier = user_identifier;
      } else if (type === "vungle" && user_identifier && placement_id) {
        paramKeys.user_identifier = user_identifier;
        paramKeys.placement_id = placement_id;
      }
      const res = await this.call(ActionTypes.PLAY_VIDEO, paramKeys);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 注册友盟push
   */
  public async registerUmengPush() {
    try {
      const res = await this.call(ActionTypes.REGISTER_UMENG_PUSH, null);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 友盟push的设置接受的id
   * @param alias master_id
   * @param type platform
   */
  public async configUmengPush(alias: string, type: string) {
    try {
      const res = await this.call(ActionTypes.CONFIG_UMENG_PUSH, {
        alias,
        type
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  public async killApp(type: "kill_process" | "finish") {
    try {
      const res = await this.call(ActionTypes.KILL_APP, {
        type
      });
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /*
   * IAP支付
   * */
  public async iapPurchase(
    product_id: string,
    is_sub: boolean,
    receipt_data?: string
  ) {
    try {
      const query: any = {
        product_id,
        type: is_sub ? "subs" : "inapp"
      };
      receipt_data && (query.receipt_data = receipt_data);
      const res: any = await this.call(ActionTypes.IAP_PURCHASE, query);
      return res;
    } catch (error) {
      // 处理错误，如果同时携带response和error
      // 1、error.code === 7，抛出error，重新走iapPurchase
      // 2、error.code !== 7，直接走正常post offer逻辑
      const { code, response } = error;
      return !!response
        ? Promise.resolve({ ...response, code })
        : Promise.reject(error);
    }
  }

  /**
   * Open Browser
   * @param url
   * @param target
   * system_browser: open_url
   * in_app_browser: app内safari
   * self_browser: 自带web_view
   * @param option
   */
  public async openBrowser(
    url: string,
    target:
      | "system_browser"
      | "in_app_browser"
      | "self_browser" = "system_browser",
    option?: OpenBrowserOption
  ) {
    try {
      const res = await this.call(ActionTypes.OPEN_BROWSER, {
        url,
        target,
        option
      });
      return res;
    } catch (error) {
      console.log("error", error);
      return Promise.reject(error);
    }
  }

  /**
   * JS 关闭浏览器
   * @param target
   */
  public async closeBrowser(
    target: "in_app_browser" | "self_browser" = "in_app_browser"
  ) {
    try {
      const res = await this.call(ActionTypes.CLOSE_BROWSER, {
        target
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 打开邮箱
   * @param recipients--->收件人
   *     subject --->标题
   * message_body -->默认的内容
   * */
  public async openEmail(
    recipients: string[],
    subject: string,
    message_body: string
  ) {
    try {
      const res = await this.call(ActionTypes.OPEN_EMAIL, {
        to_recipients: recipients,
        subject,
        message_body
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   *
   * @param app_id:string
   * */
  public async openAppStore(app_id: string) {
    try {
      const res = await this.call(ActionTypes.OPEN_APP_STORE, {
        app_id
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 告诉原生最新的web host
   * @param hy_web_host
   */
  public async configWebHost(hy_web_host: string) {
    try {
      const res = await this.call(ActionTypes.CONFIG_HY_WEB_HOST, {
        hy_web_host
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 打开相机或者相册
   * @param sourceType photo/camera, 默认是photo,
   * @param imageType jpg/png, default is jpg,
   * @param quality 0~1, default is .8
   */
  public async openCameraPhoto(
    sourceType: string = "photo",
    imageType: string = "jpg",
    quality: number = 0.1
  ) {
    try {
      const res = await this.call(ActionTypes.OPEN_IMAGE, {
        source_type: sourceType,
        image_type: imageType,
        compression_quality: quality
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 临时加载一个域名, 原生会用这个链接reload一遍主webview, 之后重启还是正常逻辑, 不受这个接口的影响
   * @param url
   */
  public async debugWebHost(url: string) {
    try {
      const res = await this.call(ActionTypes.DEBUG_LOAD_HY_WEB_HOST, { url });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /**
   * 获取原生所有存储的keys
   */
  public async nativeStorageKeys(): Promise<any> {
    try {
      const res: any = await this.call(ActionTypes.NATIVE_STORAGE_KEYS, null);
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 原生的存储set
   * */
  public async nativeStorageSet(params: object) {
    try {
      const res = await this.call(ActionTypes.NATIVE_STORAGE_SET, params);
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 原生的存储get
   * */
  public async nativeStorageGet(params: string[] | string) {
    try {
      const isArray = Array.isArray(params);
      const keys = isArray ? params : [params];
      const res = await this.call(ActionTypes.NATIVE_STORAGE_GET, { keys });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 原生的存储remove
   * */
  public async nativeStorageRemove(params: string[] | string) {
    try {
      const isArray = Array.isArray(params);
      const keys = isArray ? params : [params];
      const res = await this.call(ActionTypes.NATIVE_STORAGE_REMOVE, { keys });
      return Promise.resolve(res);
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 改变statusbar的风格
   * */
  /**
   * ChangeStatusBar
   * @param style
   */
  public async switchStatusBar(style: "white" | "black" | "auto" = "black") {
    try {
      const res = await this.call(ActionTypes.CHANGE_STATUS_BAR_STYLE, {
        style
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 震动接口
   * param: style  light/medium/heavy
   * */
  public async playImpact(style: "light" | "medium" | "heavy" = "light") {
    try {
      const res = await this.call(ActionTypes.PLAY_IMPACT, {
        style
      });
      return res;
    } catch (error) {
      // return Promise.reject(error);
    }
  }

  /*
   * 本地通知
   * param: right_now / enter_background
   * param: notifications
   * */
  public async addLocalNotification(
    type: "right_now" | "enter_background",
    notifications: any
  ) {
    try {
      const res = await this.call(ActionTypes.ADD_LOCAL_NOTIFICATION, {
        type,
        notifications
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 本地通知
   * param: right_now / enter_background
   * param: notifications
   * */
  public async removeLocalNotification(name?: string) {
    try {
      const params = name
        ? {
            identifiers: [name]
          }
        : {
            all: true
          };
      const res = await this.call(ActionTypes.REMOVE_NOTIFICATION, {
        enter_background: params,
        pending: params,
        delivered: params
      });
      return res;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  /*
   * 获取通知权限
   * */
  public async getNotificationPermission() {
    try {
      const res = await this.call(
        ActionTypes.GET_NOTIFICATION_PERMISSION,
        null
      );
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }

  /**
   * 配置原生本地的script脚本
   * @param script
   */
  public async configWebviewScript(script: string) {
    try {
      const res = await this.call(ActionTypes.CONFIG_HY_WEB_HOST, {
        script_string: script
      });
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * 获取app_flyer_info
   */
  public async getAppFlyerInfo() {
    try {
      const res = await this.call(ActionTypes.GET_APP_FLYER_INFO, null);
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /**
   * 修改statusbar的隐藏跟显示
   */
  public async changeStatusBar(hidden: boolean) {
    try {
      const res = await this.call(ActionTypes.CHANGE_STATUS_BAR_HIDDEN, {
        hidden
      });
      return res;
    } catch (e) {
      return Promise.reject(e);
    }
  }
  /*
  * 获取通知状态
  * */
  public async getNotificationState() {
    try {
      const res = await this.call(ActionTypes.GET_NOTIFICATION_AUTHORIZATION, {})
      return res;
    } catch (e) {
      return false;
    }
  }

  /*
  * 申请通知权限
  * */
  public async requestNotificationAuthorization() {
    try {
      const res = await this.call(ActionTypes.REQUEST_NOTIFICATION_AUTHORIZATION, {})
      return res;
    } catch (e) {
      return false;
    }
  }
}
export default new Jsbridge();
