import { Component, Prop, Vue } from "vue-property-decorator";
@Component
class ConfirmContent extends Vue {
  @Prop()
  private message?: string;
  @Prop()
  private notes?: string[];

  public render() {
    return (
      <div style="font-size: 14px;font-weight: 400;color: #666363;">
        {this.message && <p>{this.message}</p>}
        <div style="margin:16px 0 32px">
          {this.notes &&
            this.notes.map(item => (
              <p style="text-align:left;margin-bottom:16px">{item}</p>
            ))}
        </div>
      </div>
    );
  }
}

export default ConfirmContent;
