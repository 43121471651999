













import { Component, Prop, Vue } from "vue-property-decorator";
import SkuItem from "./SkuItem/SkuItem.vue";
import BoxContainer from "@/components/Container/BoxContainer.vue";

@Component({
  components: {
    SkuItem,
    BoxContainer
  }
})
export default class SkuSection extends Vue {
  @Prop({ default: "follow" }) // 类型
  private type?: any;
  @Prop({ default: "iap" }) // 支付类型
  private filter?: string;
  @Prop({ default: "" }) // 如果是coin，是什么活动
  private coinsType?: any;
  @Prop({ default: [] })
  private list?: any;
  @Prop() // happyHour对应的对象
  private happyHourUI?: any;

  private clickItem(item: any) {
    this.$emit("clickItem", item);
  }
}
