







import {
  Component,
  Emit,
  Model,
  Prop,
  Vue,
  Watch
} from "vue-property-decorator";
@Component({
  components: {}
})
export default class EmptyAccountPlace extends Vue {
  @Prop()
  private size?: string;
}
