import { creatUUid } from "@/api/utils/api_helpers";
import { getTimeZone, isIpad } from "@/utils/lodash";
import { transformParamsToString } from "@/api/utils/api_helpers";
import LaunchHelpers from "@/utils/launch_helpers";
import { store } from "@/main";
import * as types from "@/store/constants/index_types";

/**
 * 获取content type
 * @param method
 */
export const getContentType = (method: string = "GET") =>
  method === "GET" ? "application/json" : "application/x-www-form-urlencoded";

/**
 * 获取ur对host和url name
 * @param str
 */
const splitHostAndName = (str: any) => {
  let index = str.indexOf("/");
  for (let i = 0; i < 2; i++) {
    index = str.indexOf("/", index + 1);
  }
  return {
    host: str.slice(0, index),
    url_name: str.slice(index)
  };
};

// 截取url后面的内容去query里面
export const interceptUrlParamsToQuery = (url: string) => {
  const index: number = url.indexOf("?");
  return index > -1
    ? {
        result_url: url.substring(0, index),
        result_query: url.substr(index + 1)
      }
    : {
        url
      };
};

// pinad api签名header
export const signHeadersStringFactory = (request: any) => {
  const { url, method, params, data } = request;
  const { host, url_name } = splitHostAndName(url);
  const baseURL: string = host;
  const path: string = url_name;
  let joinPrams = "";
  let splitUrl = "";
  if (method.toLowerCase() === "get") {
    splitUrl = transformParamsToString(params);
  } else {
    splitUrl = JSON.stringify(data);
  }
  joinPrams += `${method.toUpperCase()}&${baseURL}&${path}&${splitUrl}`;
  return joinPrams;
};

// 非pinad的api，需要对参数进行处理
export const addBodyDefaultParams = async (params: any) => {
  const AppInfo: any = LaunchHelpers.getLaunchData("deviceInfo");
  const masterId = store.getters[types.GETTER_MASTER_USER_ID];
  const defaultBodyParams: any = {
    platform: AppInfo.app_platform,
    app_version: AppInfo.info && AppInfo.info.short_version,
    js_bundle_version: "2.2.2",
    timestamp: new Date().valueOf().toString(),
    request_id: creatUUid(),
    timezone: getTimeZone(),
    pad: isIpad(),
    system_version: AppInfo.device && AppInfo.device.system_version,
    device_model: AppInfo.machine,
    device_id: masterId
  };
  return params
    ? {
        ...defaultBodyParams,
        ...params
      }
    : defaultBodyParams;
};
