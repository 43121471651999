
































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { Action } from "vuex-class";
import EventBus from "@/utils/event_bus";
import PurchasedList from "@/services/payment/purchasedList";
import { payByAny } from "@/services/payment/payment_check/payment_check_logic";

@Component
export default class SpecialOfferItem extends Vue {
  @Prop({ default: "follow" })
  private type?: any;
  @Prop()
  private offer: any;
  // 必须要初始化值，并且为false，因为claimedStyleClass、btnClass和offerIcon使用的computed，默认为缓存数据，只有当isClaimed发生改变才触发
  private isClaimed: boolean = false;
  private restNum: number = 0;

  get btnClass() {
    if (this.isClaimed) {
      return "claimed";
    } else if (this.type === "follow") {
      return "follow";
    } else if (this.type === "like") {
      return "like";
    }
  }
  get offerIcon() {
    if (this.type === "follow") {
      return this.isClaimed
        ? "icon-item_icon_follower_un"
        : "icon-item_icon_follower_red";
    } else if (this.type === "like") {
      return this.isClaimed
        ? "icon-item_icon_like_un"
        : "icon-item_icon_like_red";
    }
  }

  get claimedStyleClass() {
    return {
      claimed: this.isClaimed
    };
  }

  // 当下一阶段显示的也是当前组件时，组件不会被注销，导致isClaimed不会发生改变，下一阶段的offer会被灰掉，所以必须要监听offer变化，实时判断是否isClaimed
  @Watch("offer", { deep: true })
  private watchOffer() {
    this.judgeWhetherClaimed();
  }

  // 仅当offer没被购买时，拉起购买，然后监听event bus的购买完成事件，触发进入下一个stage
  private async buyOffers() {
    if (!this.isClaimed) {
      this.offer.purchase_event_params = {
        label: `special_${this.type}_buy`,
        value: {
          offerID: this.offer.offer_id
        }
      };
      // 确保只会监听一次special_offer_buying_finished事件，避免拉起购买时取消购买，导致购买成功后事件多次触发！！
      EventBus.$off("special_offer_buying_finished");
      // 不能使用on来监听，因为on会多次累加事件，无论组件是否destroy都会累加，导致事件被多次触发，special controller判断会出错!!
      EventBus.$once("special_offer_buying_finished", () => {
        this.$emit("buyOffers", this.offer);
      });
      payByAny({ offer: this.offer });
    } else {
      // console.log("当前offer已经被购买，不能再次购买了！");
    }
  }

  private judgeWhetherClaimed() {
    if (PurchasedList.getPurchaseList().length === 0) {
      this.isClaimed = false;
    } else {
      const purchasedNum = PurchasedList.getPurchaseList().filter(
        (item: any) => {
          const offerData = item.offer_data || item.receipt_data;
          return offerData && offerData.offer_id === this.offer.offer_id;
        }
      ).length;
      this.isClaimed = purchasedNum >= this.offer.limit_count;
      this.restNum = this.offer.limit_count - purchasedNum;
      // this.isClaimed = PurchasedList.getPurchaseList().some((item: any) => {
      //   const offerData = item.offer_data || item.receipt_data;
      //   if (offerData) {
      //     return offerData.offer_id === this.offer.offer_id;
      //   } else {
      //     return false;
      //   }
      // });
    }
  }

  private created() {
    this.judgeWhetherClaimed();
  }
  private activated() {
    this.judgeWhetherClaimed();
  }
}
