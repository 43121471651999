import Jsbridge from "@/jsbridge/index";
import { paramsResolve } from "@/utils/log_event/event_helpers";
import { ga_custom_event } from "@/utils/log_event/ga_event/ga_event";

/**
 * 友盟和FB的业务和api的同时打点
 * @param action
 * @param params
 */
export const sendEvent = (action: string, params: string | object) => {
  try {
    // setTimout to delay log
    setTimeout(() => {
      const resolve_params: any = paramsResolve(params);
      Jsbridge.logEvent(["umeng", "fb"], action, resolve_params);
      ga_custom_event(action, resolve_params);
    }, 0);
  } catch (error) {
    //
  }
};

/**
 * 友盟打点
 * @param action
 * @param params
 */
export const umengEvent = (action: string, params: string | object) => {
  try {
    setTimeout(() => {
      const resolve_params: any = paramsResolve(params);
      Jsbridge.logEvent(["umeng"], action, resolve_params);
      ga_custom_event(action, resolve_params);
    }, 0);
  } catch (error) {
    //
  }
};
