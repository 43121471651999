














































import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import {
  OfferKind,
  OfferSituation,
  RecommendOffer
} from "@/services/offer/offer_handle";
import SkuButton from "@/components/SkuSection/SkuItem/SkuButton.vue";
import { payByAny } from "@/services/payment/payment_check/payment_check_logic";
import FreeFollowUtil from "@/services/free_follow";
import generator from "@/services/open_pop_up/open_generator";
import { sendEvent } from "@/utils/log_event";
import { UIA_FREE_FOLLOWER_CLICK } from "@/utils/log_event/event_const";
import nativeStorage from "@/utils/nativeStorage/native_storage";
import { HAS_SHOW_FREE_FOLLOW } from "@/utils/nativeStorage/local_keys";
import AndroidBack, { POPUP_DIALOG } from "@/utils/android_back";
import EventBus from "@/utils/event_bus";

@Component({
  components: { SkuButton }
})
export default class PopupDialog extends Vue {
  // 遮罩层开关
  @Prop({ default: false })
  private show?: boolean;
  @Prop()
  private kind?: OfferKind;
  @Prop({ default: 0 })
  private effect?: number;
  @Prop({ default: 0 })
  private discount?: number;
  @Prop()
  private offer?: RecommendOffer;

  get title() {
    return this.kind === OfferKind.follow
      ? "Special Offer"
      : this.kind === OfferKind.coin
      ? "Special Coins"
      : "5 Free Followers";
  }

  get typeName() {
    return this.kind === OfferKind.coin ? "Coins" : "Followers";
  }

  get isCoinsRecommend() {
    return this.kind === OfferKind.coin;
  }

  get desc() {
    return this.kind ? "Today Only" : "Only for lucky newbie";
  }

  get btnStyle() {
    return "padding: 14px 0;";
  }

  @Watch("show")
  private onIsShowChange(val: boolean) {
    if (!val) {
      AndroidBack.destroy(POPUP_DIALOG);
    } else {
      AndroidBack.listen({
        key: POPUP_DIALOG,
        fn: () => {
          this.closeDialog();
        }
      });
    }
  }

  private closeDialog() {
    if (!this.offer) {
      nativeStorage.set(HAS_SHOW_FREE_FOLLOW, true);
      generator.next();
      sendEvent(UIA_FREE_FOLLOWER_CLICK, { action: "close" });
    }
    this.show = false;
  }

  private getNow() {
    if (this.offer) {
      payByAny({ offer: this.offer, situation: OfferSituation.launch });
      this.show = false;
    } else {
      // 不能直接关闭，需要判断是否添加成功账号，或者购买成功
      EventBus.$off("hide_free_popup");
      EventBus.$once("hide_free_popup", () => {
        this.show = false;
      });
      FreeFollowUtil.getFreeFollow();
    }
  }
}
