import { render, staticRenderFns } from "./SkuSection.vue?vue&type=template&id=a837b56c&scoped=true&"
import script from "./SkuSection.vue?vue&type=script&lang=ts&"
export * from "./SkuSection.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a837b56c",
  null
  
)

export default component.exports