





























import { Component, Vue } from "vue-property-decorator";
import SkuButton from "@/components/SkuSection/SkuItem/SkuButton.vue";
import { Action, Getter } from "vuex-class";
import { LOGIN_TIKTOK_USER_ACTION } from "@/store/constants/tt_user_types";
import { GETTER_FORCE_LOGIN_ENABLED } from "@/store/constants/setting_types";
import routerStacks from "@/plugins/navigation/routes";
import { goToSearchOrLogin } from "@/utils/router_helper";
import { UIA_WELCOME_CLICK } from "@/utils/log_event/event_const";
import { sendEvent } from "@/utils/log_event";
import NavBar from "@/components/NavBar/NavBar.vue";
@Component({
  components: { SkuButton, NavBar }
})
export default class Splash extends Vue {
  @Action(LOGIN_TIKTOK_USER_ACTION)
  private tiktokLogin: any;
  @Getter(GETTER_FORCE_LOGIN_ENABLED)
  private forceLoginEnabled: any;
  private timer: any;
  private interval: number = 5;
  private isShowCancelIcon: boolean = false; // 是否显示左上角关闭icon

  get btnStyle() {
    return "padding: 14px 0";
  }

  get buttonText() {
    return this.forceLoginEnabled
      ? "Log in with TikTok"
      : "Enter TikTok Username";
  }

  private loginOrSearch() {
    try {
      sendEvent(UIA_WELCOME_CLICK, {
        action: "add_username"
      });
      this.forceLoginEnabled ? this.tiktokLogin() : goToSearchOrLogin();
    } catch (error) {
      //
    }
  }

  private clickClose(type: "close" | "later") {
    if (routerStacks.length > 1 && routerStacks[1].name) {
      (this.$router as any).goBack(-1);
    } else {
      this.$router.replace({
        name: "Home",
        query: {
          routerTransition: "up"
        }
      });
    }
    sendEvent(UIA_WELCOME_CLICK, {
      action: type
    });
    // AndroidBack.destroy(this.$route.name as any);
  }

  private mounted() {
    // 5s之后再显示关闭icon
    this.timer = setTimeout(() => {
      this.isShowCancelIcon = true;
    }, this.interval * 1000);
  }

  private deactivated() {
    clearTimeout(this.timer);
  }
}
