









































import { Component, Vue } from "vue-property-decorator";
import TabBar from "@/components/TabBar/TabBar.vue";
import TabBarItem from "@/components/TabBar/TabBarItem.vue";
import TiktokUserGallery from "@/components/PopGallery/TiktokUserGallery.vue";
import jsbridge from "@/jsbridge";
import EventBus from "@/utils/event_bus/index";
@Component({
  name: "Home",
  components: { TabBar, TabBarItem, TiktokUserGallery }
})
export default class Home extends Vue {
  private accountListShow = false;
  private accountListFrom: any = "";
  private showLoginUser = false;
  private tabBarList: any = [
    {
      activeIcon: "icon-tabar_icon_follower_selected",
      icon: "icon-tabar_icon_follower_unselected",
      link: "Followers",
      label: "Followers",
      path: "/home/followers",
      darkModeIcon: "icon-tabar_icon_follower_dark_unselected",
      darkModeActiveIcon: "icon-tabar_icon_follower_dark_selected"
    },
    {
      activeIcon: "icon-tabar_icon_like_selected",
      icon: "icon-tabar_icon_like_unselected",
      link: "Likes",
      label: "Likes",
      path: "/home/likes",
      darkModeIcon: "icon-tabar_icon_like_dark_unselected",
      darkModeActiveIcon: "icon-tabar_icon_like_dark_selected"
    },
    {
      activeIcon: "icon-tabar_icon_coin_selected",
      icon: "icon-tabar_icon_coin_unselected",
      link: "Earns",
      label: "Earn Coins",
      path: "/home/earncoins",
      darkModeIcon: "icon-tabar_icon_coin_dark_unselected",
      darkModeActiveIcon: "icon-tabar_icon_coin_dark_selected"
    },
    {
      activeIcon: "icon-tabar_icon_store_selected",
      icon: "icon-tabar_icon_store_unselected",
      link: "Coins",
      label: "Coins Store",
      path: "/home/coins",
      darkModeIcon: "icon-tabar_icon_store_dark_unselected",
      darkModeActiveIcon: "icon-tabar_icon_store_dark_selected"
    },
    {
      activeIcon: "icon-tabar_icon_more_selected",
      icon: "icon-tabar_icon_more_unselected",
      link: "More",
      label: "More",
      path: "/home/more",
      darkModeIcon: "icon-tabar_icon_more_dark_unselected",
      darkModeActiveIcon: "icon-tabar_icon_more_dark_selected"
    }
  ];
  get activeIndex() {
    return this.tabBarList.findIndex((v: any) => {
      return (this.$route as any).name.includes(v.link);
    });
  }

  get modeProperTabItemStyle() {
    return this.activeIndex === 2 ? { background: "#0d0c0c" } : {};
  }

  private modeProperTabItemLabelStyle(index: number) {
    return this.activeIndex === 2 && index === 2 ? { color: "#fff" } : {};
  }

  private mounted() {
    EventBus.$on(
      "show-account-list",
      ({
        show,
        login,
        from
      }: {
        show: boolean;
        login?: any;
        from?: string;
      }) => {
        this.accountListShow = show;
        this.showLoginUser = login;
        this.accountListFrom = from;
      }
    );
  }

  private activated() {
    jsbridge.switchStatusBar("white").catch(() => void 0);
  }
  private deactivated() {
    jsbridge.switchStatusBar("black").catch(() => void 0);
  }
}
