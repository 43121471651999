import { ActionTree } from "vuex";
import * as types from "@/store/constants/cg_user_types";
import { GETTER_MASTER_USER_ID } from "@/store/constants/index_types";
import * as index_types from "@/store/constants/index_types";
import { login } from "@/api/cg/service/user_service";
import { getUserInfoById } from "@/api/cg/service/user_service";

const LOGIN_RETRY_TIMES_MAX: number = 2;
let loginRetryTimes: number = 0;

const actions: ActionTree<any, any> = {
  async [types.INIT_GX_USER_INFO]({ state, dispatch }) {
    try {
      console.log("INIT_GX_USER_INFO", state);
      if (state.server_current_time === null) {
        // 没有注册过账户
        const result = await dispatch(types.LOGIN_MASTER_ID_BY_GX);
        return result;
      } else {
        // 注册过账户,直接异步刷新账户信息
        dispatch(types.UPDATE_GX_USER_INFO).catch(e => {
          void 0;
        });
        return state;
      }
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async [types.LOGIN_MASTER_ID_BY_GX]({ commit, dispatch, getters }) {
    try {
      const masterUserId = getters[GETTER_MASTER_USER_ID];
      const result: any = await login(masterUserId);
      console.log("LOGIN_MASTER_ID_BY_GX result:", result);
      commit(types.UPDATE_GX_USER_INFO, {
        userInfo: result
      });
      return result;
    } catch (error) {
      console.log("LOGIN_MASTER_ID_BY_GX error:", error);
      // 登陆的重试：
      if (loginRetryTimes < LOGIN_RETRY_TIMES_MAX) {
        console.log("LOGIN_MASTER_ID_BY_GX重试》");
        loginRetryTimes += 1;
        return Promise.resolve(await dispatch(types.LOGIN_MASTER_ID_BY_GX));
      }
      return Promise.reject(error);
    }
  },
  async [types.UPDATE_GX_USER_INFO](
    { state, commit, dispatch, getters, rootState },
    payload?: string[]
  ) {
    try {
      const masterUserId = getters[GETTER_MASTER_USER_ID];
      const result: any = await getUserInfoById(masterUserId);
      const refreshData: any = { userInfo: result };
      payload && (refreshData.filters = payload);
      commit(types.UPDATE_GX_USER_INFO, refreshData);
      return refreshData;
    } catch (error) {
      return Promise.reject(error);
    }
  },
  async [types.UPDATE_VIP_PACKAGE_STATUS](
    { state, commit, dispatch, getters, rootState },
    payload?: string[]
  ) {
    try {
      const masterUserId = getters[index_types.GETTER_MASTER_USER_ID];
      let result: any = await getUserInfoById(masterUserId);
      result = {
        vip_p_valid_count: null,
        vip_p_valid_time: null,
        ...result
      };
      commit(types.UPDATE_GX_USER_INFO, { userInfo: result });
    } catch (e) {
      //
    }
  }
};
export default actions;
