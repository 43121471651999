import { Component, Prop, Vue } from "vue-property-decorator";
import videoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
import paymentCall from "@/services/payment/paymentCall";
import { sendEvent } from "@/utils/log_event";
import { UIA_SP_CLICK } from "@/utils/log_event/event_const";
import AutoPaymentCache from "@/services/super_pay/auto_payment_cache";

@Component({
  components: {
    videoPlayer
  }
})
class RecommendAppHeader extends Vue {
  @Prop()
  private ctx: any;
  @Prop()
  private content: any;
  @Prop()
  private pic_placeholder: any;
  @Prop()
  private dialog_class: any;
  @Prop()
  private offer: any;

  public render() {
    const videoStyle =
      "border-radius:8px 8px 0 0;width: 100%;height: 100%;object-fit: cover;";
    const boxStyle = "height: 100%;";
    const videoReadyBackground = { background: "white" };
    const videoUrl = this.content.video_url || this.content.videoUrl;
    const videoPathList = videoUrl.split("/");
    const videoName = videoPathList[videoPathList.length - 1];
    return (
      <div
        class="top-content"
        style="position:relative;background: linear-gradient(to bottom, rgb(16, 17, 22), rgb(29, 29, 42));color: #fff;width: 100%;border-radius:8px 8px 0 0;height: 59.25vw"
      >
        <videoPlayer
          style={videoStyle}
          boxStyle={boxStyle}
          videoStyle={videoStyle}
          videoSrc={videoUrl}
          poster={this.pic_placeholder}
          videoReadyBackground={videoReadyBackground}
        />
        <i
          onclick={async () => {
            this.ctx.isShow = false;
            this.dialog_class === "super_pay" &&
              sendEvent(UIA_SP_CLICK, {
                promo_video: videoName,
                situation: "payment_choice",
                style: "alert",
                action: "cancel"
              });
            // 传入的有auto_exec_pay_offer，并且点击过下载
            AutoPaymentCache.set({
              offer: this.offer,
              pay_way: "back_up",
              from: "close"
            });
            // 自动调起一次支付
            paymentCall.autoExecutePay();
          }}
          class="icon-app_close_white"
          style="position:absolute;right:7px;top:7px;padding: 5px;"
        />
      </div>
    );
  }
}
export default RecommendAppHeader;
