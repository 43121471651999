















































import { Component, Vue } from "vue-property-decorator";
import NavBar from "../../components/NavBar/NavBar.vue";
import videoPlayer from "@/components/VideoPlayer/VideoPlayer.vue";
import SuperPayUtil from "@/services/super_pay";
import Button from "@/components/Button/Button.vue";
import generator from "@/services/open_pop_up/open_generator";
import { download_super_pay } from "@/services/super_pay/super_pay_helper";
import { isIos, isLongScreen } from "@/utils/lodash";
import { AppsCategory } from "@/services/super_pay/types";
import { sendEvent } from "@/utils/log_event";
import { UIA_SP_SHOW, UIA_SP_CLICK } from "@/utils/log_event/event_const";
import AndroidBack from "@/utils/android_back";
import AutoPaymentCache from "@/services/super_pay/auto_payment_cache";
import paymentCall from "@/services/payment/paymentCall";
import { getVideoCache } from "@/jsbridge/service/download_files/downloadVideo";
import { launchBack } from "@/utils/router_helper";
import { judgeAndroidVideoSourceFetch } from "@/utils/judge_version";

@Component({
  components: {
    NavBar,
    videoPlayer,
    Button
  }
})
export default class SuperPayPopup extends Vue {
  private from: any = ""; // 标记来源
  private offer: any; // 如果是支付触发的弹窗，需要记录offer
  private videoSrc: any = "";
  get posterSrc() {
    return isLongScreen()
      ? require("@/assets/icons-file/super_pay/super_pay_placeholder/sp_place_x@3x.png")
      : require("@/assets/icons-file/super_pay/super_pay_placeholder/sp_place_n@3x.png");
  }

  get videoStyle() {
    return "width: 100%;height: 100%;object-fit: cover;";
  }

  get longScreen() {
    return isLongScreen();
  }

  get topAreaStyle() {
    const clientHeight = document.body.clientHeight;
    const clientWidth = document.body.clientWidth;
    const topHeight =
      clientHeight * 0.45 > clientWidth ? clientWidth : clientHeight * 0.45;
    return `height: ${topHeight}px`;
  }

  get nameFont() {
    return isIos() ? "font-size: 24px" : "font-size: 18px";
  }

  get iconUrl() {
    return this.superPayParams && this.superPayParams.iconUrl;
  }

  get appBenefits() {
    return this.superPayParams && this.superPayParams.appBenefits;
  }

  get appName() {
    return this.superPayParams && this.superPayParams.appName;
  }

  // get videoSrc() {
  //   return this.superPayParams && this.superPayParams.videoUrl;
  //   // return "this.superPayParams && this.superPayParams.videoUrl";
  // }

  get boxStyle() {
    return {
      height: "100%"
    };
  }

  get superPayParams() {
    return SuperPayUtil.getAppsData(AppsCategory.super_pay);
  }

  private clickClose(value?: any) {
    // AndroidBack.destroy(this.$route.name as any);
    launchBack();
    value && this.sendClickEvent("cancel");
    // 如果是从支付过来，需要自动调起支付
    if (this.from) {
      AutoPaymentCache.set({
        offer: this.offer,
        pay_way: "super_pay",
        from: "close"
      });
      // 自动调起一次支付
      paymentCall.autoExecutePay();
    } else {
      generator.next();
    }
  }

  get videoName() {
    const videoPathList = this.videoSrc && this.videoSrc.split("/");
    return videoPathList[videoPathList.length - 1];
  }

  private ignore() {
    this.clickClose();
    this.sendClickEvent("have_installed");
  }

  private sendClickEvent(action: string) {
    sendEvent(UIA_SP_CLICK, {
      promo_video: this.videoName,
      situation: this.from || "launch",
      style: "default",
      action
    });
  }

  private getApp(getType: "auto_get" | "get" = "get") {
    AutoPaymentCache.set({
      offer: this.offer,
      pay_way: "super_pay"
    });
    download_super_pay("launch_popup");
    // this.sendClickEvent("get");
    this.sendClickEvent(getType);
  }

  // 打点uia_sp_show
  private sendShowEvent() {
    const super_pay = SuperPayUtil.getAppsData(AppsCategory.super_pay);
    const videoPathList = super_pay.videoUrl.split("/");
    const promo_video = videoPathList[videoPathList.length - 1];
    // 判断是开机弹窗还是支付调起的弹窗
    sendEvent(UIA_SP_SHOW, {
      situation: this.from || "launch",
      style: "default",
      promo_video
    });
  }

  private async created() {
    this.from = this.$route.query.from;
    this.offer = this.$route.query.offer;
    // 如果是安卓新包（native_code_version >= 1.1.0)，读取缓存中的资源，加速视频渲染。老包和iOS直接使用线上资源
    if (judgeAndroidVideoSourceFetch()) {
      this.videoSrc = await getVideoCache(this.superPayParams.videoUrl);
    } else {
      this.videoSrc = this.superPayParams.videoUrl;
    }
    this.sendShowEvent();
    AndroidBack.listen({
      key: this.$route.name,
      fn: () => {
        this.clickClose();
      }
    });
  }

  private mounted() {
    // 如果是支付触发的，1s自动调起下载
    this.from &&
      setTimeout(() => {
        this.getApp("auto_get");
      }, 1000);
  }
}
