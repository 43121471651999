








import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import EmptyUser from "./EmptyUser.vue";
import UserBaseInfo from "./UserBaseInfo.vue";
import { goToSearchOrLogin } from "@/utils/router_helper";
import { GETTER_TIKTOK_USER_EMPTY } from "@/store/constants/tt_user_types";
@Component({
  components: { EmptyUser, UserBaseInfo }
})
export default class UserInfo extends Vue {
  @Getter(GETTER_TIKTOK_USER_EMPTY)
  private isTiktokEmpty: any;

  private clickEmptyUser() {
    goToSearchOrLogin();
  }

  private showAccountList() {
    // EventBus.$emit("show-account-list", {
    //   show: true
    // });
    // goToLogin("tab1_top");
  }
}
