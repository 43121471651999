


























































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import {
  is_tt_feed,
  is_ads_feed
} from "@/services/earn_coins/earn_coins_consts/earn_coins_consts";
import * as setting_types from "@/store/constants/setting_types";
import { Getter } from "vuex-class";
import ActButton from "./ActButton.vue";
import CountDwonTime from "@/components/CountDwonTime/CountDwonTime.vue";

@Component({
  components: {
    ActButton,
    CountDwonTime
  }
})
export default class ButtonsGroup extends Vue {
  @Prop()
  private feed: any;
  @Prop({ default: false, type: Boolean })
  private loading: any;
  @Prop({ default: false, type: Boolean })
  private limited: any;
  @Prop({ default: false, type: Boolean })
  private spacingLimited: any;
  @Prop({ default: 0, type: Number })
  private spacingLimitedStamp: any;

  @Getter(setting_types.GETTER_TAB_COIN_STORE_SETTINGS)
  private settingData: any;

  private limitedDuration: number = 1;
  private interval: any;

  get spacing_button_style() {
    return {
      background: "none",
      color: " #121111",
      fontSize: "18px"
    };
  }

  get watchVideoReward() {
    return this.settingData.rewards.video;
  }

  get spacing_button_show() {
    // 当前feed是tt_feed类型
    return this.spacingLimited && this.tt_feed_button_show;
  }

  get skip_button_show() {
    return !this.loading && !this.limited && !this.show_retry_wait_button;
  }

  get tt_feed_button_show() {
    return is_tt_feed(this.feed);
  }

  get show_retry_wait_button() {
    return this.feed === null || this.feed === undefined;
  }

  get task_feed_button_show() {
    return !this.show_retry_wait_button;
  }

  get bottomButtonText() {
    return this.loading ? "Wait" : "Retry";
  }

  get limitDurationText() {
    return `(${this.limitedDuration}s)`;
  }

  get feed_kind() {
    return (this.feed && this.feed.kind) || "";
  }

  get is_ads_feed() {
    return is_ads_feed(this.feed);
  }

  get feed_type_text() {
    const kind = this.feed_kind;
    const format = kind.substring(0, 1).toUpperCase() + kind.substring(1);
    return format;
  }

  get feedCoinCount() {
    return this.feed && this.feed.coins;
  }

  private handleSkip() {
    this.$emit("skipFeed");
  }

  private changeAccount() {
    this.$emit("changeAccount");
  }

  private clickRetry() {
    this.$emit("clickRetry");
  }

  private handle_act_feed() {
    this.$emit("actFeed");
  }

  private handleMoreCoins() {
    this.$emit("clickMore");
  }

  private shouldCloseLimit() {
    this.$emit("shouldCloseLimit");
  }
}
