import cgRequest from "@/api/cg/base";
import * as cgConst from "@/api/cg/base/cg_const";
import { isIos } from "@/utils/lodash";
import {
  getAndroidGaid,
  getAndroidId,
  getIDFA,
  getIDFV,
  getDeviceId
} from "@/utils/js_bridge_info";
import { getResourceFromOffer } from "@/services/offer/offer_handle";
import { PayWay } from "@/services/payment/types";
import { assembleAppInfo } from "@/api/cg/util/common_util";

export const postOffer = async (
  offer: any,
  transaction_id: string,
  master_id: string | number,
  target_user_id: string,
  pay_way: PayWay,
  receipt: string,
  resource: any,
  pay_platform?: string,
  pay_version?: string
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = cgConst.POST_OFFER;
      const device_info = isIos()
        ? {
            idfv: getIDFV(),
            customer_user_id: getDeviceId(),
            idfa: getIDFA()
          }
        : {
            android_id: getAndroidId(),
            customer_user_id: getDeviceId(),
            gaid: getAndroidGaid()
          };
      const app_info = assembleAppInfo();
      const params: any = {
        offer_id: offer.offer_id,
        master_id: master_id.toString(),
        resource,
        transaction_id,
        target_user_id: target_user_id ? target_user_id : master_id,
        receipt,
        device_info,
        currency_code: "USD"
      };
      pay_platform && (params.pay_platform = pay_platform);
      app_info && (params.app_info = app_info);
      pay_version && (params.pay_version = pay_version);
      pay_way === PayWay.back_up && (params.pay_way = PayWay.service_back_up);
      resolve(await cgRequest(url, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

export const payByCoins = async (
  offer: any,
  transaction_id: string,
  master_id: string | number,
  target_user_id: string
): Promise<any> => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = cgConst.POST_OFFER_BY_COIN;
      const params: any = {
        offer_id: offer.offer_id,
        master_id: master_id.toString(),
        resource: getResourceFromOffer(offer),
        transaction_id,
        target_user_id: target_user_id ? target_user_id : master_id
      };
      resolve(await cgRequest(url, "POST", params));
    } catch (e) {
      reject(e);
    }
  });
};

export const getOrders = async (userId: string, nextCursor?: any) => {
  return new Promise(async (resolve, reject) => {
    try {
      const url = cgConst.GET_ORDER;
      const params: any = {
        user_id: userId
      };
      if (nextCursor !== undefined && nextCursor !== null) {
        params.next_cursor = nextCursor;
      }
      resolve(await cgRequest(url, "GET", params));
    } catch (e) {
      reject(e);
    }
  });
};
