import { Component, Prop, Vue } from "vue-property-decorator";
import { format } from "@/utils/num_utils";
@Component
class ConfirmContent extends Vue {
  @Prop()
  private ctx: any;
  @Prop()
  private offer: any;

  private spanStyle =
    "font-size: 16px;line-height: 22px;color: #EDA413;padding:0 4px;";
  private divStyle =
    "font-size: 16px;line-height: 22px;color: rgb(140,140,140);padding-bottom:20px;";

  get text() {
    return this.offer.kind === "follow"
      ? "Followers"
      : this.offer.kind === "golden_follow"
      ? "Golden followers"
      : this.offer.kind + "s";
  }

  public render() {
    return (
      <div style={this.divStyle}>
        Are you sure to get
        <span style={this.spanStyle}>
          {format(this.offer.effect)}&nbsp;{this.text}
        </span>
        ?
      </div>
    );
  }
}

export default ConfirmContent;
