/**
 * 管理http请求的拦截器
 */
import { RejectedFn, ResolvedFn, Interceptor } from "../types";

export default class InterceptorManager<T> {
  public interceptors: Array<Interceptor<T> | null>;

  constructor() {
    this.interceptors = [];
  }
  public use(resolved: ResolvedFn<T>, rejected?: RejectedFn): number {
    this.interceptors.push({
      resolved,
      rejected
    });
    return this.interceptors.length - 1;
  }

  public eject(id: number): void {
    if (this.interceptors[id]) {
      this.interceptors[id] = null;
    }
  }
}
