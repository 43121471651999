import { GetterTree } from "vuex";
import * as types from "@/store/constants/setting_types";
import { getIapPrice } from "@/plugins/filters/offer_filter";
import { OfferKind, OfferWay } from "@/services/offer/offer_handle";

const Getters: GetterTree<any, any> = {
  [types.GETTER_SETTING_EXIST](state) {
    return Object.keys(state.common).length > 0;
  },
  [types.GETTER_ALL_SETTING](state) {
    return state;
  },
  [types.GETTER_ONLINE_SETTING_STATE](state) {
    return state.hasLoadedOnlineSetting;
  },
  [types.GETTER_CLIENT_SETTINGS](state) {
    return state.common && state.common.client_settings;
  },
  [types.GETTER_FOLLOW_OFFERS](state) {
    return state.tab_follow && state.tab_follow.follow_offers;
  },
  [types.GETTER_GOLDEN_FOLLOW_OFFERS](state) {
    return state.tab_follow && state.tab_follow.golden_follow_offers;
  },
  [types.GETTER_TAB_COIN_STORE_SETTINGS](state) {
    return state.tab_coin_store;
  },
  [types.GETTER_SPECIAL_FOLLOW_OFFERS](state) {
    return state.tab_follow && state.tab_follow.special_follow_offers;
  },
  [types.GETTER_SERVICE_OFFERS](state) {
    return state.common && state.common.service_offers;
  },
  [types.GETTER_COIN_OFFERS](state) {
    return state.common && state.common.coin_offers;
  },
  [types.GETTER_LIKE_OFFERS](state) {
    return state.tab_like && state.tab_like.like_offers;
  },
  [types.GETTER_SPECIAL_LIKE_OFFERS](state) {
    return state.tab_like && state.tab_like.special_like_offers;
  },
  [types.GETTER_COMMENT_OFFERS](state) {
    return state.tab_like && state.tab_like.comment_offers;
  },
  [types.GETTER_VIEW_OFFERS](state) {
    return state.tab_like && state.tab_like.view_offers;
  },
  [types.GETTER_AD_APP_ID](state, getters) {
    return getters[types.GETTER_CLIENT_SETTINGS].ads.ad_app_id;
  },
  [types.GETTER_SP_BONUS_COINS](state) {
    return state.common && state.common.sp_bonus_coins;
  },
  [types.GETTER_SALE](state) {
    return state.common && state.common.sale;
  },
  [types.GETTER_ENABLE](state) {
    return (
      state.common &&
      state.common.client_settings &&
      state.common.client_settings.enable
    );
  },
  [types.GETTER_APP_TAKE_DOWN](state, getters) {
    const enable = getters[types.GETTER_ENABLE];
    return enable && enable.app_takedown;
  },
  [types.GETTER_FORCE_LOGIN_ENABLED](state, getters) {
    const enable = getters[types.GETTER_ENABLE];
    return enable && enable.force_login_enabled;
  },
  [types.GETTER_EARN_COINS_LOGIN_NONEED_ENABLED](state, getters) {
    const enable = getters[types.GETTER_ENABLE];
    return enable && enable.earn_coins_login_noneed_enabled;
  },
  [types.GETTER_LIKE_FORCE_LOGIN_ENABLED](state, getters) {
    const enable = getters[types.GETTER_ENABLE];
    return enable && enable.like_force_login_enabled;
  },
  [types.GETTER_SP_FIRST](state, getters) {
    const enable = getters[types.GETTER_ENABLE];
    return enable && enable.sp_first_priority_enabled;
  },
  [types.GETTER_CONTACT_EMAIL](state, getters) {
    return (
      (state.common &&
        state.common.client_settings &&
        state.common.client_settings.feedback &&
        state.common.client_settings.feedback.support_email) ||
      "team@likerushapp.com"
    );
  },
  [types.GETTER_FREE_FOLLOW_ENABLE](state) {
    return state.common && state.common.free_followers_for_newbie_enabled;
  },
  [types.GETTER_REDIRECT_APP](state) {
    return (
      state.common &&
      state.common.client_settings &&
      state.common.client_settings.enable &&
      state.common.client_settings.redirect_app
    );
  },
  [types.GETTER_LIMIT_COINS_SETTING](state) {
    return (
      state.common &&
      state.common.client_settings &&
      state.common.client_settings.limited_coin
    );
  },
  [types.GETTER_RECOMMEND_OFFER_LIST](state) {
    return state.common && state.common.recommend_offers;
  },
  [types.GETTER_FEATURES](state) {
    return (
      state.common &&
      state.common.client_settings &&
      state.common.client_settings.features
    );
  },
  [types.GETTER_SUPER_PAY_PARAMS](state) {
    return (
      state.common &&
      state.common.client_settings &&
      state.common.client_settings.super_pay
    );
  },
  [types.GETTER_TAB_EARN_COINS_SETTINGS](state) {
    return state.tab_earn_coins;
  },
  [types.GETTER_COIN_LIMIT_SALE](state) {
    return (
      state.common && state.common.sale && state.common.sale.coin_limit_sale
    );
  },
  [types.GETTER_DOUBLE_COINS_PERCENTAGE](state) {
    const coin_limit_sale =
      state.common && state.common.sale && state.common.sale.coin_limit_sale;
    return coin_limit_sale && coin_limit_sale.percentage
      ? coin_limit_sale.percentage
      : 200;
  },
  [types.GETTER_BIG_PRICE_LIST](state) {
    return state.common && state.common.sale && state.common.sale.big_prices;
  },
  [types.GETTER_FEATURES_ITEM_BY_FUNCTION](state, getters) {
    return (payload?: any) => {
      const featuresList =
        state.common &&
        state.common.client_settings &&
        state.common.client_settings.features;
      const findway = (name: any) => {
        if (featuresList && featuresList.length > 0) {
          const feature = featuresList.find((v: any) => v.function === name);
          return feature ? feature : {};
        } else {
          return {};
        }
      };
      const big_prices: any = getters[types.GETTER_BIG_PRICE_LIST];
      const offer_iap_price = getIapPrice(payload);
      if (big_prices && big_prices.some((v: any) => v === offer_iap_price)) {
        return {
          paypal: findway("big_pp_way"),
          stripe: findway("big_card_way"),
          superPay: findway("superpay_way"),
          backup: findway("backup_app")
        };
      } else {
        return {
          paypal: findway("small_pp_way"),
          stripe: findway("small_card_way"),
          superPay: findway("superpay_way"),
          backup: findway("backup_app")
        };
      }
    };
  },
  [types.GETTER_WP_URL](state) {
    return (
      state.common &&
      state.common.client_settings &&
      state.common.client_settings.web_p_url
    );
  },
  [types.GETTER_HELP_GUIDES_URL](state) {
    return (
      state.common &&
      state.common.client_settings &&
      state.common.client_settings.help_url
    );
  },
  [types.GETTER_OFFERS_BY_TYPE](state, getters) {
    return (
      type: OfferKind.follow | OfferKind.golden_follow | OfferKind.like,
      filters: OfferWay.iap | OfferWay.coin = OfferWay.iap
    ) => {
      switch (type) {
        case OfferKind.follow: {
          return (
            (getters[types.GETTER_FOLLOW_OFFERS] &&
              getters[types.GETTER_FOLLOW_OFFERS].filter((item: any) => {
                return item.way === filters && item.effect > 0;
              })) ||
            null
          );
        }
        case OfferKind.golden_follow: {
          return (
            (getters[types.GETTER_GOLDEN_FOLLOW_OFFERS] &&
              getters[types.GETTER_GOLDEN_FOLLOW_OFFERS].filter((item: any) => {
                return item.way === filters && item.effect > 0;
              })) ||
            null
          );
        }
        case OfferKind.like: {
          return (
            (getters[types.GETTER_LIKE_OFFERS] &&
              getters[types.GETTER_LIKE_OFFERS].filter((item: any) => {
                return item.way === filters && item.effect > 0;
              })) ||
            null
          );
        }
        default:
          break;
      }
    };
  },
  [types.GETTER_PAGE_SETTING_STATUS](state) {
    return (type: "follow" | "like") => {
      return state.hasLoadedPageSettingStatus[type];
    };
  }
};
export default Getters;
