























import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import * as types from "@/store/constants/setting_types";
import CardList from "@/components/CardList/CardList.vue";
import CountDwonTime from "@/components/CountDwonTime/CountDwonTime.vue";
import tagComponet from "@/components/Tag/Tag.vue";
import SkuButton from "@/components/SkuSection/SkuItem/SkuButton.vue";
import { isDoubleCoins } from "@/utils/general_utils";
import { format } from "@/utils/num_utils";
import LocalizeUtil from "@/services/payment/localize";
import Event_bus from "@/utils/event_bus";
import { payByAny } from "@/services/payment/payment_check/payment_check_logic";
import { OfferSituation } from "@/services/offer/offer_handle";
import listenNative from "@/jsbridge/service/listenNative";

const Tag: any = tagComponet;

@Component({
  components: {
    CardList,
    CountDwonTime
  }
})
export default class RegularCoin extends Vue {
  @Getter(types.GETTER_COIN_OFFERS)
  private coinOffers: any;
  @Getter(types.GETTER_SALE)
  private sale: any;
  @Getter(types.GETTER_DOUBLE_COINS_PERCENTAGE)
  private percentage: any;
  private hasLogEvent: boolean = false;
  private isDouble: boolean = false;

  // 自定义cardList的style
  private cardListStyle: object = {
    border: "none",
    background: "#fff"
  };
  // 自定义cardList中header的style
  private cardListHeadStyle: object = {
    border: "none",
    paddingBottom: "8px"
  };
  // 自定义cardList中SeperateLineStyle的style
  private cardListSeperateLineStyle: object = {
    width: "auto",
    marginLeft: "48px",
    height: "1px",
    backgroundColor: "transparent",
    backgroundImage: "linear-gradient(0deg,transparent 50%,#ebebeb 50%)"
  };

  get endTimeStamp() {
    // 直接取时间戳，不需要从dobule coin弹窗传值，因为counttimedown组件传入的结束的时间戳
    return this.sale.coin_limit_sale.end_time;
    // return new Date().valueOf() + 80 * 1000;
  }

  // 自定义list的style
  get listStyle() {
    return this.isDouble
      ? { border: "none", background: "#fff" }
      : { background: "#fff" };
  }

  private calcDiscount(offer: any) {
    return this.isDouble
      ? Math.ceil(100 - ((100 - (offer.discount || 0)) / this.percentage) * 100)
      : offer.discount;
  }

  // 自定义cardlist item
  private customRender(row: any) {
    // tag渲染
    const tagRender = row.is_best_deal ? <Tag type="yellow">Hot</Tag> : "";
    // 打折额度渲染
    const discountRender =
      row.discount || this.isDouble ? (
        <section class="discount-info">
          {this.calcDiscount(row)}% <span class="discount-info-flag">OFF</span>
        </section>
      ) : (
        ""
      );
    // 左侧金币icon
    const COIN_IMG = require("@/assets/icons-file/coins/coin@3x.png");

    // double的渲染
    if (this.isDouble) {
      return (
        <section class="coin-wrap" onClick={() => this.goBuyCoins(1, row)}>
          <section class="left">
            <section class="coin-symbol">
              <img class="img" src={COIN_IMG} />
              <section class="label">x2</section>
            </section>
            <section class="coin-num">
              <p class="now">{format(row.effect * 2)}</p>
              <p class="prev">{format(row.effect)}</p>
            </section>
            {tagRender}
          </section>
          <section class="right">
            {discountRender}
            <SkuButton>
              {format(LocalizeUtil.getLocalizePriceByOffer(row))}
            </SkuButton>
          </section>
        </section>
      );
    } else {
      // 非double的渲染
      return (
        <section
          class="single-coin-wrap coin-wrap"
          onClick={() => this.goBuyCoins(0, row)}
        >
          <section class="left">
            <section class="coin-symbol">
              <img class="img" src={COIN_IMG} />
            </section>
            <section class="coin-num">
              <p class="now">{format(row.effect)}</p>
            </section>
            {tagRender}
          </section>
          <section class="right">
            {discountRender}
            <SkuButton>
              {format(LocalizeUtil.getLocalizePriceByOffer(row))}
            </SkuButton>
          </section>
        </section>
      );
    }
  }

  // status为0表示regular，为1表示double
  private async goBuyCoins(status: number, coinInfo: any) {
    await payByAny({
      offer: coinInfo,
      situation: status ? OfferSituation.store_double : OfferSituation.default
    });
  }

  // 倒计时结束后自动切换到正常coins展示
  private closeDouble() {
    this.isDouble = false;
  }

  // 打点
  private logEvent() {
    // 先去除当前事件的所有监听器，避免事件监听器重复
    Event_bus.$off("double_coin_buying_finished");
    Event_bus.$off("leave_order_detail_to_doubleCoin");
    Event_bus.$once("leave_order_detail_to_doubleCoin", () => {
      this.$router.back();
    });
  }

  private refreshDoubleState() {
    this.isDouble = isDoubleCoins();
  }

  private init() {
    this.refreshDoubleState();
    listenNative.addListen(
      "will_enter_foreground",
      () => {
        this.refreshDoubleState();
      },
      { key: "double_coin" }
    );
  }

  private created() {
    console.log("re create");
    if (!this.hasLogEvent) {
      this.logEvent();
    }
    this.hasLogEvent = true;
    this.init();
  }

  private activated() {
    if (!this.hasLogEvent) {
      this.logEvent();
    }
    this.hasLogEvent = true;
    this.init();
  }

  private destroyed() {
    this.hasLogEvent = false;
    listenNative.removeListen("double_coin");
  }

  private deactivated() {
    this.hasLogEvent = false;
    listenNative.removeListen("double_coin");
  }
}
