










































































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import NavBar from "@/components/NavBar/NavBar.vue";
import SelectUser from "@/components/SelectUser/SelectUser.vue";
import Scrolller from "@/components/Scroller/Scroller.vue";
import UserInfo from "@/components/UserInfo/UserInfo.vue";
import SwiperContainer from "@/components/Swiper/Swiper.vue";
import NavTab from "@/components/NavTab/NavTab.vue";
import { swiperSlide } from "vue-awesome-swiper";
import SkuSection from "@/components/SkuSection/SkuSection.vue";
import SpacerArea from "@/components/SkuTitle/SpacerArea.vue";
import SkuSpecialTitle from "@/components/SkuTitle/SkuSpecialTitle.vue";
import Contact from "@/components/Contact/Contact.vue";
import {
  GETTER_OFFERS_BY_TYPE,
  GETTER_FOLLOW_OFFERS,
  GETTER_CLIENT_SETTINGS,
  UPDATE_SETTING_DATA,
  GETTER_ONLINE_SETTING_STATE,
  UPDATE_PAGE_SETTING_STATUS
} from "@/store/constants/setting_types";
import WebIndexDBLocalStorage from "@/utils/nativeStorage/native_storage";
import { HAS_SHOE_FOLLOW_DIFFERENCE } from "@/utils/nativeStorage/local_keys";
import { goToSearchOrLogin } from "@/utils/router_helper";
import {
  GETTER_TIKTOK_USER_EMPTY,
  REFRESH_CUREENT_TIKTOK_USER_INFO
} from "@/store/constants/tt_user_types";
import EventBus from "@/utils/event_bus";
import LinearBack from "@/components/LinearBack/LinearBack.vue";
import * as cgConst from "@/api/cg/base/cg_const";
import { showFollowDifference } from "@/services/dialog_utils";
import { Offer, OfferKind, OfferWay } from "@/services/offer/offer_handle";
import { payByAny } from "@/services/payment/payment_check/payment_check_logic";
import { intersection_observer } from "@/utils/intersection_observer/intersection_observer";
import RenderPlaceholder from "@/components/RenderPlaceholder/RenderPlaceholder.vue";
@Component({
  components: {
    NavBar,
    SelectUser,
    Scrolller,
    NavTab,
    UserInfo,
    SwiperContainer,
    swiperSlide,
    SkuSection,
    SpacerArea,
    SkuSpecialTitle,
    LinearBack,
    Contact,
    RenderPlaceholder,
    SpecialOffer: () => import("@/components/SpecialOffer/SpecialOffer.vue")
  }
})
export default class FollowersContainer extends Vue {
  @Getter(GETTER_OFFERS_BY_TYPE)
  private getterOfferByType: any;
  @Action(UPDATE_SETTING_DATA)
  private updateSettingData: any;
  @Action(REFRESH_CUREENT_TIKTOK_USER_INFO)
  private refreshTiktokUser: any;
  @Getter(GETTER_TIKTOK_USER_EMPTY)
  private isTiktokEmpty?: boolean;
  @Getter(GETTER_CLIENT_SETTINGS)
  private clientSetting: any;
  @Getter(GETTER_FOLLOW_OFFERS)
  private gettersFollowOffers: any;
  @Mutation(UPDATE_PAGE_SETTING_STATUS)
  private updatePageSettingStatus?: any;

  private render_delay_switch: boolean = false;
  private fixedTabBarShow: boolean = false; //固定tabbar显示
  private activeIndex: number = 0;
  private swiperHeight: string = "auto";
  private tabsArr = [
    {
      label: "Followers",
      acitveColor: "rgb(251, 22, 94)",
      activeTextColor: "rgb(28,30,31)"
    },
    {
      label: "Golden Followers",
      acitveColor: "rgb(255, 185, 7)",
      showDot: false,
      activeTextColor: "rgb(28,30,31)"
    }
  ];

  get followersOfferIap() {
    return this.getterOfferByType(OfferKind.follow);
  }

  get followersOfferCoins() {
    return this.getterOfferByType(OfferKind.follow, OfferWay.coin);
  }

  get goldenFollowersOfferIap() {
    return this.getterOfferByType(OfferKind.golden_follow);
  }

  get goldenFollowersOfferCoins() {
    return this.getterOfferByType(OfferKind.golden_follow, OfferWay.coin);
  }

  /* 是否显示tab label的圆点 */
  get isShowGoldenFollowDot() {
    return this.tabsArr[1].showDot;
  }

  @Watch("activeIndex")
  private watchActiveIndex(val: any) {
    console.log("watchActiveIndex", val);
    if (val === 1) {
      this.checkIsShowDifference();
    }
    this.calcSwiperHeight(val);
  }

  // // 监听是否拉取过最新setting
  // @Watch("getterOnlineSettingsState", { immediate: true })
  // private onlineSettingStateChange(val: any) {
  //   // 拉到线上setting之后执行开机弹窗逻辑
  //   if (val) {
  //     setTimeout(() => {
  //       // 必须是在首页才执行开机弹窗逻辑
  //       const currentRoute = getCurrentRoute();
  //       if (
  //         currentRoute &&
  //         currentRoute.path &&
  //         currentRoute.path.includes("/Home")
  //       ) {
  //         getNotification() ? UmengPush.checkLaunchNotice() : generator.next();
  //       }
  //     }, 300);
  //   }
  // }

  /* 当切换tab是否自动显示Difference */
  private async checkIsShowDifference() {
    const isShowDifference = await WebIndexDBLocalStorage.get(
      HAS_SHOE_FOLLOW_DIFFERENCE
    );
    if (!isShowDifference) {
      showFollowDifference();
      WebIndexDBLocalStorage.set(HAS_SHOE_FOLLOW_DIFFERENCE, true);
    }
    if (this.isShowGoldenFollowDot) {
      this.switchShowGoldenFollowDot(false);
    }
  }

  private calcSwiperHeight(val: number) {
    this.$nextTick(() => {
      const currentSlide: any = (this.$refs["slideRef" + val] as any).$el;
      const calcHeight = currentSlide
        ? currentSlide.offsetHeight + "px"
        : "auto";
      console.log("currentSlide", currentSlide);
      if (calcHeight && this.swiperHeight !== calcHeight) {
        this.swiperHeight = calcHeight;
      }
    });
  }

  private skuMounted() {
    console.log("skuMounted");
    this.calcSwiperHeight(this.activeIndex);
  }
  private clickQuestion() {
    showFollowDifference();
  }
  private clickSelectUser() {
    if (this.isTiktokEmpty) {
      goToSearchOrLogin();
    } else {
      EventBus.$emit("show-account-list", {
        show: true
      });
    }
  }
  private toCoinStore() {
    this.$router.push({
      name: "Coins"
    });
  }
  private buyOffer(offer: Offer) {
    payByAny({ offer });
  }

  private async pageInit() {
    await this.checkIsShowGoldenFollowDot();
  }

  private async refreshTikUserInfo(done: any) {
    try {
      await this.refreshTiktokUser();
      done && done();
    } catch (error) {
      done && done();
    }
  }

  /* 初始化页面时是否显示dot */
  private async checkIsShowGoldenFollowDot() {
    const hasKey = await WebIndexDBLocalStorage.has(HAS_SHOE_FOLLOW_DIFFERENCE);
    const isShowDifference = await WebIndexDBLocalStorage.get(
      HAS_SHOE_FOLLOW_DIFFERENCE
    );
    if (!hasKey || !isShowDifference) {
      this.switchShowGoldenFollowDot(true);
    }
  }

  /* 切换tab label的圆点显示或隐藏 */
  private switchShowGoldenFollowDot(status: boolean) {
    this.tabsArr[1].showDot = status;
  }

  private created() {
    this.pageInit();
  }

  private intersection_observer_scroll() {
    const root_el = document.querySelector("#scroll-fixed-container");
    const target_el = (this.$refs.navTabBar as any).$el;
    //
    intersection_observer(
      root_el,
      target_el,
      ({ target_intersect, target_top, target_ratio }: any) => {
        if (target_top < 200 && target_ratio > 0) {
          // 避免ios弹性向下滚动，也会触发区域交叉
          this.fixedTabBarShow = !target_intersect;
        }
      }
    );
  }

  private deactivated() {
    // 再次隐藏，避免回来时滚动是初始状态，但是还是显示了
    this.fixedTabBarShow && (this.fixedTabBarShow = false);
  }

  private mounted() {
    // this.render_delay_switch = false;
    this.watchActiveIndex(0);
    // add observer scroll
    this.$nextTick(() => {
      this.intersection_observer_scroll();
    });
    // 延迟更新setting
    this.updateSettingData(cgConst.TAB_FOLLOW_KEYS)
      .then(() => {
        this.updatePageSettingStatus("follow");
      })
      .catch(() => void 0);
  }
}
