











































import { Component, Vue, Watch } from "vue-property-decorator";
import { Action, Getter, Mutation } from "vuex-class";
import {
  UPDATE_SETTING_DATA,
  GETTER_OFFERS_BY_TYPE,
  GETTER_LIKE_OFFERS,
  GETTER_CLIENT_SETTINGS
} from "@/store/constants/setting_types";
import NavBar from "@/components/NavBar/NavBar.vue";
import Scrolller from "@/components/Scroller/Scroller.vue";
import NavTab from "@/components/NavTab/NavTab.vue";
import Swiper from "@/components/Swiper/Swiper.vue";
import { swiperSlide } from "vue-awesome-swiper";
import SkuSection from "@/components/SkuSection/SkuSection.vue";
import CoinsTitle from "@/components/SkuTitle/SpacerArea.vue";
import SelectUser from "@/components/SelectUser/SelectUser.vue";
import LikeInfo from "@/components/LikeInfo/LikeInfo.vue";
import EventBus from "@/utils/event_bus";
import {
  GETTER_TIKTOK_USER_EMPTY,
  GEETER_TIKTOK_LOGIN_USER
} from "@/store/constants/tt_user_types";
import { goToSearchOrLogin } from "@/utils/router_helper";
import {
  FETCH_FIRST_PAGE_MEDIA_LIST,
  REFRESH_CURRENT_USER_MEDIA_LIST,
  GETTER_CURRENT_TIKTOK_USER_MEDIA
} from "@/store/constants/tt_media_types";
import {
  GETTER_LIKE_FORCE_LOGIN_ENABLED,
  UPDATE_PAGE_SETTING_STATUS
} from "@/store/constants/setting_types";
import TiktokUserMedaiUtil from "@/utils/tik_user_media/tik_user_media";
import LinearBack from "@/components/LinearBack/LinearBack.vue";
import Contact from "@/components/Contact/Contact.vue";
import SpecialOffer from "@/components/SpecialOffer/SpecialOffer.vue";
import { Offer, OfferKind, OfferWay } from "@/services/offer/offer_handle";
import * as cgConst from "@/api/cg/base/cg_const";
import { payByAny } from "@/services/payment/payment_check/payment_check_logic";
import { tt_error_toast } from "@/utils/toast_helpers/tt_toast_helpers";
@Component({
  components: {
    NavTab,
    Swiper,
    swiperSlide,
    NavBar,
    Scrolller,
    SkuSection,
    CoinsTitle,
    SelectUser,
    LikeInfo,
    LinearBack,
    Contact,
    SpecialOffer
  }
})
export default class LikesContainer extends Vue {
  @Action(UPDATE_SETTING_DATA)
  private updateSettingData: any;
  @Action(FETCH_FIRST_PAGE_MEDIA_LIST)
  private fetchFirstPageMedia: any;
  @Action(REFRESH_CURRENT_USER_MEDIA_LIST)
  private refreshMediaList: any;
  @Getter(GETTER_OFFERS_BY_TYPE)
  private getterOfferByType: any;
  @Getter(GETTER_LIKE_OFFERS)
  private getterLikeOffer: any;
  @Getter(GETTER_CLIENT_SETTINGS)
  private clientSetting: any;
  @Getter(GETTER_TIKTOK_USER_EMPTY)
  private isTiktokEmpty?: boolean;
  @Getter(GEETER_TIKTOK_LOGIN_USER)
  private loginUser?: boolean;
  @Getter(GETTER_CURRENT_TIKTOK_USER_MEDIA)
  private currentUserMedia?: any;
  @Getter(GETTER_LIKE_FORCE_LOGIN_ENABLED)
  private likeForceLoginEnabled?: any;
  @Mutation(UPDATE_PAGE_SETTING_STATUS)
  private updatePageSettingStatus?: any;

  private activeIndex = 0;
  private cureentMediaInfo: any = null;
  private swiperHeight = "auto";
  private isFetchingVideos: boolean = false;

  @Watch("cureentMediaInfo")
  private WatchCurrentMediaInfo() {
    if (this.activeIndex === 1) {
      this.calcSwiperHeight(1);
    }
  }

  @Watch("activeIndex")
  private changeSwiperHeight(val: number) {
    this.calcSwiperHeight(val);
  }

  private calcSwiperHeight(val: number) {
    this.$nextTick(() => {
      const currentSlide = document.querySelector(
        "#likes .swiper-slide-" + val
      ) as HTMLElement;
      this.swiperHeight = currentSlide
        ? currentSlide.offsetHeight + "px"
        : "auto";
    });
  }

  get likesOfferIap() {
    return this.getterOfferByType(OfferKind.like);
  }

  get likesOfferCoins() {
    return this.getterOfferByType(OfferKind.like, OfferWay.coin);
  }

  private clickSelectUser() {
    if (this.isTiktokEmpty) {
      goToSearchOrLogin("from_like");
    } else {
      EventBus.$emit("show-account-list", {
        show: true,
        from: "from_like"
      });
    }
  }

  private toCoinStore() {
    this.$router.push({
      name: "Coins"
    });
  }

  private async refreshTikTokMedia(done: any) {
    try {
      // 避免切换tab重复加载
      if (this.isFetchingVideos) {
        done && done();
        return;
      }
      this.isFetchingVideos = true;
      //避免时间久了没加载到数据，再次下拉刷新，没有效果
      const timer = setTimeout(() => {
        this.isFetchingVideos = false;
      }, 3000);
      await this.refreshMediaList();
      done && done();
      this.isFetchingVideos = false;
      clearInterval(timer);
    } catch (error) {
      done && done();
      tt_error_toast(error);
      this.isFetchingVideos = false;
    }
  }

  private buyOffer(offer: Offer) {
    payByAny({ offer });
  }

  private getCurrentMedia() {
    this.cureentMediaInfo = TiktokUserMedaiUtil.getCurrentPost();
  }

  private async getFirstPageMedia() {
    try {
      this.$refs.likeScroller && (this.$refs.likeScroller as any).showLoading();
      // 避免切换tab重复加载
      if (this.isFetchingVideos) {
        return;
      }
      this.isFetchingVideos = true;
      //避免时间久了没加载到数据，再次下拉刷新，没有效果
      const timer = setTimeout(() => {
        this.isFetchingVideos = false;
      }, 3000);
      await this.fetchFirstPageMedia("from_like");
      clearInterval(timer);
    } catch (error) {
      //
    } finally {
      this.$refs.likeScroller && (this.$refs.likeScroller as any).hideLoading();
      this.isFetchingVideos = false;
    }
  }

  private beforeCreate() {
    TiktokUserMedaiUtil.init();
  }

  private created() {
    this.updateSettingData(cgConst.TAB_LIKE_KEYS)
      .then(() => {
        this.updatePageSettingStatus("like");
      })
      .catch(() => void 0);
  }

  private mounted() {
    this.getCurrentMedia();
    EventBus.$on("ins_media_change", () => {
      this.getCurrentMedia();
    });
    EventBus.$on("changed_current_account", () => {
      // 判断是否需要拉取first_page_media,不需要的话，还需要切换current_post
      if (this.currentUserMedia) {
        //
        TiktokUserMedaiUtil.init();
      } else {
        this.getFirstPageMedia();
      }
    });
  }

  private async activated() {
    this.getFirstPageMedia();
  }
}
