import { BASE_GX_URL, LAUNCH_EVENT_URL } from "@/config";
export interface UrlMap {
  name?: string;
  url: string;
  realm?: string; // 指定访问域名
}

export const DEFAULT_PARAMS = {
  url: BASE_GX_URL,
  timeout: 15,
  method: "POST"
};

export const GX_FETCH_SETTING: UrlMap = {
  url: `/OeFS69/99fYDG/VSWL82/RemJpz`,
  name: "cg_api_get_v3_settings_content"
};
export const GX_FETCH_APPS: UrlMap = {
  url: `/api/v5/apps/ad`,
  name: "cg_api_get_v5_fetch_apps"
};

export const APP_FLYERS_INFO: UrlMap = {
  url: `/api/v5/app_info`,
  name: "cg_api_post_v5_send_app_flyers_info"
};

export const GX_LOGIN = (userId: string): UrlMap => {
  return {
    url: `/ZtPdP5/n6oKLc/4qKGR3/${userId}/qYWn1G`,
    name: "cg_api_post_v1_user_login"
  };
};

export const SERVICE_LIST: UrlMap = {
  url: "/7KHK6q/DcIrv5/ZWkk3M/b8osLE",
  name: "cg_api_get_v5_service_list"
};

export const LINK_ACCOUNT = (userId: string): UrlMap => {
  return {
    url: `/ZtPdP5/n6oKLc/4qKGR3/${userId}/qYWn1G`,
    name: "cg_api_post_v1_link_account"
  };
};

export const EVENT_TRACKING: UrlMap = {
  url: `/event/tracking`
};

export const LAUNCH_EVENT: UrlMap = {
  url: `/web/log/other`,
  realm: LAUNCH_EVENT_URL
};

export const GET_USER_INFO = (userId: string | number): UrlMap => {
  return {
    url: `/bBE5r2/ZMCq4x/evrqQI/${userId}/CxfhS1`,
    name: "cg_api_get_v1_user_info"
  };
};

export const DISCONNECT_USER = (userId: string): UrlMap => {
  return {
    url: `/9A07IG/aTBpxy/PuWlyQ/${userId}/ZaWrUy`,
    name: "cg_api_post_v1_user_disconnect"
  };
};

// api/v5/freefollow/check
export const CHECK_FREE_FOLLOW: UrlMap = {
  url: `/XAm87G/DcIrv5/X101CD/BetlP6`,
  name: "cg_api_post_v5_free_check"
};
// api/v5/freefollow/deliver
export const DELIVER_FREE_FOLLOW: UrlMap = {
  url: `/XAm87G/DcIrv5/X101CD/mYFG36`,
  name: "cg_api_post_v5_free_deliver"
};

export const ADVERTISEMENT_ACT: UrlMap = {
  url: "/XAm87G/DcIrv5/8Ebb4M/qmHwJq",
  name: "cg_api_post_v5_advertisement_act"
};
export const ADVERTISEMENT_LIST: UrlMap = {
  url: "/XAm87G/DcIrv5/8Ebb4M/b8osLE",
  name: "cg_api_post_v5_advertisement_list"
};

export const POST_OFFER: UrlMap = {
  url: `/RfQonr/2WCTe7/umpXaM/4zUQiJ`,
  name: "cg_api_post_v3_post_offer"
};
export const POST_OFFER_BY_COIN: UrlMap = {
  url: `/RfQonr/2WCTe7/umpXaM/CRbms6`,
  name: "cg_api_post_v3_post_offer_by_coins"
};

export const GET_ORDER: UrlMap = {
  url: `/api/v3/offer/history`,
  name: "cg_api_get_v3_iap_history"
};

export const GET_FEED = (userId: string): UrlMap => {
  return {
    url: `/RfQonr/V8QwWT/UmWN3m/s1oQRQ/${userId}`,
    name: "cg_api_get_v1_get_feed"
  };
};

export const ACT_FEED = (feedId: string | number): UrlMap => {
  return {
    url: `/jWaOmz/5ZWEzb/BCH488/${feedId}/N5N9iI`,
    name: "cg_api_post_v1_feed_act"
  };
};

export const ACTIVE_TASK = (masterId: string | number): UrlMap => {
  return {
    url: `/jdO5Pk/qJyTK4/eG3bhj/${masterId}/qmHwJq`,
    name: "cg_api_post_v1_act_task"
  };
};

export const BIND_VIP: UrlMap = {
  url: `/7KHK6q/zck6JE/Q5ltTM/ZWkk3M/OIgg0w`,
  name: "cg_api_post_v3_bind_ins_user_vip"
};
export const TRANSFER_MASTER_ACCOUNT: UrlMap = {
  url: `/OeFS69/2WCTe7/evrqQI/WgKohx`,
  name: "cg_api_post_v3_transfer_master_account"
};
// ============== pinad ===============
export const CONNECT: UrlMap = {
  url: `/ad/v1/connect`,
  name: "cg_api_post_v1_pinad_connect"
};
export const GET_RECOMMEND: UrlMap = {
  url: `/ad/v1/recommend`,
  name: "cg_api_get_v1_get_hot_app_recommend"
};
export const RECOMMEND_START: UrlMap = {
  url: `/ad/v1/recommend/start`,
  name: "cg_api_post_v1_pinad_start"
};
export const RECOMMEND_FINISH: UrlMap = {
  url: `/ad/v1/recommend/finish`,
  name: "cg_api_post_v1_pinad_finish"
};

// ALL setting keys
export const DEFAULT_SETTING_KEYS: string[] = [
  "client_settings",
  "sale",
  "rewards",
  "rate_task",
  "checkin_rewards",
  "follow_offers",
  "golden_follow_offers",
  "like_offers",
  "comment_offers",
  "view_offers",
  "story_offers",
  "special_follow_offers",
  "special_like_offers",
  "auto_subs_follow_offers",
  "auto_subs_like_offers",
  "coin_offers",
  "limited_coin_offers",
  "lucky_coin_offers",
  "half_coin_offers",
  "feed_coin_offers",
  "service_offers",
  "vip_package_offers",
  "casino",
  "pro_extra_percentage",
  "free_followers_for_newbie_enabled",
  "recommend_offers",
  "big_prices"
];

// 初始化进来需要的
export const COMMON_KEYS = {
  module_name: "common",
  keys: [
    "client_settings", // 客户端参数
    "sale", // double coins...
    "coin_offers", // double coins...
    "free_followers_for_newbie_enabled", // free follow
    "recommend_offers", // recommend offer
    "service_offers", // vip
    "big_prices",
    "sp_bonus_coins",
    "follow_offers"
  ]
};

// tab1 需要的
export const TAB_FOLLOW_KEYS = {
  module_name: "tab_follow",
  keys: ["follow_offers", "golden_follow_offers", "special_follow_offers"]
};

// tab2 需要的
export const TAB_LIKE_KEYS = {
  module_name: "tab_like",
  keys: ["like_offers", "comment_offers", "view_offers", "special_like_offers"]
};

// tab3 需要的
export const TAB_EARN_COINS_KEYS = {
  module_name: "tab_earn_coins",
  keys: ["feed_coin_offers", "rewards"]
};

// tab4 需要的
export const TAB_COIN_STORE_KEYS = {
  module_name: "tab_coin_store",
  keys: [
    "lucky_coin_offers",
    "vip_package_offers",
    "limited_coin_offers",
    "rewards",
    "rate_task"
  ]
};
