






















import { Component, Vue, Prop, Watch, Emit } from "vue-property-decorator";
import listenNative from "@/jsbridge/service/listenNative";
@Component({
  components: {}
})
export default class VideoPlayer extends Vue {
  @Prop({ default: "" })
  private poster?: any;
  @Prop({ default: "" })
  private videoSrc?: any;
  @Prop({ default: {} })
  private videoStyle?: any;
  @Prop({ default: {} })
  private boxStyle?: any;
  @Prop({ default: true })
  private needLoop?: any;
  @Prop({ default: true })
  private autoplay?: any;

  private videoReady: any = false;
  private foregroundListener: any = null;
  private domReady: any = false;

  get wrappeStyle() {
    return {
      backgroundImage: `url(${this.poster})`,
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      width: "100%",
      height: "100%"
    };
  }

  private addCountDownListener() {
    if (!this.foregroundListener) {
      this.foregroundListener = listenNative.addListen(
        "will_enter_foreground",
        () => {
          this.playVedio();
        }
      );
    }
  }

  private playVedio() {
    this.$nextTick(() => {
      const videoRef: any = this.$refs.videoRef;
      try {
        console.log("videoRef", videoRef);
        const playPromise = videoRef && videoRef.play();

        if (playPromise !== undefined) {
          playPromise
            .then(() => {
              // Automatic playback started!
              console.log("playPromise success");
            })
            .catch((error: any) => {
              console.log("playPromise", error);
              // Auto-play was prevented
            });
        }
      } catch (error) {
        // (Error as any).catchErrorManually(error);
        return Promise.reject(error);
      }
    });
  }

  private removeCountDownListener() {
    if (this.foregroundListener !== null) {
      listenNative.removeListen(this.foregroundListener);
    }
  }

  private beforeDestroy() {
    this.removeCountDownListener();
  }

  private deactivated() {
    this.domReady = false;
    this.removeCountDownListener();
  }

  private activated() {
    this.playVedio();
    this.$nextTick(() => {
      setTimeout(() => {
        this.domReady = true;
      }, 0);
    });
    this.addCountDownListener();
  }

  private mounted() {
    this.addCountDownListener();
    this.playVedio();
    this.$nextTick(() => {
      this.domReady = true;
      const videoRef: any = this.$refs.videoRef;
      videoRef &&
        videoRef.addEventListener("canplaythrough", () => {
          console.log("canplaythrough:", videoRef.currentTime);
          this.videoReady = true;
          this.$emit("videoReady");
        });
    });
  }
}
