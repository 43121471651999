















import { Component, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  GEETER_TIKTOK_USER_LIST,
  GETTER_TIKTOK_USER_EMPTY
} from "@/store/constants/tt_user_types";
import EmptyUser from "@/components/UserInfo/EmptyUser.vue";
import UserOneItem from "./UserItem/UserOneItem.vue";
import UserMoreItem from "./UserItem/UserMoreItem.vue";
import ManageEntrance from "./ManageEntrance/ManageEntrance.vue";
import { goToSearchOrLogin } from "@/utils/router_helper";
@Component({
  components: { EmptyUser, UserMoreItem, UserOneItem, ManageEntrance }
})
export default class MoreUserInfo extends Vue {
  @Getter(GEETER_TIKTOK_USER_LIST)
  private insUserList: any;
  @Getter(GETTER_TIKTOK_USER_EMPTY)
  private insUserEmpty: any;

  private clickMore() {
    if (this.insUserEmpty) {
      goToSearchOrLogin();
    } else {
      this.$router.push({
        name: "AccountManage",
        query: {
          routerTransition: "forward"
        }
      });
    }
  }
}
