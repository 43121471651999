/**
 * cg api的拦截器功能：
 */
import { base64ToJson, cryptoBodyOrParams } from "@/api/utils/api_encrypt";
import { decrypt, getEncryptData } from "@/api/utils/api_encrypt";
import { DEFAULT_PARAMS } from "../base/cg_const";
import {
  addBodyDefaultParams,
  interceptUrlParamsToQuery,
  signHeadersStringFactory,
  getContentType
} from "./interceptors_helper";
import { transformParamsToString } from "@/api/utils/api_helpers";

// =================== [REQUEST] ===============================

// 对于pinad的api，处理请求头的加密签名校验
export function encryptRequestHeaders(config: any) {
  // request.headers = {};
  config.extra_data &&
    config.extra_data.sign_header &&
    (config.headers["Websame-Signature"] = cryptoBodyOrParams(
      signHeadersStringFactory(config)
    ));
  return config;
}

/**
 * 添加默认参数并加密
 * @param config
 */
export const addDefaultParamsAndEncrypt = async (config: any) => {
  const encryptBody = config.extra_data && config.extra_data.encrypt_body;
  if (!encryptBody) {
    return config;
  }
  const dataBody = {
    method: config.method,
    path: config.url,
    query: "",
    body: ""
  };
  if (config.method === "GET") {
    config.params = await addBodyDefaultParams(config.params);
    dataBody.query = transformParamsToString(config.params);

    const { result_url, result_query } = interceptUrlParamsToQuery(config.url);
    result_url && (dataBody.path = result_url);
    result_query && (dataBody.query += "&" + result_query);
    config.params &&
      (config.url = `${config.url}?${transformParamsToString(config.params)}`);
  } else {
    config.data = await addBodyDefaultParams(config.params);
    dataBody.body = config.data;
  }
  // 对url编码
  const encodeURL: any = config.disableEncode
    ? config.url
    : encodeURI(config.url);

  config.url = encodeURL;

  console.log("dataBody", dataBody);

  // 判断当前接口是否需要做加密操作
  if (
    config.extra_data &&
    config.extra_data.hasOwnProperty("encryptHandle") &&
    !config.extra_data.encryptHandle
  ) {
    return {
      url: config.url,
      method: config.method,
      data: config.data,
      extra: config.extra_data
    };
  }
  return {
    ...DEFAULT_PARAMS,
    data: getEncryptData(dataBody),
    extra: config.extra_data,
    headers: {
      "content-type": "application/json"
    }
  };
};

// =================== [RESPONSE] ===============================
// 处理http解析之后的内容
export function responseDataDecode(responseData: any) {
  let result = base64ToJson(responseData);
  // 解密response的data，只有pure的get请求才需要解密
  if (typeof result === "string") {
    result = JSON.parse(decrypt(result));
  }
  if (result && result.meta && result.meta.code === 200) {
    return typeof result.data === "string"
      ? base64ToJson(result.data)
      : result.data || result.meta.data;
  } else {
    const err = result && result.meta && result.meta.error_message;
    return Promise.reject(err);
  }
}

// http的异常抛出
export function responseToError(response: any) {
  const { status_code, data } = response;
  if (status_code && String(status_code)[0] === "4") {
    return Promise.reject(base64ToJson(data));
  } else {
    return data;
  }
}
