const WEB_CODE_VERSION = process.env.VUE_APP_VERSION; // web版本号

const BASE_GX_URL = process.env.VUE_APP_GX_BASE_URL;
const LAUNCH_EVENT_URL = process.env.VUE_APP_GX_LAUNCH_EVENT_URL;
const PIN_AD_BASE_URL = process.env.VUE_APP_GX_PIN_AD_BASE_URL; // cg的基本路径
const UMENG_BASE_URL = process.env.VUE_APP_GX_UMENG_BASE_URL; // 友盟打点
const ANDROID_SUPER_PAY_BASE = process.env.VUE_APP_ANDROID_SUPER_PAY_BASE;
const CDN_FILES_BASE_URL = process.env.VUE_APP_CDN_FILES_BASE_URL; // cdn的地址路径
const GA_ID = process.env.VUE_APP_GA_ID; // ga打点的id
const TT_SCRIPT_URL = process.env.VUE_APP_TT_SCRIPT_URL; //tt script脚本地址
const WEB_FONT_URL = process.env.VUE_APP_WEB_FONT_CDN_URL; // webfont

export const CDN_BASE_URL: any = {
  img: "https://cdn.soupep.com/img",
  js: "https://cdn.soupep.com/js",
  css: "https://cdn.soupep.com/css",
  video: "https://cdn.soupep.com/video"
};

export {
  GA_ID,
  BASE_GX_URL,
  TT_SCRIPT_URL,
  WEB_CODE_VERSION,
  PIN_AD_BASE_URL,
  UMENG_BASE_URL,
  CDN_FILES_BASE_URL,
  WEB_FONT_URL,
  ANDROID_SUPER_PAY_BASE,
  LAUNCH_EVENT_URL
};
