// eslint-disable-next-line
import { GetterTree } from "vuex";
import {
  GETTER_CURRENT_TIKTOK_USER_INFO,
  GETTER_TIKTOK_USER_EMPTY,
  GEETER_TIKTOK_USER_LIST,
  GETTER_CURRENT_TIKTOK_USER_SECRET,
  GEETER_TIKTOK_LOGIN_USER,
  TIKTOK_LOGIN_USER_EMPTY
} from "@/store/constants/tt_user_types";
import { GETTER_CURRENT_USER_ID } from "@/store/constants/index_types";
const Getters: GetterTree<any, any> = {
  [GETTER_CURRENT_TIKTOK_USER_INFO](state, getters, rootState) {
    const currntId = getters[GETTER_CURRENT_USER_ID];
    if (!currntId) {
      return null;
    }
    const findUser = state.tt_user_info_list.find(
      (item: any) => item.user_id === currntId
    );
    return findUser;
  },
  [GETTER_TIKTOK_USER_EMPTY](state) {
    return state.tt_user_info_list.length < 1;
  },
  [GEETER_TIKTOK_USER_LIST](state) {
    return state.tt_user_info_list;
  },
  [GETTER_CURRENT_TIKTOK_USER_SECRET](state, getters) {
    const userInfo = getters[GETTER_CURRENT_TIKTOK_USER_INFO];
    console.log(
      "GETTER_CURRENT_TIKTOK_USER_SECRET:",
      userInfo,
      userInfo && userInfo.tt_user_info.secret
    );
    return userInfo && userInfo.tt_user_info.secret;
  },
  [GEETER_TIKTOK_LOGIN_USER](state) {
    return state.tt_user_info_list.find(
      (item: any) => item.login_type === "login"
    );
  },
  [TIKTOK_LOGIN_USER_EMPTY](state, getters) {
    const loginList = getters[GEETER_TIKTOK_LOGIN_USER];
    return !loginList;
  }
};
export default Getters;
