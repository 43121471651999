














import { Component, Vue, Emit, Prop, Watch } from "vue-property-decorator";
import Jsbridge from "@/jsbridge/index";
@Component({
  components: {}
})
export default class CountDownTime extends Vue {
  @Prop({ default: 0 })
  private endTimeStamp?: number; // 倒计时时间的结束
  @Prop({ default: require("@/assets/icons-file/special_offer/clock@3x.png") })
  private iconSrc?: string; // icon的src，不需要icon则传''
  @Prop({ default: "" })
  private style?: string; // 设置div以及文字样式
  @Prop({ default: "width:14px" })
  private iconStyle?: string; // 设置icon样式
  @Prop({ default: true })
  private showIcon?: boolean; // 是否显示icon
  @Prop({ default: 3 })
  private timeUni?: number; // 计时单位精确数量
  @Prop({ default: true })
  private needZero?: boolean; // 需要补0

  private timeStr: any = "";
  private timer: any = null;
  private foregroundListener: any = null;

  @Watch("endTimeStamp", { immediate: true })
  private change(val: number, oldVal: number) {
    console.log("endTimeStamp:", {
      val,
      oldVal
    });
    if (val !== oldVal) {
      this.triggerCountDown(val);
    }
  }

  private addCountDownListener() {
    if (!this.foregroundListener) {
      this.foregroundListener = Jsbridge.addListen(
        "will_enter_foreground",
        () => {
          this.triggerCountDown(this.endTimeStamp);
        }
      );
    }
  }

  private removeCountDownListener() {
    if (this.foregroundListener !== null) {
      Jsbridge.removeListen(this.foregroundListener);
    }
  }

  private clearTimerAndListener() {
    this.removeCountDownListener();
    clearInterval(this.timer);
  }

  private triggerCountDown(endTimeStamp: any) {
    console.log("triggerCountDown:", endTimeStamp);
    // console.log(`切换到前台，重新开启countdow的计时器: ${(this.endTimeStamp - new Date().valueOf()) / 1000}s`);
    const nowTime = new Date().valueOf();
    const time = Math.round((endTimeStamp - nowTime) / 1000);
    if (time > 0) {
      this.startCountDown(time);
    } else {
      this.$emit("countDownTimeEnded");
      this.clearTimerAndListener();
    }
  }

  private startCountDown(time: any) {
    this.clearTimerAndListener();
    this.getTimeStr(time);
    this.addCountDownListener();
    this.timer = setInterval(() => {
      time--;
      if (time > 0) {
        this.getTimeStr(time);
        this.$emit("countingDown", time); // 新增一个countingDown事件返回
      } else {
        this.$emit("countDownTimeEnded");
        this.clearTimerAndListener();
      }
    }, 1000);
  }

  private addZero(num: number) {
    if (this.needZero) {
      return num < 10 ? "0" + num : num;
    } else {
      return num;
    }
  }

  private getTimeStr(time: number) {
    let hour: number | string = Math.floor(time / 3600);
    hour = this.addZero(hour);
    let min: number | string = 0;
    let sec: number | string = Math.round(time % 60);
    sec = this.addZero(sec);

    if (this.timeUni === 3) {
      min = Math.floor((time % 3600) / 60);
      min = this.addZero(min);
      this.timeStr = hour + ":" + min + ":" + sec;
    } else if (this.timeUni === 2) {
      min = Math.floor(time / 60);
      min = min < 10 ? "0" + min : min;
      this.timeStr = min + ":" + sec;
    } else if (this.timeUni === 1) {
      this.timeStr = sec;
    }
  }
  private mounted() {
    this.triggerCountDown(this.endTimeStamp);
  }

  private activated() {
    this.triggerCountDown(this.endTimeStamp);
  }

  private beforeDestroy() {
    console.log("countDown beforeDestroy:", this);
    this.clearTimerAndListener();
  }

  private deactivated() {
    this.clearTimerAndListener();
  }
}
