

























import { Component, Emit, Prop, Vue } from "vue-property-decorator";
import { Getter } from "vuex-class";
import {
  GETTER_CURRENT_TIKTOK_USER_INFO,
  GEETER_TIKTOK_LOGIN_USER
} from "@/store/constants/tt_user_types";
import EmptyAccountPalce from "@/components/EmptyAccountPlace/EmptyAccountPlace.vue";
import ImgFetched from "@/components/ImgFetched/ImgFetched.vue";
@Component({
  components: { EmptyAccountPalce, ImgFetched }
})
export default class SelectUser extends Vue {
  @Getter(GETTER_CURRENT_TIKTOK_USER_INFO)
  private currentTiktokUserInfo: any;
  @Getter(GEETER_TIKTOK_LOGIN_USER)
  private loginTiktokUser: any;
  @Prop()
  private login: any;
  @Prop({ default: false })
  private abnormal?: boolean;

  get userPic() {
    return this.login
      ? this.loginTiktokUser &&
          this.loginTiktokUser.tt_user_info.profile_pic_url
      : this.currentTiktokUserInfo &&
          this.currentTiktokUserInfo.tt_user_info.profile_pic_url;
  }

  get placeHolder() {
    return require("@/assets/icons-file/common/follower_placeholder.png");
  }

  @Emit("clickSelectUser")
  private clickSelectUser() {
    // console.log('click ')
  }
}
