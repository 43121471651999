export const FETCH_TIKTOK_MEDIA_LIST = "FETCH_TIKTOK_MEDIA_LIST";
export const UPDATE_TIKTOK_MEDIA_LIST = "UPDATE_TIKTOK_MEDIA_LIST";
export const GETTER_CURRENT_TIKTOK_USER_MEDIA =
  "GETTER_CURRENT_TIKTOK_USER_MEDIA";
export const CURRENT_TIKTOK_USER_MEDIA_IS_EMPTY =
  "CURRENT_TIKTOK_USER_MEDIA_IS_EMPTY";
export const LOAD_MORE_MEDIA_LIST = "LOAD_MORE_MEDIA_LIST";
export const FETCH_FIRST_PAGE_MEDIA_LIST = "FETCH_FIRST_PAGE_MEDIA_LIST";
export const REFRESH_CURRENT_USER_MEDIA_LIST =
  "REFRESH_CURRENT_USER_MEDIA_LIST";
export const CHECK_FORCE_LOGIN = "CHECK_FORCE_LOGIN";
