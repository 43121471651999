import Vue from "vue";

const observable_skip_delay: any = Vue.observable({
  is_skip_delay: false,
  end_limit_time_stamp: 0
});

const skip_delay_getters = {
  is_skip_delay() {
    return observable_skip_delay.is_skip_delay;
  },
  end_limit_time_stamp() {
    return observable_skip_delay.end_limit_time_stamp;
  }
};

const skip_delay_mutaions = {
  reset_limited() {
    observable_skip_delay.is_skip_delay = false;
    observable_skip_delay.end_limit_time_stamp = 0;
  },
  start_skip_delay(delay_time: number) {
    observable_skip_delay.is_skip_delay = true;
    observable_skip_delay.end_limit_time_stamp =
      new Date().valueOf() + delay_time * 1000;
  }
};

export { observable_skip_delay, skip_delay_getters, skip_delay_mutaions };
