













import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch
} from "vue-property-decorator";
import GoldenFollowerTip from "./GoldenFollowerTip.vue";

@Component({
  components: {
    GoldenFollowerTip
  }
})
export default class SkuSpecialTitle extends Vue {
  private item = {
    icon: "icon-type_golden",
    title: "Golden Followers"
  };

  private clickQuestion() {
    this.$emit("clickQuestion");
  }
}
