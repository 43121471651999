import Jsbridge from "@/jsbridge";
import { router, store } from "@/main";
import * as setting_types from "@/store/constants/setting_types";
import {
  LOGIN_TIKTOK_USER_ACTION,
  TIKTOK_LOGIN_USER_EMPTY
} from "@/store/constants/tt_user_types";
import routerStacks from "@/plugins/navigation/routes";

export const goToVip = (val: string, situation?: string) => {
  console.log("goToVip val", val);
  router.push({
    name: "VipPlan",
    query: {
      routerTransition: "up",
      comeFrom: val,
      situation
    }
  });
};

export const goToDoubleCoin = (delay?: boolean) => {
  const delay_time = delay ? 250 : 0;
  setTimeout(() => {
    router.push({
      name: "DoubleCoins",
      query: {
        routerTransition: "up"
      }
    });
  }, delay_time);
};

export const goToSearchOrLogin = (from?: string) => {
  const force_login = store.getters[setting_types.GETTER_FORCE_LOGIN_ENABLED];
  const loginEmpty = store.getters[TIKTOK_LOGIN_USER_EMPTY];
  const like_force_login =
    store.getters[setting_types.GETTER_LIKE_FORCE_LOGIN_ENABLED];

  if (force_login && loginEmpty) {
    store.dispatch(LOGIN_TIKTOK_USER_ACTION, from);
    return;
  }

  //判断当前页面是否在like页面:
  if (
    router.currentRoute &&
    router.currentRoute.name &&
    router.currentRoute.name === "Likes" &&
    like_force_login &&
    loginEmpty
  ) {
    store.dispatch(LOGIN_TIKTOK_USER_ACTION, from);
    return;
  }

  router.push({
    name: "SearchUser",
    query: {
      routerTransition: "up",
      from
    }
  });
};

export const goToCoinStore = () => {
  router.push({
    name: "Coins"
  });
};

export const goToFaqs = () => {
  Jsbridge.openBrowser(
    "https://ttresource.s3.amazonaws.com/html/faqs.html",
    "in_app_browser",
    {
      title: "FAQs"
    }
  ).catch(() => void 0);
};

export const launchBack = () => {
  if (routerStacks.length > 1 && routerStacks[1].name) {
    router.go(-1);
  } else {
    router.replace({
      name: "Home",
      query: {
        routerTransition: "up"
      }
    });
  }
};
