import { isString, isObject, anyTypeToString } from "@/utils/lodash";
const DEFAUL_EVENT_LABLE = "event_label";

export const paramsResolve = (params: any) => {
  try {
    if (isString(params)) {
      //
      return {
        [DEFAUL_EVENT_LABLE]: params
      };
    } else if (isObject(params)) {
      // 对象的key值需要全部转为string类型：
      Object.keys(params).forEach((key: any) => {
        params[key] = anyTypeToString(params[key]);
      });
      return params;
    } else {
      return String(params);
    }
  } catch (error) {
    return {};
  }
};
