


























import {
  Component,
  Emit,
  Inject,
  Model,
  Prop,
  Provide,
  Vue,
  Watch
} from "vue-property-decorator";
import Tag from "@/components/Tag/Tag.vue";
import { bestTagShow, getTypeObj } from "@/utils/sku_section";
import { format } from "@/utils/num_utils";
import SkuButton from "./SkuButton.vue";
import LocalizeUtil from "@/services/payment/localize";

@Component({
  components: {
    Tag,
    SkuButton
  }
})
export default class SkuList extends Vue {
  @Prop({ default: [] })
  private item: any;
  @Prop({ default: "follow" }) // 类型
  private type?: any;

  private bestTagShow(item: any) {
    return bestTagShow(item);
  }

  get typeObj() {
    return getTypeObj(this.type);
  }

  get offerNativePrice() {
    const price = LocalizeUtil.getLocalizePriceByOffer(this.item);
    return format(price);
  }

  get format() {
    return format;
  }
}
