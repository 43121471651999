














let vm: any;
import { Component, Vue } from "vue-property-decorator";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Getter } from "vuex-class";
import { GETTER_CURRENT_TIKTOK_USER_MEDIA } from "@/store/constants/tt_media_types";
import Event_bus from "@/utils/event_bus";
import TiktokUserMedaiUtil from "@/utils/tik_user_media/tik_user_media";
import { canUseWebP } from "@/utils/lodash";

@Component({
  components: { swiper, swiperSlide }
})
export default class LessPostSwiper extends Vue {
  @Getter(GETTER_CURRENT_TIKTOK_USER_MEDIA)
  private currentMediaList: any;
  private slideIndex: number = 0;
  private can_user_webp: boolean = canUseWebP();

  private image_pic(item: any) {
    return item.image_thumb;
  }

  get swiperOption() {
    return {
      slidesPerView: "auto",
      centeredSlides: true,
      initialSlide: this.slideIndex,
      lazy: {
        loadPrevNextAmount: 3
      },
      on: {
        slideChange() {
          vm.slideChange();
        },

        tap() {
          vm.clickSlide();
        }
      }
    };
  }
  get postList() {
    return this.currentMediaList && this.currentMediaList.tt_media_list;
  }

  private slideChange() {
    if (this.postSwiper) {
      const activeIndex = this.postSwiper.activeIndex;
      this.changeSwiper(activeIndex);
    }
  }

  private async changeSwiper(val: number) {
    const currentMedia = this.postList[val];
    this.slideIndex = val;
    TiktokUserMedaiUtil.setCurrentPost(currentMedia);
  }

  private clickSlide() {
    if (this.postSwiper) {
      const clickedIndex = this.postSwiper.clickedIndex;
      this.postSwiper && this.postSwiper.slideTo(clickedIndex, 0, false);
    }
  }

  get postSwiper() {
    return this.$refs.postSwiper && (this.$refs.postSwiper as any).swiper;
  }

  private swiperSwipe() {
    this.$nextTick(() => {
      const postInfo = TiktokUserMedaiUtil.getCurrentPost();
      // const postInfo: any = null;
      if (postInfo) {
        let index = this.postList.findIndex((item: any) => {
          return item.id === postInfo.id;
        });
        if (this.slideIndex !== index) {
          this.postSwiper && this.postSwiper.slideTo(index, 0, false);
          this.slideIndex = index;
        }
      }
    });
  }
  private created() {
    vm = this;
  }

  private mounted() {
    Event_bus.$on("ins_media_change", () => {
      this.swiperSwipe();
    });
  }
}
