




















import { Component, Prop, Vue } from "vue-property-decorator";
@Component
export default class UserItem extends Vue {
  @Prop()
  private userInfo: any;
  @Prop({ default: false })
  private search!: boolean;
  private vipBindId: any = "";
}
