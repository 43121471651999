import cgRequest from "@/api/cg/base";
import * as cgConst from "@/api/cg/base/cg_const";
import { getUUid } from "@/api/utils/api_helpers";

export const getSettingData = async (masterId: any, params: string[] = []) => {
  return new Promise(async (resolve, reject) => {
    try {
      const param =
        params.length > 0 ? params.join() : cgConst.DEFAULT_SETTING_KEYS.join();
      const info = {
        keys: param,
        next_page: await getUUid(),
        master_id: masterId.toString()
      };
      const result = await cgRequest(cgConst.GX_FETCH_SETTING, "GET", info);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 获取sp\ba\fa
 * @param master_id
 * @param bid 自己的bundleId
 * @param bids 当前手机的所有bundleId
 */
export const getAppsData = async (
  master_id: string,
  bid: string,
  bids: string[] = []
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const info = {
        master_id,
        bid,
        bids: JSON.stringify(bids)
      };
      const result = await cgRequest(cgConst.GX_FETCH_APPS, "GET", info);
      console.log("getAppsData result", result);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};

/**
 * 获取
 * @param master_id
 * @param platform
 * @param app_info
 * */
export const sendAppInfo = async (
  master_id: string,
  platform: string,
  app_info: any
) => {
  return new Promise(async (resolve, reject) => {
    try {
      const info = {
        master_id,
        platform,
        app_info
      };
      const result = await cgRequest(cgConst.APP_FLYERS_INFO, "POST", info);
      resolve(result);
    } catch (error) {
      reject(error);
    }
  });
};
