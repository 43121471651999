










































import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { fixedIOS } from "./fixedIOS";
import TTLoading from "@/components/LoadingAnimation/TTLoading.vue";
@Component({
  components: {
    TTLoading
  }
})
export default class Scroller extends Vue {
  @Prop()
  private pullFn?: any; // 下拉刷新的方法
  @Prop()
  private loadFn?: any; // 上拉加载更多的方法
  @Prop({ default: "Pull to refresh" })
  private refreshText?: string;
  @Prop({ default: "loading···" })
  private loadingText?: string;
  @Prop({ default: `You've reached the end` })
  private endedText?: string;
  @Prop({
    default: require("@/assets/icons-file/common/right-icon.png")
  })
  private refreshIcon?: string;

  private translateY: number = 0;
  private moveY: number = 0;
  private container: any;
  private containerHeight: number = 0;
  private isCanLoadMore = true; // 是否能加载更多，避免多次触发加载更多
  private isBeforeRefresh = true; // true 显示pull to fresh false显示loaing
  private isrefreshIconRotate = false; // fresh icon 是否旋转
  private loadMoreEnded = false; // 显示无更多
  private showLoadingMore = false; // 显示加载更多的动画
  private transitionStyle: string = "all 0ms linear 0s";
  private startOffsetX = 0;
  private startOffsetY = 0;

  public showLoading() {
    this.translateY = 50;
    this.isBeforeRefresh = false;
  }

  public hideLoading() {
    this.translateY = 0;
    this.isBeforeRefresh = true;
  }

  private touchStartEvent(e: any) {
    // 滑动的时候需要将transition的值改变，不然下拉刷新的时候，滑动不流畅
    this.transitionStyle = "all 0ms linear 0s";
    this.moveY = e.touches[0].clientY;
    this.startOffsetX = e.touches[0].clientX;
  }
  private touchMoveEvent(e: any) {
    const scrollTop = this.container.scrollTop;
    const pageHeight = e.currentTarget.getBoundingClientRect().height;
    this.containerHeight = this.container.offsetHeight;
    const nowY = e.touches[0].clientY;
    const offsetY = nowY - this.moveY;
    if (this.startOffsetY === 0) {
      this.startOffsetY = offsetY;
      this.startOffsetX = e.touches[0].clientX - this.startOffsetX;
    }
    // 避免上下滚动的同时出现左右滑动,如果是左右滑动，就不执行下拉刷新或者上拉加载
    if (Math.abs(this.startOffsetY) >= Math.abs(this.startOffsetX)) {
      e.stopPropagation();
    } else {
      return;
    }
    // 判断是否是下拉刷新
    if (scrollTop < 1) {
      if (offsetY > 0) {
        this.translateY < 100
          ? (this.translateY += offsetY)
          : (this.translateY += 0.5);
      } else {
        this.translateY > 0
          ? (this.translateY += offsetY)
          : (this.translateY = 0);
      }
      if (this.translateY > 50 && !this.isrefreshIconRotate) {
        this.isrefreshIconRotate = true;
      }
    }
    this.moveY = nowY;
    // 判断是否是上拉加载更多 loadMoreEnded为true就意味着已经全部加载完毕了
    if (
      scrollTop + this.containerHeight >= pageHeight - 100 &&
      this.isCanLoadMore &&
      this.loadFn &&
      !this.loadMoreEnded
    ) {
      this.showLoadingMore = true;
      this.isCanLoadMore = false;
      this.loadFn(this.laodDone);
    }
  }
  // 下拉刷新的回调
  private freshDone() {
    this.translateY = 0;
    this.isBeforeRefresh = true;
    this.isrefreshIconRotate = false;
    this.loadMoreEnded = false; // 刷新后，改变loadMoreEnded可以继续加载更多
  }
  // 上拉加载更多的回调函数，如果全部加载完毕拉，需要传入一个true，改变loadMoreEnded，滑动到底部，不会继续加载拉
  private laodDone(end?: boolean) {
    this.isCanLoadMore = true;
    this.showLoadingMore = false;
    end ? (this.loadMoreEnded = true) : void 0;
  }
  private touchEndEvent(e: any) {
    // 松开手指后，需要设置transition，下拉刷新返回的动画
    this.startOffsetY = 0;
    this.transitionStyle = "all 300ms linear 0s";
    if (this.translateY >= 50) {
      this.translateY = 50;
      this.isBeforeRefresh = false;
      this.pullFn ? this.pullFn(this.freshDone) : this.freshDone();
    } else if (this.translateY >= 0) {
      this.translateY = 0;
    }
  }

  private mounted() {
    this.$nextTick(() => {
      this.container = this.$refs.scrollontainer;
      this.containerHeight = this.container.offsetHeight;
    });
    fixedIOS();
  }
}
