let isIosCache: any = null; // default true

export function isNumber(val: any) {
  const regPos = /^\d+(\.\d+)?$/; // 非负浮点数
  const regNeg = /^(-(([0-9]+\.[0-9]*[1-9][0-9]*)|([0-9]*[1-9][0-9]*\.[0-9]+)|([0-9]*[1-9][0-9]*)))$/; // 负浮点数
  return regPos.test(val) || regNeg.test(val);
}

export const parsePrice = (str: string) => {
  const value = str.match(/\d+(?:\.\d+)?/g);
  return value ? value[0] : "";
};

export function isString(x: any) {
  return Object.prototype.toString.call(x) === "[object String]";
}

export function isObject(obj: any) {
  return Object.prototype.toString.call(obj) === "[object Object]";
}

export function isBlob(val: any) {
  return toString.call(val) === "[object Blob]";
}

export function isFormData(val: any) {
  return typeof FormData !== "undefined" && val instanceof FormData;
}

export function isArrayBuffer(val: any) {
  return toString.call(val) === "[object ArrayBuffer]";
}

function forEach(obj: any, fn: any) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === "undefined") {
    return;
  }

  // Force an array if not already something iterable
  if (typeof obj !== "object") {
    /*eslint no-param-reassign:0*/
    obj = [obj];
  }

  if (Array.isArray(obj)) {
    // Iterate over array values
    for (let i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
}

export function deepMerge(...params: any) {
  const result: any = {};
  function assignValue(val: any, key: any) {
    if (typeof result[key] === "object" && typeof val === "object") {
      result[key] = deepMerge(result[key], val);
    } else if (typeof val === "object") {
      result[key] = deepMerge({}, val);
    } else {
      result[key] = val;
    }
  }

  for (let i = 0, l = params.length; i < l; i++) {
    forEach(params[i], assignValue);
  }
  return result;
}

/**
 * 判断是否为JSON 字符串
 * @param str
 */
export function isJSON(str: any) {
  if (isObject(str)) {
    return true;
  } else if (!isString(str)) {
    return false;
  } else if (str === "[]") {
    return true;
  }

  str = str.replace(/\s/g, "").replace(/\n|\r/, "");

  if (/^\{(.*?)\}$/.test(str)) {
    return /"(.*?)":(.*?)/g.test(str);
  } else if (/^\[(.*?)\]$/.test(str)) {
    return str
      .replace(/^\[/, "")
      .replace(/\]$/, "")
      .replace(/},{/g, "}\n{")
      .split(/\n/)
      .map((s: any) => {
        return isJSON(s);
      })
      .reduce((prev: any, curr: any) => {
        return !!curr;
      });
  }

  return false;
}

/**
 * 删除unvalidkey
 * @param data
 */
export function deleteUnvalidKey(data: any) {
  const newObject: any = {};

  for (const props in data) {
    if (data[props] === undefined || data[props] === null) {
      console.log("prosp:", props);
    } else {
      newObject[props] = data[props];
    }
  }

  return newObject;
}

export function getTimeZone() {
  try {
    const timezoneNum = -(new Date().getTimezoneOffset() / 60);
    const timezoneStr = timezoneNum.toString();
    let timezoneResult: string;
    if (timezoneNum > 9 || timezoneNum < -9) {
      if (timezoneStr.length > 2) {
        timezoneResult = timezoneStr + "00";
      } else {
        timezoneResult = "+" + timezoneStr + "00";
      }
    } else {
      const arr = timezoneStr.split("");
      if (timezoneStr.length > 1) {
        timezoneResult = arr[0] + "0" + arr[1] + "00";
      } else {
        timezoneResult = "+0" + timezoneStr + "00";
      }
    }
    return timezoneResult;
  } catch (error) {
    return "+0800";
  }
}

export const getLang = () =>
  window.navigator.language ||
  (window.navigator as any).userLanguage ||
  "en_US";

export function isIpad() {
  if (
    window.navigator.userAgent &&
    window.navigator.userAgent.toLowerCase().indexOf("ipad") > -1
  ) {
    return "y";
  } else {
    return "n";
  }
}

export function isLength(value: any) {
  return typeof value === "number" && value > -1 && value % 1 === 0;
}

export function isArrayLike(value: any) {
  return value != null && isLength(value.length);
}

export function isEmpty(value: any) {
  if (value === null || value === undefined) {
    return true;
  }
  if (
    (isArrayLike(value) && typeof value === "string") ||
    typeof value.splice === "function" ||
    Array.isArray(value)
  ) {
    return !value.length;
  }
  for (const key in value) {
    if (Object.prototype.hasOwnProperty.call(value, key)) {
      return false;
    }
  }
  return true;
}

export function trimAll(str: string) {
  return str.replace(/\s+/g, "");
}

export function isEmail(str: string) {
  const re = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
  return re.test(str);
}

/**
 * 换取当前的时间戳
 */
export function getNowTimeStamp() {
  return new Date().valueOf();
}

// 时间戳的差值
export const diffTimeStamp = (now: number, old: number) =>
  now > old ? now - old : old - now;

/**
 * 根据时间戳，获取不通格式的时间
 * @param date
 * @param fmt 获取的格式   yyyy-MM-dd hh:mm:ss.S 输出   2020-11-17 14:09:04.33  输入hh:mm:ss 输出14:09:04
 * 输入 yyyy-qq/MM/dd 输出 2020-04/11/17 年季度月日
 */
export function timestampToTime(date: number | Date, fmt: string) {
  const newDate = new Date(date);
  const o: {
    [propName: string]: number;
  } = {
    "M+": newDate.getMonth() + 1, // 月份
    "d+": newDate.getDate(), // 日
    "h+": newDate.getHours(), // 小时
    "m+": newDate.getMinutes(), // 分
    "s+": newDate.getSeconds(), // 秒
    "q+": Math.floor((newDate.getMonth() + 3) / 3), // 季度
    S: newDate.getMilliseconds() // 毫秒
  };
  if (/(y+)/.test(fmt)) {
    fmt = fmt.replace(
      RegExp.$1,
      (newDate.getFullYear() + "").substr(4 - RegExp.$1.length)
    );
  }
  for (const k in o) {
    if (new RegExp("(" + k + ")").test(fmt)) {
      fmt = fmt.replace(
        RegExp.$1,
        RegExp.$1.length === 1
          ? "" + o[k]
          : ("00" + o[k]).substr(("" + o[k]).length)
      );
    }
  }
  return fmt;
}

/**
 * 获取倒计时
 * @param time s
 * @param needHour 是否要小时
 * @param objData
 */
export function timeToHMS(
  time: any,
  objData: boolean = false,
  needHour: boolean = true
) {
  const h =
    Math.floor(time / 3600) < 10
      ? "0" + Math.floor(time / 3600)
      : Math.floor(time / 3600);
  const m =
    Math.floor((time / 60) % 60) < 10
      ? "0" + Math.floor((time / 60) % 60)
      : Math.floor((time / 60) % 60);
  const s =
    Math.floor(time % 60) < 10
      ? "0" + Math.floor(time % 60)
      : Math.floor(time % 60);
  const str = m + ":" + s;
  if (objData) {
    return {
      h,
      m,
      s
    };
  }
  return needHour ? h + ":" + str : str;
}

export function isIos() {
  if (isIosCache !== null) {
    return isIosCache;
  } else {
    const u = window.navigator.userAgent;
    const isHigherVersionPad =
      window.navigator.platform === "MacIntel" &&
      window.navigator.maxTouchPoints > 1;
    const isiOS =
      !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) || isHigherVersionPad; // ios终端
    isIosCache = isiOS;
    return isiOS;
  }
}

export function IosVersion() {
  let version = "";
  if (isIos()) {
    const str = window.navigator.userAgent.toLowerCase();
    const ver: any = str.match(/cpu iphone os (.*?) like mac os/);
    version = ver[1].replace(/_/g, ".");
  }
  return version;
}

export function canUseWebP() {
  if (isIos()) {
    const version = IosVersion();
    if (parseFloat(version) > 14) {
      return true;
    } else {
      return false;
    }
  } else {
    return true;
  }
}

export const checkIsImage = (url: string) => {
  const base64Reg = /^\s*data:([a-z]+\/[a-z0-9-+.]+(;[a-z-]+=[a-z0-9-]+)?)?(;base64)?,([a-z0-9!$&',()*+;=\-._~:@\/?%\s]*?)\s*$/i;
  return url.match(/\.(jpeg|jpg|gif|png)$/) != null || base64Reg.test(url);
};

/*
 * 节流函数
 * func: 需要执行的函数， wait: 频率
 * */
export function throttle(func: any, wait: number) {
  let previous = 0;
  return function(this: any) {
    const now = Date.now();
    const context: any = this;
    const args = arguments;
    if (now - previous > wait) {
      func.apply(context, args);
      previous = now;
    }
  };
}

/*
 * 防抖函数
 * func 函数 delay延时
 * */
export function debounce(func: any, delay: any) {
  let timer: any = null;
  return () => {
    if (timer) {
      clearTimeout(timer);
    }
    timer = setTimeout(func, delay);
  };
}

export const isLongScreen = () => {
  return screen.height / screen.width >= 2;
};

export const deepClone = (source: any, hash = new WeakMap()) => {
  // 如果不是对象，则直接返回
  if (!isObject(source)) {
    return source;
  }
  // 如果hash表中存在，获取hash表中存储过的值
  if (hash.has(source)) {
    return hash.get(source);
  }

  // 过滤数组还是对象
  const target = Array.isArray(source) ? [...source] : { ...source };
  hash.set(source, target);

  // reflect.ownKeys方法取出包含symbol类型的所有的key
  Reflect.ownKeys(target).forEach((key: any) => {
    if (isObject(source[key])) {
      target[key] = deepClone(source[key], hash);
    } else {
      target[key] = source[key];
    }
  });
  return target;
};

export const anyTypeToString = (data: any) => {
  const type = typeof data;
  if (type === "function") {
    return undefined;
  } else if (type === "string") {
    return data;
  } else if (type === "object") {
    return JSON.stringify(data);
  } else {
    return String(data);
  }
};

// 格式化大额数字成 9,000,789 的格式
export const formatToThousands = (num: number) => {
  return (num || 0).toString().replace(/(\d)(?=(?:\d{3})+$)/g, "$1,");
};

export const removeNoSenseKeys = (resource: any) => {
  for (const key in resource) {
    if (resource.hasOwnProperty(key)) {
      resource[key] === undefined || resource[key] === null
        ? delete resource[key]
        : void 0;
    }
  }
  return resource;
};
