import { UPDATE_TIKTOK_USER_INFO } from "@/store/constants/tt_user_types";
// eslint-disable-next-line
import { UpdateTikUserInfo } from "@/store/modules/tt_user_module";
export default {
  [UPDATE_TIKTOK_USER_INFO](state: any, payLoad: UpdateTikUserInfo) {
    const { userInfo, isDelete } = payLoad;
    const user_list = state.tt_user_info_list;
    const findIndex = user_list.findIndex(
      (item: any) => item.user_id === userInfo.user_id
    );
    if (isDelete) {
      user_list.splice(findIndex, 1);
    } else {
      if (findIndex > -1) {
        const nowUser = user_list[findIndex];
        const user_type =
          nowUser.login_type === "login" ? "login" : userInfo.login_type;
        userInfo.login_type = user_type;
        user_list.splice(findIndex, 1, userInfo);
      } else {
        user_list.unshift(userInfo);
      }
    }
    return true;
  }
};
