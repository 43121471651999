




import { Component, Vue, Prop } from "vue-property-decorator";
@Component({
  components: {}
})
export default class LinearBack extends Vue {
  @Prop()
  private height: any;
}
