

























import { Component, Vue, Prop } from "vue-property-decorator";
import AccountFeed from "./FeedType/AccountFeed.vue";
import PostFeed from "./FeedType/PostFeed.vue";
import CoinFeed from "./FeedType/CoinFeed.vue";
import EmptyFeed from "./FeedType/EmptyFeed.vue";
import FeedLimit from "./FeedLimit/FeedLimit.vue";
import TTLoading from "@/components/LoadingAnimation/TTLoading.vue";
import LuckyCoinFeed from "./FeedType/LuckyCoinFeed.vue";
import AdsFeed from "./FeedType/AdFeed.vue";
import Guide from "./Guide/index.vue";
import PoolLoading from "./PoolLoading/PoolLoading.vue";


import {
  FOLLOWER_FEED,
  COIN_FEED,
  POST_FEED_KINDS,
  LUCK_COINST_FEED,
  VIDEO_AD_FEED
} from "@/services/earn_coins/earn_coins_consts/earn_coins_consts";

@Component({
  components: {
    AdsFeed,
    AccountFeed,
    PostFeed,
    CoinFeed,
    EmptyFeed,
    FeedLimit,
    TTLoading,
    LuckyCoinFeed,
    Guide,
    PoolLoading
  }
})
export default class Pool extends Vue {
  @Prop()
  private feed: any;
  @Prop({ type: Boolean })
  private loading: any;
  @Prop()
  private limited: any;
  @Prop()
  private limitedDurationTimestamp: any;
  @Prop({ type: Boolean })
  private showGuide: any;

  private FOLLOWER_FEED: string = FOLLOWER_FEED;
  private COIN_FEED: string = COIN_FEED;
  private POST_FEED_KINDS: string[] = POST_FEED_KINDS;
  private LUCK_COINST_FEED: string = LUCK_COINST_FEED;
  private VIDEO_AD_FEED: string = VIDEO_AD_FEED;

  get feedType() {
    return this.feed && this.feed.kind && this.feed.kind.toLowerCase();
  }

  get feedCoins() {
    return this.feed && this.feed.coins;
  }

  get guideType() {
    return this.feedType === FOLLOWER_FEED ? "Follow" : "Like";
  }

  get showEarnCoinGuide() {
    return (
      this.showGuide &&
      (this.feedType === FOLLOWER_FEED ||
        POST_FEED_KINDS.includes(this.feedType))
    );
  }

  get coinFeedOffer() {
    return this.feed && this.feed.offer;
  }

  get feedResource() {
    return this.feed && this.feed.subresources[0];
  }

  private buyCoinFeed() {
    this.$emit("buyCoinFeed");
  }

  private buyLuckyCoinFeed(lucky_coin_offer: any) {
    this.$emit("buyCoinFeed", lucky_coin_offer);
  }

  private shouldCloseLimit() {
    this.$emit("shouldCloseLimit");
  }

  private clickAds() {
    this.$emit("clickAds");
  }
}
