/**
 * 将任意输入类型转成只包含数字的String
 *
 * @param num
 * @returns {string/undefined}
 */
export const toNumStr = (num: any) => {
  let output: any;
  switch (typeof num) {
    case "number":
      output = `${num}`;
      break;
    case "string":
      const tem = parseInt(num, 10);
      if (!isNaN(tem)) {
        output = `${tem}`;
      }
      break;
  }
  return output;
};

/**
 * 1000+的数字以上转化成1k...等简写
 * @param num
 */
export const simplify = (num: any) => {
  const tem: any = toNumStr(num);
  if (tem === undefined) {
    return tem;
  }
  const len: any = tem.length;
  if (len <= 3) {
    return tem;
  } else if (len >= 7) {
    // 百万的级别，带m
    return tem.slice(0, len - 6) + "." + tem[len - 6] + "m";
  } else {
    return tem.slice(0, len - 3) + "." + tem[len - 3] + "k";
  }
};

/**
 * 格式化数字，每三位添加逗号
 * @param num
 */

export const format = (num: number | string) => {
  try {
    if (num === undefined || num === null) {
      return num;
    }
    let str = num.toString();
    if (str.indexOf(",") > -1) {
      str = str.replace(new RegExp(",", "g"), "");
    }
    const intSum = str
      .substring(0, str.indexOf(".") > -1 ? str.indexOf(".") : str.length)
      .replace(/\B(?=(?:\d{3})+$)/g, ","); // 取到整数部分
    const dot = str.indexOf(".") > -1 ? str.substring(str.indexOf(".")) : ""; // 取到小数部分搜索
    return intSum + dot;
  } catch (e) {
    return num;
  }
};

/**
 * 随机出一个范围内的整数
 *
 * @param max
 * @param min
 */
export const randomNum = (max: number, min: number) => {
  return Math.floor(Math.random() * (max - min + 1) + min);
};
/**
 * 保留两位小数
 * @param x
 * @param y 保留小数的位数
 */
export const toDecimal = (x: any, y: number = 2) => {
  let f = parseFloat(x);
  if (isNaN(f)) {
    return false;
  }
  f = Math.round(x * 100) / 100;
  let s = f.toString();
  let rs = s.indexOf(".");
  if (rs < 0) {
    rs = s.length;
    s += ".";
  }
  while (s.length <= rs + y) {
    s += "0";
  }
  return s;
};

export const uniqueArr = (arr: any[], attr: string) => {
  const res = new Map();
  return arr.filter((v: any) => !res.has(v[attr]) && res.set(v[attr], 1));
};
