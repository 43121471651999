import { GetterTree } from "vuex";
import * as types from "@/store/constants/cg_user_types";
import * as setting_types from "@/store/constants/setting_types";
const Getters: GetterTree<any, any> = {
  [types.GETTER_COINS](state) {
    return state.coins;
  },
  [types.GETTER_PAYPAL_ENABLE](state) {
    return state.paypal_enabled;
  },
  [types.GETTER_CARD_ENABLE](state) {
    return state.stripe_enabled;
  },
  [types.GETTER_SERVER_CURRENT_TIME](state) {
    return state.server_current_time;
  },
  [types.GETTER_XMAIL](state) {
    return state.xmail;
  },
  [types.GETTER_VIP_VALID_COUNT](state, getters) {
    if (state.vip_p_valid_count === null) {
      return getters[setting_types.GETTER_SALE].vip_p_total_count;
    } else {
      return state.vip_p_valid_count;
    }
  },
  [types.GETTER_VIP_VALID_TIME](state) {
    return state.vip_p_valid_time;
  }
};
export default Getters;
