import { CacheModule } from "@/services/cacheModule";
import { APP_FLYER_INFO } from "@/utils/nativeStorage/local_keys";
import listenNative from "@/jsbridge/service/listenNative";
import jsBridge from "@/jsbridge/index";
import { sendAppInfo } from "@/api/cg/service/setting_service";
import { store } from "@/main";
import * as types from "@/store/constants/index_types";
import { getAppPlatform } from "@/utils/js_bridge_info";
import { RECEIVE_APPS_FLYERS_INFO } from "@/jsbridge/service/contants";
import { assembleAppInfo } from "@/api/cg/util/common_util";

export class AppFlyerInfo extends CacheModule {
  constructor() {
    super(APP_FLYER_INFO, null, true);
  }

  // 等待缓存
  private getCacheInfo() {
    return new Promise(async (resolve, reject) => {
      const cacheInfo = await this.init(APP_FLYER_INFO);
      console.log("getCacheInfo", cacheInfo);
      cacheInfo && resolve(cacheInfo);
    });
  }

  // 监听原生主动的触发
  listenNativeReceiveInfo() {
    return new Promise(async (resolve, reject) => {
      listenNative.addListen(RECEIVE_APPS_FLYERS_INFO, (data: any) => {
        console.log("listenNativeReceiveInfo", data);
        data && this.setAppFlyerInfo(data);
        data && resolve(data);
      });
    });
  }
  // 主动向原生请求
  async getAppFlyerInfoFromNative() {
    return new Promise(async (resolve, reject) => {
      try {
        const result = await jsBridge.getAppFlyerInfo();
        console.log("getAppFlyerInfoFromNative", result);
        result && this.setAppFlyerInfo(result);
        result && resolve(result);
      } catch (error) {
        // 原生可能拿不到af_info,会直接返回错误，不抛错
      }
    });
  }

  public waitAppFlyerInfo() {
    return new Promise(async (resolve, reject) => {
      // 如果缓存里面有，直接用缓存。否则尝试去监听原生的方法以及主动去获取
      Promise.race([
        this.getCacheInfo(),
        this.listenNativeReceiveInfo(),
        this.getAppFlyerInfoFromNative()
      ]).then(result => {
        resolve(result);
      });
    });
  }

  private setAppFlyerInfo(params: any) {
    this.setCache(params);
    const master_id = store.getters[types.GETTER_MASTER_USER_ID];
    const platform = getAppPlatform();
    sendAppInfo(master_id, platform, assembleAppInfo()).catch(() => void 0);
  }
}

export default new AppFlyerInfo();
